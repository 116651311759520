import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import Chip from "@mui/material/Chip";
import { Grid } from "@mui/material";

import { useQuery } from "@apollo/client";
import { GET_CUSTOMER_BY_ID } from "services/customer-service";
import { dashboardServiceClient } from "graphql/client";
import Loader from "components/MDLoader";
import dayjs from "dayjs";

const columns = [
  { field: "date", headerName: "Date", width: 130, valueGetter: (params) => params?.row?.date ? dayjs(params?.row?.date).format("DD-MM-YYYY") : "N/A" },
  { field: "mobileSource", headerName: "Mobile Source", width: 130 },
  { field: "txn", headerName: "Txn", width: 130 },
  { field: "tillReceipt", headerName: "Till Receipt", width: 130 },
  { field: "storeCode", headerName: "Store Code", width: 130 },
  // { field: "city", headerName: "City", width: 130 },
  // { field: "txnType", headerName: "Txn Type", width: 130 },
  // { field: "txnSubType", headerName: "Txn Sub Type", width: 130 },
  {
    field: "status",
    headerName: "Status",
    width: 130,
    renderCell: (params) => (
      <Chip
        color={params.value === "Active" ? "success" : "error"}
        label={params.value}
        variant="outlined"
      />
    ),
  },
  { field: "amount", headerName: "Amount", width: 130 },
  // { field: "balance", headerName: "Balance", width: 130 },
  // { field: "campaign", headerName: "Campaign", width: 130 },
];

function CustomerTransactionForm({ customerData }) {
  console.log(customerData);
  const { loading, error, data } = useQuery(GET_CUSTOMER_BY_ID, {
    client: dashboardServiceClient,
    variables: { id: customerData?.id },
  });
  if (loading)
    return (
      <>
        <Loader />
      </>
    );
  if (error) return <>Error ...</>;
  console.log(data?.customer?.account?.transaction);

  const rowData =
    data?.customer?.account?.transaction?.map((txn) => ({
      id: txn?.id,
      date: txn?.txnDate,
      mobileSource: txn?.source,
      txn: txn?.txnId,
      tillReceipt: txn?.receiptNo,
      storeCode: txn?.storeCode,
      // city: `City${id + 1}`,
      // txnType: id % 2 === 0 ? "Type1" : "Type2",
      // txnSubType: id % 2 === 0 ? "SubType1" : "SubType2",
      status: txn?.txnStatus,
      amount: txn?.amount,
      // balance: `$${(id + 1) * 20}`,
      // campaign: `Campaign${(id % 3) + 1}`,
    })) || [];

  return (
    <Grid>
      <Grid item xs={12} style={{ backgroundColor: "white" }}>
        <DataGrid
          autoHeight
          rows={rowData}
          columns={columns}
          pageSize={5}
          pageSizeOptions={[5, 10]}
        />
      </Grid>
    </Grid>
  );
}

export default CustomerTransactionForm;
