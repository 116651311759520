import React, { useEffect, useState, useContext, createContext, useMemo, useRef } from "react";

// @mui material components
import {
    Grid,
    Box,
    FormControl,
    Select,
    InputLabel,
    MenuItem,
    Autocomplete,
    TextField,
} from "@mui/material";
import DashboardLayout from "ui/LayoutContainers/DashboardLayout";
import DashboardNavbar from "ui/Navbars/DashboardNavbar";
import { dashboardServiceClient } from "graphql/client";
import { useMutation, useQuery } from "@apollo/client";
import { GET_STORES_LIST } from "services/store-service";
import { BRANDS_LIST } from "services/brand-service";
import { ReactComponent as ArrowDropDownIcon } from "assets/images/Arrow-Down.svg";
import { GridExpandMoreIcon } from '@mui/x-data-grid'
import { SearchContext, FilterContext } from "context";
import { Add as AddIcon } from "@mui/icons-material";
import FixtureMasterTabs from "./fixtureTabs";
import MDButton from "components/MDButton";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { GET_STORES_LIST_NEW } from "services/store-service";
import select from "assets/theme/components/form/select";
import useStoreSelection from "../device-Master/storeSelectionHook";

const user =
    localStorage.getItem("userDetails") &&
        localStorage.getItem("userDetails") !== "undefined"
        ? JSON.parse(localStorage.getItem("userDetails"))
        : {};
export const DropDownSelectionContext = createContext();
export const GridContext = createContext();
export const useGridContext = () => useContext(GridContext);

function FixtureMaster() {
    const gridRef = useRef();
    const [tabValue, setTabValue] = useState(0);
    const [open, setOpen] = useState(false);
    const [createClick, setCreateClick] = useState(false)
    const {
        selectedStore,
        setSelectedStore,
        locations,
        selectedLocation,
        setSelectedLocation,
        subLocations,
        selectedSubLocation,
        setSelectedSubLocation,
        assetTypeBatchCodes,
        selectedAssetTypeBatchCode,
        setSelectedAssetTypeBatchCode,
        assetTypeCodes,
        selectedAssetTypeCode,
        setSelectedAssetTypeCode,
        storeLoading,
        storeError,
        storeList,
    } = useStoreSelection();
    const context = useContext(DropDownSelectionContext);
    const dropDownSelections = useMemo(() => ({ selectedStore, selectedLocation, selectedSubLocation, selectedAssetTypeBatchCode, selectedAssetTypeCode }), [selectedStore, selectedLocation, selectedSubLocation, selectedAssetTypeBatchCode, selectedAssetTypeCode]);

    // Store List
    
    const ControlSection = () => (
        <>
            <Grid item xs={12} sm={6} md={6} />
            <Grid item xs={12} sm={6} md={6} textAlign="right">
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        gap: 4,
                    }}
                >
                    {tabValue === 0 ? <>
                    </> :
                        <>
                            <MDButton
                                type="button"
                                variant="outlined"
                                color='dark'
                                circular={true}
                                onClick={() => {
                                    setOpen(true);
                                }}
                                startIcon={<FileUploadIcon />}
                            >
                                Upload Excel
                            </MDButton>
                            <MDButton
                                type="button"
                                variant="outlined"
                                color='dark'
                                circular={true}
                                onClick={() => {
                                    setOpen(true);
                                }}
                                startIcon={<FileDownloadIcon />}
                            >
                                Template
                            </MDButton>
                        </>
                    }
                </Box>
            </Grid>
        </>
    )

    const AutoCompleteDropDown = ({ label, id, onChange, menuItems, value, optionLabelKey=null }) => {
        return (
          <Box mr={1} width={200}>
            <Autocomplete
            onChange={onChange}
              disablePortal
              id={id}
              value={value}
              options={menuItems}
              renderOption={(props, option)=>{
                if(optionLabelKey && (option.value?.[optionLabelKey] === value))
                    return <Box sx={{background:'#F0F2F5'}} {...props} value={option}>{option.label}</Box>
                if (option.value == value)
                    return <Box sx={{background:'#F0F2F5'}} {...props} value={option}>{option.label}</Box>
                
                return <Box {...props} value={option}>{option.label}</Box>
              }}
              sx={{ "& .MuiInputBase-root":{height: 40, borderRadius: "20px", px: 2, backgroundColor: "white.main"} }}
            renderInput={(params) => <TextField {...params} label={label} />}
            />
          </Box>
        );
      };
    const StoreDropdown = (store) => {
        return (
            <Grid item xs={2}>
                <FormControl fullWidth>
                    <InputLabel>Select Store</InputLabel>
                    <Select
                        as={Select}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="store"
                        label=" Select Store"
                        IconComponent={() => (
                            <GridExpandMoreIcon fontSize="medium" />
                        )}
                        sx={{ height: 40, borderRadius: "100px" }}
                        value={selectedStoreId || []}
                        onChange={(e) => {
                            setSelectedStoreId(e.target.value);
                        }}
                    >
                        {storeList?.stores?.results
                            ?.filter((item) => item.status === "Upcoming")
                            .map((item) => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.name}
                                </MenuItem>
                            ))}
                    </Select>
                </FormControl>
            </Grid>
        );
    };
    const SectionDropdown = (section) => {
        return (
            <Grid item xs={2}>
                <FormControl fullWidth>
                    <InputLabel>Select Section</InputLabel>
                    <Select
                        as={Select}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="section"
                        label="Select Section"
                        IconComponent={() => (
                            <GridExpandMoreIcon fontSize="medium" />
                        )}
                        sx={{ height: 40, borderRadius: "100px" }}
                        value={selectedSection || []}
                        onChange={(e) => {
                            setSelectedSection(e.target.value);
                        }}
                    >
                        <MenuItem key="section1" value="section1">
                            Section 1
                        </MenuItem>
                    </Select>
                </FormControl>
            </Grid>
        );
    };
    const TypeDropdown = (section) => {
        return (
            <Grid item xs={2}>
                <FormControl fullWidth>
                    <InputLabel>Select Type</InputLabel>
                    <Select
                        as={Select}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="type"
                        label="Select Type"
                        IconComponent={() => (
                            <GridExpandMoreIcon fontSize="medium" />
                        )}
                        sx={{ height: 40, borderRadius: "100px" }}
                        value={selectedType || []}
                        onChange={(e) => {
                            setSelectedType(e.target.value);
                        }}
                    >
                        <MenuItem key="Type1" value="Type1">
                            Type 1
                        </MenuItem>
                    </Select>
                </FormControl>
            </Grid>
        );
    };
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <GridContext.Provider value={{externalGridRef: gridRef}}>
                <Grid container spacing={2} display="flex">
                    <Grid item xs={9} display="flex" gap={5}>
                        {tabValue === 0 && <>
                            {/* <StoreDropdown />
                            <SectionDropdown />
                            <TypeDropdown /> */}
                            <AutoCompleteDropDown
                                optionLabelKey="name"
                                label="Select Store"
                                id="demo-simple-select-store"
                                menuItems={storeList?.stores?.results
                                ?.filter((item) => item.status === "Active")
                                .map((item) => (
                                    {label:item.name, value: item}
                                )) || []}
                                value={selectedStore?.name}
                                onChange={(e,selectedOption) => {
                                setSelectedStore(selectedOption?.value)
                            }} />
                            <AutoCompleteDropDown
                                optionLabelKey="locationName"
                                label="Select Location"
                                id="demo-simple-select-location"
                                menuItems={locations
                                ?.map((item) => (
                                    {label:item.locationName||'No Name', value: item}
                                ))}
                                value={selectedLocation? selectedLocation?.locationName|| 'No Name':undefined}
                                onChange={(e,selectedOption) => {
                                setSelectedLocation(selectedOption?.value)
                            }} />
                            <AutoCompleteDropDown
                                optionLabelKey="name"
                                label="Select Sub Location"
                                id="demo-simple-select-sublocation"
                                menuItems={subLocations
                                ?.map((item) => (
                                    {label:item.name||'No Name', value: item}
                                ))}
                                value={selectedSubLocation? selectedSubLocation?.name || 'No Name' : undefined}
                                onChange={(e,selectedOption) => {
                                setSelectedSubLocation(selectedOption?.value)
                            }} />
                            <AutoCompleteDropDown
                                label="Select Asset Type Batch Code"
                                id="demo-simple-select-sublocationtype"
                                menuItems={assetTypeBatchCodes}
                                value={selectedAssetTypeBatchCode}
                                onChange={(e,selectedOption) => {
                                setSelectedAssetTypeBatchCode(selectedOption?.value)
                            }} />
                            <AutoCompleteDropDown
                                label="Select Asset Type Code"
                                id="demo-simple-select-sublocationtype"
                                menuItems={assetTypeCodes}
                                value={selectedAssetTypeCode}
                                onChange={(e,selectedOption) => {
                                setSelectedAssetTypeCode(selectedOption?.value)
                            }} />
                        </>}
                    </Grid>
                    <Grid item xs={3} display="flex">
                        <ControlSection />
                    </Grid>
                    <Box
                        sx={{
                            width: "100%",
                            minHeight: "auto",
                            backgroundColor: "#FFFFFF",
                            borderRadius: "10px",
                            mt: 4,
                        }}
                    >
                        <DropDownSelectionContext.Provider value={dropDownSelections}>
                            <FixtureMasterTabs
                                tabValue={tabValue}
                                setTabValue={setTabValue}
                                user={user}
                                open={open}
                                setOpen={setOpen}
                                createClick={createClick}
                                setCreateClick={setCreateClick}
                            />
                        </DropDownSelectionContext.Provider>
                    </Box>
                </Grid>
            </GridContext.Provider>
        </DashboardLayout>
    );
}

export default FixtureMaster;
