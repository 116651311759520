import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  MenuItem,
  Typography,
  IconButton,
  Select,
  FormControl,
  InputLabel
} from "@mui/material";
import FileUploader from "components/MDFileUpload";
import { ReactComponent as ArrowDropDownIcon } from "assets/images/Arrow-Down.svg";
import { dashboardServiceClient } from "graphql/client";
import { useLazyQuery, useQuery, gql, useMutation } from "@apollo/client";
import { Save as SaveIcon, Delete as DeleteIcon, CloudUpload as UploadIcon, Edit as EditIcon, CheckCircle as CheckCircleIcon } from '@mui/icons-material';
import { GET_BRAND_DETAILS, CREATE_BRAND_METADATA, UPDATE_BRAND_METADATA } from "services/brand-service";
import { useNotification } from 'context';
import Loader from "components/MDLoader";


const validationSchema = Yup.object({
  bankName: Yup.string().required('Bank Name is required'),
  bankAccountNo: Yup.string()
    .matches(/^[0-9]{9,18}$/, 'Bank Account No. must be 9-18 digits')
    .required('Bank Account No. is required'),
  bankIFSCCode: Yup.string()
    .matches(/^[A-Z]{4}0[A-Z0-9]{6}$/, 'Invalid IFSC Code')
    .required('Bank IFSC Code is required'),
  bankAddress: Yup.string().required('Bank Address is required'),
  branch: Yup.string().required('Branch is required'),
  city: Yup.string().required('City is required'),
  state: Yup.string().required('State is required'),
  bankAccountType: Yup.string().required('Bank Account Type is required'),
  micrNo: Yup.string()
    .matches(/^[0-9]{9}$/, 'MICR No. must be 9 digits')
    .required('MICR No. is required'),
  cancelledChequePath: Yup.string().required('Cancelled Cheque is required'),
  cancelledChequeUrl: Yup.string().required('Cancelled Cheque is required'),

});

const useMetadataMutation = (
  brandData,
  brandMetadata,
  setNotification,
  handleTabChange,
  setBrandData
) => {
  const mutationOptions = {
    client: dashboardServiceClient,
    refetchQueries: [
      {
        query: GET_BRAND_DETAILS,
        variables: { username: brandData?.username || "" },
      },
    ],
  };

  const [createBrandMetadataMutation] = useMutation(
    CREATE_BRAND_METADATA,
    mutationOptions
  );
  const [updateBrandMetadataMutation] = useMutation(
    UPDATE_BRAND_METADATA,
    mutationOptions
  );

  const handleSubmit = (values) => {
    // console.log("brandMetadata.id ==== ", brandMetadata);
    const mutation =
      brandMetadata && brandMetadata.id
        ? updateBrandMetadataMutation
        : createBrandMetadataMutation;
    const mutationInput =
      brandMetadata && brandMetadata.id
        ? {
          metaDataInput: {
            id: brandMetadata.id,
            key: "BankDetails",
            value: { ...values },
          },
        }
        : {
          metaDataInput: {
            brandId: brandData.id,
            key: "BankDetails",
            value: { ...values },
          },
        };

    mutation({ variables: mutationInput })
      .then((res) => {
        setBrandData(brandData);
        handleTabChange(null, 4);
        setNotification({
          color: "success",
          isVisible: true,
          message:
            brandMetadata && brandMetadata.id
              ? "Bank Details data updated successfully"
              : "Bank Details data created successfully",
        });
      })
      .catch((error) => {
        console.error(" Error:", error.message);
        setNotification({
          color: "error",
          isVisible: true,
          message: error.message || "Something went wrong",
        });
      });
  };

  return handleSubmit;
};

const BankDetailsForm = ({ brandData, handleTabChange, setBrandData, user }) => {
  const { setNotification } = useNotification();

  const bankDetailsData =
    brandData?.brandMetaData.find((data) => data.key === "BankDetails") || {};

  const handleSubmit = useMetadataMutation(
    brandData,
    bankDetailsData,
    setNotification,
    handleTabChange,
    setBrandData
  );
  return (
    <Formik
      initialValues={{
        bankName: bankDetailsData?.value?.bankName || "",
        bankAccountNo: bankDetailsData?.value?.bankAccountNo || "",
        bankIFSCCode: bankDetailsData?.value?.bankIFSCCode || "",
        bankAddress: bankDetailsData?.value?.bankAddress || "",
        branch: bankDetailsData?.value?.branch || "",
        city: bankDetailsData?.value?.city || "",
        state: bankDetailsData?.value?.state || "",
        bankAccountType: bankDetailsData?.value?.bankAccountType || "",
        micrNo: bankDetailsData?.value?.micrNo || "",
        cancelledChequePath: bankDetailsData?.value?.cancelledChequePath || bankDetailsData?.value?.cancelledCheque || "",
        cancelledChequeUrl: bankDetailsData?.value?.cancelledChequeUrl || "",

      }}
      validationSchema={validationSchema}
      onSubmit={(values) => handleSubmit(values)}
    >
      {({ setFieldValue, values, errors, touched, handleSubmit, resetForm }) => (
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12} mt={2}>
              <Field
                as={TextField}
                name="bankName"
                label="Bank Name"
                fullWidth
                error={touched.bankName && Boolean(errors.bankName)}
                helperText={touched.bankName && errors.bankName}
                inputProps={{
                  style: { fontFamily: 'Montserrat' },
                }}
                InputLabelProps={{
                  style: { fontFamily: 'Montserrat' },
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <Field
                as={TextField}
                name="bankAccountNo"
                label="Bank Account No."
                fullWidth
                error={touched.bankAccountNo && Boolean(errors.bankAccountNo)}
                helperText={touched.bankAccountNo && errors.bankAccountNo}
                inputProps={{
                  style: { fontFamily: 'Montserrat' },
                }}
                InputLabelProps={{
                  style: { fontFamily: 'Montserrat' },
                }}
              />

            </Grid>

            <Grid item xs={12}>
              <Field
                as={TextField}
                name="bankIFSCCode"
                label="Bank IFSC Code"
                fullWidth
                error={touched.bankIFSCCode && Boolean(errors.bankIFSCCode)}
                helperText={touched.bankIFSCCode && errors.bankIFSCCode}
                inputProps={{
                  style: { fontFamily: 'Montserrat' },
                }}
                InputLabelProps={{
                  style: { fontFamily: 'Montserrat' },
                }}
              />

            </Grid>

            <Grid item xs={12}>
              <Field
                as={TextField}
                name="bankAddress"
                label="Bank Address"
                fullWidth
                error={touched.bankAddress && Boolean(errors.bankAddress)}
                helperText={touched.bankAddress && errors.bankAddress}
                inputProps={{
                  style: { fontFamily: 'Montserrat' },
                }}
                InputLabelProps={{
                  style: { fontFamily: 'Montserrat' },
                }}
              />

            </Grid>

            <Grid item xs={12}>
              <Field
                as={TextField}
                name="branch"
                label="Branch"
                fullWidth
                error={touched.branch && Boolean(errors.branch)}
                helperText={touched.branch && errors.branch}
                inputProps={{
                  style: { fontFamily: 'Montserrat' },
                }}
                InputLabelProps={{
                  style: { fontFamily: 'Montserrat' },
                }}
              />

            </Grid>

            <Grid item xs={6}>
              <Field
                as={TextField}
                name="city"
                label="City"
                fullWidth
                error={touched.city && Boolean(errors.city)}
                helperText={touched.city && errors.city}
                inputProps={{
                  style: { fontFamily: 'Montserrat' },
                }}
                InputLabelProps={{
                  style: { fontFamily: 'Montserrat' },
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <Field
                as={TextField}
                name="state"
                label="State"
                fullWidth
                error={touched.state && Boolean(errors.state)}
                helperText={touched.state && errors.state}
                inputProps={{
                  style: { fontFamily: 'Montserrat' },
                }}
                InputLabelProps={{
                  style: { fontFamily: 'Montserrat' },
                }}
              />

            </Grid>

            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel id="bankAccountType" sx={{ fontFamily: "Montserrat" }}>Bank Account Type</InputLabel>
                <Field
                  as={Select}
                  select
                  name="bankAccountType"
                  label="Bank Account Type"
                  fullWidth
                  IconComponent={() => (
                    <ArrowDropDownIcon style={{ marginRight: "18px" }} />
                  )}
                  sx={{ height: "40px", fontFamily: "Montserrat" }}

                  error={touched.bankAccountType && !!errors.bankAccountType}
                  helperText={touched.bankAccountType ? errors.bankAccountType : ""}
                >
                  <MenuItem value="Current Account" sx={{ fontFamily: "Montserrat" }}>Current Account</MenuItem>
                  <MenuItem value="Savings Account" sx={{ fontFamily: "Montserrat" }}>Savings Account</MenuItem>
                </Field>
                {touched?.bankAccountType && errors?.bankAccountType && (
                  <Typography fontSize={12} color="error">
                    {errors?.bankAccountType}
                  </Typography>
                )}
              </FormControl>

            </Grid>

            <Grid item xs={6}>
              <Field
                as={TextField}
                name="micrNo"
                label="MICR No."
                fullWidth
                error={touched.micrNo && Boolean(errors.micrNo)}
                helperText={touched.micrNo && errors.micrNo}
                inputProps={{
                  style: { fontFamily: 'Montserrat' },
                }}
                InputLabelProps={{
                  style: { fontFamily: 'Montserrat' },
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography>
                <span style={{ fontWeight: 'bold', color: "#000", fontFamily: "Montserrat", fontSize: "15px" }}>Cancelled Cheque</span>
                <span style={{ color: '#9E9E9E', fontSize: '12px', fontFamily: "Montserrat" }}> Please upload JPEG, PNG or PDF files only.</span>
              </Typography>
              <FileUploader
                hasDisplayName
                isMultiple={false}
                isImage={true}
                isPdf={true}
                fieldName={"Cancel Cheque Upload"}
                preLoadedFile={
                  !!values.cancelledChequeUrl
                ? [{fileName:values.cancelledChequeUrl, displayName:values.cancelledChequePath}]
                : null
                }
                signedUrlCallback={(newUrl, index, publicUrl) => {
                  setFieldValue("cancelledChequePath", newUrl);
                  setFieldValue("cancelledChequeUrl", publicUrl);
                }}
                isFieldValid={touched?.cancelledChequeUrl && errors?.cancelledChequeUrl}
              />
              {touched.cancelledChequeUrl && errors.cancelledChequeUrl && (
                <Typography fontSize={12} color="error">
                  {errors.cancelledChequeUrl}
                </Typography>
              )}
            </Grid>

            <Grid item xs={12}>
              <Grid container sx={{ mt: 3 }} justifyContent={"flex-end"} gap={3}>
                <Button
                  sx={{ color: "#000000", borderRadius: "50px", borderColor: "#000000", fontFamily: "Montserrat" }}
                  variant="outlined"
                  onClick={() => resetForm({
                    values: {
                      bankName: "",
                      bankAccountNo: "",
                      bankIFSCCode: "",
                      bankAddress: "",
                      branch: "",
                      city: "",
                      state: "",
                      bankAccountType: "",
                      micrNo: "",
                      cancelledChequePath: "",
                      cancelledChequeUrl: ""
                      
                    },
                  })}
                >
                  Reset
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  style={{
                    marginRight: "10px",
                    backgroundColor: "#000000",
                    color: "#fff",
                    borderRadius: "50px",
                    fontFamily: "Montserrat"
                  }}
                >
                  Save
                </Button>
              </Grid>

            </Grid>

          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default BankDetailsForm;
