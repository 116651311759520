import { Drawer, Grid, Icon, Typography } from "@mui/material";
import { AvailableForms } from "../productTable/tableData";
import AddBulkProduct from "./AddBulkProduct";
import AddSingleProduct from "./addSingleProduct";
import addSingleProductIcon from "../../../assets/images/add-single-product.svg";
import AddSuperAdminBulkProduct from "./addSuperAdminBulkProduct/AddSuperAdminBulkProduct";

export default function AddProductForms({ form, setForm }) {
  return (
    <Drawer
      anchor="right"
      open={form}
      onClose={() => {
        setForm(false);
      }}
      PaperProps={{
        style: {
          width: "50%",
          height: "100vh",
          margin: "0px",
          borderRadius: "0px",
        },
      }}
    >
      <Grid
        container
        sx={{
          backgroundColor: "white",
          pb: 2,
          pt: 5,
          pl: "10px",
          pr: "10px",
        }}
      >
        <Grid item xs={12} display={"flex"} alignItems={"center"} pl={"20px"}>
          <Icon
            component={() => <img src={addSingleProductIcon} />}
            fontSize="large"
          />
          <Typography
            variant="h5"
            sx={{ display: "inline", marginLeft: "10px" }}
          >
            {form === AvailableForms.singleProduct
              ? "Add Single Product"
              : form === AvailableForms.bulkProduct
              ? "Add Bulk Product"
              : "Add Super Admin Bulk Product"}
          </Typography>
        </Grid>
      </Grid>

      {form === AvailableForms.singleProduct ? (
        <AddSingleProduct setForm={setForm} />
      ) : form === AvailableForms.bulkProduct ? (
        <AddBulkProduct setForm={setForm} />
      ) : (
        <AddSuperAdminBulkProduct setForm={setForm} />
      )}
    </Drawer>
  );
}
