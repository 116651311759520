// import {
//   Accordion,
//   AccordionSummary,
//   AccordionDetails,
//   Typography,
//   Button,
//   Grid,
//   TextField,
//   Switch,
//   FormControlLabel,
//   MenuItem,
//   FormControl,
//   Select,
//   InputLabel,
//   Box,
//   IconButton,
//   Icon,
// } from "@mui/material";
// import React from "react";
// import FileUploaderContainer from "./playList";
// import { GridExpandMoreIcon } from "@mui/x-data-grid";
// import { Formik, Form, Field, FieldArray } from "formik";
// import MDButton from "components/MDButton";
// import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { ReactComponent as previewIcon } from "assets/images/previewIcon.svg";
// import { ReactComponent as commentIcon } from "assets/images/commentIcon.svg";
// import { ReactComponent as downloadIcon } from "assets/images/downloadIcon.svg";
// import { ReactComponent as deleteIcon } from "assets/images/deleteIcon.svg";
// import dayjs from "dayjs";
// import duration from "dayjs/plugin/duration";
// import { useQuery, useMutation } from "@apollo/client";
// import {
//   LIST_ALL_PLAYLIST,
//   CREATE_VIDEO_PLAYLIST,
//   LIST_VIDEO_PLAYLIST,
// } from "services/advertisment-service";
// import tab from "assets/theme/components/tabs/tab";
// import { vmServiceClient } from "graphql/client";
// dayjs.extend(duration);

// function AdsDeviceMap({ user, tabValue, device, selectedBrandId, Brand }) {
//   const [isSubmitting, setIsSubmitting] = React.useState(false);
//   const initialPlaylists = device?.devicePlaylists?.map((item) => ({
//     name: item.code,
//     videosCount: item?.playlistVideos.length || 0,
//     startTime: item?.startDateTime || null,
//     endTime: item?.endDateTime || null,
//     duration: sumDurations(item?.playlistVideos?.map((i) => i.duration) || []),
//     status: item?.status,
//     playlistId: item?.uuid,
//   })) || [
//     {
//       name: "",
//       videosCount: 0,
//       startTime: null,
//       endTime: null,
//       duration: "00:00:00",
//       status: null,
//     },
//   ];

//   const { loading, error, data, refetch } = useQuery(LIST_ALL_PLAYLIST, {
//     client: vmServiceClient,
//     variables: {
//       page: 1,
//       limit: 10,
//       sortBy: "uuid",
//       order: "DESC",
//       relations: ["playlistVideos"],
//     },
//     fetchPolicy: "network-only",
//     notifyOnNetworkStatusChange: true,
//     onCompleted: (data) => {
//       setRowCount(data?.brandSpaceReadiness?.total);
//     },
//   });

//   console.log("DATA ==== ", data);
//   const dummyPlaylists = data?.findAllDevicePlaylists?.results.map((item) => ({
//     name: item.code,
//     videosCount: item.playlistVideos.length,
//     startTime: item?.startDateTime,
//     endTime: item?.endDateTime,
//     duration: sumDurations(
//       item.startDateTime || null,
//       item.endDateTime || null
//     ),
//     status: item?.status,
//     playlistId: item?.uuid,
//   })) || [
//     {
//       name: "Chill Vibes",
//       videosCount: 8,
//       startTime: null,
//       endTime: null,
//       duration: "20 Mins",
//       status: "Pending",
//       playlistId: [],
//     },
//   ];
//   const ShowLabel = ({ title }) => <p> ({title})</p>;

//   const [createVideoMutation] = useMutation(CREATE_VIDEO_PLAYLIST, {
//     client: vmServiceClient,
//     refetchQueries: [
//       {
//         query: LIST_VIDEO_PLAYLIST,
//         variables: {
//           page: 1,
//           limit: 100,
//           sortBy: "updatedAt",
//           order: "DESC",
//           relations: [
//             "playlist",
//             "playlist.devices",
//             "playlist.devices.deviceLocation",
//           ],
//           searchCols: ["brandId"],
//           searchBy: user && user?.role !== "admin" ? [selectedBrandId] : [],
//         },
//       },
//     ],
//   });
//   function sumDurations(timesArray) {
//     if (!Array.isArray(timesArray)) {
//       console.error("Invalid input: timesArray is not an array", timesArray);
//       return "0s"; // Return a default or error value
//     }

//     // Convert the input to a string if it's not already, and make sure it's a string representation of time
//     const toSeconds = (timeStr) => {
//       timeStr = String(timeStr);
//       if (timeStr.includes("s")) {
//         return parseFloat(timeStr);
//       } else if (timeStr.includes(":")) {
//         const [hours, minutes, seconds] = timeStr.split(":").map(parseFloat);
//         return hours * 3600 + minutes * 60 + seconds;
//       }
//       return parseFloat(timeStr);
//     };

//     // Convert all time strings to seconds and sum them
//     const totalSeconds = timesArray.reduce((sum, time) => {
//       if (typeof time === "string" || typeof time === "number") {
//         return sum + toSeconds(time);
//       } else {
//         console.error("Invalid time value:", time);
//         return sum; // Ignore invalid input and continue accumulating
//       }
//     }, 0);

//     // Return the total in seconds with one decimal place
//     return `${totalSeconds.toFixed(1)}s`;
//   }

//   const handleSubmit = ({
//     path,
//     name,
//     publicurl,
//     videoMetadata,
//     playlistId,
//   }) => {
//     if (isSubmitting) return; // Prevent multiple submissions
//     setIsSubmitting(true);
//     console.log("DEVICE PLAYLIST ID ==== ", name, playlistId);
//     const payload = {
//       brandId: selectedBrandId,
//       name: name,
//       duration: String(videoMetadata?.duration), // Ensure this is a string. Example: "00:08:19"
//       aspectRatio: videoMetadata.aspectRatio, // Provide a default value if null
//       orientation: videoMetadata.orientation.toUpperCase(), // Ensure correct enum value
//       size: String(videoMetadata.size), // Convert to string if it's numeric
//       path: path,
//       publicUrl: publicurl,
//       status: "PENDING",
//       //   playlistIds: tab === 2 ? [playlistId] : [],
//       playlistIds: playlistId ? [playlistId] : [],
//     };
//     // return;
//     createVideoMutation({
//       variables: { input: payload },
//       onCompleted: () => {
//         setIsSubmitting(false);
//         setResetUploader(true); // Set to true to reset the uploader
//         setTimeout(() => setResetUploader(false), 0); // Optional: reset the state immediately

//         refetch({
//           variables: {
//             page: 1,
//             limit: 100,
//             sortBy: "updatedAt",
//             order: "DESC",
//             relations: [
//               "playlist",
//               "playlist.devices",
//               "playlist.devices.deviceLocation",
//             ],
//             searchCols: ["brandId"],
//             searchBy: user && user?.role !== "admin" ? [selectedBrandId] : [],
//           },
//         });
//       },
//       onError: () => setIsSubmitting(false),
//     });
//   };

//   console.log("ADS DEVICE Dummy Play List ==== ", dummyPlaylists);
//   console.log("initialPlaylists ==== ", initialPlaylists)
//   return (
//     <Formik
//       initialValues={{ playlists: initialPlaylists }}
//       onSubmit={(values) => {
//         console.log("values  ADS ==== ", values);
//         // handleSubmit(values);
//       }}
//     >
//       {({ values, handleChange, setFieldValue }) => (
//         <Form style={{ marginLeft: "20px" }}>
//           <Grid item xs={12} mb={3} ml={1}>
//             <Grid container>
//               <Grid item xs={2}>
//                 {" "}
//                 <Typography variant="h6">Name </Typography>{" "}
//               </Grid>
//               <Grid item xs={1}>
//                 {" "}
//                 <Typography variant="h6">Videos </Typography>{" "}
//               </Grid>
//               <Grid item xs={2}>
//                 {" "}
//                 <Typography variant="h6">From Time </Typography>{" "}
//               </Grid>
//               <Grid item xs={2}>
//                 {" "}
//                 <Typography variant="h6">To Time </Typography>{" "}
//               </Grid>
//               <Grid item xs={1}>
//                 {" "}
//                 <Typography variant="h6">Duration </Typography>{" "}
//               </Grid>
//               <Grid item xs={2}>
//                 {" "}
//                 <Typography variant="h6">Status </Typography>{" "}
//               </Grid>
//               <Grid item xs={2}>
//                 {" "}
//                 <Typography variant="h6">Action </Typography>{" "}
//               </Grid>
//             </Grid>
//           </Grid>
//           <FieldArray
//             name="playlists"
//             render={(arrayHelpers) => (
//               <Grid container spacing={2}>
//                 {console.log("VALUES for map ==== ", values)}
//                 {values.playlists.map((playlist, index) => (
//                   <Grid container>
//                     <Grid item xs={12}>
//                       <Accordion style={{ marginBottom: "20px" }}>
//                         <AccordionSummary
//                           expandIcon={
//                             <GridExpandMoreIcon
//                               fontSize="medium"
//                               sx={{ color: "#000000" }}
//                             />
//                           }
//                           style={{ flexDirection: "row-reverse" }}
//                         >
//                           <Grid item xs={2} mr={1} pr={2}>
//                             <TextField
//                               fullWidth
//                               variant="outlined"
//                               value={playlist.name}
//                               onChange={handleChange(`playlists.${index}.name`)}
//                             />
//                           </Grid>
//                           <Grid item xs={1}>
//                             {" "}
//                             <Typography variant="h6">
//                               {playlist.videosCount}
//                             </Typography>{" "}
//                           </Grid>
//                           <Grid item xs={2} mr={1}>
//                             {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
//                                                             <DatePicker
//                                                                 value={playlist[index].startTime}
//                                                                 onChange={value => setFieldValue(`playlists[${index}].startTime`, value)}
//                                                                 renderInput={(params) => <TextField {...params} />}
//                                                             />
//                                                         </LocalizationProvider> */}
//                             {dayjs(playlist.startTime).format(
//                               "YYYY-MM-DD HH:mm:ss"
//                             )}
//                           </Grid>
//                           <Grid item xs={2} mr={1}>
//                             {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
//                                                             <DatePicker
//                                                                 value={playlist[index].endTime}
//                                                                 onChange={value => setFieldValue(`playlists[${index}].endTime`, value)}
//                                                                 renderInput={(params) => <TextField {...params} />}
//                                                             />
//                                                         </LocalizationProvider> */}
//                             {dayjs(playlist.endTime).format(
//                               "YYYY-MM-DD HH:mm:ss"
//                             )}
//                           </Grid>
//                           <Grid item xs={1}>
//                             <Typography variant="h6">
//                               {playlist.duration}
//                             </Typography>
//                           </Grid>

//                           <Grid item xs={2}>
//                             {" "}
//                             <Box sx={{ maxWidth: 90, minHeight: 50 }}>
//                               {/* <FormControl fullWidth>
//                                                             <InputLabel id="demo-simple-select-label">Status</InputLabel>
//                                                             <Select
//                                                                 labelId="demo-simple-select-label"
//                                                                 id="demo-simple-select"
//                                                                 // value={age}
//                                                                 label="Status"
//                                                                 sx={{ height: 40 }}
//                                                             // onChange={handleChange}
//                                                             >
//                                                                 <MenuItem value='PENDING'>Pending</MenuItem>
//                                                                 <MenuItem value='active'>Active</MenuItem>
//                                                                 <MenuItem value='inactive'>Inactive</MenuItem>
//                                                             </Select>
//                                                         </FormControl> */}
//                               {playlist.status}
//                             </Box>{" "}
//                           </Grid>
//                           <Grid item xs={2}>
//                             <Box>
//                               <IconButton
//                                 aria-label="edit"
//                                 onClick={() => alert(`Edit: ${playlist.name}`)}
//                               >
//                                 <Icon
//                                   component={previewIcon}
//                                   fontSize="small"
//                                 />
//                               </IconButton>
//                               <IconButton
//                                 aria-label="save"
//                                 onClick={() => alert(`Save: ${playlist.name}`)}
//                               >
//                                 <Icon
//                                   component={commentIcon}
//                                   fontSize="small"
//                                 />
//                               </IconButton>
//                               <IconButton
//                                 aria-label="download"
//                                 onClick={() =>
//                                   alert(`Download: ${playlist.name}`)
//                                 }
//                               >
//                                 <Icon
//                                   component={downloadIcon}
//                                   fontSize="small"
//                                 />
//                               </IconButton>
//                               <IconButton
//                                 aria-label="delete"
//                                 onClick={() => {
//                                   arrayHelpers.remove(index);
//                                 }}
//                               >
//                                 <Icon component={deleteIcon} fontSize="small" />
//                               </IconButton>
//                             </Box>
//                           </Grid>
//                         </AccordionSummary>
//                         <AccordionDetails>
//                           <Grid container spacing={2}>
//                             <Grid item xs={12}>
//                               <FileUploaderContainer
//                                 preLoadedFile={[]} // Assuming no initial files
//                                 isMultiple={true}
//                                 signedUrlCallback={(
//                                   path,
//                                   name,
//                                   publicurl,
//                                   videoMetadata
//                                 ) => {
//                                   //   console.log(
//                                   //     "Uploading file ==== ",
//                                   //     publicurl
//                                   //   );
//                                   handleSubmit({
//                                     path,
//                                     name,
//                                     publicurl,
//                                     videoMetadata,
//                                     playlistId: playlist.playlistId,
//                                   }); // need to submit an actual payload
//                                 }}
//                                 fieldName={"Upload Video"}
//                                 isFieldValid={true}
//                                 ItemMapComponent={ShowLabel}
//                               />
//                             </Grid>
//                           </Grid>
//                         </AccordionDetails>
//                       </Accordion>
//                     </Grid>
//                   </Grid>
//                 ))}
//                 <Grid container>
//                   {tabValue !== 2 ? (
//                     <Grid item xs={4} mr={1}>
//                       <FormControl fullWidth>
//                         <InputLabel
//                           id="dummy-playlist-select-label"
//                           style={{ color: "#5E5E5E", fontWeight: "bold" }}
//                         >
//                           Add Playlist
//                         </InputLabel>
//                         <Select
//                           labelId="dummy-playlist-select-label"
//                           id="dummy-playlist-select"
//                           value={values.selectedPlaylist}
//                           label="Add Playlist"
//                           sx={{
//                             "& .MuiOutlinedInput-notchedOutline": {
//                               borderColor: "#5E5E5E",
//                             },
//                             "&:hover .MuiOutlinedInput-notchedOutline": {
//                               borderColor: "#5E5E5E	", // color when hovered
//                             },
//                             "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
//                               borderColor: "#5E5E5E	", // color when the component is focused
//                             },
//                             height: 40,
//                             borderRadius: 100,
//                           }}
//                           color="primary"
//                           onChange={(event) => {
//                             const selectedDummy = dummyPlaylists.find(
//                               (d) => d.name === event.target.value
//                             );
//                             if (selectedDummy) {
//                               arrayHelpers.push(selectedDummy);
//                             }
//                           }}
//                         >
//                           {dummyPlaylists.map((playlist, index) => (
//                             <MenuItem key={index} value={playlist.name}>
//                               {playlist.name}
//                             </MenuItem>
//                           ))}
//                         </Select>
//                       </FormControl>
//                     </Grid>
//                   ) : (
//                     <></>
//                   )}
//                   <Grid item xs={5}>
//                     <MDButton
//                       type="button"
//                       variant="outlined"
//                       color="dark"
//                       sx={{ borderRadius: "100px" }}
//                       onClick={() =>
//                         arrayHelpers.push({
//                           name: `Playlist ${values.playlists.length + 1}`,
//                           videosCount: 0,
//                           fromDate: "",
//                           toDate: "",
//                           duration: "",
//                           status: "Pending",
//                         })
//                       }
//                     >
//                       + New Playlist
//                     </MDButton>
//                   </Grid>
//                 </Grid>
//               </Grid>
//             )}
//           />
//         </Form>
//       )}
//     </Formik>
//   );
// }

// export default AdsDeviceMap;

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
  Grid,
  TextField,
  Switch,
  FormControlLabel,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  Box,
  IconButton,
  Icon,
} from "@mui/material";
import React, { useEffect } from "react";
import FileUploaderContainer from "./playList";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import { Formik, Form, Field, FieldArray } from "formik";
import MDButton from "components/MDButton";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ReactComponent as previewIcon } from "assets/images/previewIcon.svg";
import { ReactComponent as commentIcon } from "assets/images/commentIcon.svg";
import { ReactComponent as downloadIcon } from "assets/images/downloadIcon.svg";
import { ReactComponent as deleteIcon } from "assets/images/deleteIcon.svg";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import { useQuery, useMutation } from "@apollo/client";

import tab from "assets/theme/components/tabs/tab";
import { vmServiceClient } from "graphql/client";
import Loader from "components/MDLoader";
import { CREATE_VIDEO, LIST_ALL_VIDEOS, LIST_ALL_PLAYLISTS } from "services/advertisment-service";
dayjs.extend(duration);

function AdsDeviceMap({ user, tabValue, device, allDeviceList, selectedBrandId, Brand }) {
   const [isSubmitting, setIsSubmitting] = React.useState(false);
  // const initialValues={
  //   allDeviceList.map((item) => ({
  //     name: item.code,
  //     videosCount: item?.devicePlaylists?.length || 0,
  //     startTime: item?.startDateTime || null,
  //     endTime: item?.endDateTime || null,
  //     duration: sumDurationsTest(item?.devicePlaylists?.map((i) => i.playlistVideos?.map((j) => j.duration)) || []),
  //     status: item?.status,
  //     playlistId: item?.uuid,
  //     devicePlaylists: item?.devicePlaylists
  //   }))
  // }
  // function sumDurationsTest(durations) {  
  //   return durations.reduce((a, b) => a + b, 0);
  // }

 

// let temp = allDeviceList?.map((elem, ind)=>{
//   return elem?.devicePlaylists?.map((item) => ({
//     name: item.code,
//     videosCount: item?.playlistVideos.length || 0,
//     startTime: item?.startDateTime || null,
//     endTime: item?.endDateTime || null,
//     duration: sumDurations(item?.playlistVideos?.map((i) => i.duration) || []),
//     status: item?.status,
//     playlistId: item?.uuid,
//     devicePlaylists: item?.devicePlaylists
//   }))
// })

// console.log("TEMP ==== ", temp)

  // const initialPlaylists =
  // device?.devicePlaylists?.map((item) => ({
  //     name: item.code,
  //     videosCount: item?.playlistVideos.length || 0,
  //     startTime: item?.startDateTime || null,
  //     endTime: item?.endDateTime || null,
  //     duration: sumDurations(item?.playlistVideos?.map((i) => i.duration) || []),
  //     status: item?.status,
  //     playlistId: item?.uuid,
  //     devicePlaylists: item?.devicePlaylists
  //   })) || [
  //     {
  //       name: "Chilling Playlist",
  //       videosCount: 0,
  //       startTime: null,
  //       endTime: null,
  //       duration: "00:00:00",
  //       status: null,
  //     },
  //   ];

  const { loading, error, data, refetch } = useQuery(LIST_ALL_PLAYLISTS, {
    client: vmServiceClient,
    variables: {
      page: 1,
      limit: 10,
      sortBy: "uuid",
      order: "DESC",
      relations: ["playlistVideos"],
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setRowCount(data?.brandSpaceReadiness?.total);
    },
  });

  const initialPlaylists =
  data?.findAllDevicePlaylists?.results?.map((item) => ({
    name: item.code,
    videosCount: item?.playlistVideos.length || 0,
    startTime: item?.startDateTime || null,
    endTime: item?.endDateTime || null,
    duration: sumDurations(item?.playlistVideos?.map((i) => i.duration) || []),
    status: item?.status,
    playlistId: item?.uuid,
    devicePlaylists: item?.playlistVideos
  })) || [
    {
      name: "Chilling Playlist",
      videosCount: 0,
      startTime: null,
      endTime: null,
      duration: "00:00:00",
      status: null,
    },
  ];

  const dummyPlaylists =
    data?.findAllDevicePlaylists?.results?.map((item) => ({
      name: item.code,
      videosCount: item.playlistVideos.length,
      startTime: item?.startDateTime,
      endTime: item?.endDateTime,
      duration: sumDurations(
        item.startDateTime || null,
        item.endDateTime || null
      ),
      status: item?.status,
      playlistId: item?.uuid,
    })) || [
      {
        name: "Chill Vibes",
        videosCount: 8,
        startTime: null,
        endTime: null,
        duration: "20 Mins",
        status: "Pending",
        playlistId: [],
      },
    ];

    
  // const dummyDropdownPlaylists =
  // data?.findAllDevicePlaylists?.results?.map((item) => ({
  //   name: item.code,
  //   videosCount: item.playlistVideos.length,
  //   startTime: item?.startDateTime,
  //   endTime: item?.endDateTime,
  //   duration: sumDurations(
  //     item.startDateTime || null,
  //     item.endDateTime || null
  //   ),
  //   status: item?.status,
  //   playlistId: item?.uuid,
  // })) || [
  //   {
  //     name: "Chill Vibes",
  //     videosCount: 8,
  //     startTime: null,
  //     endTime: null,
  //     duration: "20 Mins",
  //     status: "Pending",
  //     playlistId: [],
  //   },
  // ];

  const ShowLabel = ({ title }) => <p> ({title})</p>;

  const [createVideoMutation] = useMutation(CREATE_VIDEO, {
    client: vmServiceClient,
    refetchQueries: [
      {
        query: LIST_ALL_VIDEOS,
        variables: {
          page: 1,
          limit: 100,
          sortBy: "updatedAt",
          order: "DESC",
          relations: [
            "playlist",
            "playlist.devices",
            "playlist.devices.deviceLocation",
          ],
          searchCols: ["brandId"],
          searchBy: user && user?.role !== "admin" ? [selectedBrandId] : [],
        },
      },
    ],
  });
  function sumDurations(timesArray) {
    if (!Array.isArray(timesArray)) {
      console.error("Invalid input: timesArray is not an array", timesArray);
      return "0s"; // Return a default or error value
    }

    // Convert the input to a string if it's not already, and make sure it's a string representation of time
    const toSeconds = (timeStr) => {
      timeStr = String(timeStr);
      if (timeStr.includes("s")) {
        return parseFloat(timeStr);
      } else if (timeStr.includes(":")) {
        const [hours, minutes, seconds] = timeStr.split(":").map(parseFloat);
        return hours * 3600 + minutes * 60 + seconds;
      }
      return parseFloat(timeStr);
    };

    // Convert all time strings to seconds and sum them
    const totalSeconds = timesArray.reduce((sum, time) => {
      if (typeof time === "string" || typeof time === "number") {
        return sum + toSeconds(time);
      } else {
        console.error("Invalid time value:", time);
        return sum; // Ignore invalid input and continue accumulating
      }
    }, 0);

    // Return the total in seconds with one decimal place
    return `${totalSeconds.toFixed(1)}s`;
  }

  const handleSubmit = ({
    path,
    name,
    publicurl,
    videoMetadata,
    playlistId,
    index
  }) => {
    if (isSubmitting) return; // Prevent multiple submissions
    setIsSubmitting(true);
    console.log("DEVICE PLAYLIST ID ==== ", playlistId, index);
    const payload = {
      brandId: selectedBrandId,
      name: name,
      duration: String(videoMetadata?.duration), // Ensure this is a string. Example: "00:08:19"
      aspectRatio: videoMetadata.aspectRatio, // Provide a default value if null
      orientation: videoMetadata.orientation.toUpperCase(), // Ensure correct enum value
      size: String(videoMetadata.size), // Convert to string if it's numeric
      path: path,
      publicUrl: publicurl,
      status: "PENDING",
      //   playlistIds: tab === 2 ? [playlistId] : [],
      playlistIds: playlistId ? [playlistId] : [],
    };
    // return;
    createVideoMutation({
      variables: { input: payload },
      onCompleted: () => {
        setIsSubmitting(false);
        setResetUploader(true); // Set to true to reset the uploader
        setTimeout(() => setResetUploader(false), 0); // Optional: reset the state immediately

        refetch({
          variables: {
            page: 1,
            limit: 100,
            sortBy: "updatedAt",
            order: "DESC",
            relations: [
              "playlist",
              "playlist.devices",
              "playlist.devices.deviceLocation",
            ],
            searchCols: ["brandId"],
            searchBy: user && user?.role !== "admin" ? [selectedBrandId] : [],
          },
        });
      },
      onError: () => setIsSubmitting(false),
    });
  };

  if(loading) return <Loader />

  return (
    <Formik
      initialValues={{playlists:initialPlaylists}}
      onSubmit={(values) => {
        console.log("values  ADS ==== ", values);
        // handleSubmit(values);
      }}
    >
      {({ values, handleChange, setFieldValue }) => (
        <Form style={{ marginLeft: "20px" }}>
          <Grid item xs={12} mb={3} ml={1}>
            <Grid container>
              <Grid item xs={2}>
                {" "}
                <Typography variant="h6">Name </Typography>{" "}
              </Grid>
              <Grid item xs={1}>
                {" "}
                <Typography variant="h6">Videos </Typography>{" "}
              </Grid>
              <Grid item xs={2}>
                {" "}
                <Typography variant="h6">From Time </Typography>{" "}
              </Grid>
              <Grid item xs={2}>
                {" "}
                <Typography variant="h6">To Time </Typography>{" "}
              </Grid>
              <Grid item xs={1}>
                {" "}
                <Typography variant="h6">Duration </Typography>{" "}
              </Grid>
              <Grid item xs={2}>
                {" "}
                <Typography variant="h6">Status </Typography>{" "}
              </Grid>
              <Grid item xs={2}>
                {" "}
                <Typography variant="h6">Action </Typography>{" "}
              </Grid>
            </Grid>
          </Grid>
          <FieldArray
            name="playlists"
            render={(arrayHelpers) => (
              <Grid container spacing={2}>
                {values.playlists?.map((playlist, index) => (
                  <Grid container key={index}>
                    
                    <Grid item xs={12}>
                      <Accordion style={{ marginBottom: "20px" }}>
                        <AccordionSummary
                          expandIcon={
                            <GridExpandMoreIcon
                              fontSize="medium"
                              sx={{ color: "#000000" }}
                            />
                          }
                          style={{ flexDirection: "row-reverse" }}
                        >
                          <Grid item xs={2} mr={1} pr={2}>
                            <TextField
                              fullWidth
                              variant="outlined"
                              value={playlist.name}
                              onChange={handleChange(`playlists.${index}.name`)}
                            />
                          </Grid>
                          <Grid item xs={1}>
                            {" "}
                            <Typography variant="h6">
                              {playlist.videosCount}
                            </Typography>{" "}
                          </Grid>
                          <Grid item xs={2} mr={1}>
                            {dayjs(playlist.startTime).format(
                              "YYYY-MM-DD HH:mm:ss"
                            )}
                          </Grid>
                          <Grid item xs={2} mr={1}>
                            {dayjs(playlist.endTime).format(
                              "YYYY-MM-DD HH:mm:ss"
                            )}
                          </Grid>
                          <Grid item xs={1}>
                            <Typography variant="h6">
                              {playlist.duration}
                            </Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Box sx={{ maxWidth: 90, minHeight: 50 }}>
                              {playlist.status}
                            </Box>{" "}
                          </Grid>
                          <Grid item xs={2}>
                            <Box>
                              <IconButton
                                aria-label="edit"
                                onClick={() => alert(`Edit: ${playlist.name}`)}
                              >
                                <Icon
                                  component={previewIcon}
                                  fontSize="small"
                                />
                              </IconButton>
                              <IconButton
                                aria-label="save"
                                onClick={() => alert(`Save: ${playlist.name}`)}
                              >
                                <Icon
                                  component={commentIcon}
                                  fontSize="small"
                                />
                              </IconButton>
                              <IconButton
                                aria-label="download"
                                onClick={() =>
                                  alert(`Download: ${playlist.name}`)
                                }
                              >
                                <Icon
                                  component={downloadIcon}
                                  fontSize="small"
                                />
                              </IconButton>
                              <IconButton
                                aria-label="delete"
                                onClick={() => {
                                  arrayHelpers.remove(index);
                                }}
                              >
                                <Icon component={deleteIcon} fontSize="small" />
                              </IconButton>
                            </Box>
                          </Grid>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                            {console.log("Index ==== ", index, playlist.playlistId)}
                              <FileUploaderContainer
                                preLoadedFile={[]} // Assuming no initial files
                                isMultiple={true}
                                signedUrlCallback={(
                                  path,
                                  name,
                                  publicurl,
                                  videoMetadata
                                ) => {
                                  handleSubmit({
                                    path,
                                    name,
                                    publicurl,
                                    videoMetadata,
                                    playlistId: playlist.playlistId,
                                    index: index,
                                  }); // need to submit an actual payload
                                }}
                                fieldName={"Upload Video"}
                                isFieldValid={true}
                                ItemMapComponent={ShowLabel}
                              />
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                  </Grid>
                ))}
                <Grid container>
                  {tabValue !== 2 ? (
                    <Grid item xs={4} mr={1}>
                      <FormControl fullWidth>
                        <InputLabel
                          id="dummy-playlist-select-label"
                          style={{ color: "#5E5E5E", fontWeight: "bold" }}
                        >
                          Add Playlist
                        </InputLabel>
                        <Select
                          labelId="dummy-playlist-select-label"
                          id="dummy-playlist-select"
                          value={values.selectedPlaylist}
                          label="Add Playlist"
                          sx={{
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#5E5E5E",
                            },
                            "&:hover .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#5E5E5E	", // color when hovered
                            },
                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#5E5E5E	", // color when the component is focused
                            },
                            height: 40,
                            borderRadius: 100,
                          }}
                          color="primary"
                          onChange={(event) => {
                            const selectedDummy = dummyPlaylists.find(
                              (d) => d.name === event.target.value
                            );
                            // console.log("SELECTED ==== ", selectedDummy)
                            if (selectedDummy) {
                              arrayHelpers.push(selectedDummy);
                            }
                          }}
                        >
                          {dummyPlaylists.map((playlist, index) => (
                            <MenuItem key={index} value={playlist.name}>
                              {playlist.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  ) : (
                    <></>
                  )}
                  <Grid item xs={5}>
                    <MDButton
                      type="button"
                      variant="outlined"
                      color="dark"
                      sx={{ borderRadius: "100px" }}
                      onClick={() =>
                        arrayHelpers.push({
                          name: `Playlist ${values.playlists.length + 1}`,
                          videosCount: 0,
                          fromDate: "",
                          toDate: "",
                          duration: "",
                          status: "Pending",
                        })
                      }
                    >
                      + New Playlist
                    </MDButton>
                  </Grid>
                </Grid>
              </Grid>
            )}
          />
        </Form>
      )}
    </Formik>
  );
}

export default AdsDeviceMap;
