import React, { useEffect, useState, useContext, createContext, useMemo } from "react";

// @mui material components
import {
  Grid,
  Box,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Tabs,
  Autocomplete,
  TextField,
} from "@mui/material";
import DashboardLayout from "ui/LayoutContainers/DashboardLayout";
import DashboardNavbar from "ui/Navbars/DashboardNavbar";
import AdsTabs from "./adsTabs";
import { dashboardServiceClient } from "graphql/client";
import { useMutation, useQuery } from "@apollo/client";
import { GET_STORES_LIST_NEW } from "services/store-service";
import { BRANDS_LIST } from "services/brand-service";
import { ReactComponent as ArrowDropDownIcon } from "assets/images/Arrow-Down.svg";
import { GridExpandMoreIcon } from '@mui/x-data-grid'
import { SearchContext, FilterContext } from "context";
import useStoreSelection from "./storeSelectionHook";
import { GET_BRAND_CATEGORY_STORE } from "services/brand-service";

const user =
  localStorage.getItem("userDetails") &&
    localStorage.getItem("userDetails") !== "undefined"
    ? JSON.parse(localStorage.getItem("userDetails"))
    : {};

export const DropDownSelectionContext = createContext();

function Advertisment() {
  const [tabValue, setTabValue] = useState(0);
    const {
      loading: storeLoading,
      error: storeError,
      data: storeList,
    } = useQuery(GET_STORES_LIST_NEW, {
      client: dashboardServiceClient,
      variables: { input: { take: 100, skip: 0 } },
    });
    
    const { loading, data, error, refetch } = useQuery(BRANDS_LIST, {
      client: dashboardServiceClient,
      variables: {
        take:10000,
        skip:0,
        filter: {}
      },
    });
    const Store = storeList?.stores?.results;
    const [storeOptions, setStoreOptions] = useState(Store);
    const [selectedStore, setSelectedStore] = useState(null);
    const Brand = data?.brandlist?.results
    const [brandOptions, setBrandOptions] = useState(Brand);
    const [selectedBrand, setSelectedBrand] = useState(
      user?.role !== "admin"
        ? Brand?.find((brand) => brand.username == user?.username)
        : null
    );
    
    const {
      data: brandCategoryStoreList,
      refetch:brandCategoryStoreRefetch
    } = useQuery(GET_BRAND_CATEGORY_STORE, {
      client: dashboardServiceClient,
      variables: {
        take: 1000000,
        skip: 0,
        filter: { brand: { id: selectedBrand?.id }, store:{id:selectedStore?.id}, isDeleted: false },
      },
      fetchPolicy:"network-only"
    });
    useEffect(()=>{
      setBrandOptions(Brand)
      setStoreOptions(Store?.filter(store => store.status=='Active'));
    },[Brand, Store])
    // useEffect(()=>{
      //   setSelectedStore(storeList?.stores?.results?.[0]);
// },[storeList])

  const commonSelections = useMemo(() => ({ selectedStoreId:selectedStore?.id, selectedBrandId:selectedBrand?.id, user, tabValue,Brand:data?.brandlist?.results }), [selectedStore, selectedBrand, user, tabValue,data]);

  const AdsDropDown = ({ labelId, label, id, name, iconComponent, onChange, menuItems=[], value, disabled=false }) => {
    return (
      <Box mr={1} width={200}>
        <Autocomplete
        onChange={onChange}
          // disablePortal
          id={id}
          options={menuItems}
          disabled={disabled}
          value={value}
          sx={{ "& .MuiInputBase-root":{height: 40, borderRadius: "20px", px: 2, backgroundColor: disabled?"#F0F2F5": "white.main"} }}
        renderInput={(params) => <TextField {...params} label={label} />}
        />
      </Box>
    );
  };
  useEffect(() => {
    if (user && user.role !== "admin") {
      setSelectedBrand(Brand?.find(brand=>brand.username==user?.username))
    }
  }, [user, Brand])

  useEffect(()=>{
    if(selectedBrand && !selectedStore){
      brandCategoryStoreRefetch({
        variables: {
          take: 1000000,
          skip: 0,
          filter: { brand: { id: selectedBrand?.id }, isDeleted: false },
        },
      }).then(({data})=>{
        const filteredStores = {}
        data?.brandCategoryStores?.results.forEach(bcs=>{
          if(filteredStores[bcs?.store?.id]==null){
            filteredStores[bcs?.store?.id] = bcs?.store;
          }
        })
        setStoreOptions(Object.values(filteredStores));
        setBrandOptions(Brand);
      })
    } else if(selectedStore && !selectedBrand){
      brandCategoryStoreRefetch({
        variables: {
          take: 1000000,
          skip: 0,
          filter: { store: { id: selectedStore?.id }, isDeleted: false },
        },
      }).then(({data})=>{
        const filteredBrands = {}
        data?.brandCategoryStores?.results.forEach(bcs=>{
          if(filteredBrands[bcs?.brand?.id]==null){
            filteredBrands[bcs?.brand?.id] = bcs?.brand;
          }
        })
        setBrandOptions(Object.values(filteredBrands));
        setStoreOptions(Store)
      })
    } else if(!selectedStore && !selectedBrand){
      setStoreOptions(Store)
      setBrandOptions(Brand)
    }
  
  },[selectedBrand, selectedStore])

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;
  if (user && user.role !=='admin' && !selectedBrand) return <></>
  return (
    <DashboardLayout>
      <DashboardNavbar />


      <Grid container mt={2}>
        <Grid item xs={12} display="flex">
        { user && user.role=='admin' && 
          <Autocomplete
            onChange={(e, selectedOption) => {
              setSelectedBrand(selectedOption?.value)
            }}
            id="demo-simple-select"
            options={brandOptions
              ?.map((item) => {
                return (
                  {label:item.name, value: item}
                );
              })||[]}
            value={selectedBrand?.name}
            sx={{ "& .MuiInputBase-root":{height: 40, borderRadius: "20px", px: 2, backgroundColor: "white.main"},mr:1, width:200 }}
            renderInput={(params) => <TextField {...params} label={'Select Brand'} />}
            renderOption={(props, option)=>{
              if((option.value?.id === selectedBrand?.id))
                  return <Box sx={{background:'#F0F2F5'}} {...props} value={option}>{option.label}</Box>
              return <Box {...props} value={option}>{option.label}</Box>
            }}
          />
          }
            <Autocomplete
              onChange={(e, selectedOption) => {
                setSelectedStore(selectedOption?.value)
              }}
              id="demo-simple-select"
              options={storeOptions
                ?.map((item) => {
                  return (
                    {label:item.name, value: item}
                  );
                })||[]}
              value={selectedStore?.name || ''}
              sx={{ "& .MuiInputBase-root":{height: 40, borderRadius: "20px", px: 2, backgroundColor: "white.main"},mr:1, width:200 }}
              renderInput={(params) => <TextField {...params} label={'Select Store'} />}
              renderOption={(props, option)=>{
                if((option.value?.id === selectedStore?.id))
                    return <Box sx={{background:'#F0F2F5'}} {...props} value={option}>{option.label}</Box>
                return <Box {...props} value={option}>{option.label}</Box>
              }}
            />
        </Grid>
        <Box
          sx={{
            width: "100%",
            minHeight: "auto",
            backgroundColor: "#FFFFFF",
            borderRadius: "10px",
            mt: 4,
          }}
        >
          <DropDownSelectionContext.Provider value={commonSelections}>
            <AdsTabs
              key={selectedBrand?.id||"_noBrand"}
              tabValue={tabValue}
              setTabValue={setTabValue}
              // user={user}
              // selectedBrandId={selectedBrandId}
              // Brand={Brand}
            />
          </DropDownSelectionContext.Provider>
        </Box>
      </Grid>
    </DashboardLayout>
  );
}

export default Advertisment;
