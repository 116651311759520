import { gql } from "@apollo/client";

export const GET_CUSTOMER_LIST = gql`
query  GetCustomerList($take: Int, $skip: Int, $search: String, $filter: JSONObject, $orderby: JSONObject){
  customerList(listUserFilter: { take: $take, skip: $skip, search: $search, filter: $filter, orderby: $orderby}) {
    total
    results {
      id
      accountId
      mobile
      firstName
      lastName
      email
      dob
      remarks
      isEmailVerified
      gender
      deviceId
      osName
      fcmId
      status
      refferalCode
      referraredBy
      gpsLocation
      loginDate
      lastOfflineActivity
      createdAt
      updatedAt
      account {
        id
        regSource
        expiryAmount
        expiryDate
        upcomingCashback
        upcomingCashbackDate
        userId
        balance
        expiryAmount
        expiryDate
        upcomingCashback
        upcomingCashbackDate
        isActive
        createdAt
        updatedAt
        address {
          id
          addressLine1
          addressLine2
          city
          country
          email
          name
          phone
          pincode
          state
          landmarks
          addressType
          isDeleted
          createdAt
          updatedAt
        }
      }
    }
  }
}
`
export const CREATE_UPDATE_CUSTOMER_MUTATION = gql`
mutation createCustomer($createUserInput: CreateUserInput!) {
  createCustomer(createUserInput: $createUserInput)
}
`
export const UPDATE_CUSTOMER_MUTATION = gql`
mutation updateUser($updateUserInput: UpdateUserInput!) {
  updateUser(updateUserInput: $updateUserInput){
    id
  }
}
`

export const GET_CUSTOMER_BY_ID = gql`
query ($id:String!) {
  customer(id: $id) {
    id
    accountId
    mobile
    firstName
    lastName
    email
    isEmailVerified
    dob
    deviceId
    osName
    fcmId
    status
    refferalCode
    referraredBy
    gpsLocation
    loginDate
    lastOfflineActivity
    remarks
    createdAt
    updatedAt
    account {
      id
      regSource
      expiryAmount
      expiryDate
      upcomingCashback
      upcomingCashbackDate
      userId
      balance
      isActive
      createdAt
      updatedAt
      transaction {
        id
        accountId
        customerId
        txnId
        source
        txnDate
        storeCode
        receiptNo
        amount
        description
        offerId
        createdAt
        updatedAt
        txnStatus
      }
      address {
        id
        addressLine1
        addressLine2
        city
        country
        email
        name
        phone
        pincode
        state
        addressType
        isDeleted
        createdAt
        updatedAt
        landmarks
        defaultSelect
      }
    }
  }
}
`
export const CREATE_OR_UPDATE_ADDRESS = gql`
mutation createOrupdateAddress ($request:AddressInput!){
  createOrupdateAddress(
    request: $request 
  ) {
    id
  }
}
`
