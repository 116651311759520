import React, { useState } from "react";
import DashboardLayout from "ui/LayoutContainers/DashboardLayout";
import DashboardNavbar from "ui/Navbars/DashboardNavbar";
import ProductNavbar from "./ProductNavbar";
import ProductData from "./productTable/ProductData";
import AddProductForms from "./forms/AddProductForms";
import { ProductContextProvider } from "./context/productContext";

function Product() {
  const [form, setForm] = useState(false);

  const [productContext, setProductContext] = useState({
    addedSingleProduct: false,
    addedBulkProduct: false,
  });

  return (
    <DashboardLayout>
      <ProductContextProvider value={{ productContext, setProductContext }}>
        <DashboardNavbar />
        <ProductNavbar setForm={setForm} />
        <ProductData setForm={setForm} />
        <AddProductForms form={form} setForm={setForm} />
      </ProductContextProvider>
    </DashboardLayout>
  );
}

export default Product;
