import { Grid, Typography } from "@mui/material";
import CustomStyledButton from "./CustomStyledButton";

// import addIcon from "../../assets/images/add-icon.svg";
import AddIcon from "@mui/icons-material/Add";
import addIconWhite from "../../assets/images/add-icon-white.svg";
import downloadIcon from "../../assets/images/export-product.svg";
import downloadIconWhite from "../../assets/images/export-product-white.svg";
import { AvailableForms } from "./productTable/tableData";
import MDButton from "components/MDButton";
import IosShareIcon from "@mui/icons-material/IosShare";

export default function ProductNavbar({ setForm }) {
  const user = localStorage.getItem("userDetails");

  const userJson = user ? JSON.parse(user) : {};

  const role = userJson.role;

  const isAdmin = role === "admin";

  const navbarButtons = [
    {
      key: "add-product",
      lable: "Single Product",
      onClick: () => {
        setForm(AvailableForms.singleProduct);
      },
      startIcon: <AddIcon />,
      hoverIcon: <AddIcon />,
    },
    {
      key: "add--bulk-product",
      lable: "Bulk Product Upload",
      onClick: () => {
        setForm(AvailableForms.bulkProduct);
      },
    },
    {
      key: "export",
      lable: "Export",
      onClick: () => {},
      startIcon: <IosShareIcon />,
      hoverIcon: <IosShareIcon />,
    },
  ];

  return (
    <Grid container justifyContent={"space-between"} gap={1}>
      <Grid item xs={12} sm={6} md={6}>
        <Typography
          sx={{ fontFamily: "montserrat", color: "#000", fontWeight: "700" }}
          ml={2}
        >
          Products
        </Typography>
      </Grid>
      <Grid>
        {isAdmin && (
          <MDButton
            style={{ marginLeft: "8px" }}
            circular={true}
            onClick={() => {
              setForm(AvailableForms.superProduct);
            }}
            variant={"contained"}
            color={"black"}
            key={"super-product-upload"}
            label={"Super Product Upload"}
          >
            Super Product Upload
          </MDButton>
        )}
        {navbarButtons.map((btn) => {
          return (
            <MDButton
              style={{ marginLeft: "8px" }}
              circular={true}
              variant={"contained"}
              color={"black"}
              {...btn}
              startIcon={btn.startIcon}
            >
              {btn.lable}
            </MDButton>
          );
        })}
      </Grid>
    </Grid>
  );
}
