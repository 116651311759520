import { useQuery } from '@apollo/client';
import { UploadSharp } from '@mui/icons-material';
import { Box, Button, Chip, FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Select, TextField, styled } from '@mui/material';
import { GridExpandMoreIcon } from '@mui/x-data-grid';
import MDButton from 'components/MDButton';
import { vmServiceClient } from 'graphql/client';
import React, { useContext, useState } from 'react'
import { LIST_ALL_PLAYLISTS } from 'services/advertisment-service';
import { DropDownSelectionContext } from '..';

export default function PlaylistSelect({ onSubmit, playlists=[] }) {
  const [componentKey, setComponentKey] = useState(Date.now());
  const { selectedBrandId, user, Brand} = useContext(DropDownSelectionContext);
    const { loading, error, data:playlistData, refetch } = useQuery(LIST_ALL_PLAYLISTS, {
        client: vmServiceClient,
        variables: {
          "page": 1,
          "limit": 100,
          "sortBy": "updatedAt",
          "order": "DESC",
          "relations": [],
          "searchCols": ["brandId"],
          "searchBy": user && user?.role !== "admin" ? [selectedBrandId || '_noBrand'] : [selectedBrandId || '_noBrand'],
          "filter":{status: 'APPROVED'}
        //   "searchBy": user && user?.role !== "admin" ? [selectedBrandId] : []
        },
      });

    const handlePlaylistSelect = (event) => {
      // setSelectedVideo(event.target.value);
      onSubmit(event.target.value);
      event.stopPropagation();
      setComponentKey(Date.now());
    };
  
    return (
      <Box mr={1} width={250} key={componentKey}>
        <FormControl fullWidth >
          <InputLabel sx={{ pb: 1, color: "black.main",  top:-2 }}>Select Uploaded Playlist</InputLabel>
          <Select
            as={Select}
            labelId="demo-simple-select-label"
            label="Select Uploaded Playlist"
            IconComponent={() => (
              <GridExpandMoreIcon fontSize="medium" />
            )}
            
            sx={{ height: 40, borderRadius: "20px", px: 2, backgroundColor: "white.main", border:1, borderColor: "black.main"}}
            onChange={handlePlaylistSelect}
          >
            {playlistData?.findAllPlaylists?.results.filter(playlist=>!playlists.includes(playlist.uuid)).map((playlist) => (
              <MenuItem key={playlist.uuid} value={playlist}>
                {`${playlist?.name}(${playlist?.code})`}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    );
}
