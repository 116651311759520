
import React from 'react'
import AgGrid from 'components/MDGrid';

function ReportTables({rowData,columnDefs}) {
  return (
    <div
    className={
        "ag-theme-quartz-dark"
    }
    style={{ height: 500 }}
>
    <AgGrid
        queryData={rowData}
        columns={columnDefs}
        gridProps={{
          rowHeight:60,
          defaultColDef:{
            enableRowGroup:true,
            enablePivot:true,
            enableValue:true,
            floatingFilter: true,
            sortable: true,
            editable: false,
            filter: true,
            flex: 1,
            minWidth: 200,
          },
          pivotMode:false,
          sideBar:{
            toolPanels: ['columns', 'filters'], 
          }, 
          enableRangeSelection:true,
        }}  
    />
</div>
  )
}

export default ReportTables 
