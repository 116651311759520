import React, { useEffect, useState } from "react";

// @mui material components
import {
  Grid,
  Box,
  Card,
  CardContent,
  CardActions,
  Button,
  Typography,
  Chip,
  Icon,
  Drawer,
  Pagination,
  FormControl,
  Select,
  MenuItem
} from "@mui/material";
import { Add as AddIcon, Store as StoreIcon } from "@mui/icons-material";
import { ReactComponent as searchIcon } from "assets/images/searchIcon.svg";
// Custom components and services
import DashboardLayout from "ui/LayoutContainers/DashboardLayout";
import DashboardNavbar from "ui/Navbars/DashboardNavbar";
import { GET_THEMES } from "services/theme-service";
import { dashboardServiceClient } from "graphql/client";
import { useQuery } from "@apollo/client";
import ThemeForm from "./themeForm";
import Loader from "components/MDLoader";
import { SearchContext,FilterContext } from "context";
import { useContext } from 'react';
import MDButton from "components/MDButton";

const ThemeCard = ({ item, handleCardClick }) => (
  <Grid item xs={12} sm={6} md={3}>
    <Card sx={{ minWidth: 100, borderRadius: 0, overflowY: "auto" }}>
      <CardContent>
        <Chip
          style={{ borderRadius: 0 }}
          label={item.isDeleted === false ? "Inactive" : "Active"}
          color={item.isDeleted === false ? "error" : "success"}
          variant="filled"
        />
        <Typography
          sx={{
            fontSize: 16,
            mt: 2,
            mb: 2,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          variant="h5"
          gutterBottom
        >
          {item.name}
        </Typography>
        <Typography
          sx={{
            fontSize: 13,
            mt: 2,
            fontFamily: "Inter, sans-serif",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          component="div"
          color="text.secondary"
        >
          {item.themeBrand.map((brand) => brand.name)}
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small" onClick={() => handleCardClick(item)}>
          {"Details >"}
        </Button>
      </CardActions>
    </Card>
  </Grid>
);

function Theme() {
  const [open, setOpen] = useState(false);
  const [themeData, setthemeData] = useState(null);
  const[themeList, setThemeList]=useState(null)
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20); 
  const { searchKeyword,setSearchKeyword } = useContext(SearchContext);
  const totalPages = themeList ? Math.ceil(themeList.themes.total / itemsPerPage) : 0;
  const skip = (currentPage - 1) * itemsPerPage;

  const handleCardClick = (item) => {
    setthemeData(item);
    setOpen(true);
  };
  const { loading, error, data,refetch } = useQuery(GET_THEMES, {
    client: dashboardServiceClient,
    variables: { listThemeFilter:{take: itemsPerPage, skip: skip,search:searchKeyword,orderby:{isDeleted:"DESC"}}, },
  });
  useEffect(()=>{
    if(data && JSON.stringify(data) !== JSON.stringify(themeList)){
      setThemeList(data)
    }
  },[data])
  const handleItemsPerPageChange = (event) => {
    const newItemsPerPage = event.target.value;
    const newTotalPages = Math.ceil(themeList.themes.total / newItemsPerPage);
    setItemsPerPage(newItemsPerPage);
    if (currentPage > newTotalPages) {
      setCurrentPage(1); // Reset to first page
    }
  };
  useEffect(() => {
    setCurrentPage(1)
    }, [searchKeyword]);
    useEffect(() => {
      // Reset search keyword when entering the Brands tab
      setSearchKeyword("");
      setTimeout(() => {
        if (refetch) {
          refetch();
        }
      }, 100);
    }, []);
    if (loading)
    return (
      <>
        <Loader />
      </>
    );
  if (error) return <p>Error: {error.message}</p>;
  const handleClose = () => {
    setOpen(false)
    if(refetch){
      refetch()
    }
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6}>
          <Typography sx={{fontFamily:"montserrat", color:"#000", fontWeight:"700"}} ml={1}>Theme</Typography> 
        </Grid>
        <Grid item xs={12} sm={6} md={6} textAlign="right">
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            {/* <Icon sx={{ mr: 2 }} component={searchIcon} fontSize="large" /> */}
            <MDButton
              type="button"
              variant="contained"
              color='black'
              circular={true} 
              startIcon={<AddIcon />}
              onClick={() => {
                setOpen(true);
                setthemeData(null);
              }}
            >
              New Theme
            </MDButton>
          </Box>
        </Grid>
        {data.themes.results.map((item, index) => (
          <ThemeCard
            key={item.id || index}
            item={item}
            handleCardClick={handleCardClick}
          />
        ))}
        <Drawer
          anchor="right"
          open={open}
          onClose={() => setOpen(false)}
          PaperProps={{ style: { width: "50%" } }}
        >
          <Grid
            container
            sx={{ backgroundColor: "#F0F2F5", pb: 3, pt: 2, pl: 2 }}
          >
            <Grid item xs={12}>
              &nbsp;
            </Grid>
            <Grid item xs={12}>
              <Icon
                sx={{ mr: 2, marginLeft: "10px" }}
                component={StoreIcon}
                fontSize="large"
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5" sx={{ marginLeft: "10px" }}>
                Theme Details
              </Typography>
            </Grid>
          </Grid>
          <Box sx={{ width: "100%", minHeight: "70vh", padding: "20px" }}>
            <ThemeForm handleClose={handleClose} themeData={themeData} />
          </Box>
        </Drawer>
      </Grid>
      <Box sx={{ display: "flex", justifyContent: "end", alignItems: "center", margin: '20px 0'}}>
      <Grid item xs={12} m={1}>
      <Pagination 
       style={{
        display: 'flex',
        justifyContent: 'center', 
        transform: 'scale(1.2)', 
        margin: '20px 0' 
    }}
          count={totalPages}
          page={currentPage}
          onChange={(event, page) => setCurrentPage(page)}
          color="secondary"
        />
        </Grid>
        <Grid item xs={2} justifyContent="space-between" display="flex">
        <FormControl variant="outlined" size="small" sx={{ minWidth: 90,marginLeft: 2 }}>
        <Typography fontSize={12} fontWeight="bold">
            &nbsp; Page Size
          </Typography>
          <Select
            value={itemsPerPage}
            onChange={handleItemsPerPageChange}
            sx={{ height: "30px", fontSize: "0.875rem" }}
            inputProps={{ 'aria-label': 'Without label' }}
            minHeight="20px"
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
          </Select>
        </FormControl>
        </Grid>
        </Box>
    </DashboardLayout>
  );
}

export default Theme;
