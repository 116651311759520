import React, { useEffect } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  Grid,
  TextField,
  Typography,
  Box,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormControlLabel,
  Switch,
  Button,
  InputLabel,
  Select,
  MenuItem,
  FormControl
} from "@mui/material";
import { ReactComponent as ArrowDropDownIcon } from "assets/images/Arrow-Down.svg";
import GalleryIcon from "@mui/icons-material/Photo";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import MDProgress from "components/MDProgress";
import { dashboardServiceClient } from "graphql/client";
import { GET_CUSTOMER_LIST } from "services/customer-service";
import { useMutation } from "@apollo/client";
import { CREATE_UPDATE_CUSTOMER_MUTATION } from "services/customer-service";
import { UPDATE_CUSTOMER_MUTATION } from "services/customer-service";
import { useNotification } from "context";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import MDButton from "components/MDButton";



const validationSchema = Yup.object().shape({
  firstName: Yup.string().matches(/^[a-zA-Z]+$/, "Enter Valid First Name")
    .required("First Name is required").max(30, "Maximum 30 characters allowed"),
  lastName: Yup.string().matches(/^[a-zA-Z]+$/, "Enter Valid Last Name").required("Last Name is required").max(30, "Maximum 30 characters allowed"),
  accountId: Yup.string(),
  email: Yup.string().email("Invalid email").required("Email is required"),
  mobile: Yup.string()
    .required("Mobile is required")
    .matches(/^[6-9][0-9]{9}$/, "Please Enter Valid Mobile Number"),
  dob: Yup.date()
    .required("Date of Birth is required")
    .max(dayjs().subtract(10, 'years').toDate(), "You must be at least 10 years old"),
  balance: Yup.number(),
  lastLogin: Yup.date().nullable(),
  comments: Yup.string().max(300, "Maximum 300 characters allowed"),
  channel: Yup.string(),
  gender: Yup.string().required("Please Select Gender")
});

const useCustomerMutations = (customerData, handleClose, handleTabChange, setNotification) => {
  const mutationOptions = {
    client: dashboardServiceClient,
    refetchQueries: [{ query: GET_CUSTOMER_LIST, variables: { search: "" } }],
  }

const [updateCustomerMutation] = useMutation(
  UPDATE_CUSTOMER_MUTATION,
  mutationOptions
)
const handleSubmit =(values) => {
  const payload = {
    id:values.id,
    firstName: values.firstName,
    lastName: values.lastName,
    // accountId: values?.accountId || null,
    email: values.email,
    mobile: values.mobile,
    dob: values.dob,
    balance: values.balance,
    id: values.id,
    // lastLogin: values.lastLogin,
    remarks: values.comments,
    regSource: "Web",
    status: values.status,
    gender:values.gender?.toLowerCase()
  };
  const mutation = updateCustomerMutation;
  const mutationInput = {updateUserInput :payload};
  mutation({ variables: mutationInput })
  .then((res) => {
    if (res.data && res.data.updateUser) {
      console.log("Customer updated successfully:", res.data.updateUser);
      // handleTabChange(null, 1);
      handleClose();
      setNotification({
        color: "success",
        isVisible: true,
        message:
        customerData && customerData?.id 
            ? "Customer Detail  updated successfully"
            : "Customer Detail  created successfully",
      });
    } else {
      console.error("GraphQL Error:", res?.errors);
      setNotification({
        color: "error",
        isVisible: true,
        message: res?.errors?.message || "Something went wrong",
      });
      // Handle GraphQL errors here
    }
  })
  .catch((error) => {
    console.error("Error:", error?.message);
    setNotification({
      color: "error",
      isVisible: true,
      message: error?.message || "Something went wrong",
    });
  });

  }
  return handleSubmit;

}
const FormikField = ({ name, label, touched, errors, rows, ...rest }) => (
  <Field
    name={name}
    as={TextField}
    label={label}
    fullWidth
    rows={rows}
    multiline={rows ? true : false}
    error={touched[name] && !!errors[name]}
    helperText={touched[name] && errors[name]}
    {...rest}
  />
);

const CustomerDetailComponent = ({ customerData, handleClose, handleTabChange, user }) => {
  const { setNotification } = useNotification();

  const handleSubmit = useCustomerMutations(customerData, handleClose, handleTabChange, setNotification);


  return (
    <Formik
      initialValues={{
        id:customerData?.id,
        firstName: customerData?.firstName || "",
        lastName: customerData?.lastName || "",
        accountId: customerData?.accountId || "",
        email: customerData?.email || "",
        mobile: customerData?.mobile,
        dob: customerData?.dob || dayjs().format("YYYY-MM-DD"),
        balance: customerData?.account?.balance || 0,
        lastLogin: customerData?.loginDate || null,
        lastOfflineActivity: customerData?.lastOfflineActivity || null,
        comments: customerData?.remarks || "",
        channel: customerData?.account?.regSource || "",
        status: customerData?.status || false,
        gender: customerData?.gender?.toLowerCase() || "",
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        console.log(values);
        handleSubmit(values);
      }}
    >
      {({
        handleChange,
        values,
        errors,
        touched,
        setFieldValue,
        setFieldTouched,
        validateField,
      }) => (
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormikField
                disabled={true}
                name="accountId"
                label="Account ID"
                touched={touched}
                errors={errors}
                inputProps={{
                  style: { fontFamily: "Montserrat" },
                }}
                InputLabelProps={{
                  style: { fontFamily: "Montserrat" },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormikField
                name="firstName"
                label="First Name"
                touched={touched}
                errors={errors}
                inputProps={{
                  style: { fontFamily: "Montserrat" },
                }}
                InputLabelProps={{
                  style: { fontFamily: "Montserrat" },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormikField
                name="lastName"
                label="Last Name"
                touched={touched}
                errors={errors}
                inputProps={{
                  style: { fontFamily: "Montserrat" },
                }}
                InputLabelProps={{
                  style: { fontFamily: "Montserrat" },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl 
               error={touched.gender && !!errors.gender}
              fullWidth>
                <InputLabel id="demo-simple-select-label" sx={{ fontFamily: "Montserrat" }}>Gender</InputLabel>
                <Select
                  as={Select}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="gender"
                  label="Gender"
                  IconComponent={() => (
                    <ArrowDropDownIcon style={{ marginRight: "18px" }} />
                  )}
                  value={values?.gender?.toLowerCase()}
                  sx={{
                    height: "40px",
                    fontFamily: "Montserrat",
                  }}
                  onChange={(e) => {
                    setFieldValue("gender", e.target.value);
                    setFieldTouched(`gender`, true,false);
                    validateField(`gender`, e.target.value);
                  }}
                >
                  <MenuItem sx={{ fontFamily: "Montserrat" }} value="male">Male</MenuItem>
                  <MenuItem sx={{ fontFamily: "Montserrat" }} value="female">Female</MenuItem>
                  <MenuItem sx={{ fontFamily: "Montserrat" }} value="other">Other</MenuItem>
                </Select>
                {touched.gender && errors.gender ? (
                  <Typography fontSize={12} color="error">{errors.gender}</Typography>
                ) : null}
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormikField
                name="email"
                label="Email"
                touched={touched}
                errors={errors}
                inputProps={{
                  style: { fontFamily: "Montserrat" },
                }}
                InputLabelProps={{
                  style: { fontFamily: "Montserrat" },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <FormikField
                disabled={!!values.accountId} // Set disabled based on accountId existence
                name="mobile"
                label="Mobile"
                touched={touched}
                errors={errors}
                inputProps={{
                  style: { fontFamily: "Montserrat" },
                }}
                InputLabelProps={{
                  style: { fontFamily: "Montserrat" },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker
                  //  slotProps={
                  //   errors.dob && touched.dob
                  //     ? {
                  //         textField: {
                  //           error:
                  //             !!touched.dob &&
                  //             errors.dob,
                  //           helperText: errors.dob,
                  //         },
                  //       }
                  //     : null
                  // }
                  // Here I have made some changes in slot props to change the font family to montserrat (If in future it is causing error the above slotprops was used previously you can check that...)
                  slotProps={{
                    textField: {
                      error: !!touched.dob && errors.dob,
                      helperText: errors.dob,
                      InputLabelProps: {
                        sx: { fontFamily: 'Montserrat, sans-serif' },
                      },
                      InputProps: {
                        sx: { fontFamily: 'Montserrat, sans-serif' },
                      },
                    },
                  }}
                    label="Date of Birth"
                    name="dob"
                    sx={{ width: "55rem", fontFamily:"Montserrat" }}
                    format="DD/MM/YYYY"
                    value={values.dob ? dayjs(values.dob) : null}
                    maxDate={dayjs()}
                    onChange={(newValue) =>
                      setFieldValue(
                        "dob",
                        newValue ? newValue.format("YYYY-MM-DD") : null
                      )
                    }
                    renderInput={(params) => (
                      <FormikField
                        as={TextField}
                        {...params}
                        fullWidth
                        touched={touched}
                        errors={errors}
                      />
                    )}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Grid>
            <Grid item xs={6}>
              <FormikField
                disabled
                name="balance"
                label="Wallet Balance"
                touched={touched}
                errors={errors}
                inputProps={{
                  style: { fontFamily: "Montserrat" },
                }}
                InputLabelProps={{
                  style: { fontFamily: "Montserrat" },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker
                  slotProps={{
                    textField: {
                      error: !!touched.lastLogin && errors.lastLogin,
                      helperText: errors.lastLogin,
                      InputLabelProps: {
                        sx: { fontFamily: 'Montserrat, sans-serif' },
                      },
                      InputProps: {
                        sx: { fontFamily: 'Montserrat, sans-serif' },
                      },
                    },
                  }}
                    label="Last Login"
                    name="lastLogin"
                    sx={{ width: "55rem", fontFamily:"Montserrat" }}
                    format="DD/MM/YYYY"
                    value={values.lastLogin ? dayjs(values.lastLogin) : null}
                    maxDate={dayjs()}
                    onChange={(newValue) =>
                      setFieldValue(
                        "lastLogin",
                        newValue ? newValue.format("YYYY-MM-DD") : null
                      )
                    }
                    disabled
                    renderInput={(params) => (
                      <FormikField
                        as={TextField}
                        {...params}
                        fullWidth
                        touched={touched}
                        errors={errors}
                      />
                    )}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Grid>
            <Grid item xs={6}>
              <FormikField
                name="lastOfflineActivity"
                label={"Last Offline Activity"}
                touched={touched}
                errors={errors}
                disabled
                inputProps={{
                  style: { fontFamily: "Montserrat" },
                }}
                InputLabelProps={{
                  style: { fontFamily: "Montserrat" },
                }}
              />
            </Grid>
            <Grid item xs={12}>
                  <Grid item xs={12} sx={{display:'flex', alignItems:'center'}}>
                    <Typography sx={{ fontWeight: 'bold', color: "#000", fontFamily: "Montserrat", fontSize: "15px", mr:1 }} component="legend">Activate</Typography>
                    <Switch
                      checked={values.status}
                      onChange={(event) =>
                          setFieldValue(
                              "status",
                              event.target.checked
                          )
                      }
                      name="status"
                      color="success"
                    />
                  </Grid>
                  {touched?.status &&
                    errors?.status && (
                      <Typography fontSize={12} color="error">
                        {errors?.status}
                      </Typography>
                  )}
                </Grid>
            {/* <Grid item xs={12} mt={1}>
              <Field
                as={TextField}
                name="status"
                value="Is it active?"
                fullWidth
                InputProps={{
                  readOnly: true,
                  endAdornment: (
                    <FormControlLabel
                      control={
                        <Switch
                          disabled={!(user && user?.role === "admin")}
                          checked={values.status}
                          onChange={(e) =>
                            setFieldValue(
                              "status",
                              e.target.checked
                            )
                          }
                        />
                      }
                      labelPlacement="start"
                    />
                  ),
                }}
              />
            </Grid> */}
            {/* <Grid item xs={12}>
              <FormikField
                name="comments"
                label="Comments"
                touched={touched}
                rows={3}
                errors={errors}
              />
            </Grid> */}
            {/* <Grid item xs={12}>
              <FormikField
                name="channel"
                label="Channel"
                touched={touched}
                errors={errors}
              />
            </Grid> */}
           {user && user?.role === "admin" ? (
             <Grid container spacing={1}>
             <Grid container xs={12} sx={{ mt: 4, ml: 2, pb: 2 }} flexDirection={"row-reverse"} gap={3}>
                  <MDButton
                    type="submit"
                    variant="contained"
                    circular={true}
                    color="black"
                  >
                    Save
                  </MDButton>
                  <MDButton onClick={handleClose} circular={true} variant="outlined" color="black">
                    Cancel
                  </MDButton>
                </Grid>
              </Grid>
            ) : <></>}

          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default CustomerDetailComponent;
