import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import Chip from "@mui/material/Chip";
import { Grid, Pagination as MuiPagination, Box ,IconButton, Icon} from '@mui/material';
import { useQuery,useMutation, useLazyQuery } from "@apollo/client";
import { ReactComponent as deleteIcon } from "assets/images/deleteIcon.svg";
import { dashboardServiceClient } from "graphql/client";
import Loader from "components/MDLoader";
import { SearchContext,FilterContext } from "context";
import { useContext ,useEffect} from 'react';
import { GET_BRAND_CATEGORY_STORE } from "services/brand-service";
import dayjs from 'dayjs';
import { REMOVE_BRAND_CATEGORY_STORE } from "services/brand-service";
import AgGrid from "components/MDGrid";
import { MAP_BRAND_STORE } from "services/brand-service";
import { REMOVE_BRAND_STORE } from "services/brand-service";
import { createOrderByFromOrderModel } from "layouts/cutsomer/utils";
import { useGridContext } from ".";

const user = localStorage.getItem("userDetails") && localStorage.getItem("userDetails") !== "undefined" ? JSON.parse(localStorage.getItem("userDetails")) : {};

const columns = (handleDeleteClick)=>[
  {
    field: "store",
    headerName: "Store Name",
    filter: 'agTextColumnFilter',
    width: 300,
    valueGetter: (params) => params?.data?.store?.name || '',
    getFilterObj:(value)=>({
      store:{name: value?.filter}
    }),
    getSortObj:(sortOrder)=>({store: {name:sortOrder}}),
    pivot:true,
    enableValue:true
  },
  {
    field: "start",
    headerName: "Start Date",
    width: 300,
    filter: 'agDateColumnFilter',
    getFilterObj:(value)=>({start: value?.dateFrom}),
    valueGetter: (params) =>
    params?.data?.start
      ? dayjs(params?.data?.start).format("DD-MM-YYYY")
      : "N/A",
  },

  {
    field: "end",
    headerName: "End Date",
    width: 300,
    filter: 'agDateColumnFilter',
    getFilterObj:(value)=>({end: value?.dateFrom}),
    valueGetter: (params) =>
    params?.data?.end
      ? dayjs(params?.data?.end).format("DD-MM-YYYY")
      : "N/A",
  },{
    field:"status",
    headerName:"Status",
    width: 200,
    filter: 'agSetColumnFilter',
    filterParams: {
        values: ['Active', 'Upcoming','Inactive'],
    },
    getFilterObj:(filter)=>{
      // console.log(value,"VALUE");
      return {status:filter.values?.map(val=>val?.toLowerCase())}
    },
    cellRenderer: (params) => {
        const value = params.value;
        const capitalizeFirstLetter = (str) => {
            return str.replace(/\b\w/g, char => char.toUpperCase());
          };
        return (
          <Chip
          label={capitalizeFirstLetter(value)}
          color={
              value === "active"
                ? "success"
                : value === "upcoming"
                  ? "warning"
                  : "error"
            }
            size="small"
          />
        );
      }
  },
 ...(user && user.role === "admin" ? [{
    field: 'actions',
    headerName: 'Actions',
    width: 140,
    filter:false,
    sortable: false,
    cellRenderer: (params) => (
        <IconButton aria-label="delete" >
            <Icon component={deleteIcon} onClick={(e) => handleDeleteClick(e , params?.data?.id)} fontSize="small" />
        </IconButton>
    )
}]:[])
];


function StoreAgreementLandingPage({ onRowClick,brandData }) {
  const { searchKeyword,setSearchKeyword } = useContext(SearchContext);
  const [isLoading, setIsLoading] = React.useState(false);
  const { externalGridRef } = useGridContext()

  // const { loading, error, data, refetch } = useQuery(MAP_BRAND_STORE, {
  //   client: dashboardServiceClient,
  //   variables: {listBrandStoreFilter: {
  //     take: 1000,
  //     skip: 0,
  //     search: searchKeyword,
  //     filter: { brand: { id: brandData?.id }, isDeleted: false },
  //   }},
  //   fetchPolicy: 'network-only', // Ignore cache for this query
  //   notifyOnNetworkStatusChange: true,
  // });
  const [fetchBrandStores] = useLazyQuery(MAP_BRAND_STORE, {
    client: dashboardServiceClient,
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });
  const [removeBrandStore, { loading: removing, error: removeError }] =
  useMutation(REMOVE_BRAND_STORE, {
      client: dashboardServiceClient,
      refetchQueries: [
          {
              query: MAP_BRAND_STORE,
              variables:{ listBrandStoreFilter: {
                take:20,
                skip:0,
                filter:{}
              }},
              fetchPolicy: "network-only",
          },
      ],
      onCompleted: () => {
        // refetch(); // Ensure refetch is triggered after mutation
        if (externalGridRef) {
          externalGridRef.current?.refreshServerSide({purge:true});
        }
      }
  });
  const handleDeleteClick = async (e, brandStoreId) => {
    e.stopPropagation();
    e.preventDefault();
    setIsLoading(true);
    try {
        await removeBrandStore({
            variables: { id: brandStoreId },
        });
        // await refetch();
       
    } catch (error) {
        console.error("Failed to remove:", error);
    } finally {
        setIsLoading(false);
    }
};
  if (isLoading) 
    return (
      <>
        <Loader />
      </>
    );
  if (removeError) return <p>Error: {removeError.message}</p>;

  // const storeAgreementData = data?.brandStores?.results

  const columnDefs = columns(handleDeleteClick) 
  return (
        <AgGrid
            gridProps={{
              rowHeight:60,
              defaultColDef:{
                floatingFilter: true,
                sortable: true,
                editable: false,
                filter: true,
                flex: 1,
                minWidth: 200,
              },
              pivotMode:false,
              // sideBar:{
              //   toolPanels: ['columns', 'filters'], 
              // }, 
              enableRangeSelection:true,
              onRowClicked: (row)=>{
                if(row.event.defaultPrevented) {
                  return null
                }
                onRowClick(row)
              }, // Use onRowClicked event
            }}
            // queryData={storeAgreementData}
            readMethod={{
              fetchFn: fetchBrandStores,
              inputParser: ({ startRow, endRow, filter, sortModel }) => {
                return ({
                  listBrandStoreFilter: {
                    take: endRow - startRow,
                    skip: startRow,
                    search: searchKeyword,
                    filter:{ brand: { id: brandData?.id }, isDeleted: false, ...filter },
                    orderby: createOrderByFromOrderModel(sortModel, columnDefs)
                  }
              })},
              resultsParser: ({ data }) => ({
                results: data?.brandStores?.results,
                total: data?.brandStores?.total,
              }),
            }}
            externalGridRef={externalGridRef}
            columns={columnDefs}
        />
  );
}

export default StoreAgreementLandingPage;
