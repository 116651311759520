import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import Chip from "@mui/material/Chip";
import { Grid, Pagination as MuiPagination, Box ,TablePagination} from '@mui/material';
import { useLazyQuery, useQuery } from "@apollo/client";
import { GET_CUSTOMER_LIST } from "services/customer-service";
import { dashboardServiceClient } from "graphql/client";
import Loader from "components/MDLoader";
import { SearchContext,FilterContext } from "context";
import { useContext ,useEffect} from 'react';
import AgGrid from "components/MDGrid";
import { createOrderByFromOrderModel } from "./utils";
import dayjs from "dayjs";

const columns = [
  {
    field: "name",
    headerName: "Name",
    width: 200,
    valueGetter: (params) => {
      const firstName = params.row.firstName || "";
      const lastName = params.row.lastName || "";
      return firstName || lastName ? `${firstName} ${lastName}` : "N/A";
    },
  },
  { field: "accountId", headerName: "Account ID", width: 130 },
  { field: "email", headerName: "Email", width: 200 },
  { field: "mobile", headerName: "Phone", width: 130 },
  {
    field: "walletBalance",
    headerName: "Wallet Balance",
    width: 160,
    valueGetter: (params) => params.row.account.balance,
  },
  { field: "loginDate", headerName: "Last Login", width: 160 },
  {
    field: "status",
    headerName: "Status",
    width: 130,
    renderCell: (params) => (
      <Chip
        color={params?.value === true ? "success" : "error"}
        label={params?.value === true ? "Active" : "Inactive"}
        variant="outlined"
      />
    ),
  },
  {
    field: "address1",
    headerName: "Address Line 1",
    width: 200,
    valueGetter: (params) => params.row.account?.address[0]?.addressLine1,
  },
  {
    field: "address2",
    headerName: "Address Line 2",
    width: 200,
    valueGetter: (params) => params.row.account?.address[0]?.addressLine2,
  },
  {
    field: "city",
    headerName: "City",
    width: 130,
    valueGetter: (params) => params.row.account?.address[0]?.city,
  },
  {
    field: "state",
    headerName: "State",
    width: 130,
    valueGetter: (params) => params.row.account?.address[0]?.state,
  },
  {
    field: "pincode",
    headerName: "Pincode",
    width: 130,
    valueGetter: (params) => params.row.account?.address[0]?.pincode,
  },

  {
    field: "channel",
    headerName: "Channel",
    width: 130,
    valueGetter: (params) => params.row.account.regSource,
  },
];
const columnDefs = [
  {
    field: "name",
    headerName: "Name",
    tooltipField: "firstName",
    headerTooltip: "Namex",
    width: 200,
    getFilterObj:(value)=>({
      firstName: value?.filter
    }),
    getSortObj:(sortOrder)=>({firstName: sortOrder}),
    valueGetter: (params) => {
      const firstName = params.data.firstName || "";
      const lastName = params.data.lastName || "";
      return firstName || lastName ? `${firstName} ${lastName}` : "N/A";
    },
  },
  { field: "accountId", headerName: "Account ID", width: 130,},
  { field: "email", headerName: "Email", width: 200,},
  { field: "mobile", headerName: "Phone", width: 130,},
  {
    field: "walletBalance",
    headerName: "Wallet Balance",
    width: 160,
    getFilterObj:(value)=>({
      account:{ balance: value?.filter}
    }),
    valueGetter: (params) => params.data.account.balance,
  },
  { field: "loginDate", headerName: "Last Login", width: 160, valueGetter: (params)=> params?.data?.loginDate ? dayjs(params?.data?.loginDate).format("DD/MM/YYYY") : "N/A" },
  {
    field: "status",
    headerName: "Status",
    width: 130,
    filter: 'agSetColumnFilter',
    filterParams: {
        values: ['Active', 'Inactive'],
        suppressAndOrCondition: true,
    },
    getFilterObj:(filter)=>{
      // console.log(value,"VALUE");
      return {status:filter.values?.map(val=>val=='Active')}
    },
    cellRenderer: (params) => {
      // const isActive = params.value === true;
      // return <span class="ag-cell-status ${isActive ? 'active' : 'inactive'}">${isActive ? 'Active' : 'Inactive'}</span>
      return <Chip
        color={params?.value === true ? "success" : "error"}
        label={params?.value === true ? "Active" : "Inactive"}
        variant="outlined"
      />
    },
  },
  {
    field: "address1",
    headerName: "Address Line 1",
    width: 200,
    filter:false,
    valueGetter: (params) => params.data.account?.address[0]?.addressLine1,
  },
  {
    field: "address2",
    headerName: "Address Line 2",
    width: 200,
    filter:false,
    valueGetter: (params) => params.data.account?.address[0]?.addressLine2,
  },
  {
    field: "city",
    headerName: "City",
    width: 130,
    filter:false,
    valueGetter: (params) => params.data.account?.address[0]?.city,
  },
  {
    field: "state",
    headerName: "State",
    width: 130,
    filter:false,
    valueGetter: (params) => params.data.account?.address[0]?.state,
  },
  {
    field: "pincode",
    headerName: "Pincode",
    width: 130,
    filter:false,
    valueGetter: (params) => params.data.account?.address[0]?.pincode,
  },
  {
    field: "channel",
    headerName: "Channel",
    width: 130,
    getFilterObj:(value)=>({
      account:{ regSource: value?.filter}
    }),
    valueGetter: (params) => params.data.account.regSource,
  },
];

function CustomerLandingPage({ onRowClick }) {
  const { searchKeyword,setSearchKeyword } = useContext(SearchContext);
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 20,
  });

  const { loading, error, data, refetch } = useQuery(GET_CUSTOMER_LIST, {
    client: dashboardServiceClient,
    variables: {
      take: paginationModel.pageSize,
      skip: paginationModel.page * paginationModel.pageSize,
      search: searchKeyword
    },
    fetchPolicy: 'network-only', // Ignore cache for this query
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setRowCount(data?.customerList?.total); 
    },
  });
  const [rowCount, setRowCount] = React.useState(data?.customerList?.total || 0);

  const [fetchCustomers] = useLazyQuery(GET_CUSTOMER_LIST, {
    client: dashboardServiceClient,
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  React.useEffect(() => {
    setRowCount((prevRowCount) =>
      data?.customerList?.total !== undefined
        ? data?.customerList?.total
        : prevRowCount,
    );
  }, [data?.customerList?.total, setRowCount]);
  if (loading)
    return (
      <>
        <Loader />
      </>
    );
  if (error) return <p>Error: {error.message}</p>;

  const customers = data?.customerList?.results;
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };


  
  return (
    // <DataGrid
    //  autoHeight
    //     rows={customers}
    //     columns={columns}
    //     rowCount={rowCount}
    //     pageSizeOptions={[5, 10, 20, 50, 100]}
    //     getRowId={(row) => row.id}
    //     onRowClick={onRowClick}
    //     loading={loading}
    //     onPageChange={handleChangePage}
    //     paginationModel={paginationModel}
    //     paginationMode="server"
    //     onPaginationModelChange={setPaginationModel}
    // />
    <AgGrid
      columns={columnDefs}
      readMethod={{
        fetchFn: fetchCustomers,
        inputParser: ({ startRow, endRow, filter, sortModel }) => {
          return ({
          take: endRow - startRow,
          skip: startRow,
          search: searchKeyword,
          filter,
          orderby: createOrderByFromOrderModel(sortModel, columnDefs)
        })},
        resultsParser: ({ data }) => ({
          results: data?.customerList?.results,
          total: data?.customerList?.total,
        }),
      }}
      gridProps={{
        onRowClicked:({data})=>onRowClick({row:data}),
      }}
      defaultColDefProp={{filter: "agTextColumnFilter"}}
    />
  );
}

export default CustomerLandingPage;
