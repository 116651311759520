import React from "react";
import DashboardLayout from "../../ui/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../ui/Navbars/DashboardNavbar";
import EcommerceMediaTab from "./tab";

function EcommerceMediaIndex() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <EcommerceMediaTab />
    </DashboardLayout>
  );
}

export default EcommerceMediaIndex;
