import React, { useState } from "react";
import MasterAttributeLandingPage from "./masterAttributeLandingPage";
import { Grid, Box, Icon, Button, Drawer, Typography } from "@mui/material";
import DashboardLayout from "ui/LayoutContainers/DashboardLayout";
import DashboardNavbar from "ui/Navbars/DashboardNavbar";
import { ReactComponent as SearchIcon } from "assets/images/searchIcon.svg";
import { ReactComponent as CustomerIcon } from "assets/images/customer.svg";
import { Add as AddIcon } from "@mui/icons-material";
import AttributesForm from "./atrributeForm";
import MDButton from "components/MDButton";
import axios from "axios";

function MasterAttribute() {
  const [open, setOpen] = useState(false);
  const [selectedAttribute, setSelectedAttribute] = useState(null);

  const handleRowClick = (params) => {
    setSelectedAttribute(params.row);
    handleOpen();
  };

  const handleExportToExcel = () => {
    // fetchMasterAttributes({
    //   onCompleted: (data) => {
    //     const sanitizedData = data?.masterAttributes?.results?.map((item) =>({
    //       ...item,
    //       values: JSON.stringify(item.values)
    //     })
    //     );
    //     const worksheet = XLSX.utils.json_to_sheet(sanitizedData);
    //     const workbook = XLSX.utils.book_new();
    //     XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      
    //     XLSX.writeFile(workbook, "master-attributes.xlsx");
    //   },
    // })
    axios({
      url:
        process.env.REACT_APP_NODE_ENV === "development"
          ? `${process.env.REACT_APP_API_BASE_URL}/dashboard-service/master-attribute/export`
          : `dashboard-service/master-attribute/export`,
      method: "post",
      // url: "http://localhost:3002/master-attribute/export",
      responseType: "blob",data:{take:100,skip:0,search:''},
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response.data])
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "master-attribute.xlsx"); // 设置下载文件名
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("error:", error);
      });
  };
  const ControlSection = ({ handleOpen, setCategoryData ,user}) => (
    user && user?.role === "admin" ? (<>
      <Grid item xs={12} sm={6} md={6} />
      <Grid item xs={12} sm={6} md={6} textAlign="right">
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            mb:1
          }}
          gap={2}
        >
          {/* <Icon sx={{ mr: 2 }} component={SearchIcon} fontSize="large" /> */}
          <MDButton
            type="button"
            variant="contained"
            color='black'
            circular={true} 
            onClick={() => {
              setOpen(true);
              setSelectedAttribute(null);
            }}
            startIcon={<AddIcon />}
          >
            New Attribute
          </MDButton>
          
          <MDButton
            type="button"
            variant="contained"
            color="black"
            sx={{
              fontFamily: "Montserrat",
              fontSize: "15px",
              fontWeight: 400,
              ml:2
            }}
            circular={true}
            startIcon={<AddIcon />}
            onClick={() => {
              handleExportToExcel();
            }}
          >
            Export
          </MDButton>

        </Box>
      </Grid>
    </>) : <></>
   
  );
  const AttributeDrawer = ({ open, handleClose, selectedAttribute }) => (
    <Drawer
      anchor="right"
      open={open}
      onClose={handleClose}
      PaperProps={{ sx: { width: "50%" } }}
    >
      <Grid
        container
        sx={{ backgroundColor: "#F0F2F5", pb: 3, pt: 2, pl: "5px" }}
      >
        <Grid item xs={12}>
          &nbsp;
        </Grid>
        <Grid item xs={12}>
          <Icon
            sx={{ mr: 2, ml: 2 }}
            component={CustomerIcon}
            fontSize="large"
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" sx={{ ml: 2 }}>
          Attribute
          </Typography>
        </Grid>
      </Grid>
      <Box sx={{ width: "100%", minHeight: "70vh" }}>
        <AttributesForm user={user} attributeData={selectedAttribute} handleClose={handleClose}/>
      </Box>
    </Drawer>
  );
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const user = localStorage.getItem("userDetails") && localStorage.getItem("userDetails") !== "undefined" ? JSON.parse(localStorage.getItem("userDetails")) : {};

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid item xs={12} sm={6} md={3}>
        <Grid display={"flex"} justifyContent={"space-between"}>
          <Typography sx={{fontFamily:"montserrat", color:"#000", fontWeight:"700"}} ml={1}>Master Attribute</Typography>
          <ControlSection handleOpen={handleOpen} user={user}/>
        </Grid>
        <MasterAttributeLandingPage onRowClick={handleRowClick} />
        <AttributeDrawer
          open={open}
          handleClose={handleClose}
          selectedAttribute={selectedAttribute}
        />
      </Grid>
    </DashboardLayout>
  );
}

export default MasterAttribute;
