import * as React from "react";
import { useEffect } from "react";
import Box from "@mui/material/Box";
import { RadioGroup, FormControlLabel, Radio } from "@mui/material";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import dayjs from "dayjs";
import { GET_STORES_LIST } from "services/store-service";
import { dashboardServiceClient } from "graphql/client";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useGridApiContext } from "@mui/x-data-grid";
import {
  GridRowModes,
  DataGrid,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from "@mui/x-data-grid";
import "./style.css";
import * as Yup from "yup";
import Loader from "components/MDLoader";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { CREATE_BRAND_CATEGORY_STORE } from "services/brand-service";
import { GET_BRAND_CATEGORY_STORE } from "services/brand-service";
import { UPDATE_BRAND_CATEGORY_STORE } from "services/brand-service";
import { REMOVE_BRAND_CATEGORY_STORE } from "services/brand-service";

const validationSchema = Yup.object().shape({
  storeId: Yup.string().required("Store Name is required"),
  categoryId: Yup.string().required("Category is required"),
  start: Yup.date()
    .required("Start date is required")
    .when("id", (id, yup) =>
      id
        ? yup
        : yup.min(dayjs().add(1, "day"), "Start date should be in the future")
    ),
  end: Yup.date()
    .required("End date is required")
    .min(dayjs().add(1, "day"), "End date should be in the future")
    .when(
      "start",
      (start, yup) =>
        start && yup.min(start, "End date should be later than start date")
    ),
});

export default function FullFeaturedCrudGrid({
  categoryOptions,
  brandData,
  tabValue,
}) {
  const user =
    localStorage.getItem("userDetails") &&
    localStorage.getItem("userDetails") !== "undefined"
      ? JSON.parse(localStorage.getItem("userDetails"))
      : {};
  const [rows, setRows] = React.useState([]);
  const [snackbar, setSnackbar] = React.useState(null);
  const [marginFlag, setMarginFlag] = React.useState(null);

  const [rowModesModel, setRowModesModel] = React.useState({});
  const {
    loading: storeLoading,
    error: storeError,
    data: storeList,
  } = useQuery(GET_STORES_LIST, {
    client: dashboardServiceClient,
    variables: { listStoreFilter: { take: 100, skip: 0 } },
  });

  const [
    getBrandCategoryStoreList,
    {
      data: brandCategoryStoreList,
      loading: brandCategoryStoreLoading,
      error: brandCategoryStoreError,
    },
  ] = useLazyQuery(GET_BRAND_CATEGORY_STORE, {
    client: dashboardServiceClient,
    variables: {
      take: 1000000,
      skip: 0,
      filter: { brand: { id: brandData?.id }, isDeleted: false },
    },
  });

  const [createBrandCategoryStore, { data, loading, error }] = useMutation(
    CREATE_BRAND_CATEGORY_STORE,
    {
      client: dashboardServiceClient,
      refetchQueries: [
        {
          query: GET_BRAND_CATEGORY_STORE,
          variables: {
            take: 1000000,
            skip: 0,
            filter: { brand: { id: brandData?.id }, isDeleted: false },
          },
        },
      ],
    }
  );

  const [
    updateBrandCategoryStore,
    { data: updateData, loading: updateLoading, error: updateError },
  ] = useMutation(UPDATE_BRAND_CATEGORY_STORE, {
    client: dashboardServiceClient,
    refetchQueries: [
      {
        query: GET_BRAND_CATEGORY_STORE,
        variables: {
          take: 1000000,
          skip: 0,
          filter: { brand: { id: brandData?.id } },
        },
      },
    ],
  });

  const [
    removeBrandCategoryStore,
    { data: removeData, loading: removeLoading, error: removeError },
  ] = useMutation(REMOVE_BRAND_CATEGORY_STORE, {
    client: dashboardServiceClient,
    refetchQueries: [
      {
        query: GET_BRAND_CATEGORY_STORE,
        variables: {
          take: 1000000,
          skip: 0,
          filter: { brand: { id: brandData?.id }, isDeleted: false },
        },
      },
    ],
  });

  useEffect(() => {
    if (storeError) {
      console.error("Error fetching stores:", storeError);
    }
    if (storeLoading) {
      <Loader />;
    }
  }, [storeError]);

  useEffect(() => {
    // call the getList api here
    if (brandData && brandData.id) getBrandCategoryStoreList();
  }, []);

  useEffect(() => {
    // set the row data here
    const rows =
      brandCategoryStoreList && brandCategoryStoreList?.brandCategoryStores
        ? brandCategoryStoreList?.brandCategoryStores?.results?.map((item) => ({
            id: item.id,
            storeId: item.store.id,
            categoryId: item.category.id,
            start: new Date(item.start),
            end: new Date(item.end),
            brandId: item?.brand?.id,
            // margin: item.markup || item.markdown,
            // markup: item?.markup,
            // markdown: item?.markdown,
            margin: item.markup > 0 ? item.markup : item.markdown,
            marginStructure:
              item.markup || item.markdown
                ? item.markup > 0
                  ? "markup"
                  : "markdown"
                : "",
          }))
        : [];
    setRows(rows);
  }, [brandCategoryStoreList]);

  const handleCloseSnackbar = () => setSnackbar(null);

  const handleMarkUp = (params) => {
    const editRow = rows.find((row) => row.id === params.id);
    editRow.marginStructure = "markup";
    setRows(rows.map((row) => (row.id === params.id ? editRow : row)));
    setMarginFlag((prev) => ({ ...prev, [params.id]: "markup" }));
  };

  const handleMarkDown = (params) => {
    const editRow = rows.find((row) => row.id === params.id);
    editRow.marginStructure = "markdown";
    setRows(rows.map((row) => (row.id === params.id ? editRow : row)));
    setMarginFlag((prev) => ({ ...prev, [params.id]: "markdown" }));
  };

  const handleAddClick = () => {
    const id = Date.now().toString() + Math.random().toString();
    setRows((oldRows) => [
      ...oldRows,
      {
        id,
        storeId: "",
        categoryId: "",
        start: new Date(),
        end: new Date(),
        isNew: true,
      },
    ]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: "category" },
    }));
  };
  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => async () => {
    try {
      await removeBrandCategoryStore({ variables: { id } });
      setRows(rows.filter((row) => row.id !== id));
    } catch (error) {
      console.error(error.message);
      setSnackbar({ children: error.message, severity: "error" });
    }
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = async (newRow) => {
    let updatedRow = { ...newRow, isNew: false };
    try {
      // Validate the rowData object against the schema
      const currentValidationSchema =
        tabValue === 2
          ? validationSchema.concat(
              Yup.object().shape({
                margin: Yup.number()
                  .required("Margin is required")
                  .min(1, "Margin cannot be less than 1")
                  .max(100, "Margin cannot be more than 100"),
                marginStructure: Yup.string()
                  .required("Margin structure is required")
                  .oneOf(
                    ["markup", "markdown"],
                    "Margin structure must be either markup or markdown"
                  ),
              })
            )
          : validationSchema;
      await currentValidationSchema.validate(newRow);

      const uuidRegex =
        /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;

      console.log("row id", uuidRegex.test(newRow.id), newRow.id);
      // If validation passes, change the view
      if (!uuidRegex.test(newRow.id)) {
        // create new record
        const response = await createBrandCategoryStore({
          variables: {
            createBrandCategoryStoreInput: {
              categoryId: newRow.categoryId,
              storeId: newRow.storeId,
              start: newRow.start,
              end: newRow.end,
              brandId: brandData.id,
              markup: newRow.marginStructure === "markup" ? newRow.margin : 0,
              markdown:
                newRow.marginStructure === "markdown" ? newRow.margin : 0,
            },
          },
        });
        console.log(response.data.createBrandCategoryStore);
        setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
        updatedRow = {
          ...updatedRow,
          id: response?.data?.createBrandCategoryStore?.id,
        };
      } else {
        // update record
        const response = await updateBrandCategoryStore({
          variables: {
            updateBrandCategoryStoreInput: {
              id: newRow.id,
              categoryId: newRow.categoryId,
              storeId: newRow.storeId,
              start: newRow.start,
              end: newRow.end,
              brandId: brandData.id,
              markup: newRow.marginStructure === "markup" ? newRow.margin : 0,
              markdown:
                newRow.marginStructure === "markdown" ? newRow.margin : 0,
            },
          },
        });
        console.log(response.data.updateBrandCategoryStore);
      }

      return updatedRow;
    } catch (error) {
      // Handle validation errors here...
      setSnackbar({ children: error.message, severity: "error" });
      console.error(error);
      // You could show an error message to the user here...
    }
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const validateStartDate = (value) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Remove time part
    const selectedDate = new Date(value);
    if (selectedDate < today) {
      return { hasError: true, error: "Start date should be in the future" };
    }
    return { hasError: false, error: "" };
  };

  const validateEndDate = (value, startValue) => {
    const startDate = new Date(startValue);
    const endDate = new Date(value);
    if (endDate <= startDate) {
      return {
        hasError: true,
        error: "End date should be later than start date",
      };
    }
    return { hasError: false, error: "" };
  };

  const columns = [
    {
      field: "categoryId",
      headerName: "Category",
      editable: user && user?.role === "admin" && tabValue === 1 ? true : false,
      flex: 1,
      type: "singleSelect",
      valueOptions: categoryOptions
        ?.filter((item) => !item?.isDeleted)
        .map((category) => ({
          value: category.id,
          label: category.metaTitle,
        })),
      preProcessEditCellProps: (params) => {
        const hasError = !params.props.value; // Simplified validation: check if value is truthy
        return { ...params.props, error: hasError };
      },
    },
    {
      field: "storeId",
      headerName: "Store",
      editable: user && user?.role === "admin" && tabValue === 1 ? true : false,
      type: "singleSelect",
      flex: 1,
      valueOptions: storeLoading
        ? []
        : storeList?.stores?.results
            ?.filter((item) => item.status === "Active")
            .map((store) => ({
              value: store.id,
              label: store.name,
            })) || [],
      preProcessEditCellProps: (params) => {
        const hasError = !params.props.value; // Again, simplified validation
        return { ...params.props, error: hasError };
      },
    },
    {
      field: "start",
      headerName: "Start date",
      type: "date",
      flex: 1,
      editable: user && user?.role === "admin" && tabValue === 1 ? true : false,
      preProcessEditCellProps: (params) => {
        const { hasError, error } = validateStartDate(params.props.value);
        return { ...params.props, error: hasError, helperText: error };
      },
    },
    {
      field: "end",
      headerName: "End date",
      type: "date",
      flex: 1,
      editable: user && user?.role === "admin" && tabValue === 1 ? true : false,
      preProcessEditCellProps: (params) => {
        const startValue =
          params.api && params.api.getRow(params.id)
            ? params.api.getRow(params.id).start
            : null;
        const { hasError, error } = validateEndDate(
          params.props.value,
          startValue
        );
        return { ...params.props, error: hasError, helperText: error };
      },
    },
    {
      field: "marginStructure",
      headerName: "Margin Structure",
      width: 250,
      editable: false,
      renderCell: (params) => {
        // Get the API context for handling changes
        const apiRef = useGridApiContext();
        const isInEditMode =
          rowModesModel[params.id]?.mode === GridRowModes.Edit;

        const getEditMode = user && user?.role === "admin" && !isInEditMode;
        // const editedRow = rows.find((row) => row.id === params.id);
        // console.log(editedRow)
        return (
          <RadioGroup
            sx={{ display: "contents" }}
            row
            name="margin-structure"
            value={params.value}
            onChange={(event) => {
              apiRef.current.setEditCellValue({
                id: params.id,
                field: params.field,
                value: event.target.value,
              });
            }}
          >
            <FormControlLabel
              value="markup"
              control={
                <Radio
                  disabled={user && user?.role !== "admin" ? true : getEditMode}
                  onChange={() => handleMarkUp(params)}
                  // checked={marginFlag?.[params?.id] === "markup" || (editedRow?.markup !== 0  && editedRow?.markup !== null)}
                />
              }
              label="Mark-up"
            />
            <FormControlLabel
              value="markdown"
              control={
                <Radio
                  disabled={user && user?.role !== "admin" ? true : getEditMode}
                  onChange={() => handleMarkDown(params)}
                  // checked={marginFlag?.[params?.id] === "markdown" || (editedRow?.markdown !== 0  && editedRow?.markdown !== null)}
                />
              }
              label="Mark-down"
            />
          </RadioGroup>
        );
      },
    },
    {
      field: "margin",
      headerName: "Margin%",
      align:'center',
      headerAlign:'center',
      flex: 1,
      type: "number",
      editable: user && user?.role === "admin",
    },
    {
      field: "actions",
      type: "actions",
      flex: 1,
      headerName: "Actions",
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          tabValue !== 2 ? (
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={handleDeleteClick(id)}
              color="inherit"
            />
          ) : (
            <></>
          ),
        ];
      },
    },
  ];

  if (brandCategoryStoreLoading || loading || updateLoading) return <Loader />;
  if (brandCategoryStoreError || error || updateError)
    return (
      <Snackbar
        open
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={handleCloseSnackbar}
        autoHideDuration={6000}
      >
        <Alert
          severity="error"
          children="Error while service aggrement operation"
          onClose={handleCloseSnackbar}
        />
      </Snackbar>
    );
  return (
    <Box className="custom">
      <DataGrid
        autoHeight
        rows={rows}
        columns={columns}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        pagination={false}
        hideFooter={true}
        // rowHeight={51}
        columnVisibilityModel={{
          margin: tabValue === 2,
          marginStructure: tabValue === 2,
          actions: user && user?.role === "admin",
        }}
      />
      {tabValue && tabValue === 1 && user && user?.role === "admin" && (
        <Button
          startIcon={<AddIcon />}
          onClick={handleAddClick}
          sx={{ color: "#344767" }}
        >
          Add New Service Agreement
        </Button>
      )}

      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={handleCloseSnackbar}
          autoHideDuration={6000}
        >
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
    </Box>
  );
}
