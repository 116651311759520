import JSZip from "jszip";
import * as XLSX from "xlsx";
import {saveAs} from "file-saver"

export const sumDurations = (timesArray) => {
    if (!Array.isArray(timesArray)) {
        console.error("Invalid input: timesArray is not an array", timesArray);
        return "0s"; // Return a default or error value
    }

    // Convert the input to a string if it's not already, and make sure it's a string representation of time
    const toSeconds = (timeStr) => {
        timeStr = String(timeStr);
        if (timeStr.includes("s")) {
            return parseFloat(timeStr);
        } else if (timeStr.includes(":")) {
            const [hours, minutes, seconds] = timeStr.split(":").map(parseFloat);
            return hours * 3600 + minutes * 60 + seconds;
        }
        return parseFloat(timeStr);
    };

    // Convert all time strings to seconds and sum them
    const totalSeconds = timesArray.reduce((sum, time) => {
        if (typeof time === "string" || typeof time === "number") {
            return sum + toSeconds(time);
        } else {
            console.error("Invalid time value:", time);
            return sum; // Ignore invalid input and continue accumulating
        }
    }, 0);

    // Return the total in seconds with one decimal place
    return `${totalSeconds.toFixed(1)}s`;
}

export const extractFileType = (url) => {
    // Regular expression to match the file extension
    const fileTypeRegex = /\.([a-zA-Z0-9]+)(?=\?|$)/;
    
    // Apply the regex to the URL
    const match = url.match(fileTypeRegex);
    
    // Extract and return the file type
    if (match && match.length > 1) {
        return match[1];
    } else {
        return null; // Return null if no match is found
    }
};

export const zipFiles = async (zipFileName, files)=>{
    const zip = JSZip();
    const videosFolder = zip.folder("videos");
    const successfullyAddedFiles = [];
    for (let i = 0; i < files.length; i++) {
        const url = files[i].url;
        try {
            // Fetch the file
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error(`Failed to fetch file: ${response.statusText}`);
            }
    
            // Convert response to ArrayBuffer
            const fileData = await response.arrayBuffer();
    
            // Determine file extension
            const fileType = extractFileType(url);
            if(fileType==null) continue;
            // Add file to zip
            // zip.file(`${files[i].name}`, fileData,{ type: 'binary' });
            videosFolder.file(`${files[i].name}`, fileData, { type: 'binary' });
            successfullyAddedFiles.push({ name: files[i].name, order: files[i].order });
    
            // console.log('Zip file created and downloaded successfully!');
        } catch (error) {
            console.error('Error zipping file:', error);
        }
        
    }
    const excelData = successfullyAddedFiles.length > 0 ? successfullyAddedFiles : [{ name: "", order: "" }];

    // Generate Excel file
    const worksheet = XLSX.utils.json_to_sheet(excelData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Videos');
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    
    // Add Excel file to zip
    zip.file('videos_info.xlsx', excelBuffer, { binary: true });
    
    zip.generateAsync({ type: 'blob' }).then((content) => {
        saveAs(content, `${zipFileName}.zip`);
        console.log('Zip file created and downloaded successfully!');
    }).catch((error) => {
        console.error('Error generating zip file:', error);
    });
}