import * as React from "react";
import { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Chip from "@mui/material/Chip";
import dayjs from 'dayjs';
import { Grid, Pagination as MuiPagination, Box ,TablePagination,Button, IconButton,Icon} from '@mui/material';
import { useQuery } from "@apollo/client";
import { GET_PROMOTION_LIST } from "services/promotion-service";
import { BRANDS_LIST } from "services/brand-service";
import { dashboardServiceClient } from "graphql/client";
import Loader from "components/MDLoader";
import { SearchContext,FilterContext } from "context";
import { useContext ,useEffect} from 'react';
import { ReactComponent as copyIcon } from "assets/images/copyIcon.svg";
import { ReactComponent as commentIcon } from "assets/images/commentIcon.svg";
import { ReactComponent as downloadIcon } from "assets/images/downloadIcon.svg";
import { ReactComponent as deleteIcon } from "assets/images/deleteIcon.svg";



function PromotionLandingPage({ onRowClick, selectedPromotionId,onDuplicate,user }) {
  const [loadingBrand, setLoadingBrand] = useState(false);
  const getStatusChipProps = (status) => {
    switch (status.toLowerCase()) {
      case "draft":
        return { label: "Draft", color: "default" };
      case "pending":
        return { label: "Pending", color: "warning" };
      case "approved":
        return { label: "Approved", color: "success" };
      case "rejected":
        return { label: "Rejected", color: "error" };
      case "live":
        return { label: "Live", color: "primary" };
      default:
        return { label: status, color: "default" };
    }
  };

  const columns = [
    { field: "id", headerName: "ID", width: 160 },
    {
      field: "promotionBrand",
      headerName: "Brand",
      width: 200,
      valueGetter: (params) => {
        return params.row.promotionBrand
          ?.filter((item) => !item?.isDeleted)
          .map((item) => {
            const brandProfileMetadata = item.brand.brandMetaData?.find(meta => meta.key === "brandCompanyProfile");
            return brandProfileMetadata ? brandProfileMetadata.value.brandName : "";
          });
      }
    },
    // { field: "email", headerName: "Store", width: 200 },

    { field: "name", headerName: "Promotion Name", width: 160 },

    { field: "start", headerName: "From Date", width: 160 ,
      valueGetter: (params) => {
        return params.row.start
         ? dayjs(params.row.start).format("YYYY-MM-DD")
          : "N/A";
      },   
   },
    { field: "end", headerName: "To Date", width: 160,
    valueGetter: (params) => {
      return params.row.end
       ? dayjs(params.row.end).format("YYYY-MM-DD")
        : "N/A";
    }, 
    },

    {
      field: "status",
      headerName: "Status",
      width: 160,
        renderCell: (params) => {
      const { label, color } = getStatusChipProps(params.row.status);
      return <Chip label={label} color={color} size="small" />;
    },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 240,
      sortable: false,
      renderCell: (params) => (
        <>
          <IconButton onClick={(event) => onDuplicate(params.row, event)} >
            <Icon component={copyIcon} fontSize="small" />
          </IconButton>
          <IconButton aria-label="save" onClick={() => alert("Clicked")}>
            <Icon component={commentIcon} fontSize="small" />
          </IconButton>
          <IconButton aria-label="download" >
            <Icon component={downloadIcon} fontSize="small" />
          </IconButton>
          <IconButton aria-label="delete" >
            <Icon component={deleteIcon} fontSize="small" />
          </IconButton>
        </>
      )
    },
  ];
  const { loading:brandLoading, data:brandlist, error:brandError,refetch:brandRfetch } = useQuery(BRANDS_LIST, {
    onCompleted: () => setLoadingBrand(false),
    client: dashboardServiceClient,
    variables: {
      filter:
        user && user?.role === "admin"
          ? {}
          : {
            id: user?.userId,
          },
    },
    skip: true // Initially skip the query
  });
  const { searchKeyword,setSearchKeyword } = useContext(SearchContext);
  const {selectedBrands}=useContext(FilterContext)
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 20,
  });

  const { loading, error, data, refetch } = useQuery(GET_PROMOTION_LIST, {
    client: dashboardServiceClient,
    variables: {
      take: paginationModel.pageSize,
      skip: paginationModel.page * paginationModel.pageSize,
      search: searchKeyword,
      filter :{
        promotionBrand:{
          isDeleted:false,
          brand:user && user?.role === "admin" ? {id:selectedBrands} : {id : user.userId}
        }
      }
    },
    fetchPolicy: 'network-only', // Ignore cache for this query
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setRowCount(data?.promotions?.total); 
      brandRfetch();

    },
  });
  const [rowCount, setRowCount] = React.useState(data?.promotions?.total || 0);
  React.useEffect(() => {
    setPaginationModel({
      ...paginationModel,
      page: 0,
    });  }, [selectedBrands]);
  React.useEffect(() => {
    setRowCount((prevRowCount) =>
      data?.promotions?.total !== undefined
        ? data?.promotions?.total
        : prevRowCount,
    );
  }, [data?.promotions?.total, setRowCount]);
  if (loading || loadingBrand)
    return (
      <>
        <Loader />
      </>
    );
  if (error) return <p>Error: {error.message}</p>;

  const promotions = data?.promotions?.results;
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  
  return (
        <DataGrid
         autoHeight
            rows={promotions}
            columns={columns}
            rowCount={rowCount}
            pageSizeOptions={[10, 20, 50, 100]}
            getRowId={(row) => row.id}
            onRowClick={(params) => onRowClick(params.row)}
            loading={loading}
            onPageChange={handleChangePage}
            paginationModel={paginationModel}
            paginationMode="server"
            onPaginationModelChange={setPaginationModel}
        />
  );
}

export default PromotionLandingPage;
