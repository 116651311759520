import { Button, Grid, TextField, FormControl } from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import dayjs from "dayjs";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import React,{useEffect} from "react";
import { useMutation,useQuery } from "@apollo/client";
import { dashboardServiceClient } from "graphql/client";
import { GET_STORES } from "services/store-service";
import { CREATE_STORE_METADATA } from "services/store-service";
import { UPDATE_STORE_METADATA } from "services/store-service";
import { useNotification } from "context";
import MDButton from "components/MDButton";
import Loader from "components/MDLoader";

const validationSchema = Yup.object({
  week: Yup.array()
    .of(
      Yup.object({
        day: Yup.string().required("Day is required"),
        start: Yup.string().required("Start time is required"),
        end: Yup.string()
          .required("End time is required")
          .test(
            "is-greater",
            "End time should be greater than start time",
            function (value) {
              const { start } = this.parent;
              return dayjs(value, "HH:mm").isAfter(dayjs(start, "HH:mm"));
            }
          ),
      })
    )
    .required("Week data is required"),
});

const useStoreMetadataMutation = (
  storeData,
  storeMetadata,
  setNotification,
  handleTabChange,
  setStoreData
) => {
  const mutationOptions = {
    client: dashboardServiceClient,
    refetchQueries: [{ query: GET_STORES, variables: {listStoreFilter :{take:20, skip:0, search: "" }} }],
  };

  const [createStoreMutation] = useMutation(
    CREATE_STORE_METADATA,
    mutationOptions
  );
  const [updateStoreMutation] = useMutation(
    UPDATE_STORE_METADATA,
    mutationOptions
  );

  const handleSubmit = (values) => {
    console.log(values);

    const mutation =
      storeMetadata && storeMetadata.id
        ? updateStoreMutation
        : createStoreMutation;
    const mutationInput =
      storeMetadata && storeMetadata.id
        ? {
            updateStoreMetadataInput: {
              id: storeMetadata.id,
              key: "calendar",
              value: { ...values },
            },
          }
        : {
            createStoreMetaDataInput: {
              storeId: storeData.id,
              key: "calendar",
              value: { ...values },
            },
          };

    mutation({ variables: mutationInput })
      .then((res) => {
        console.log(
          res.data[
            storeData && storeData.id
              ? "updateStoreMetadata"
              : "createStoreMetadata"
          ]
        );
        setStoreData(storeData);
        handleTabChange(null, 3);
        setNotification({
          color: "success",
          isVisible: true,
          message:
            storeData && storeData.id
              ? "Store calendar data updated successfully"
              : "Store calendar data created successfully",
        });
      })
      .catch((error) => {
        console.error(" Error:", error.message);
        setNotification({
          color: "error",
          isVisible: true,
          message: error.message || "Something went wrong",
        });
      });
  };

  return handleSubmit;
};

const StoreDetailsCalender = ({
  storeData,
  handleClose,
  handleTabChange,
  setStoreData,
}) => {

 const  {data , loading, error} = useQuery( GET_STORES,{
  client: dashboardServiceClient,
  variables: {listStoreFilter :{take:20, skip:0, search: "" }}
 })
 const storeData1 = data?.stores?.results?.find(store => store?.id === storeData?.id)
  const isCalendarPresent =
    (storeData1 &&
      storeData1?.storeMetaData &&
      storeData1?.storeMetaData.length &&
      [...storeData1?.storeMetaData]
        .reverse()
        .find((item) => item.key === "calendar")) ||
    null;
  const initialValues = isCalendarPresent
    ? isCalendarPresent.value
    : {
        week: [
          { day: "Sun", start: "", end: "" },
          { day: "Mon", start: "", end: "" },
          { day: "Tue", start: "", end: "" },
          { day: "Wed", start: "", end: "" },
          { day: "Thu", start: "", end: "" },
          { day: "Fri", start: "", end: "" },
          { day: "Sat", start: "", end: "" },
        ],
      };

  const { setNotification } = useNotification();
  const handleSubmit = useStoreMetadataMutation(
    storeData,
    isCalendarPresent,
    setNotification,
    handleTabChange,
    setStoreData
  );

  if (loading) return <Loader />;
  if (error) return console.log(error);
  return (
    <Formik
      initialValues={initialValues}
      validateOnChange={true}
      validateOnBlur={true}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        console.log(values, storeData);
        handleSubmit(values);
      }}
    >
      {({
        values,
        setFieldValue,
        setFieldTouched,
        validateField,
        errors,
        touched,
      }) => (
        <Form>
          <Grid container spacing={2}>
            {values.week.map((_, index) => (
              <Grid container item xs={12} key={index} spacing={2}>
                <Grid item xs={2}>
                  <Field
                    name={`week[${index}].day`}
                    as={TextField}
                    label="Day"
                    required
                    disabled
                  />
                </Grid>
                <Grid item xs={5}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["TimePicker"]}>
                      <TimePicker
                        slotProps={
                          errors &&
                          errors?.week &&
                          errors?.week.length &&
                          errors?.week[index]?.start &&
                          touched &&
                          touched?.week &&
                          touched?.week?.length &&
                          touched?.week[index]?.start
                            ? {
                                textField: {
                                  error:
                                    !!touched?.week[index]?.start &&
                                    errors?.week[index]?.start,
                                  helperText: errors?.week[index]?.start,
                                },
                              }
                            : null
                        }
                        label="Start Time"
                        name={`week[${index}].start`}
                        maxTime={dayjs(
                          values.week[index].end,
                          "HH:mm"
                        ).subtract(1, "minute")}
                        value={
                          values?.week[index]?.start
                            ? dayjs(values?.week[index]?.start, "HH:mm")
                            : null
                        }
                        onChange={(newValue) => {
                          setFieldValue(
                            `week[${index}].start`,
                            dayjs(newValue).format("HH:mm")
                          );
                          setFieldTouched(`week[${index}].start`, true,false); // Changed
                          validateField(`week[${index}].start`);
                        }}
                        viewRenderers={{
                          hours: renderTimeViewClock,
                          minutes: renderTimeViewClock,
                          seconds: renderTimeViewClock,
                        }}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={5}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["TimePicker"]}>
                      <TimePicker
                        slotProps={
                          errors &&
                          errors?.week &&
                          errors?.week?.length &&
                          errors?.week[index]?.end &&
                          touched &&
                          touched?.week &&
                          touched?.week?.length &&
                          touched?.week[index]?.end
                            ? {
                                textField: {
                                  error:
                                    !!touched?.week[index]?.end &&
                                    errors.week[index].end,
                                  helperText: errors.week[index].end,
                                },
                              }
                            : null
                        }
                        label="End Time"
                        name={`week[${index}].end`}
                        minTime={dayjs(values.week[index].start, "HH:mm").add(
                          1,
                          "minute"
                        )}
                        value={
                          values.week[index].end
                            ? dayjs(values.week[index].end, "HH:mm")
                            : null
                        }
                        onChange={(newValue) => {
                          setFieldValue(
                            `week[${index}].end`,
                            dayjs(newValue).format("HH:mm")
                          );
                          setFieldTouched(`week[${index}].end`, true,false);
                          validateField(`week[${index}].end`);
                        }}
                        viewRenderers={{
                          hours: renderTimeViewClock,
                          minutes: renderTimeViewClock,
                          seconds: renderTimeViewClock,
                        }}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </Grid>
              </Grid>
            ))}
            <Grid container xs={12} sx={{ mt: 4, ml: 2, pb: 2 }} flexDirection={"row-reverse"} gap={3}>
                <MDButton
                  type="submit"
                  variant="contained"
                  circular={true}
                  color="black"
                >
                  Save
                </MDButton>
                <MDButton color="black" onClick={handleClose} variant="outlined" circular={true}>
                  Cancel
                </MDButton>
              </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default StoreDetailsCalender;
