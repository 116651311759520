import React, { useEffect, useState } from "react";
import {
  Paper,
  IconButton,
  Card,
  CardMedia,
  Typography,
  Grid,
} from "@mui/material";
import SwipeableViews from "react-swipeable-views";
import { virtualize } from "react-swipeable-views-utils";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { styled } from "@mui/material/styles";

const VirtualizeSwipeableViews = virtualize(SwipeableViews);

const MobileFrame = styled(Paper)({
  maxWidth: "300px",
  minWidth: "299px",
  height: "667px",
  margin: "auto",
  marginLeft: "5%",
  border: "16px solid #000",
  borderRadius: "36px",
  boxShadow: "0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)",
  position: "sticky",
  display: "flex",
  flexDirection: "column",
  backgroundColor: "#fff",
  marginTop: 10,
  top: "11%",
  zIndex: 1200,
});

const Header = styled("div")({
  backgroundColor: "#344767",
  color: "#FFFFFF",
  fontSize: "16px",
  borderTopLeftRadius:"10px",
  borderTopRightRadius:"10px",
  textAlign: "center",
  fontWeight: "bold",
  zIndex: 2,
});

const ScrollableContent = styled("div")({
  overflow: "auto",
  flex: "1",
  display: "flex",
  flexDirection: "column",
  "-ms-overflow-style": "none",
  "scrollbar-width": "none",
  "&::-webkit-scrollbar": {
    display: "none",
  },
});
const ThumbnailsContainer = styled(Grid)({
  position: "relative",
  padding: "10px 0",
});

const CarouselContainer = styled("div")({
  position: "relative",
  padding: "10px 0",
});

const ArrowButton = styled(IconButton)({
  position: "absolute",
  top: "50%",
  transform: "translateY(-50%)",
  zIndex: 1,

  backgroundColor: "transparent",
  "&:hover": {
    backgroundColor: "rgba(0, 0, 0, 0.04)", // You can adjust this for the hover state
  },
  borderRadius: "0",
  overflow: "hidden",
});
const MobileView = ({ values }) => {
  const thumbnails = values?.thumbnail?.map((t) => ({
    path: t?.path,
    publicurl: t?.publicurl,
  }));
  const coverPics = values?.coverpic?.map((cp) =>
    cp?.map((c) => ({ path: c?.path, publicurl: c?.publicurl }))
  );

  const trackIndex = { 0: 0};
  const [coverPicIndexes, setCoverPicIndexes] = useState(trackIndex);

  useEffect(() => {
    const updatedCoverPicIndexes = { ...coverPicIndexes };
    if (coverPics.length !== Object.keys(coverPicIndexes).length) {
      coverPics.forEach((_, index) => {
        updatedCoverPicIndexes[index] = updatedCoverPicIndexes[index] ?? 0;
      });
    }
    coverPics.forEach((coverPicGroup, groupIndex) => {
      if (updatedCoverPicIndexes[groupIndex] >= coverPicGroup.length) {
        updatedCoverPicIndexes[groupIndex] = Math.max(coverPicGroup.length - 1, 0);
      }
    });
    if (JSON.stringify(updatedCoverPicIndexes) !== JSON.stringify(coverPicIndexes)) {
      setCoverPicIndexes(updatedCoverPicIndexes);
    }
  }, [coverPics, coverPicIndexes]);
  // Handlers to change the index for the cover pics

  const handleChangeIndex = (currentIndex, groupIndex) => {
    setCoverPicIndexes({ ...coverPicIndexes, [groupIndex]: currentIndex });
  };

  return (
    <MobileFrame>
      <Header>Broadway App </Header>
      <ScrollableContent>
        {/* Thumbnails grid */}
        <ThumbnailsContainer container>
          {thumbnails.map((thumbnail, index) => (
            <CardMedia
              image={thumbnail.publicurl}
              sx={{
                width: "100%",
                height: "auto",
                width: "200px",
                height: "200px",
                marginLeft: "35px",
              }}
            />
          ))}
        </ThumbnailsContainer>

        {/* Cover Pics carousel */}
        {coverPics.map((coverPicGroup, groupIndex) => (
          <>
            {coverPicGroup[0].publicurl ? (<>
                <CarouselContainer key={`coverPicGroup-${groupIndex}`}>
            <SwipeableViews
              index={coverPicIndexes[groupIndex]}
              onChangeIndex={(index) => handleChangeIndex(index, groupIndex)}
            >
              {coverPicGroup.map((item, index) => (
                <div
                  key={index}
                  style={{
                    padding: "0 10px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {/* <Card raised> */}
                  <CardMedia
                    component="img"
                    image={item.publicurl}
                    alt={`Cover Pic ${groupIndex + 1} - Image ${index + 1}`}
                    sx={{
                      width: "100%",
                      height: "auto",
                      width: "200px",
                      height: "200px",
                      margin: "0px",
                    }}
                  />
                  {/* </Card> */}
                </div>
              ))}
            </SwipeableViews>
            <ArrowButton
              style={{ left: 0 }}
              onClick={() =>
                handleChangeIndex(coverPicIndexes[groupIndex] - 1, groupIndex)
              }
              disabled={coverPicIndexes[groupIndex] === 0}
            >
              <ArrowBackIosNewIcon />
            </ArrowButton>
            <ArrowButton
              style={{ right: 0 }}
              onClick={() =>
                handleChangeIndex(coverPicIndexes[groupIndex] + 1, groupIndex)
              }
              disabled={
                coverPicIndexes[groupIndex] === coverPicGroup.length - 1
              }
            >
              <ArrowForwardIosIcon />
            </ArrowButton>
          </CarouselContainer>
            </>) : null}
          </>
        ))}
      </ScrollableContent>
    </MobileFrame>
  );
};

export default MobileView;
