import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import Chip from "@mui/material/Chip";
import { Grid } from "@mui/material";
// import { mockData } from "./mockData";
import { useQuery } from "@apollo/client";
import dayjs from 'dayjs';
import { PARTICIPANTS_LIST } from "services/events-service";
import { eventServiceClient } from "graphql/client";
import Loader from "components/MDLoader";
import { SearchContext, FilterContext } from "context";
import { useContext } from "react";

const columns = [
  {
    field: "name",
    headerName: "Customer Name",
    width: 230,
    valueGetter: (params) => params?.row?.order?.account?.name || "N/A"
  },
  {
    field: "memberType",
    headerName: "Member Type",
    width: 200,
    valueGetter: (params) => params?.row?.order?.account ? "Registered": "Unregistered" ,

  },
 
  {
    field: "ticketName",
    headerName: "Ticket Type",
    width: 130,
    valueGetter: (params) => params?.row?.ticket?.name,

  },
  {
    field: "mobile",
    headerName: "Mobile No.",
    width: 130,
    valueGetter: (params) => params?.row?.order?.account?.mobile || "",

  },
  {
    field: "totalPrice",
    headerName: "Price",
    width: 130,
  },
  {
    field: "quantity",
    headerName: "Count",
    width: 100,
  },
  {
    field: "status",
    headerName: "Status",
    width: 230,
    valueGetter:(params) => params?.row?.status.charAt(0).toUpperCase()+params?.row?.status?.slice(1)
  },
];

function EventParticipants({ onRowClick, eventData }) {
    const [paginationModel, setPaginationModel] = React.useState({
        page: 0,
        pageSize: 20,
      });
      
  const { loading, error, data, refetch } = useQuery(PARTICIPANTS_LIST, {
    client: eventServiceClient,
    variables: {
         ticketListFilter:{
            take: paginationModel.pageSize,
            skip: paginationModel.page * paginationModel.pageSize,
            filter:{
              status: "completed",
                ticket : {
                    event : {
                      id : eventData?.id
                    }
                  }
            }
         } 
    },
    fetchPolicy: "network-only", // Ignore cache for this query
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setRowCount(data?.bookedTickets?.total);
    },
  });
  const [rowCount, setRowCount] = React.useState(data?.bookedTickets?.total || 0);
  React.useEffect(() => {
    setRowCount((prevRowCount) =>
      data?.bookedTickets?.total !== undefined ? data?.bookedTickets?.total : prevRowCount
    );
  }, [data?.bookedTickets?.total, setRowCount]);
  if (loading)
    return (
      <>
        <Loader />
      </>
    );
  if (error) return <p>Error: {error.message}</p>;

  const participants = eventData?.id ? data.bookedTickets.results : []
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  return (
    <Grid container spacing={1} mt={2}>
      <Grid item xs={12} style={{ backgroundColor: "white" }}>
        <DataGrid
          autoHeight
          rows={participants}
          columns={columns}
          rowCount={rowCount}
          pageSizeOptions={[10, 20, 50, 100]}
          getRowId={(row) => row.id}
          onRowClick={onRowClick}
          loading={loading}
          onPageChange={handleChangePage}
          paginationModel={paginationModel}
          paginationMode="server"
          onPaginationModelChange={setPaginationModel}
        />
      </Grid>
    </Grid>
  );
}

export default EventParticipants;
