import React, { useState } from "react";
import { Formik, Form, Field, FieldArray } from "formik";
import {
  Grid,
  TextField,
  Button,
  Typography,
  FormControlLabel,
  Switch,
  Select,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import * as Yup from "yup";
import { styled } from "@mui/material/styles";
import { CREATE_OR_UPDATE_ADDRESS } from "services/customer-service";
import { useMutation, useQuery } from "@apollo/client";
import { dashboardServiceClient } from "graphql/client";
import { GET_CUSTOMER_LIST } from "services/customer-service";
import { useNotification } from "context";
import MDButton from "components/MDButton";

const validationSchema = Yup.object().shape({
  addresses: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string().matches(/^[a-zA-Z ]+$/, "Enter a valid name")
          .required("Name is required")
          .max(60, "Maximum 60 characters allowed"),
        officeAddress1: Yup.string().required(
          "Office Address Line 1 is required"
        ),
        officeAddress2: Yup.string(),
        city: Yup.string().required("City is required"),
        state: Yup.string().required("State is required"),
        pincode: Yup.string()
          .required("Pincode is required")
          .matches(/^\d{6}$/, "Pincode must be exactly 6 digits"),
        phoneNo: Yup.string()
          .required("Mobile number is required")
          .matches(/^[6-9][0-9]{9}$/, "Please Enter Valid Mobile Number"),
        isActive: Yup.boolean(),
        // default: Yup.string().required("Default is required"),
      })
    )
    .min(1, "At least one address is required"),
});
const useAddressMutation = (customerData, handleClose, setNotification) => {
  const mutationOptions = {
    client: dashboardServiceClient,
    refetchQueries: [{ query: GET_CUSTOMER_LIST, variables: { search: "" } }],
  };

  const [createOrUpdateAddressMutation] = useMutation(
    CREATE_OR_UPDATE_ADDRESS,
    mutationOptions
  );
  const handleSubmit = (values) => {
    const payload = {
      variables: {
        request: {
          addressInput: values.addresses.map((address) => ({
            accountId: customerData.accountId,
            id: address.id,
            addressLine1: address.officeAddress1,
            addressLine2: address.officeAddress2,
            city: address.city,
            country: "India",
            name: address.name,
            state: address.state,
            pincode: address.pincode,
            phone: address.phoneNo,
            addressType: address?.id ? address?.addressType?.toLowerCase() : "shipping",
            landmarks: address.landmarks,
            isDeleted: !address.isActive,
          })),
        },
      },
    };
    createOrUpdateAddressMutation(payload)
      .then((res) => {
        console.log(res);
        handleClose();
        setNotification({
          color: "success",
          isVisible: true,
          message: "Operation Succesfull"
        });
      })
      .catch((err) => console.log(err));
    setNotification({
      color: "error",
      isVisible: true,
      message: error.message || "Something went wrong",
    });
  };

  return handleSubmit;
};
function CustomerAddressForm({ customerData, handleClose, user }) {
  const { setNotification } = useNotification();

  const handleSubmit = useAddressMutation(customerData, handleClose, setNotification);

  return (
    <Formik
      initialValues={{
        addresses: customerData?.account?.address.length
          ? customerData?.account?.address.map((address, index) => ({
            id: address?.id || null,
            name: address?.name || "",
            officeAddress1: address?.addressLine1 || "",
            officeAddress2: address?.addressLine2 || "",
            city: address?.city || "",
            state: address?.state || "",
            pincode: address?.pincode || "",
            phoneNo: address?.phone || "",
            isActive: !address?.isDeleted || false,
            // default: address?.default||"",
            landmarks: address?.landmarks || "",
            addressType: address?.addressType,
          }))
          : [
            {
              name: "",
              officeAddress1: "",
              officeAddress2: "",
              city: "",
              state: "",
              pincode: "",
              phoneNo: "",
              isActive: false,
              // default: address?.default||"",
              landmarks: "",
            },
          ],
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        handleSubmit(values);
      }}
    >
      {({ values, isValid, errors, touched, setFieldValue, handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <FieldArray name="addresses">
            {({ push }) => (
              <Grid container spacing={2}>
                {Array.isArray(values.addresses) &&
                  values.addresses.map((_, index) => (
                    <>
                      <Grid m={2} key={index}>
                        <Grid container spacing={2}>
                          <Typography fontSize={12} ml={2}>{`Address ${index + 1
                            }`}</Typography>
                          <Grid item xs={12}>
                            <Field
                              as={TextField}
                              label="Name"
                              fullWidth
                              name={`addresses[${index}].name`}
                              error={
                                !!touched.addresses?.[index]?.name &&
                                !!errors.addresses?.[index]?.name
                              }
                              helperText={
                                touched.addresses?.[index]?.name &&
                                errors.addresses?.[index]?.name
                              }
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Field
                              as={TextField}
                              label="Address Line 1"
                              fullWidth
                              name={`addresses[${index}].officeAddress1`}
                              error={
                                !!touched.addresses?.[index]?.officeAddress1 &&
                                !!errors.addresses?.[index]?.officeAddress1
                              }
                              helperText={
                                touched.addresses?.[index]?.officeAddress1 &&
                                errors.addresses?.[index]?.officeAddress1
                              }
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Field
                              as={TextField}
                              label="Address Line 2"
                              fullWidth
                              name={`addresses[${index}].officeAddress2`}
                              error={
                                !!touched.addresses?.[index]?.officeAddress2 &&
                                !!errors.addresses?.[index]?.officeAddress2
                              }
                              helperText={
                                touched.addresses?.[index]?.officeAddress2 &&
                                errors.addresses?.[index]?.officeAddress2
                              }
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <Field
                              as={TextField}
                              label="City"
                              fullWidth
                              name={`addresses[${index}].city`}
                              error={
                                !!touched.addresses?.[index]?.city &&
                                !!errors.addresses?.[index]?.city
                              }
                              helperText={
                                touched.addresses?.[index]?.city &&
                                errors.addresses?.[index]?.city
                              }
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <Field
                              as={TextField}
                              label="State"
                              fullWidth
                              name={`addresses[${index}].state`}
                              error={
                                !!touched.addresses?.[index]?.state &&
                                !!errors.addresses?.[index]?.state
                              }
                              helperText={
                                touched.addresses?.[index]?.state &&
                                errors.addresses?.[index]?.state
                              }
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <Field
                              as={TextField}
                              label="Pincode"
                              fullWidth
                              name={`addresses[${index}].pincode`}
                              error={
                                !!touched.addresses?.[index]?.pincode &&
                                !!errors.addresses?.[index]?.pincode
                              }
                              helperText={
                                touched.addresses?.[index]?.pincode &&
                                errors.addresses?.[index]?.pincode
                              }
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <Field
                              as={TextField}
                              label="Phone No"
                              fullWidth
                              name={`addresses[${index}].phoneNo`}
                              error={
                                !!touched.addresses?.[index]?.phoneNo &&
                                !!errors.addresses?.[index]?.phoneNo
                              }
                              helperText={
                                touched.addresses?.[index]?.phoneNo &&
                                errors.addresses?.[index]?.phoneNo
                              }
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <Field
                              as={TextField}
                              label="Landmark"
                              fullWidth
                              name={`addresses[${index}].landmarks`}
                              error={
                                !!touched.addresses?.[index]?.landmarks &&
                                !!errors.addresses?.[index]?.landmarks
                              }
                              helperText={
                                touched.addresses?.[index]?.landmarks &&
                                errors.addresses?.[index]?.landmarks
                              }
                            />
                          </Grid>
                          {/* <Grid item xs={4}>
                     <Field
                       as={Select}
                       select
                       value={values.selectedValue}
                       label="Default"
                       fullWidth
                       name={`addresses[${index}].default`}
                       error={
                         !!touched.addresses?.[index]?.default &&
                         !!errors.addresses?.[index]?.default
                       }
                       helperText={
                         touched.addresses?.[index]?.default &&
                         errors.addresses?.[index]?.default
                       }
                       sx={{ height: 40 }}
                     >
                       <MenuItem value="shipping">Shipping</MenuItem>
                       <MenuItem value="billing">Billing</MenuItem>
                     </Field>
                       </Grid> */}
                          <Grid item xs={12}>
                            <Field
                              as={TextField}
                              name={`addresses[${index}].isActive`}
                              value="Is it active?"
                              fullWidth
                              InputProps={{
                                readOnly: true,
                                endAdornment: (
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        disabled={!(user && user?.role === "admin")}
                                        checked={
                                          values.addresses[index]?.isActive
                                        }
                                        onChange={(e) =>
                                          setFieldValue(
                                            `addresses[${index}].isActive`,
                                            e.target.checked
                                          )
                                        }
                                      />
                                    }
                                    labelPlacement="start"
                                  />
                                ),
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                  ))}
                {user && user?.role === "admin" ? (
                  <Grid item xs={12}>
                    <MDButton
                      fullWidth
                      type="button"
                      variant="gradient"
                      color="secondary"
                      onClick={() => {
                        push({
                          officeAddress1: "",
                          officeAddress2: "",
                          city: "",
                          state: "",
                          pincode: "",
                          phoneNo: "",
                          isActive: true,
                          // default: address?.default||"",
                          landmarks: "",
                        });
                      }}
                    >
                      + ADD ANOTHER
                    </MDButton>
                    <Grid container spacing={1}>
                      <Grid container xs={12} sx={{ mt: 4, ml: 2, pb: 2 }} flexDirection={"row-reverse"} gap={3}>
                        <MDButton
                          type="submit"
                          variant="contained"
                          circular={true}
                          color="black"
                        >
                          Save
                        </MDButton>
                        <MDButton onClick={handleClose} circular={true} variant="outlined" color="black">
                          Cancel
                        </MDButton>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : <></>}
              </Grid>
            )}
          </FieldArray>
        </Form>
      )}
    </Formik>
  );
}

export default CustomerAddressForm;
