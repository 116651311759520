import { useLazyQuery,useQuery } from '@apollo/client';
import { Box, Chip, Grid, Icon, IconButton } from '@mui/material';
import AgGrid from 'components/MDGrid';
import { reportServiceClient } from 'graphql/client';
import React,{useState,useContext} from 'react';
import { ReactComponent as previewIcon } from "assets/images/previewIcon.svg";
import { GET_LOYALITY_CUSTOMER } from 'services/loyality-service';
import { DateFiltersContext } from './index';

const columnDefs = [
    {
        headerName: "Name",
        field: "name",
        width: 150
    },
    {
        headerName: "Mobile",
        field: "mobile",
        width: 150
    },
    {
        headerName: "Total Active Rewards",
        field: "totalActiveRewards",
        width: 150
    },
    {
        headerName: "Total Rewards Burned",
        field: "totalRewardsBurned",
        width: 150
    },
    {
        headerName: "Total Rewards Earned",
        field: "totalRewardsEarned",
        width: 150
    },
    {
        headerName: "Customer Status",
        field: "customerStatus",
        width: 150,
        cellRenderer: params => {
            return (
                <Chip
                  label={params.data.status}
                  sx={{
                    backgroundColor: params?.data?.status === "Active" ? "#8feba8" : "#ebaba9",
                    color: params?.data?.status === "Active" ? "green" : "red"
                  }}
                  color={params?.data?.status === 'Active' ? 'success' : 'error'}
                  size="small"
                />
            );
        },
    }
];
function CustomerTab() {
    const { startDate, endDate ,setCustomerData,setIsDetailPage,setLoyalityCustomer} = useContext(DateFiltersContext);
    const handleRowClick = (params) => {
        const rowData = params.data;
        setLoyalityCustomer(rowData?.accountId);
        setIsDetailPage(true);
    };

    // const createOrderByFromOrderModel = (sortModel, columnDefs) => {
    //     let orderBy = {}
    //     sortModel.forEach(sortM => {
    //         const sortOrder = sortM.sort === 'asc' ? 'asc' : 'desc'
    //         const column = columnDefs.find((colDef) => colDef.field == sortM.colId)
    //         if (column.getSortObject != null) {
    //             orderBy = { ...orderBy, ...column.getSortObj(sortOrder) }
    //         } else {
    //             orderBy[sortM.colId] = sortOrder;
    //         }
    //         // const sortKey = column.getSortKey? column.getSortKey() : sortM.colId
    //         // orderBy[sortKey] = sortOrder
    //     })
    //     console.log(orderBy, 'orderBy')  // debug statement, remove before production
    //     return orderBy;
    // }

    // const [fetchCustomers] = useLazyQuery(GET_CUSTOMER_LIST, {
    //     client: reportServiceClient,
    //     fetchPolicy: "network-only",
    //     notifyOnNetworkStatusChange: true,
    // });
    // const [fetchCustomers] = useLazyQuery(GET_LOYALITY_CUSTOMER, {
    //     client: reportServiceClient,
    //     variables:{filter:{take:10000,skip:0,startDate:"2023-01-01",endDate:"2024-08-31"}},
    //     fetchPolicy: "network-only",
    //     notifyOnNetworkStatusChange: true,
    // });
    // useEffect(() => {
    //     if (!fetchCustomers.loading &&!fetchCustomers.error) {
    //         setRows(fetchCustomers?.data?.getLoyaltyCustomer?.results);
    //     }
    // }, []);
    const {data,loading, error} = useQuery(GET_LOYALITY_CUSTOMER,
        {
        client: reportServiceClient,
        variables:{filter:{take:10000,skip:0,startDate:startDate,endDate:endDate}},
        fetchPolicy: "network-only",
        notifyOnNetworkStatusChange: true,
        onCompleted: (data) => {
            setCustomerData(data?.getLoyaltyCustomer?.results)
        }
    },
    )
    return (
        <Grid>
            {/* <AgGrid
                columns={columnDefs}
                readMethod={{
                    fetchFn: fetchCustomers,
                    inputParser: ({ startRow, endRow, filter, sortModel }) => {
                        return ({
                            take: endRow - startRow,
                            skip: startRow,
                            search: "",
                            filter,
                            orderby: createOrderByFromOrderModel(sortModel, columnDefs)
                        })
                    },
                    resultsParser: ({ data }) => ({
                        results: data?.customerList?.results,
                        total: data?.customerList?.total,
                    }),
                }}
                gridProps={{
                    onRowClicked: ({ data }) => console.log(data)
                }}
                defaultColDefProp={{ filter: "agTextColumnFilter" }}
            /> */}
            <AgGrid
                gridProps={{
                    rowHeight: 60,
                    defaultColDef: {
                        editable: false,
                        filter: true,
                        flex: 1,
                        minWidth: 200,
                    },
                    pivotMode: false,
                    sideBar: {
                        toolPanels: ['columns', 'filters'],
                    },
                    enableRangeSelection: true,
                    onRowClicked: handleRowClick
                }}
                queryData={data?.getLoyaltyCustomer?.results || []}
                columns={columnDefs}
            />
        </Grid>
    );
}

export default CustomerTab;
