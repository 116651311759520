import React from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Grid, TextField, Button } from "@mui/material";
import { dashboardServiceClient } from "graphql/client";
import { useMutation } from "@apollo/client";
import { UPDATE_STORE_MUTATION } from "services/store-service";
import { GET_STORES } from "services/store-service";
import { useNotification } from "context";
import Loader from "components/MDLoader";
import MDButton from "components/MDButton";

const validationSchema = Yup.object().shape({
  officeAddressLine1: Yup.string().required(
    "Office Address Line 1 is required"
  ),
  officeAddressLine2: Yup.string().required(
    "Office Address Line 2 is required"
  ),
  city: Yup.string().required("City is required"),
  state: Yup.string().required("State is required"),
  pincode: Yup.string()
    .required("Pincode is required")
    .max(6, "Maximum 6 characters allowed")
    .matches(/^\d+$/, "Pincode must be a number")
    .matches(/^[1-9]{1}[0-9]{5}$/, "Invalid Pincode"),
  longitude: Yup.string()
    .required("Longitude is required")
    .matches(
      /^-?([1-8]?[1-9]|[1-9]0)\.{1}\d{1,6}/,
      "Longitude must be a valid value"
    ),
  latitude: Yup.string()
    .required("Latitude is required")
    .matches(
      /^-?([1-8]?[1-9]|[1-9]0)\.{1}\d{1,6}/,
      "Latitude must be a valid value"
    ),
});

const AddressFormComponent = ({
  handleClose,
  storeData,
  handleTabChange,
  setStoreData,
}) => {
  const [updateStoreMutation, { loading, error }] = useMutation(
    UPDATE_STORE_MUTATION,
    {
      client: dashboardServiceClient,
      refetchQueries: [
        {
          query: GET_STORES,
          variables: { listStoreFilter: { take: 100, skip: 0 } },
        }, // replace "yourSearchTerm" with the actual term you'd use
      ],
    }
  );
  const { setNotification } = useNotification();
  if (loading)
    return (
      <>
        <Loader />
      </>
    );
  if (error) return <p>Error: {error.message}</p>;
  return (
    <Formik
      initialValues={{
        officeAddressLine1: storeData ? storeData.address1 : "",
        officeAddressLine2: storeData ? storeData.address2 : "",
        city: storeData ? storeData.city : "",
        state: storeData ? storeData.state : "",
        pincode: storeData ? storeData.pincode : "",
        longitude: storeData ? storeData.longitude : "",
        latitude: storeData ? storeData.latitude : "",
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        console.log(values);
        console.log(storeData);
        updateStoreMutation({
          variables: {
            updateStoreInput: {
              id: storeData.id,
              address1: values.officeAddressLine1,
              address2: values.officeAddressLine2,
              city: values.city,
              latitude: values.latitude,
              longitude: values.longitude,
              pincode: values.pincode,
              state: values.state,
            },
          },
        })
          .then((res) => {
            // Note: This will be the response of the mutation
            setStoreData(storeData);
            handleTabChange(null, 2);
            setNotification({
              color: "success",
              isVisible: true,
              message:
                storeData && storeData?.id
                  ? "Store Address  updated successfully"
                  : "Store Address  created successfully",
            });
          })
          .catch((error) => {
            console.error("Error:", error.message);
            setNotification({
              color: "error",
              isVisible: true,
              message: error.message || "Something went wrong",
            });
          });
      }}
    >
      {({ errors, touched }) => (
        <Form key={JSON.stringify(storeData)}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Field
                name="officeAddressLine1"
                as={TextField}
                label="Address Line 1"
                fullWidth
                error={
                  touched.officeAddressLine1 && !!errors.officeAddressLine1
                }
                helperText={
                  touched.officeAddressLine1 && errors.officeAddressLine1
                }
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                name="officeAddressLine2"
                as={TextField}
                label="Address Line 2"
                fullWidth
                error={
                  touched.officeAddressLine2 && !!errors.officeAddressLine2
                }
                helperText={
                  touched.officeAddressLine2 && errors.officeAddressLine2
                }
              />
            </Grid>
            <Grid item xs={4}>
              <Field
                name="city"
                as={TextField}
                label="City"
                fullWidth
                error={touched.city && !!errors.city}
                helperText={touched.city && errors.city}
              />
            </Grid>
            <Grid item xs={4}>
              <Field
                name="state"
                as={TextField}
                label="State"
                fullWidth
                error={touched.state && !!errors.state}
                helperText={touched.state && errors.state}
              />
            </Grid>
            <Grid item xs={4}>
              <Field
                name="pincode"
                as={TextField}
                label="Pincode"
                fullWidth
                error={touched.pincode && !!errors.pincode}
                helperText={touched.pincode && errors.pincode}
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                name="longitude"
                as={TextField}
                label="Longitude"
                fullWidth
                error={touched.longitude && !!errors.longitude}
                helperText={touched.longitude && errors.longitude}
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                name="latitude"
                as={TextField}
                label="Latitude"
                fullWidth
                error={touched.latitude && !!errors.latitude}
                helperText={touched.latitude && errors.latitude}
              />
            </Grid>
            <Grid container xs={12} sx={{ mt: 4, ml: 2, pb: 2 }} flexDirection={"row-reverse"} gap={3}>
                <MDButton
                  type="submit"
                  variant="contained"
                  circular={true}
                  color="black"
                >
                  Save
                </MDButton>
                <MDButton color="black" onClick={handleClose} variant="outlined" circular={true}>
                  Cancel
                </MDButton>
              </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default AddressFormComponent;
