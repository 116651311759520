import React from "react";
import { Formik, Form, Field, FieldArray } from "formik";
import * as Yup from "yup";
import { Grid, Typography, Button, TextField } from "@mui/material";
import FileUploader from "components/MDFileUpload";
import { useNotification } from "context";
import { dashboardServiceClient } from "graphql/client";
import { GET_BRAND_DETAILS } from "services/brand-service";
import { useMutation, useQuery } from "@apollo/client";
import { CREATE_BRAND_METADATA } from "services/brand-service";
import { UPDATE_BRAND_METADATA } from "services/brand-service";
import Autocomplete from "@mui/material/Autocomplete";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { LIST_BRAND_PRODUCT } from "services/brand-service";
import Loader from "components/MDLoader";
import MobileView from "./mobileContainer";

const useStoreMetadataMutation = (
  brandData,
  brandMetadata,
  setNotification,
  handleTabChange,
  handleClose,
  setBrandData
) => {
  const mutationOptions = {
    client: dashboardServiceClient,
    refetchQueries: [
      {
        query: GET_BRAND_DETAILS,
        variables: { username: brandData?.username || "" },
      },
    ],
  };

  const [createBrandMetadataMutation] = useMutation(
    CREATE_BRAND_METADATA,
    mutationOptions
  );
  const [updateBrandMetadataMutation] = useMutation(
    UPDATE_BRAND_METADATA,
    mutationOptions
  );

  const handleSubmit = (values) => {
    const mutation =
      brandMetadata && brandMetadata.id
        ? updateBrandMetadataMutation
        : createBrandMetadataMutation;
    const mutationInput =
      brandMetadata && brandMetadata.id
        ? {
            metaDataInput: {
              id: brandMetadata.id,
              key: "brandDiscovery",
              value: { ...values },
            },
          }
        : {
            metaDataInput: {
              brandId: brandData.id,
              key: "brandDiscovery",
              value: { ...values },
            },
          };

    mutation({ variables: mutationInput })
      .then((res) => {
        setBrandData(brandData);
        handleTabChange(null, 3);
        setNotification({
          color: "success",
          isVisible: true,
          message:
            brandMetadata && brandMetadata.id
              ? "Brand Discovery data updated successfully"
              : "Brand Discovery data created successfully",
        });
      })
      .catch((error) => {
        console.error(" Error:", error.message);
        setNotification({
          color: "error",
          isVisible: true,
          message: error.message || "Something went wrong",
        });
      });
  };

  return handleSubmit;
};

const validationSchema = Yup.object({
  thumbnail: Yup.array().of(
    Yup.object().shape({
      path: Yup.string().required(),
      publicurl: Yup.string().required(),
    })
  ),
  coverpic: Yup.array()
    .of(
      Yup.array()
        .of(
          Yup.object().shape({
            path: Yup.string().required(),
            publicurl: Yup.string().required(),
          })
        )
        .min(1, "One image is required")
    )
    .min(1, "One coverpic is required"),
  productName: Yup.array().test(
    "non-empty-array",
    "Product Name should have at least one item",
    (value) => Array.isArray(value) && value.length > 0
  ),
  testimonials: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required("Customer name is required"),
      lastOrderdate: Yup.date().required("Last ordered date is required"),
      review: Yup.string().required("Review is required"),
      images: Yup.array().of(
        Yup.object().shape({
          path: Yup.string().required(),
          publicurl: Yup.string().required(),
        })
      ),
    })
  ),
});

const BrandDiscoveryForm = ({
  handleClose,
  brandData,
  handleTabChange,
  setBrandData,
}) => {
  const isOfferPresent =
    (brandData &&
      brandData.brandMetaData &&
      brandData.brandMetaData.length &&
      [...brandData.brandMetaData]
        .reverse()
        .find((item) => item.key === "brandDiscovery")) ||
    null;
  const { setNotification } = useNotification();

  const handleSubmit = useStoreMetadataMutation(
    brandData,
    isOfferPresent,
    setNotification,
    handleTabChange,
    handleClose,
    setBrandData
  );

  const {
    loading: productLoading,
    error: productError,
    data: productData,
  } = useQuery(LIST_BRAND_PRODUCT, {
    client: dashboardServiceClient,
    variables: {
      listProductFilter: {
        take: 20000,
        skip: 0,
        search: "",
        filter: { brand: { id: brandData.id } },
      },
    },
  });

  if (productLoading)
    return (
      <>
        <Loader />
      </>
    );
  if (productError) return <p>Error: {error.message}</p>;

  return (
    <Formik
      initialValues={
        isOfferPresent
          ? isOfferPresent.value
          : {
              thumbnail: [{ path: "", publicurl: "" }],
              coverpic: [[{ path: "", publicurl: "" }]],
              productName: [],
              testimonials: [
                {
                  name: "",
                  lastOrderdate: null,
                  review: "",
                  images: [{ path: "", publicurl: "" }],
                },
              ],
            }
      }
      validationSchema={validationSchema}
      onSubmit={(values) => {
        handleSubmit(values);
      }}
    >
      {({
        setFieldValue,
        values,
        setFieldTouched,
        validateField,
        handleBlur,
        touched,
        errors,
      }) => (
        <Form style={{ display: "flex" }}>
          <Grid container spacing={2}>
            <FieldArray name="thumbnail">
              {({ push }) => (
                <Grid container spacing={2}>
                  {Array.isArray(values.thumbnail) &&
                    values.thumbnail.map((_, index) => (
                      <Grid item xs={15} key={index}>
                        <Typography fontSize={12} fontWeight={"bold"}>
                          Discovery Thumbnail {index + 1}
                        </Typography>

                        <FileUploader
                          fieldName={`Offer Thumbnail ${index + 1}`}
                          isMultiple={false}
                          preLoadedFile={!!_.path ? [_.path] : null}
                          isFieldValid={
                            touched?.thumbnail &&
                            touched?.thumbnail.length &&
                            touched?.thumbnail[index]?.path &&
                            errors?.thumbnail &&
                            errors?.thumbnail.length &&
                            errors?.thumbnail[index]?.path
                          }
                          signedUrlCallback={(newUrl, id, publicReadUrl) => {
                            setFieldValue(`thumbnail[${index}].path`, newUrl);
                            setFieldTouched(`thumbnail[${index}].path`, newUrl);
                            validateField(`thumbnail[${index}].path`, newUrl);
                            setFieldValue(
                              `thumbnail[${index}].publicurl`,
                              publicReadUrl
                            );
                            setFieldTouched(
                              `thumbnail[${index}].publicurl`,
                              publicReadUrl
                            );
                            validateField(
                              `thumbnail[${index}].publicurl`,
                              publicReadUrl
                            );
                          }}
                        />
                        {touched?.thumbnail &&
                          touched?.thumbnail.length &&
                          touched?.thumbnail[index]?.path &&
                          errors?.thumbnail &&
                          errors?.thumbnail.length &&
                          errors?.thumbnail[index]?.path && (
                            <MDTypography
                              color="error"
                              fontWeight="light"
                              fontSize="16px"
                            >
                              please upload a correct thumbnail image
                            </MDTypography>
                          )}
                      </Grid>
                    ))}
                  <Grid item xs={12}>
                    <MDButton
                      variant="gradient"
                      color="primary"
                      fullWidth
                      onClick={() => {
                        push({ path: "", publicurl: "" });
                      }}
                    >
                      + Add Another
                    </MDButton>
                  </Grid>
                </Grid>
              )}
            </FieldArray>

            <FieldArray name="coverpic">
              {({ push: coverPush }) => (
                <Grid container spacing={2} sx={{ marginTop: "20px" }}>
                  {Array.isArray(values.coverpic) &&
                    values.coverpic.map((_, index) => (
                      <Grid item xs={15} key={index}>
                        <Typography fontSize={12} fontWeight={"bold"}>
                          Discovery Coverpic {index + 1}
                        </Typography>

                        <FileUploader
                          fieldName={`Offer Coverpic ${index + 1}`}
                          isMultiple={true}
                          preLoadedFile={_.filter((pic) => {
                            if (!!pic.path) return pic.path;
                          }).map((pic) => pic.path)}
                          isFieldValid={
                            touched?.coverpic &&
                            touched?.coverpic.length &&
                            touched?.coverpic[index] &&
                            touched?.coverpic[index][0]?.path &&
                            errors?.coverpic &&
                            errors?.coverpic.length &&
                            errors?.coverpic[index] &&
                            errors?.coverpic[index][0]?.path
                          }
                          signedUrlCallback={(
                            newUrl,
                            url_index,
                            publicReadUrl
                          ) => {
                            const updatedImagePathArray = [..._];
                            if (newUrl) {
                              // Update the URL at the given url_index
                              updatedImagePathArray[url_index] = {
                                path: newUrl,
                                publicurl: publicReadUrl,
                              };
                            } else {
                              // Delete the URL at the given url_index
                              updatedImagePathArray.splice(url_index, 1);
                              if (updatedImagePathArray.length === 0) {
                                updatedImagePathArray[0] = {
                                  path: "",
                                  publicurl: "",
                                };
                              }
                            }

                            setFieldValue(
                              `coverpic[${index}]`,
                              updatedImagePathArray
                            );
                            setFieldTouched(
                              `coverpic[${index}]`,
                              updatedImagePathArray
                            );
                            validateField(
                              `coverpic[${index}]`,
                              updatedImagePathArray
                            );
                          }}
                        />
                        {touched?.coverpic &&
                          touched?.coverpic.length &&
                          touched?.coverpic[index] &&
                          touched?.coverpic[index].length &&
                          touched?.coverpic[index][0]?.path &&
                          errors?.coverpic &&
                          errors?.coverpic.length &&
                          errors?.coverpic[index] &&
                          touched?.coverpic[index].length &&
                          errors?.coverpic[index][0]?.path && (
                            <MDTypography
                              color="error"
                              fontWeight="light"
                              fontSize="16px"
                            >
                              please upload atleast one coverpic image
                            </MDTypography>
                          )}
                      </Grid>
                    ))}
                  <Grid item xs={12}>
                    <MDButton
                      variant="gradient"
                      color="primary"
                      fullWidth
                      onClick={() => {
                        coverPush([{ path: "", publicurl: "" }]);
                      }}
                    >
                      + Add Another
                    </MDButton>
                  </Grid>
                </Grid>
              )}
            </FieldArray>

            <Grid item xs={12}>
              <Typography variant="h7" sx={{ fontSize: "13px" }}>
                Product Listing
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                multiple
                id="tags-standard"
                name="productName"
                value={values.productName}
                options={productData.products.results.map((item) => ({
                  id: item.id,
                  label: item.title,
                }))} // Changed this to brands
                getOptionLabel={(option) => option.label || "Unknown"}
                onChange={(event, newValue) => {
                  console.log("newValu pathake", newValue);
                  setFieldValue("productName", newValue);
                }}
                onBlur={() => {
                  setFieldTouched("productName");
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Product Name"
                    placeholder="Select"
                    helperText={touched.productName ? errors.productName : ""}
                    error={touched.productName && Boolean(errors.productName)}
                    sx={{
                      ".MuiAutocomplete-input": {
                        color: "#344767",
                      },
                      ".MuiAutocomplete-option": {
                        "&[aria-selected='true']": {
                          backgroundColor: "#344767 !important",
                          color: "white",
                        },
                        "&[data-focus='true']": {
                          backgroundColor: "#da1751 !important",
                        },
                      },
                      ".MuiAutocomplete-tag": {
                        bgcolor: "#344767",
                        color: "white",
                      },
                    }}
                  />
                )}
              />
            </Grid>

            <FieldArray name="testimonials">
              {({ push }) => (
                <Grid container spacing={2}>
                  {Array.isArray(values.testimonials) &&
                    values.testimonials.map((_, testimonial_index) => (
                      <>
                        <Grid item xs={12} key={testimonial_index}>
                          <Typography variant="h7" sx={{ fontSize: "13px" }}>
                            Testimonial {testimonial_index + 1}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Field
                            name={`testimonials[${testimonial_index}].name`}
                            as={TextField}
                            label="Customer Name"
                            value={_.name}
                            fullWidth
                            helperText={
                              touched?.testimonials &&
                              touched?.testimonials.length &&
                              touched?.testimonials[testimonial_index]?.name &&
                              errors?.testimonials &&
                              errors?.testimonials.length &&
                              errors?.testimonials[testimonial_index]?.name
                                ? errors?.testimonials[testimonial_index]?.name
                                : ""
                            }
                            error={
                              touched?.testimonials &&
                              touched?.testimonials.length &&
                              touched?.testimonials[testimonial_index]?.name &&
                              errors?.testimonials &&
                              errors?.testimonials.length &&
                              errors?.testimonials[testimonial_index]?.name
                            }
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={["DatePicker"]}>
                              <DatePicker
                                slotProps={
                                  errors?.testimonials &&
                                  errors?.testimonials?.length &&
                                  errors?.testimonials[testimonial_index]
                                    ?.lastOrderdate &&
                                  touched?.testimonials &&
                                  touched?.testimonials?.length &&
                                  touched?.testimonials[testimonial_index]
                                    ?.lastOrderdate
                                    ? {
                                        textField: {
                                          error:
                                            !!touched.testimonials &&
                                            errors.testimonials.length &&
                                            errors.testimonials[
                                              testimonial_index
                                            ].lastOrderdate &&
                                            errors.testimonials &&
                                            errors.testimonials.length &&
                                            errors.testimonials[
                                              testimonial_index
                                            ].lastOrderdate,
                                          helperText:
                                            errors.testimonials &&
                                            errors.testimonials.length &&
                                            errors.testimonials[
                                              testimonial_index
                                            ].lastOrderdate,
                                        },
                                      }
                                    : null
                                }
                                label="Last Ordered Date"
                                name={`testimonials[${testimonial_index}].lastOrderdate`}
                                maxDate={dayjs()}
                                value={
                                  values?.testimonials[testimonial_index]
                                    ?.lastOrderdate
                                    ? dayjs(
                                        values?.testimonials[testimonial_index]
                                          ?.lastOrderdate
                                      )
                                    : null
                                }
                                onChange={(newValue) => {
                                  const formattedDate = dayjs(newValue).format(
                                    "YYYY-MM-DD"
                                  );
                                  setFieldValue(
                                    `testimonials[${testimonial_index}].lastOrderdate`,
                                    formattedDate
                                  );
                                  setFieldTouched(
                                    `testimonials[${testimonial_index}].lastOrderdate`,
                                    true
                                  );
                                  validateField(
                                    `testimonials[${testimonial_index}].lastOrderdate`
                                  );
                                }}
                                onBlur={handleBlur}
                                renderInput={(params) => (
                                  <Field
                                    as={TextField}
                                    {...params}
                                    fullWidth
                                    helperText={
                                      touched?.testimonials &&
                                      touched?.testimonials.length &&
                                      touched?.testimonials[testimonial_index]
                                        ?.lastOrderdate &&
                                      errors?.testimonials &&
                                      errors?.testimonials.length &&
                                      errors?.testimonials[testimonial_index]
                                        ?.lastOrderdate
                                        ? errors?.testimonials[
                                            testimonial_index
                                          ]?.lastOrderdate
                                        : ""
                                    }
                                    error={
                                      touched?.testimonials &&
                                      touched?.testimonials.length &&
                                      touched?.testimonials[testimonial_index]
                                        ?.lastOrderdate &&
                                      errors?.testimonials &&
                                      errors?.testimonials.length &&
                                      errors?.testimonials[testimonial_index]
                                        ?.lastOrderdate
                                    }
                                  />
                                )}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        </Grid>

                        <Grid item xs={12}>
                          <Field
                            as={TextField}
                            // id="feedback"
                            label="Review"
                            name={`testimonials[${testimonial_index}].review`}
                            multiline
                            rows={2}
                            fullWidth
                            value={_.review}
                            // onChange={formik.handleChange}
                            // onBlur={formik.handleBlur}
                            helperText={
                              touched?.testimonials &&
                              touched?.testimonials.length &&
                              touched?.testimonials[testimonial_index]
                                ?.review &&
                              errors?.testimonials &&
                              errors?.testimonials.length &&
                              errors?.testimonials[testimonial_index]?.review
                                ? errors?.testimonials[testimonial_index]
                                    ?.review
                                : ""
                            }
                            error={
                              touched?.testimonials &&
                              touched?.testimonials.length &&
                              touched?.testimonials[testimonial_index]
                                ?.review &&
                              errors?.testimonials &&
                              errors?.testimonials.length &&
                              errors?.testimonials[testimonial_index]?.review
                            }
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <FileUploader
                            fieldName={`Customer Image ${
                              testimonial_index + 1
                            }`}
                            isMultiple={true}
                            preLoadedFile={_.images
                              .filter((pic) => {
                                if (!!pic.path) return pic.path;
                              })
                              .map((pic) => pic.path)}
                            isFieldValid={
                              touched?.testimonials &&
                              touched?.testimonials.length &&
                              touched?.testimonials[testimonial_index] &&
                              touched?.testimonials[testimonial_index]
                                ?.images &&
                              touched?.testimonials[testimonial_index]?.images
                                .length &&
                              touched?.testimonials[testimonial_index]
                                ?.images[0].path &&
                              errors?.testimonials &&
                              errors?.testimonials.length &&
                              errors?.testimonials[testimonial_index] &&
                              errors?.testimonials[testimonial_index]?.images &&
                              errors?.testimonials[testimonial_index]?.images
                                .length &&
                              errors?.testimonials[testimonial_index]?.images[0]
                                .path
                            }
                            signedUrlCallback={(
                              newUrl,
                              url_index,
                              publicReadUrl
                            ) => {
                              const updatedImagePathArray = [..._.images];
                              if (newUrl) {
                                // Update the URL at the given url_index
                                updatedImagePathArray[url_index] = {
                                  path: newUrl,
                                  publicurl: publicReadUrl,
                                };
                              } else {
                                // Delete the URL at the given url_index

                                updatedImagePathArray.splice(url_index, 1);
                                if (updatedImagePathArray.length === 0) {
                                  updatedImagePathArray[0] = {
                                    path: "",
                                    publicurl: "",
                                  };
                                }
                              }

                              setFieldValue(
                                `testimonials[${testimonial_index}].images`,
                                updatedImagePathArray
                              );
                              setFieldTouched(
                                `testimonials[${testimonial_index}].images`,
                                true
                              );
                              validateField(
                                `testimonials[${testimonial_index}].images`
                              );
                            }}
                          />
                          {touched?.testimonials &&
                            touched?.testimonials.length &&
                            touched?.testimonials[testimonial_index] &&
                            touched?.testimonials[testimonial_index]?.images &&
                            touched?.testimonials[testimonial_index]?.images
                              .length &&
                            touched?.testimonials[testimonial_index]?.images[0]
                              .path &&
                            errors?.testimonials &&
                            errors?.testimonials.length &&
                            errors?.testimonials[testimonial_index] &&
                            errors?.testimonials[testimonial_index]?.images &&
                            errors?.testimonials[testimonial_index]?.images
                              .length &&
                            errors?.testimonials[testimonial_index]?.images[0]
                              .path && (
                              <MDTypography
                                color="error"
                                fontWeight="light"
                                fontSize="16px"
                              >
                                please upload atleast one image
                              </MDTypography>
                            )}
                        </Grid>
                      </>
                    ))}
                  <Grid item xs={12}>
                    <MDButton
                      variant="gradient"
                      color="primary"
                      fullWidth
                      onClick={() => {
                        push({
                          name: "",
                          lastOrderdate: null,
                          review: "",
                          images: [{ path: "", publicurl: "" }],
                        });
                      }}
                    >
                      + Add Another
                    </MDButton>
                  </Grid>
                </Grid>
              )}
            </FieldArray>

            <Grid item xs={12} sx={{ mt: 2, pb: 2 }}>
              <Button
                type="submit"
                variant="contained"
                style={{
                  marginRight: "10px",
                  backgroundColor: "#344767",
                  color: "#fff",
                  borderRadius: "100px",
                }}
              >
                Save
              </Button>
              <Button sx={{ color: "#344767" }} onClick={handleClose}>
                Cancel
              </Button>
            </Grid>
          </Grid>
          <MobileView values={values} />
        </Form>
      )}
    </Formik>
  );
};

export default BrandDiscoveryForm;
