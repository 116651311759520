import { Tab, Tabs } from "@mui/material";
import { AvailableTabs } from "./tableData";

const getTabStyles = (tabName, activeTab) => {
  const commonStyle = { padding: "0.5rem 1rem", padding: "0.5rem 1rem" };

  if (tabName === activeTab) {
    return { ...commonStyle, color: "#E91F63 !important" };
  }

  return { ...commonStyle, color: "black !important" };
};

export default function TableTabs({ selectedTab, onChange }) {
  const user = localStorage.getItem("userDetails");

  const userJson = user ? JSON.parse(user) : {};

  const role = userJson.role;

  const isAdmin = role === "admin";

  return (
    <Tabs
      sx={{
        width: "fit-content",
      }}
      value={selectedTab}
      onChange={onChange}
    >
      <Tab
        label="Single Product"
        value={AvailableTabs.singleProduct}
        sx={{
          marginRight: "1rem",
          // ...getTabStyles(AvailableTabs.singleProduct, selectedTab),
        }}
      />
      <Tab
        label="Bulk Product List"
        value={AvailableTabs.bulkProduct}
        sx={{
          marginRight: "1rem",
          // ...getTabStyles(AvailableTabs.singleProduct, selectedTab),
        }}
        // sx={{ ...getTabStyles(AvailableTabs.bulkProduct, selectedTab) }}
      />

      {isAdmin && (
        <Tab label="Super Product List" value={AvailableTabs.superProduct} />
      )}
      {/* <IconButton onClick={refresh}>
        <Refresh fontSize="medium" />
      </IconButton> */}
    </Tabs>
  );
}
