import React, { useEffect, useState } from "react";
import { useNotification } from "context";
import { UPDATE_BRAND } from "services/brand-service";
import { CREATE_BRAND } from "services/brand-service";
import * as Yup from "yup";
import * as XLSX from "xlsx";

// @mui material components
import {
  Grid,
  Box,
  Button,
  Typography,
  Icon,
  IconButton,
  Pagination,
  FormControl,
  Select,
  MenuItem,
  Drawer,
  TextField,
  InputAdornment,
} from "@mui/material";

import { Add as AddIcon, Star, Store as StoreIcon, Visibility, VisibilityOff } from "@mui/icons-material";
import { Formik, Field, Form, ErrorMessage } from "formik";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

// Custom components and services
import DashboardLayout from "ui/LayoutContainers/DashboardLayout";
import DashboardNavbar from "ui/Navbars/DashboardNavbar";
import BasicTabs from "./form";
import { dashboardServiceClient } from "graphql/client";
import { useLazyQuery, useQuery, gql, useMutation } from "@apollo/client";
import { GET_BRANDS_LIST } from "services/brand-service";
import { GET_BRAND_DETAILS } from "services/brand-service";
import Loader from "components/MDLoader";
import { SearchContext, FilterContext } from "context";
import { useContext } from "react";
import MDButton from "components/MDButton";
import BrandListingPage from "./BrandListingPage";

const ValidationSchema = Yup.object({
  name: Yup.string()
    .required("Company name is required")
    .max(50, "Maximum 50 characters allowed"),
  username: Yup.string()
    .required("Email Id is required")
    .email("Please enter valid email address"),
  password: Yup.string(),
  vendorId: Yup.string().optional("Vendor Id is required"),
  customerId: Yup.string().optional("Customer Id is required"),
});

const useBrandMutation = (brandData, handleClose, setNotification) => {
  const mutationOptions = {
    client: dashboardServiceClient,
    refetchQueries: [{ query: GET_BRANDS_LIST, variables: { take: 20, skip: 0, search: "", filter: { isDeleted: false }, orderby: { brandStatus: "DESC", name: "ASC" } } }],
  };

  const [createCategoryMutation] = useMutation(CREATE_BRAND, mutationOptions);
  const [updateCategoryMutation] = useMutation(UPDATE_BRAND, mutationOptions);

  const handleSubmit = (values) => {
    console.log("values ==== ", values);
    const payload = {
      name: values?.name,
      username: values?.username,
    };

    const mutation =
      brandData && brandData?.id
        ? updateCategoryMutation
        : createCategoryMutation;
    const mutationInput =
      brandData && brandData?.id
        ? {
          input: {
            id: brandData?.id,
            ...payload,
            vendorId: values?.vendorId,
            customerId: values?.customerId,
          },
        }
        : { input: { ...payload, password: values?.password } };
    console.log(...[mutation, '👀 [index.js:83]: mutation'].reverse());
    mutation({ variables: mutationInput })
      .then((res) => {
        // console.log(
        //   res.data[brandData && brandData?.id ? "updateBrand" : "createBrand"]
        // );
        if (res.data === null) {
          handleClose();
          console.log(res?.errors?.[0]?.message)
          // refetch();
          setNotification({
            color: "error",
            isVisible: true,
            message:
              brandData && brandData?.id
                ? res?.errors?.[0]?.message
                : res?.errors?.[0]?.message,
          });
        }
        else {
          handleClose();
          // refetch();
          setNotification({
            color: "success",
            isVisible: true,
            message:
              brandData && brandData?.id
                ? "Updated successfully"
                : "Created successfully",
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error.message);
        handleClose();
        setNotification({
          color: "error",
          isVisible: true,
          message: error.message,
        });
      });
  };

  return handleSubmit;
};

const BrandForm = ({ brandData, handleClose }) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const { setNotification } = useNotification();
  const handleSubmit = useBrandMutation(
    brandData,
    handleClose,
    setNotification
  );
  useEffect(() => {
  }, [brandData])
  return (
    <Formik
      initialValues={{
        name: brandData?.id ? brandData?.name : "",
        username: brandData?.id ? brandData?.username : "",
        password: brandData?.id ? brandData?.password || "********" : "",
        vendorId: brandData?.id ? brandData?.vendorId : "",
        customerId: brandData?.id ? brandData?.customerId : "",
      }}
      validationSchema={ValidationSchema}
      onSubmit={(values) => {
        console.log(
          ...[values, "values"].reverse()
        );
        handleSubmit(values);
      }}
    >
      {(formik) => {
        return (
          <Drawer
            anchor="right"
            open={open}
            onClose={handleClose}
            PaperProps={{ sx: { width: "50%" } }}
          >
            <Form onSubmit={formik.handleSubmit}>
              <Grid container sx={{ pb: 3, pt: 2, pl: "5px" }}>
                <Grid item xs={12}>
                  &nbsp;
                </Grid>
                <Grid item>
                  <Icon sx={{ ml: 2 }} component={Star} fontSize="large" />
                </Grid>
                <Grid item>
                  <p
                    style={{
                      fontFamily: "Montserrat",
                      fontSize: "20px",
                      fontWeight: "700",
                      marginLeft: "10px",
                    }}
                  >
                    {brandData?.id ? "Update Brand Details" : "Create New Brand"}
                  </p>
                </Grid>
              </Grid>
              <Box sx={{ width: "100%", minHeight: "70vh" }}>
                <Grid container spacing={3}>
                  {brandData?.id && (
                    <>
                      <Grid item xs={11} mx="auto">
                        <Field
                          name="vendorId"
                          as={TextField}
                          label="Ginesys Vendor Id"
                          fullWidth
                          value={formik.values.vendorId}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.vendorId && !!formik.errors.vendorId
                          }
                        />
                        <ErrorMessage
                          name="vendorId"
                          component="div"
                          className="error"
                        />
                      </Grid>

                      <Grid item xs={11} mx="auto">
                        <Field
                          name="customerId"
                          as={TextField}
                          label="Ginesys Customer Id"
                          fullWidth
                          value={formik.values.customerId}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.customerId &&
                            !!formik.errors.customerId
                          }
                        />
                        <ErrorMessage
                          name="customerId"
                          component="div"
                          className="error"
                        />
                      </Grid>
                    </>
                  )}
                  <Grid item xs={11} mx="auto">
                    <Field
                      name="name"
                      as={TextField}
                      label="Company Name"
                      fullWidth
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      error={formik.touched.name && !!formik.errors.name}
                    />
                    <ErrorMessage
                      name="name"
                      component="div"
                      className="error"
                    />
                  </Grid>
                  <Grid item xs={11} mx="auto">
                    <Field
                      name="username"
                      as={TextField}
                      label="Email Id"
                      fullWidth
                      value={formik.values.username}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.username && !!formik.errors.username
                      }
                    />
                    <ErrorMessage
                      name="username"
                      component="div"
                      className="error"
                    />
                  </Grid>
                  <Grid item xs={11} mx="auto">
                    <Field
                      id="password"
                      as={TextField}
                      type={showPassword ? 'text' : 'password'}
                      name="password"
                      fullWidth
                      disabled={brandData?.id}
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      inputProps={{ style: { fontFamily: 'Montserrat' } }}
                      InputLabelProps={{ style: { fontFamily: 'Montserrat' } }}
                      error={
                        formik.touched.password && !!formik.errors.password
                      }
                      // helperText={formik.touched.password ? formik.errors.password : ""}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      label="Password"
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction="row-reverse"
                  justifyContent="flex-start"
                  alignItems="center"
                  gap={3}
                  xs={11}
                  mx="auto"
                  sx={{
                    marginTop: "4rem",
                  }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    style={{
                      backgroundColor: "#000000",
                      color: "#fff",
                      borderRadius: "50px",
                      fontFamily: "Montserrat",
                    }}
                  >
                    {brandData?.id ? "Update" : "Create"}
                  </Button>
                  <MDButton
                    variant="outlined"
                    color="black"
                    circular={true}
                    sx={{ borderRadius: "50px", fontFamily: "Montserrat" }}
                    onClick={handleClose}
                  >
                    Cancel
                  </MDButton>
                </Grid>
              </Box>
            </Form>
          </Drawer>
        );
      }}
    </Formik>
  );
};

const Brands = () => {
  const user =
    localStorage.getItem("userDetails") &&
      localStorage.getItem("userDetails") !== "undefined"
      ? JSON.parse(localStorage.getItem("userDetails"))
      : {};
  const [brandData, setBrandData] = useState(null);
  const { searchKeyword, setSearchKeyword } = useContext(SearchContext);
  const { selectedBrands } = useContext(FilterContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20); // You can set this as you prefer
  const [brandList, setBrandList] = useState(null);
  const [allBrandList, setAllBrandList] = useState(null);
  const [tabValue, setTabValue] = useState(0);

  const [showTabForm, setShowTabForm] = useState(false);
  const [showBrandForm, setShowBrandForm] = useState(false);

  const handleExportToExcel = () => {
    console.log("brandList ==== ", allBrandList);

    const flatten = (obj, parent = "", res = {}) => {
      for (let key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          const propName = parent ? `${parent}.${key}` : key;
          if (typeof obj[key] === "object" && obj[key] !== null) {
            if (Array.isArray(obj[key])) {
              obj[key].forEach((item, index) => {
                flatten(item, `${propName}[${index}]`, res);
              });
            } else {
              flatten(obj[key], propName, res);
            }
          } else {
            res[propName] = obj[key];
          }
        }
      }
      return res;
    };

    const flattenedData = allBrandList.brandlist?.results?.map((item) =>
      flatten(item)
    );
    const worksheet = XLSX.utils.json_to_sheet(flattenedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    XLSX.writeFile(workbook, "data.xlsx");
  };

  const queryToUse =
    user && user?.role === "admin" ? GET_BRANDS_LIST : GET_BRAND_DETAILS;

  const totalPages = brandList
    ? Math.ceil(brandList?.brandlist?.total / itemsPerPage)
    : 0;
  const skip = (currentPage - 1) * itemsPerPage;

  const { loading, error, data, refetch } = useQuery(queryToUse, {
    client: dashboardServiceClient,
    variables: {
      username: user && user?.role === "admin" ? "" : user?.username,
      take: itemsPerPage,
      skip: skip,
      search: searchKeyword,
      filter: selectedBrands ? { id: selectedBrands, isDeleted: false } : { isDeleted: false },
      orderby: { brandStatus: "DESC", name: "ASC" }
    },
  });

  const [
    fetchAllData,
    { data: allData, loading: allLoading, error: allError },
  ] = useLazyQuery(queryToUse, {
    client: dashboardServiceClient,
    variables: {
      username: user && user?.role === "admin" ? "" : user?.username,
      take: 100000,
      skip: 0,
      search: searchKeyword,
      filter: selectedBrands ? { id: selectedBrands, isDeleted: false } : { isDeleted: false },
    },
  });

  useEffect(() => {
    fetchAllData();
  }, []);

  useEffect(() => {
    if (allData) {
      setAllBrandList(allData);
    }
  }, [allData]);

  useEffect(() => {
    if (data && JSON.stringify(data) !== JSON.stringify(brandList)) {
      setBrandList(data);
    }
  }, [data]);

  const handleItemsPerPageChange = (event) => {
    const newItemsPerPage = event.target.value;
    const newTotalPages = Math.ceil(
      brandList?.brandlist?.total / newItemsPerPage
    );
    setItemsPerPage(newItemsPerPage);
    if (currentPage > newTotalPages) {
      setCurrentPage(1); // Reset to first page
    }
  };
  useEffect(() => {
    setSearchKeyword("");
    setTimeout(() => {
      if (refetch) {
        refetch();
      }
    }, 100);
  }, []);
  useEffect(() => {
    refetch({
      variables: {
        skip: (currentPage - 1) * itemsPerPage,
        take: itemsPerPage,
        search: searchKeyword,
        filter: selectedBrands ? { id: selectedBrands, isDeleted: false } : { isDeleted: false },
      },
    });
  }, [currentPage, itemsPerPage, refetch, searchKeyword, selectedBrands]);

  useEffect(() => {
    setCurrentPage(1);
  }, [searchKeyword, selectedBrands]);

  const [getBrandDetail, { data: brandDetail }] = useLazyQuery(
    GET_BRAND_DETAILS,
    {
      client: dashboardServiceClient,
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        console.log("Fetched brand details:", data);
        setBrandData(data?.brand);
      },
    }
  );

  const handleBrandData = (data) => {
    getBrandDetail({
      variables: {
        username: data.username,
      },
    })
      .then((res) => {
        // console.log(res.data.brand);
        setBrandData(res.data.brand);
        setOpen(true);
      })
      .catch((err) => console.log(err));
  };

  const ControlSection = ({ user }) =>
    user && user?.role === "admin" ? (
      <>
        <Grid item xs={12} sm={6} md={6} />
        <Grid item xs={12} sm={6} md={6} textAlign="right"></Grid>
      </>
    ) : (
      <></>
    );

  const handleRowClick = (params) => {
    // const filteredFetailedRowData = brandList?.brandlist?.results?.filter((elem) => elem.id === params.row.id);
    console.log(params.row, "params")
    setBrandData(params.row);
    setShowTabForm(true);
  };

  if (loading) return <Loader />;

  if (error) return <p>Error: {error.message}</p>;
  return (
    <DashboardLayout>
      {!showTabForm && <DashboardNavbar />}
      {showBrandForm && (
        <BrandForm
          brandData={brandData}
          handleClose={() => setShowBrandForm(false)}
        />
      )}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6}>
          <Typography sx={{ fontFamily: "montserrat", color: "#000", fontWeight: "700" }} ml={1}>Brands</Typography>
        </Grid>
        {console.log(
          ...[user?.role, "👀 [index.js:471]: user?.role"].reverse()
        )}
        {user && user?.role === "admin" && !showTabForm && (
          <>
            <Grid item xs={12} sm={6} md={6} textAlign="right">
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  gap: 2,
                }}
              >
                {/* <Icon sx={{ mr: 2 }} component={searchIcon} fontSize="large" /> */}
                <MDButton
                  type="button"
                  variant="contained"
                  color="black"
                  sx={{
                    fontFamily: "Montserrat",
                    fontSize: "15px",
                    fontWeight: 400,
                  }}
                  circular={true}
                  startIcon={<AddIcon />}
                  onClick={() => {
                    handleExportToExcel();
                  }}
                >
                  Export
                </MDButton>

                <MDButton
                  type="button"
                  variant="contained"
                  color="black"
                  sx={{
                    fontFamily: "Montserrat",
                    fontSize: "15px",
                    fontWeight: 400,
                  }}
                  circular={true}
                  startIcon={<AddIcon />}
                  onClick={() => {
                    setShowBrandForm(true)
                    setBrandData(null)
                  }}
                >
                  New Brand
                </MDButton>
              </Box>
            </Grid>
          </>
        )}
        <Grid item xs={12} sm={12} md={12}>
          <ControlSection user={user} />
          {user && user?.role === "admin" ? (
            <>
              {showTabForm ? (
                <>
                  <Grid mt={2}>
                    <IconButton
                      aria-label="Go Back"
                      onClick={() => { setShowTabForm(false); setTabValue(0); }}
                    >
                      <ArrowBackIcon />
                      <Typography
                        sx={{ fontSize: "16px", ml: 1, fontWeight: "bold" }}
                      >
                        Back
                      </Typography>
                    </IconButton>
                  </Grid>
                  <Box
                    sx={{
                      width: "100%",
                      minHeight: "auto",
                      backgroundColor: "#FFFFFF",
                      borderRadius: "10px",
                      mt: 4,
                    }}
                  >
                    <BasicTabs
                      brandData={brandData || data.brand}
                      setBrandData={handleBrandData}
                      tabValue={tabValue}
                      setTabValue={setTabValue}
                      user={user}
                    />
                  </Box>
                </>
              ) : (
                <Box
                  sx={{
                    width: "100%",
                    minHeight: "auto",
                    backgroundColor: "#FFFFFF",
                    borderRadius: "10px",
                    mt: 4,
                  }}
                >
                  <BrandListingPage
                    onRowClick={handleRowClick}
                    setShowBrandForm={setShowBrandForm}
                    setBrandData={setBrandData}
                  />
                </Box>
              )}

            </>
          ) : (
            <Box
              sx={{
                width: "100%",
                minHeight: "auto",
                backgroundColor: "#FFFFFF",
                borderRadius: "10px",
                mt: 4,
              }}
            >
              <BasicTabs
                brandData={brandData || data.brand}
                setBrandData={handleBrandData}
                tabValue={tabValue}
                setTabValue={setTabValue}
                user={user}
              />
            </Box>
          )}
        </Grid>
      </Grid>
      {!open && user && user?.role === "admin" ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            margin: "20px 0",
          }}
        >
          <Grid item xs={12} m={1}>
            <Pagination
              style={{
                display: "flex",
                justifyContent: "center",
                transform: "scale(1.2)",
                margin: "20px 0",
              }}
              count={totalPages}
              page={currentPage}
              onChange={(event, page) => setCurrentPage(page)}
              color="secondary"
            />
          </Grid>
          <Grid item xs={2} justifyContent="space-between" display="flex">
            <FormControl
              variant="outlined"
              size="small"
              sx={{ minWidth: 90, marginLeft: 2 }}
            >
              <Typography fontSize={12} fontWeight="bold">
                &nbsp; Page Size
              </Typography>
              <Select
                value={itemsPerPage}
                onChange={handleItemsPerPageChange}
                sx={{ height: "30px", fontSize: "0.875rem" }}
                inputProps={{ "aria-label": "Without label" }}
                minHeight="20px"
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={50}>50</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Box>
      ) : (
        <></>
      )}
    </DashboardLayout>
  );
};

export default Brands;
