import React from "react";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Drawer,
  Grid,
  Box,
  Icon,
  IconButton,
} from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Add as AddIcon } from "@mui/icons-material";

import { useMutation, useQuery } from "@apollo/client";
import { feedServiceClient } from "graphql/client";
import { GET_SECTIONS_BY_PAGE } from "services/ecommerce-media-service";

import MDButton from "components/MDButton";
import SectionForm from "./form";
import AgGrid from "./table";
import { generateYupSchema } from "./utils";
import { ActionCellRenderer } from "layouts/ecommerce-component/sectionListing";
import { UPDATE_COMPONENT_METADATA } from "services/ecommerce-component-service";
import { ReactComponent as previewIcon } from "assets/images/previewIcon.svg";

const getPreview = (data) => {
  const media = data?.media;
  const handlePreview = (media) => {
    if (Array.isArray(media)) {
      // return media.map(m=>m.metadata.type).join(', ')
      const newTab = window.open("", "_blank");
      newTab.document.body.innerHTML = ``;
      media.forEach((m) => {
        if (m.metadata.type == "image") {
          newTab.document.body.innerHTML += `<h3 style="text-align:center">${m.fileName}</h3><img src="${m.publicUrl}" style="width: 100%; height: 500px;margin-bottom:20px"/>`;
        } else if (m.metadata.type == "video") {
          newTab.document.body.innerHTML += `<h3 style="text-align:center">${m.fileName}</h3><video controls autoplay style="width: 100%; height: 500px;margin-bottom:20px"><source src="${m.publicUrl}" type="video/mp4"></video>`;
        }
      });
      newTab.focus();
    } else {
      const newTab = window.open("", "_blank");
      if (media.metadata.type == "image") {
        newTab.document.body.innerHTML = `<h3 style="text-align:center">${media.fileName}</h3><img src="${media.publicUrl}" style="width: 100%; height: 500px;margin-bottom:20px"/>`;
      } else if (media.metadata.type == "video") {
        newTab.document.body.innerHTML = `<h3 style="text-align:center">${media.fileName}</h3><video controls autoplay style="width: 100%; height: 500px;margin-bottom:20px"><source src="${media.publicUrl}" type="video/mp4"></video>`;
      }
      newTab.focus();
    }
  };
  return (
    <IconButton aria-label="preview" onClick={() => handlePreview(media)}>
      <Icon component={previewIcon} fontSize="small" />
    </IconButton>
  );
};
import { REMOVE_COMPONENT_METADATA } from "services/ecommerce-component-service";

function SectionListing({ page = "brandLanding" }) {
  const { data, loading, error, refetch } = useQuery(GET_SECTIONS_BY_PAGE, {
    client: feedServiceClient,
    variables: {
      input: {
        page,
      },
    },
  });
  const [updateComponentMetadata] = useMutation(UPDATE_COMPONENT_METADATA, {
    client: feedServiceClient,
  });
  const [deleteComponentMetadata] = useMutation(REMOVE_COMPONENT_METADATA, {
    client: feedServiceClient,
  });
  const [componentFormState, setComponentFormState] = React.useState({});

  React.useEffect(() => {
    let componentFormState = {};
    (data?.sections?.results || []).forEach((section) => {
      (section?.component || []).forEach((component) => {
        componentFormState = {
          ...componentFormState,
          [component.name]: false,
        };
      });
    });
    setComponentFormState(componentFormState);
  }, [data]);

  const getAccordionSummary = (section) => {
    const guideLine =
      section.sectionMetaData.find((meta) => meta.key === "guideline") || [];
    const summary = Object.entries(guideLine.value?.data || {}).reduce(
      (acc, item) => {
        return `${acc} | ${item[0]}: ${item[1]}`;
      },
      section.name
    );
    return summary;
  };

  const getAccordionDetail = (
    section,
    componentFormState,
    setComponentFormState
  ) => {
    console.log(...[section, "👀 [section.jsx:67]: section"].reverse());
    const handleEdit = (data) => {
      // console.log(...[component, "👀 [section.jsx:68]: component"].reverse());
      // // setSelectedComponent(component);
      // setShowCreateComponentForm((prevState) => ({
      //   ...prevState,
      //   [component.name]: true,
      // }));
      const component = data?._component;
      let formState = { ...componentFormState };
      Object.keys(formState).forEach((key) => {
        if (key !== component.name) {
          formState[key] = false;
        } else {
          formState[key] = data;
        }
      });
      setComponentFormState({
        ...formState,
      });
      console.log(data, "CLICK EDIT");
    };
    let columnDef = [
      {
        headerName: "Actions",
        field: "actions",
        cellRenderer: "customCellRenderer",
        colId: "actions",
        minWidth: 50,
        maxWidth: 50,
        cellRenderer: (params) => {
          return (
            <ActionCellRenderer
              deleteCallback={() => {
                console.log(
                  ...[params.data, "👀 [section.jsx:87]: params.data"].reverse()
                );
                deleteComponentMetadata({
                  variables: {
                    id: params?.data?.id,
                  },
                  onCompleted: () => {
                    console.log("Component deleted successfully");
                    refetch();
                  },
                });
                // deleteSection({
                //   variables: { id: params.data.id },
                // });
                // queryRefetch({variables:{page}})
                // setKey(Date.now());
              }}
              editCallback={() => {
                handleEdit(params.data);
              }}
              params={params}
            />
          );
        },
        filter: false,
      },
    ];

    section.component.forEach((component) => {
      component.componentMetaData.forEach((meta) => {});
    });

    let queryData = [];
    return (
      <React.Fragment>
        {section?.component?.filter(component=>!component.isDeleted)?.map((component) => {
          const jsonSchema = component.componentMetaData.find(
            (meta) => meta.key === "validationSchema" && !meta.isDeleted
          )?.value;
          const validationKeys = {};
          // let yupSchema = null
          if (jsonSchema) {
            // yupSchema = generateYupSchema(validationSchema);
            Object.keys(jsonSchema).forEach((key) => {
              validationKeys[key] = true;
            });
            // console.log(JSON.stringify(yupSchema),component.name);
          }

          const dynamicColDef =
            component.componentMetaData.find((meta) => meta.key === "columnDef")
              ?.value || [];
          if (Array.isArray(dynamicColDef)) {
            columnDef = [
              ...columnDef,
              ...dynamicColDef.map((col) => {
                if (typeof col.valueGetter === "string") {
                  col.valueGetter = new Function(
                    "params",
                    `return ${col.valueGetter.split("=>")[1].trim()}`
                  );
                }
                if (typeof col.cellRenderer === "string") {
                  col.cellRenderer = new Function(
                    "params",
                    `return ${col.cellRenderer.split("=>")[1].trim()}`
                  );
                }
                return col;
              }),
            ];
          } else {
            // * it is an object
            columnDef = [
              ...columnDef,
              ...Object.values(dynamicColDef).map((col) => {
                let newCol = { ...col };

                // Handle valueGetter
                if (typeof col.valueGetter === "string") {
                  const valueGetterCode = col.valueGetter
                    .split("=$>")[1]
                    ?.trim();
                  if (valueGetterCode) {
                    try {
                      newCol.valueGetter = new Function(
                        "params",
                        `return ${valueGetterCode}`
                      );
                    } catch (error) {
                      console.error(
                        "Error creating valueGetter function:",
                        error
                      );
                    }
                  }
                }

                // Handle cellRenderer
                if (typeof col.cellRenderer === "string") {
                  const cellRendererCode = col.cellRenderer
                    .split("=$>")[1]
                    ?.trim();
                  if (cellRendererCode) {
                    try {
                      newCol.cellRenderer = function (params) {
                        // Pass getPreview to the evaluated code
                        return new Function(
                          "params",
                          "getPreview",
                          `return ${cellRendererCode}`
                        )(params, getPreview);
                      };
                    } catch (error) {
                      console.error(
                        "Error creating cellRenderer function:",
                        error
                      );
                    }
                  }
                }

                return newCol;
              }),
            ];
          }
          console.log(
            ...[
              columnDef,
              component.name,
              "👀 [section.jsx:125]: columnDef",
            ].reverse()
          );

          const formDataRows =
            component.componentMetaData
              .filter((meta) => meta.key === "formData" && !meta.isDeleted)
              ?.map((formData) => ({
                id: formData.id,
                _component: component,
                ...formData?.value?.data,
              }))
              .sort(
                (form1, form2) => form1.displayOrder - form2.displayOrder
              ) || [];
          return (
            <React.Fragment key={component.id}>
              <AgGrid
                columns={columnDef}
                queryData={formDataRows}
                updateMethod={{
                  refatch: refetch,
                  mutation: updateComponentMetadata,
                  key: "updateComponent",
                }}
                page={page}
                queryLoading={loading}
              />
              <MDButton
                type="button"
                variant="contained"
                color="black"
                sx={{
                  fontFamily: "Montserrat",
                  fontSize: "15px",
                  fontWeight: 400,
                  marginLeft: "auto",
                  marginBottom: "10px",
                  float: "right",
                  marginTop: "10px",
                }}
                circular={true}
                startIcon={<AddIcon />}
                onClick={() => {
                  let formState = { ...componentFormState };
                  Object.keys(formState).forEach((key) => {
                    if (key !== component.name) {
                      formState[key] = false;
                    } else {
                      formState[key] = !formState[key];
                    }
                  });
                  setComponentFormState({
                    ...formState,
                  });
                }}
              >
                {`Add ${component.name}`}
              </MDButton>
              <Drawer
                anchor="right"
                open={componentFormState[component.name]}
                onClose={() => {
                  let formState = { ...componentFormState };
                  Object.keys(formState).forEach((key) => {
                    formState[key] = false;
                  });
                  setComponentFormState({
                    ...formState,
                  });
                }}
                PaperProps={{ sx: { width: "60%" } }}
              >
                <Grid
                  container
                  sx={{ backgroundColor: "#F0F2F5", pb: 3, pt: 2, pl: "5px" }}
                >
                  <Grid item xs={12}>
                    &nbsp;
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h5" sx={{ ml: 2 }}>
                      {page} - {section?.name} - {component?.name}
                    </Typography>
                  </Grid>
                </Grid>
                <Box sx={{ width: "100%", minHeight: "70vh", padding: "20px" }}>
                  <SectionForm
                    page={page}
                    section={section}
                    jsonSchema={jsonSchema}
                    component={component}
                    totalRows={formDataRows}
                    selectCategory={validationKeys["category"]}
                    selectBrand={validationKeys["brand"]}
                    selectEvent={validationKeys["event"]}
                    selectProduct={validationKeys["product"]}
                    selectNews={validationKeys["news"]}
                    selectMedia={validationKeys["media"]}
                    showCtaText={validationKeys["ctaText"]}
                    showCtaLink={validationKeys["ctaLink"]}
                    showStatus={true}
                    formData={componentFormState[component.name]}
                    handleClose={(doRefetch) => {
                      let formState = { ...componentFormState };
                      Object.keys(formState).forEach((key) => {
                        formState[key] = false;
                      });
                      setComponentFormState({
                        ...formState,
                      });
                      if (doRefetch) {
                        refetch();
                      }
                    }}
                  />
                </Box>
              </Drawer>
            </React.Fragment>
          );
        })}
      </React.Fragment>
    );
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error...</div>;

  return (
    <React.Fragment>
      {(data?.sections?.results || []).map((section) => (
        <Accordion defaultExpanded={false} key={section.id}>
          <AccordionSummary
            sx={{ backgroundColor: "#EFF4F9" }}
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel1-content-${section.internalName}`}
            id={`panel1-header-${section.internalName}`}
          >
            <Typography>{getAccordionSummary(section)}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {getAccordionDetail(
              section,
              componentFormState,
              setComponentFormState
            )}
          </AccordionDetails>
        </Accordion>
      ))}
    </React.Fragment>
  );
}

export default SectionListing;
