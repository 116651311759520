import React, { useEffect } from "react";
import { Formik, Form, Field } from "formik";
import {
  Grid,
  TextField,
  Button,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Typography
} from "@mui/material";
import * as Yup from "yup";
import { ReactComponent as ArrowDropDownIcon } from "assets/images/Arrow-Down.svg";
import MDButton from "components/MDButton";
import dayjs from "dayjs";
import Loader from "components/MDLoader";
import { useNotification } from "context";
import { useMutation, useQuery } from "@apollo/client";
import { dashboardServiceClient } from "graphql/client";
import { eventServiceClient } from "graphql/client";
import { GET_STORES_LIST } from "services/store-service";
import { GET_CATEGORIES } from "services/product-category-service";
import { CREATE_SPONSER } from "services/events-service";
import { UPDATE_SPONSER } from "services/events-service";
import { GET_EVENTS } from "services/events-service";
import ReactQuill from "react-quill";


const validationSchema = Yup.object({
  sponsorshipTier: Yup.string().required("Sponsorship tier is required"),
  termsConditions: Yup.string()
    .nullable()
    .notRequired()
    .test(
      "has-text-content",
      "Terms and Conditions must have text content",
      function(value) {
        // If no value is provided, pass validation
        if (!value) return true;
        // Check for meaningful text content if value is provided
        const textContent = value.replace(/<[^>]*>?/gm, '').trim();
        return !!textContent || !value;
      }
    ),
  });

const useSponserMutation = (eventData, handleClose, setNotification) => {
  const mutationOptions = {
    client: eventServiceClient,
    refetchQueries: [
      {
        query: GET_EVENTS,
        variables: {
          eventListFilter: { take: 20, skip: 0, search: "", filter: {} },
        },
      },
    ],
  };
  const user =
  localStorage.getItem("userDetails") &&
  localStorage.getItem("userDetails") !== "undefined"
    ? JSON.parse(localStorage.getItem("userDetails"))
    : {};
  const [createEventSponser] = useMutation(CREATE_SPONSER, mutationOptions);

  const handleSubmit = (values) => {
    const payload = {
      sponserTier: values?.sponsorshipTier,
      eventId: eventData.id,
      brandId:  user?.userId,
    };

    const mutation = createEventSponser;
    const mutationInput = { createEventSponserInput: payload };

    mutation({ variables: mutationInput })
      .then((res) => {
        console.log(res.data["createEventSponser"]);
        handleClose();
        setNotification({
          color: "success",
          isVisible: true,
          message: "Event Interest Submitted",
        });
      })
      .catch((error) => {
        console.error("Error:", error.message);
        setNotification({
          color: "error",
          isVisible: true,
          message: error.message || "Something went wrong",
        });
      });
  };

  return handleSubmit;
};
const EventsForm = ({ eventData, handleClose }) => {
  const { setNotification } = useNotification();
  const handleSubmit = useSponserMutation(
    eventData,
    handleClose,
    setNotification
  );

  const {
    loading: storeLoading,
    error: storeError,
    data: storeList,
  } = useQuery(GET_STORES_LIST, {
    client: dashboardServiceClient,
    variables: { listStoreFilter: { take: 100, skip: 0 } },
  });
  const storeName =
    storeList?.stores?.results?.find((store) => store.id === eventData.storeId)
      ?.name || "Store Name Not Found";
  const {
    loading,
    error,
    data: categoryList,
  } = useQuery(GET_CATEGORIES, {
    client: dashboardServiceClient,
    variables: {
      listCategoryProductFilter: {
        take: 1000,
        skip: 0,
        search: "",
        filter: { isNodeItem: true, brandCategoryStore: { isDeleted: false } },
      },
    },
  });
  const categoryName =
    categoryList?.categoryProduct?.results?.find(
      (cat) => cat.id === eventData.categoryId
    )?.title || "Category Name Not Found";

  if (storeLoading)
    return (
      <>
        <Loader />
      </>
    );
  if (storeError) return <p>Error: {error?.message}</p>;

  function stripHtml(html) {
    return html.replace(/<\/?[^>]+(>|$)/g, "");
  }
  return (
    <Formik
      initialValues={{
        eventName: (eventData && eventData?.name) || "",
        eventType:
          (eventData && eventData.eventType === "oneToOne"
            ? "One To One"
            : "One To Many") || "",
        storeName: (eventData && eventData.storeId) || "",
        description: (eventData && (eventData.description)) || "",
        eventStartDate: (eventData && eventData.startDate) || "",
        eventEndDate: (eventData && eventData.endDate) || "",
        eventStartTime: (eventData && eventData.startTime) || "",
        eventEndTime: (eventData && eventData.endTime) || "",
        eventCategory: categoryName || "",
        termsConditions: (eventData && (eventData.termsCondition?.text)) || "",
        sponsorshipTier:
          (eventData && eventData?.sponser[0]?.sponserTier) || "",
      }}
      validateOnChange={true}
      validateOnBlur={true}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        handleSubmit(values);
      }}
    >
      {({
        values,
        setFieldValue,
        setFieldTouched,
        validateField,
        errors,
        touched,
      }) => (
        <Form style={{ padding: "20px" }}>
          {console.log({ values, errors, touched }, "Formik State")}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Field
                as={TextField}
                name="eventName"
                label="Name of the Event"
                value={eventData.name}
                disabled
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                as={TextField}
                name="eventType"
                label="Event Type"
                value={eventData.eventType === "oneToOne"
                  ? "One To One"
                  : "One To Many" || ""}
                disabled
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                as={TextField}
                name="storeName"
                label="Store Name"
                value={storeName}
                disabled
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
            <Field
                as={TextField}
                name="description"
                label="Description"
                value={eventData.description}
                disabled
                fullWidth
                multiline
                rows={2}
                  />
            </Grid>
            <Grid item xs={6}>
              <Field
                as={TextField}
                name="eventStartDate"
                label="Event Start Date"
                value={dayjs(eventData.startDate).format("MM/DD/YYYY")}
                disabled
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                as={TextField}
                name="eventStartTime"
                label="Event Start Time"
                value={dayjs(eventData.startTime, "HH:mm:ss").format("hh:mm A")}
                disabled
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                as={TextField}
                name="eventEndDate"
                label="Event End Date"
                value={dayjs(eventData.endDate).format("MM/DD/YYYY")}
                disabled
                fullWidth
              />
            </Grid>

            <Grid item xs={6}>
              <Field
                as={TextField}
                name="eventEndTime"
                label="Event End Time"
                value={dayjs(eventData.endTime, "HH:mm:ss").format("hh:mm A")}
                disabled
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                as={TextField}
                name="eventCategory"
                label="Event Category"
                value={categoryName}
                disabled
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
            <Typography fontSize={15} fontFamily="bold" >Terms and Conditions</Typography>
              <ReactQuill
                    theme="snow"
                    value={values.termsConditions}
                    readOnly={true} 
                    modules={{ toolbar: false }} 
                  />
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel id="sponsorship-tier-label">
                  Select Sponsorship Tier
                </InputLabel>
                <Field
                  as={Select}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="sponsorshipTier"
                  label="Select Sponsorship Tier"
                  IconComponent={() => (
                    <ArrowDropDownIcon style={{ marginRight: "18px" }} />
                  )}
                  sx={{ height: 40 }}
                  value={values.sponsorshipTier|| []}
                  onChange={(e) => {
                    setFieldValue("sponsorshipTier", e.target.value);
                    console.log(e.target.value,"e.target.value")
                  }}
                  helperText={touched.sponsorshipTier && errors.sponsorshipTier}
                  error={
                    touched.sponsorshipTier && Boolean(errors.sponsorshipTier)
                  }
                >
                  {eventData &&
                    eventData.sponserTier &&
                    eventData?.sponserTier?.map((tier) => (
                      <MenuItem key={tier} value={tier}>
                        {tier?.charAt(0)?.toUpperCase() + tier?.slice(1)}
                      </MenuItem>
                    ))}
                </Field>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
            <MDButton
                  type="submit"
                  variant="outlined"
                  circular={true}
                  color="dark"
              >
                Show Interest
              </MDButton>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default EventsForm;
