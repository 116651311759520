import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { FormHelperText } from "@mui/material";
import {
  Grid,
  TextField,
  FormControlLabel,
  Switch,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import {
  MobileDateTimePicker,
  DatePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { ReactComponent as ArrowDropDownIcon } from "assets/images/Arrow-Down.svg";
import { BRANDS_LIST } from "services/brand-service";
import {
  GET_PROMOTION_LIST,
  CREATE_PROMOTION_MUTATION,
  UPDATE_PROMOTION_MUTATION,
} from "services/promotion-service";
import { GET_STORES_LIST } from "services/store-service";
import { dashboardServiceClient } from "graphql/client";
import { useMutation, useQuery } from "@apollo/client";
import ReactQuill from "react-quill";
import { useNotification } from "context";
import "react-quill/dist/quill.snow.css"; // import styles
import "./promotion.css"
import MDButton from "components/MDButton";
import Loader from "components/MDLoader";


const usePromotionMutations = (
  promotionData,
  handleClose,
  handleTabChange,
  setNotification
) => {
  const mutationOptions = {
    client: dashboardServiceClient,
    refetchQueries: [
      {
        query: GET_PROMOTION_LIST,
        variables: {
          take: 20, skip: 0, search: "", filter: {}, fetchPolicy: 'network-only',
        },
      },
    ],
  };
  const [createPromotionMutation] = useMutation(
    CREATE_PROMOTION_MUTATION,
    mutationOptions
  );
  const [updatePromotionMutation] = useMutation(
    UPDATE_PROMOTION_MUTATION,
    mutationOptions
  );
  const handleSubmit = (values) => {
    const payload = {
      name: values?.promoName || "",
      description: values?.promoDesc || "",
      start: values?.start,
      end: values?.end,
      startTime: values?.startTime || "",
      endTime: values?.endTime || "",
      promotionBrand: values?.brands || '',
      storeId: values?.storeName || "",
      activationPeriod: values?.activationPeriod || "",
      status: values?.status ||"draft"
    };
    const mutation =
      promotionData && promotionData.id
        ? updatePromotionMutation
        : createPromotionMutation;
    const mutationInput =
      promotionData && promotionData.id
        ? { updatePromotionInput: { id: promotionData.id, ...payload } }
        : { createPromotionInput: payload };
    mutation({ variables: mutationInput })
      .then((res) => {
        console.log(
          res.data[
          promotionData && promotionData.id
            ? "updatePromotion"
            : "createPromotion"
          ]
        );
        handleTabChange(
          null,
          1,
          res.data[
          promotionData && promotionData.id
            ? "updatePromotion"
            : "createPromotion"
          ]
        );
        setNotification({
          color: "success",
          isVisible: true,
          message:
            promotionData && promotionData?.id
              ? "Promotion  updated successfully"
              : "Promotion  created successfully",
        });
      })
      .catch((error) => {
        console.error("Error:", error.message);
        setNotification({
          color: "error",
          isVisible: true,
          message: error.message || "Something went wrong",
        });
      });
  };
  return handleSubmit;
};
const PromotionForm = ({
  promotionData,
  user,
  handleClose,
  handleTabChange,
  setBrandChange,
  setSelectedPromotion
}) => {
  const { setNotification } = useNotification();
  const isAdmin = user && user.role === "admin";
  const defaultBrandId = isAdmin ? "" : user?.userId;

  const {
    loading: storeLoading,
    error: storeError,
    data: storeList,
  } = useQuery(GET_STORES_LIST, {
    client: dashboardServiceClient,
    variables: { listStoreFilter: { take: 100, skip: 0 } },
  });
  // return dayjs(storeTime?.start, "HH:mm");
  const getMinMaxTime = (
    storeId = null,
    eventEndTime = null,
    eventStartTime = null,
    eventStartDate = null,
    eventEndDate = null,
    isMinTime = true,
    timeFor = "startTime"
  ) => {
    // now validate the storeId against the storeList
    const store = storeList?.stores?.results?.find(
      (store) => store.id === storeId
    );
    // get the storemetadata from the store and filter key with value `calendar`
    const storeMetaData = store?.storeMetaData?.find(
      (meta) => meta.key === "calendar"
    );
    // if storeMetaData is not found then return the default time
    if (!storeMetaData) {
      return dayjs("23:59", "HH:mm");
    }
    // if storeMetaData is found then check the eventStartDate if present then check which day of week it is
    const eventStartDay = eventStartDate ? dayjs(eventStartDate).day() : null;
    const eventEndDay = eventEndDate ? dayjs(eventEndDate).day() : null;
    // now get startStoreTIme and endStoreTime from the storeMetaData using the eventStartDay === 'Sun' | 'Mon' like this
    const storeStartDayTime = storeMetaData.value.week[eventStartDay];
    const storeEndDayTime = storeMetaData.value.week[eventEndDay];
    // now return the min and max values allowed will be storeStartDayTime?.start < timeWeNeedToReturn < eventEndTime if present < storeEndDayTime?.end
    if (timeFor === "startTime") {
      return {
        min: dayjs(storeStartDayTime?.start, "HH:mm"),
        max: eventEndTime
          ? dayjs(eventEndTime, "HH:mm")
          : dayjs(storeEndDayTime?.end, "HH:mm"),
      };
    } else {
      return {
        min: eventStartTime
          ? dayjs(eventStartTime, "HH:mm")
          : dayjs(storeStartDayTime?.start, "HH:mm"),
        max: dayjs(storeEndDayTime?.end, "HH:mm"),
      };
    }
  };
  const validationSchema = Yup.object({
    // id: Yup.string(),
    brands: Yup.string().required("Select a brand"),
    storeName: Yup.string().required("Store name is required"),
    promoName: Yup.string()
      .required("Promo Name is required")
      .max(50, "Maximum 50 characters allowed"),
    start: Yup.date().required("Start date and time are required"),
    end: Yup.date()
      .required("End date and time are required"),
    startTime: Yup.string().required("Start time is required").test("is-within-store-start-time", "Start time should be in store start and end time range", function (value) {
      const min = getMinMaxTime(this.parent.storeName, this.parent.endTime, this.parent.startTime, this.parent.start, this.parent.end, true).min
      const max = getMinMaxTime(this.parent.storeName, this.parent.endTime, this.parent.startTime, this.parent.start, this.parent.end, false).max

      const timeToCheck = dayjs(value, "HH:mm");
      const minTime = dayjs(min, "HH:mm");
      const maxTime = dayjs(max, "HH:mm");

      // Check if the time is within the range
      return isSameOrAfter(timeToCheck, minTime) && isSameOrBefore(timeToCheck, maxTime);
    }),
    endTime: Yup.string().required("End time is required").test("is-within-store-end-time", "End time should be in store start and end time range", function (value) {
      const min = getMinMaxTime(this.parent.storeName, this.parent.endTime, this.parent.startTime, this.parent.start, this.parent.end, true, 'endTime').min
      const max = getMinMaxTime(this.parent.storeName, this.parent.endTime, this.parent.startTime, this.parent.start, this.parent.end, false, 'endTime').max

      const timeToCheck = dayjs(value, "HH:mm");
      const minTime = dayjs(min, "HH:mm");
      const maxTime = dayjs(max, "HH:mm");

      // Check if the time is within the range
      return isSameOrAfter(timeToCheck, minTime) && isSameOrBefore(timeToCheck, maxTime);
    }),
    activationPeriod: Yup.string().required("Activation period is required"),
    tc: Yup.string(),
    promoDesc: Yup.string()
      .required("Promo Description is required")
      .max(100, "Maximum 100 characters allowed"),
    isDeleted: Yup.boolean(),
    status: Yup.string()
  });

  const isSameOrAfter = (timeToCheck, compareTime) => {
    return timeToCheck.isSame(compareTime) || timeToCheck.isAfter(compareTime);
  };

  const isSameOrBefore = (timeToCheck, compareTime) => {
    return timeToCheck.isSame(compareTime) || timeToCheck.isBefore(compareTime);
  };

  const handleSubmit = usePromotionMutations(
    promotionData,
    handleClose,
    handleTabChange,
    setNotification
  );

  const [brandOptions, setBrandOptions] = useState([]);
  const { loading, data, error } = useQuery(BRANDS_LIST, {
    client: dashboardServiceClient,
    variables: {
      filter:
        user && user?.role === "admin"
          ? {
            isDeleted: false,
          }
          : {
            id: user?.userId,
          },
    },
  });

  useEffect(() => {
    if (data && data.brandlist && data.brandlist.results) {
      setBrandOptions(data?.brandlist?.results?.map((brand) => brand));
    }
  }, [data]);
  const brandIdsToNames = (brandIds) => {
    const brand = brandOptions.find((b) => b.id === brandIds)?.name;
    // const brandProfileMetadata = brand?.brandMetaData?.find(meta => meta.key === "brandCompanyProfile");
    // return brandProfileMetadata ? brandProfileMetadata.value.brandName : "Unknown";
    return brand;
  };
  if (loading) return <Loader />;
  if (error) return <p>Error: {error.message}</p>;
  return (
    <Formik
      key={promotionData ? promotionData.id : "new"} // Key the component to the promotion ID or 'new' for new promotions
      initialValues={{
        id: promotionData?.id,
        promoName: promotionData?.name || "",
        promoDesc: promotionData?.description || "",
        start: promotionData?.start ? dayjs(promotionData.start) : dayjs(),
        end: promotionData?.end
          ? dayjs(promotionData.end)
          : dayjs().add(1, "day"),
        startTime: (promotionData && promotionData.startTime) || "",
        endTime: (promotionData && promotionData.endTime) || "",
        isDeleted: promotionData?.id ? !promotionData.isDeleted : false,
        brands: isAdmin
          ? promotionData?.promotionBrand
            ?.filter((item) => !item?.isDeleted)
            .map((item) => item?.brand?.id)[0] || ""
          : defaultBrandId,
        storeName: (promotionData && promotionData.storeId) || "",
        activationPeriod: (promotionData && promotionData.activationPeriod) || "",
        status: (promotionData && promotionData.status) || "draft",
      }}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        handleSubmit(values);
      }}
    >
      {({
        values,
        setFieldValue,
        setFieldTouched,
        errors,
        touched,
        validateField,
        isSubmitting,
        handleChange,
        handleBlur
      }) => {
        return (
          <Form>
            <Grid container spacing={2} p={2}>
              <Grid item xs={12}>
                <Field as={TextField} name="id" label="ID" fullWidth disabled />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="brand-select-label">Brands</InputLabel>
                  <Select
                    labelId="brand-select-label"
                    id="brand-select"
                    name="brands"
                    label="Brands"
                    disabled={!isAdmin}
                    style={{ padding: "10px" }}
                    value={values.brands}
                    onChange={(e) => {
                      setFieldValue("brands", e.target.value);
                      setBrandChange(true);
                      const brand = brandOptions.find(
                        (brand) => brand.id === e.target.value
                      );
                      if (brand) {
                        setSelectedPromotion({
                          ...promotionData,
                          promotionBrand: [
                            { isDeleted: false, id: brand.id, brand: brand },
                          ],
                        });
                      }
                      // set
                    }}
                    renderValue={(selected) => brandIdsToNames(selected)}
                    error={
                      touched.brands && Boolean(errors.brands)
                    }
                    helperText={
                      touched.brands && errors.brands
                    }
                  >
                    {brandOptions
                      ?.filter((item) => item.brandStatus === "Live")
                      ?.map((brand) => {
                        return (
                          <MenuItem key={brand.id} value={brand.id} >
                            {brand ? brand?.name : "Unknown"}
                          </MenuItem>
                        );
                      })}
                  </Select>
                  {touched.brands && errors.brands && (
                    <Typography fontSize={12} color="error">
                      {errors.brands}
                    </Typography>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  fullWidth
                  error={touched.storeName && Boolean(errors.storeName)}
                >
                  <InputLabel id="demo-simple-select-label">
                    Store Name
                  </InputLabel>
                  <Select
                    as={Select}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="storeName"
                    label="Store Name"
                    IconComponent={() => (
                      <ArrowDropDownIcon
                        style={{ marginRight: "18px", marginTop: "10px" }}
                      />
                    )}
                    value={values.storeName}
                    onChange={(e) => {
                      setFieldValue("storeName", e.target.value);
                      setFieldValue("start", "");
                      setFieldValue("end", "");
                      setFieldValue("startTime", "");
                      setFieldValue("endTime", "");
                    }}
                    onBlur={handleBlur}
                    error={touched.storeName && Boolean(errors.storeName)}
                    sx={{ height: 40 }}
                  >
                    {storeList &&
                      storeList.stores.results
                        ?.filter((item) => item.status === "Active")
                        .map((store) => (
                          <MenuItem key={store.id} value={store.id}>
                            {store.name}
                          </MenuItem>
                        ))}
                  </Select>
                  {touched.storeName && errors.storeName ? (
                    <FormHelperText>{errors.storeName}</FormHelperText>
                  ) : null}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Field
                  as={TextField}
                  name="promoName"
                  label="Promo Name"
                  fullWidth
                  error={touched.promoName && !!errors.promoName}
                  helperText={touched.promoName && errors.promoName}
                />
              </Grid>
              <Grid item xs={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                      slotProps={
                        errors.start && !!touched.start ? {
                          textField: {
                            error: !!touched.start && errors.start,
                            helperText: errors.start
                          }
                        } : null
                      }
                      label="Start Date"
                      format="DD/MM/YYYY"
                      name="start"
                      value={values.start ? dayjs(values.start) : null}
                      onChange={(newValue) =>
                        setFieldValue(
                          "start",
                          dayjs(newValue).format("YYYY-MM-DD")
                        )
                      }
                      minDate={values.id ? undefined : dayjs().add(0, "day")}
                      // disabled={!values.storeName}
                      renderInput={(params) => (
                        <Field
                          as={TextField}
                          {...params}
                          fullWidth
                          error={touched?.start && Boolean(errors?.start)}
                          helperText={touched?.start && errors?.start}
                        />
                      )}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </Grid>
              <Grid item xs={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                      slotProps={
                        errors.end && !!touched.end? {
                          textField: {
                            error: !!touched.end && errors.end,
                            helperText: errors.end
                          }
                        } : null
                      }
                      format="DD/MM/YYYY"
                      label="End Date"
                      name="end"
                      minDate={
                        values.start
                          ? dayjs(values.start).add(0, "day")
                          : dayjs().add(0, "day")
                      }
                      value={values.end ? dayjs(values.end) : null}
                      onChange={(newValue) =>
                        setFieldValue(
                          "end",
                          dayjs(newValue).format("YYYY-MM-DD")
                        )
                      }
                      // disabled={!values.storeName}
                      renderInput={(params) => (
                        <Field
                          as={TextField}
                          {...params}
                          fullWidth
                          error={touched?.end && Boolean(errors?.end)}
                          helperText={touched?.end && errors?.end}
                        />
                      )}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Activation Period</InputLabel>
                  <Select
                    as={Select}
                    disabled={!isAdmin}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="activationPeriod"
                    label="Activation Period"
                    IconComponent={() => (
                      <ArrowDropDownIcon style={{ marginRight: "18px" }} />
                    )}
                    sx={{ height: 40 }}
                    value={values?.activationPeriod || []}
                    onChange={(e) => {
                      setFieldValue("activationPeriod", e.target.value);
                      setFieldTouched(`activationPeriod`, false, false);
                      if(e.target.value ==="allTime"){
                        setFieldValue("startTime", "00:00");
                        setFieldValue("endTime", "23:59");
                      }
                      else {
                        setFieldValue("startTime", "");
                        setFieldValue("endTime", "");
                      }
                    }}
                    helperText={touched?.activationPeriod && errors?.activationPeriod}
                    error={touched?.activationPeriod && Boolean(errors?.activationPeriod)}
                  >
                    <MenuItem value="specificTime">Specific Time</MenuItem>
                    <MenuItem value="allTime">All Time</MenuItem>
                  </Select>
                  {touched?.activationPeriod && errors?.activationPeriod && (
                    <Typography fontSize={12} color="error">
                      {errors?.activationPeriod}
                    </Typography>
                  )}
                </FormControl>
              </Grid>
             { values && values.activationPeriod !== "allTime"&& 
              <>
              <Grid item xs={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["TimePicker"]}>
                    <TimePicker
                      slotProps={
                        errors.startTime && touched.startTime
                          ? {
                            textField: {
                              error:
                                !!touched.startTime && errors.startTime,
                              helperText: errors.startTime,
                            },
                          }
                          : null
                      }
                      label="Start Time"
                      name="startTime"
                      minTime={
                        getMinMaxTime(
                          values.storeName,
                          values.endTime,
                          values.startTime,
                          values.start,
                          values.end,
                          true
                        ).min
                      }
                      maxTime={
                        getMinMaxTime(
                          values.storeName,
                          values.endTime,
                          values.startTime,
                          values.start,
                          values.end,
                          false
                        ).max
                      }
                      value={
                        values.startTime
                          ? dayjs(values.startTime, "HH:mm")
                          : null
                      }
                      onChange={(newValue) => {
                        setFieldValue(
                          "startTime",
                          dayjs(newValue).format("HH:mm")
                        );
                        setFieldTouched("startTime", true, false); // Changed
                        validateField("startTime");
                      }}
                      viewRenderers={{
                        hours: renderTimeViewClock,
                        minutes: renderTimeViewClock,
                        seconds: renderTimeViewClock,
                      }}
                    // disabled={!values.storeName || !values.start || !values.end}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </Grid>
              <Grid item xs={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["TimePicker"]}>
                    <TimePicker
                      slotProps={
                        errors.endTime && touched.endTime
                          ? {
                            textField: {
                              error: !!touched.endTime && errors.endTime,
                              helperText: errors.endTime,
                            },
                          }
                          : null
                      }
                      label="End Time"
                      name="endTime"
                      minTime={
                        getMinMaxTime(
                          values.storeName,
                          values.endTime,
                          values.startTime,
                          values.start,
                          values.end,
                          true,
                          "endTime"
                        ).min
                      }
                      maxTime={
                        getMinMaxTime(
                          values.storeName,
                          values.endTime,
                          values.startTime,
                          values.start,
                          values.end,
                          false,
                          "endTime"
                        ).max
                      }
                      value={
                        values.endTime ? dayjs(values.endTime, "HH:mm") : null
                      }
                      onChange={(newValue) => {
                        setFieldValue(
                          "endTime",
                          dayjs(newValue).format("HH:mm")
                        );
                        setFieldTouched("endTime", true, false);
                        validateField("endTime");
                      }}
                      viewRenderers={{
                        hours: renderTimeViewClock,
                        minutes: renderTimeViewClock,
                        seconds: renderTimeViewClock,
                      }}
                      renderInput={(params) => (
                        <Field
                          as={TextField}
                          {...params}
                          fullWidth
                          error={
                            touched.eventDate && Boolean(errors.eventDate)
                          }
                          helperText={touched.eventDate && errors.eventDate}
                        />
                      )}
                    // disabled={!values.storeName || !values.start || !values.end}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </Grid>
              </>}
              <Grid item xs={12}>
                <Field
                  as={TextField}
                  name="promoDesc"
                  label="Promo Description"
                  fullWidth
                  error={touched.promoDesc && !!errors.promoDesc}
                  helperText={touched.promoDesc && errors.promoDesc}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Status</InputLabel>
                  <Select
                    as={Select}
                    disabled={!isAdmin || values.status === 'draft'}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="status"
                    label="Status"
                    IconComponent={() => (
                      <ArrowDropDownIcon style={{ marginRight: "18px" }} />
                    )}
                    sx={{ height: 40 }}
                    value={values?.status || []}
                    onChange={(e) => {
                      setFieldValue("status", e.target.value);
                      setFieldTouched(`status`, false, false);
                    }}
                    helperText={touched?.status && errors?.status}
                    error={touched?.status && Boolean(errors?.status)}
                  >
                      {values.status === "draft" && <MenuItem value="draft">Draft</MenuItem>}
                      {values.status === "pending" && (
                        <>
                          <MenuItem value="approve">Approve</MenuItem>
                          <MenuItem value="rejected">Rejected</MenuItem>
                        </>
                      )}
                      {values.status === "approved" && <MenuItem value="live">Live</MenuItem>} 
                  </Select>
                  {touched?.status && errors?.status && (
                    <Typography fontSize={12} color="error">
                      {errors?.status}
                    </Typography>
                  )}
                </FormControl>
              </Grid>

              <Grid container xs={12} sx={{ mt: 2 }} flexDirection={"row-reverse"} gap={3}>
                <MDButton
                  type="submit"
                  variant="contained"
                  circular={true}
                  color="black"
                >
                  Next
                </MDButton>
                <MDButton
                  color="black"
                  variant="outlined"
                  circular={true}
                  // style={{ backgroundColor: "#FFFFFF" }}
                  onClick={handleClose}
                >
                  Cancel
                </MDButton>
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

export default PromotionForm;
