import React, { useEffect, useState } from "react";
import {
  Grid,
  Box,
  Card,
  CardActions,
  CardContent,
  Button,
  Typography,
  Divider,
  Drawer,
  Icon,
  CardMedia,
  Pagination,
  FormControl,
  Select,
  MenuItem
} from "@mui/material";
import { ReactComponent as searchIcon } from "assets/images/searchIcon.svg";
import { ReactComponent as newsIcon } from "assets/images/newsIcon.svg";
import AddIcon from "@mui/icons-material/Add";

import DashboardLayout from "ui/LayoutContainers/DashboardLayout";
import DashboardNavbar from "ui/Navbars/DashboardNavbar";
import NewsFormComponent from "./NewsForm";
import { useQuery } from "@apollo/client";
import { GET_NEWS } from "services/news-service";
import { dashboardServiceClient } from "graphql/client";
import ImageCarousel from "layouts/cateogries/imageCarousel";
import Loader from "components/MDLoader";
import { SearchContext,FilterContext } from "context";
import { useContext } from 'react';
import MDButton from "components/MDButton";

const NewsCard = ({ item, onClick }) => (
  <Grid item xs={12} sm={6} md={3}>
    <Card sx={{ maxWidth: 345, overflowY: "hidden" }} onClick={onClick}>
      <CardMedia
        component={() => (
          <ImageCarousel
            images={
              item.coverpic && item.coverpic.length
                ? item.coverpic.map((item) => ({
                    label: item.path,
                    imgPath: item.publicurl,
                  }))
                : [
                    {
                      label: "fallback image",
                      imgPath:
                        "https://generation-sessions.s3.amazonaws.com/5a37fbcdffda25ac00cae4d0559b4567/img/frame-1171274784@2x.png",
                    },
                  ]
            }
          />
        )}
        sx={{ margin: 0, width: "100%" }}
        alt="news image"
      />
      <CardContent sx={{ paddingTop: "16px", paddingBottom: "0px" }}>
        <Typography
          gutterBottom
          variant="h7"
          sx={{
            fontSize: "13px",
            fontWeight: "bold",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          component="div"
        >
          {item.name}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            fontSize: "12px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          color="text.secondary"
        >
          {item.title}
        </Typography>
      </CardContent>
      <Divider />
      <CardActions sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography
          sx={{ fontSize: "14px", padding: "5px" }}
          color="text.secondary"
          size="small"
        >
          Orders
        </Typography>
        <Typography
          sx={{ fontSize: "14px", padding: "5px" }}
          color="text.secondary"
          size="small"
        >
          1,23,45,678
        </Typography>
      </CardActions>
    </Card>
  </Grid>
);

function News() {
  const [open, setOpen] = useState(false);
  const [newsData, setNewsData] = useState(null);
  const[newsList, setNewsList]=useState(null)
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20); // You can set this as you prefer
  const { searchKeyword,setSearchKeyword } = useContext(SearchContext);
  const {selectedBrands}=useContext(FilterContext)
  const totalPages = newsList ? Math.ceil(newsList.news.total / itemsPerPage) : 0;
  const skip = (currentPage - 1) * itemsPerPage;
  const { loading, error, data,refetch } = useQuery(GET_NEWS, {
    client: dashboardServiceClient,
    variables:{ listNewsFilter:{ take: itemsPerPage, skip: skip, search: searchKeyword,orderby:{isDeleted:"ASC"} }, }// Replace 'YOUR_SEARCH_TERM' with the desired search term or variable.
  });
  const handleItemsPerPageChange = (event) => {
    const newItemsPerPage = event.target.value;
    const newTotalPages = Math.ceil(newsList.news.total / newItemsPerPage);
    setItemsPerPage(newItemsPerPage);
    if (currentPage > newTotalPages) {
      setCurrentPage(1); // Reset to first page
    }
  };
  useEffect(()=>{
    if(data && JSON.stringify(data) !== JSON.stringify(newsList)){
      setNewsList(data)
    }
  },[data])
  useEffect(() => {
    setCurrentPage(1)
    }, [searchKeyword]);
    useEffect(() => {
      // Reset search keyword when entering the Brands tab
      setSearchKeyword("");
      setTimeout(() => {
        if (refetch) {
          refetch();
        }
      }, 100);
    }, []);
  if (loading)
    return (
      <>
        <Loader />
      </>
    );
  if (error) return <p>Error: {error.message}</p>;
  const handleClose=() => {
    setOpen(false);
    setNewsData(null);
    if (refetch) {
        refetch();
      }
  }
  const handleCardClick = (item) => {
    setNewsData(item);
    setOpen(true);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6}>
          <Typography sx={{fontFamily:"montserrat", color:"#000", fontWeight:"700"}} ml={1}>News</Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            {/* <Icon sx={{ mr: 2 }} component={searchIcon} fontSize="large" /> */}
            <MDButton
                type="button"
                variant="contained"
                color='black'
                circular={true} 
              startIcon={<AddIcon />}
              onClick={() => {
                setOpen(true);
                setNewsData(null);
              }}
            >
              New News
            </MDButton>
          </Box>
        </Grid>

        {data.news.results.map((item, index) => (
          <NewsCard
            key={item.id || index}
            item={item}
            onClick={() => handleCardClick(item)}
          />
        ))}

        <Drawer
          anchor="right"
          open={open}
          onClose={() => setOpen(false)}
          PaperProps={{ sx: { width: "50%" } }}
        >
          <Grid
            container
            sx={{ backgroundColor: "#F0F2F5", pb: 3, pt: 2, pl: 1.5 }}
          >
            <Grid item xs={12}>
              <br />
            </Grid>
            <Grid item xs={12}>
              <Icon
                sx={{ mr: 2, marginLeft: "10px" }}
                component={newsIcon}
                fontSize="large"
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5" sx={{ marginLeft: "10px" }}>
                News
              </Typography>
            </Grid>
          </Grid>
          <Box sx={{ width: "100%", minHeight: "70vh", padding: "20px" }}>
            <NewsFormComponent
              handleClose={handleClose}
              newsData={newsData}
            />
          </Box>
        </Drawer>
      </Grid>
      <Box sx={{ display: "flex", justifyContent: "end", alignItems: "center", margin: '20px 0'}}>
      <Grid item xs={12} m={1}>
      <Pagination 
       style={{
        display: 'flex',
        justifyContent: 'center', 
        transform: 'scale(1.2)', 
        margin: '20px 0' 
    }}
          count={totalPages}
          page={currentPage}
          onChange={(event, page) => setCurrentPage(page)}
          color="secondary"
        />
        </Grid>
        <Grid item xs={2} justifyContent="space-between" display="flex">
        <FormControl variant="outlined" size="small" sx={{ minWidth: 90,marginLeft: 2 }}>
        <Typography fontSize={12} fontWeight="bold">
            &nbsp; Page Size
          </Typography>
          <Select
            value={itemsPerPage}
            onChange={handleItemsPerPageChange}
            sx={{ height: "30px", fontSize: "0.875rem" }}
            inputProps={{ 'aria-label': 'Without label' }}
            minHeight="20px"
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
          </Select>
        </FormControl>
        </Grid>
        </Box>
    </DashboardLayout>
  );
}

export default News;
