import React, { useState } from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  MenuItem,
  Select,
  Grid,
} from "@mui/material";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { BulkProductStatus } from "../productTable/tableData";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useNotification } from "context";
import { useMutation } from "@apollo/client";
import { CHANGE_STATUS_BULK_PRODUCT_UPLOAD } from "services/product-service";
import { productServiceClient } from "graphql/client";
import MDButton from "components/MDButton";

// Validation schema using Yup
const validationSchema = Yup.object().shape({
  message: Yup.string().required("Message is required"),
  status: Yup.string()
    .required("Status is required")
    .oneOf(Object.values(BulkProductStatus), "Invalid status"),
});

export const ChangeStatusModalForm = ({
  open,
  onClose,
  initialValues,
  onSubmit,
}) => {
  const { status } = initialValues;

  const { setNotification } = useNotification();

  const [changeStatusBulkProductUpload] = useMutation(
    CHANGE_STATUS_BULK_PRODUCT_UPLOAD,
    { client: productServiceClient }
  );

  const [isSubmittingForm, setIsSubmittingForm] = useState(false);

  // Determine valid status changes based on current status
  const validStatusChanges = {
    [BulkProductStatus.PENDING]: [
      BulkProductStatus.REJECTED,
      BulkProductStatus.APPROVED,
    ],
  };

  // Filter out invalid options based on current status
  const statusOptions = Object.values(BulkProductStatus).filter((option) =>
    validStatusChanges[status]?.includes(option)
  );

  statusOptions.push(status);

  const changeStatus = async (values) => {
    const variables = {
      status: Object.keys(BulkProductStatus).find(
        (key) => BulkProductStatus[key] === values.status
      ),
      message: values.message,
      id: open.id,
    };

    const res = await changeStatusBulkProductUpload({
      variables: { updatedData: variables },
    });

    console.log({ res });

    return res;
  };

  const handleFormSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    setIsSubmittingForm(true);

    try {
      const res = await changeStatus(values);

      setNotification({
        color: "success",
        isVisible: true,
        message: "Status updated succesfully",
      });

      onClose();

      if (typeof onSubmit === "function") {
        onSubmit(res, null);
      }
    } catch (error) {
      const response = error.response;
      let errorMessage = error.message;

      if (response) {
        errorMessage = response.data.message;
      }

      setNotification({
        color: "error",
        isVisible: true,
        message: errorMessage,
      });

      if (typeof onSubmit === "function") {
        onSubmit(null, err);
      }
    } finally {
      setSubmitting(false);
      setIsSubmittingForm(false);
    }
  };

  return (
    <Modal open={open} onClose={onClose} component={"paper"}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          borderRadius: "12px",
          p: 4,
        }}
      >
        <Typography variant="h6" gutterBottom>
          Update Status
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleFormSubmit}
        >
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            errors,
            touched,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Box sx={{ mb: 2 }}>
                <Field
                  as={TextField}
                  fullWidth
                  id="message"
                  name="message"
                  label="Message"
                  variant="outlined"
                  value={values.message}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.message && Boolean(errors.message)}
                  helperText={touched.message && errors.message}
                />
              </Box>
              <Box sx={{ mb: 2 }}>
                <Field
                  as={Select}
                  fullWidth
                  id="status"
                  name="status"
                  select
                  label="Status"
                  variant="outlined"
                  value={values.status}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.status && Boolean(errors.status)}
                  helperText={touched.status && errors.status}
                  sx={{
                    height: "40px",
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#7b809a !important",
                    },
                  }}
                  IconComponent={() => (
                    <ArrowDropDownIcon style={{ marginRight: "18px" }} />
                  )}
                >
                  {statusOptions.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option?.toUpperCase()}
                    </MenuItem>
                  ))}
                </Field>
              </Box>
              <Grid
                item
                xs={12}
                sx={{
                  pr: 2,
                  pb: 2,
                  justifyContent: "flex-end",
                  display: "flex",
                }}
                gap={2}
              >
                <MDButton
                  variant="outlined"
                  // style={{
                  //   backgroundColor: "#FFFFFF",
                  //   marginRight: "10px",
                  //   border: "1px solid #E93165",
                  //   borderRadius: "24px",
                  //   color: "#E93165",
                  // }}
                  color={"black"}
                  circular={true}
                  onClick={onClose}
                >
                  Cancel
                </MDButton>
                <MDButton
                  type="submit"
                  variant="contained"
                  // style={{
                  //   background: "#E93165",
                  //   borderRadius: "24px",
                  //   color: "#fff",
                  // }}
                  color={"black"}
                  circular={true}
                  disabled={isSubmittingForm}
                >
                  {isSubmittingForm ? "Loading..." : "Save"}
                </MDButton>
              </Grid>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default ChangeStatusModalForm;
