import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import Chip from "@mui/material/Chip";
import { Grid, Pagination as MuiPagination, Box ,TablePagination} from '@mui/material';
import dayjs from 'dayjs';
import { useQuery } from "@apollo/client";
import { GET_CHANNELS } from "services/channel-service";
import { dashboardServiceClient } from "graphql/client";
import Loader from "components/MDLoader";
import { SearchContext,FilterContext } from "context";
import { useContext ,useEffect} from 'react';

const columns = [
  {
    field: "name",
    headerName: "Channel Name",
    width: 250,
    renderCell: (params) => {
      const channelType = params.row.channelType;
      let name = params.row.name;

      if (channelType === "offline" && params?.row?.channelStore?.length && params?.row?.channelStore[0].store) {
        name = params?.row?.channelStore[0].store?.name;
      }

      return <span>{name || 'N/A'}</span>; // 'N/A' for null/undefined cases
    },
  },
  { field: "channelType",
   headerName: "Channel Type",
   width: 250 ,
    renderCell: (params) => {
      const channelType = params.row.channelType;
      const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
      };
      const capitalizedChannelType = channelType ? capitalizeFirstLetter(channelType) : 'N/A';

      return <span>{capitalizedChannelType}</span>;
    },
},
  {
    field: "createdAt",
    headerName: "Created At",
    width: 230,
    valueGetter: (params) =>
      params.row.createdAt
        ? dayjs(params.row.createdAt).format("DD-MM-YYYY HH:mm a")
        : "N/A",
  },
  {
    field: "updatedAt",
    headerName: "Updated At",
    width: 230,
    valueGetter: (params) =>
      params.row.updatedAt
        ? dayjs(params.row.updatedAt).format("DD-MM-YYYY HH:mm a")
        : "N/A",
  },
];

function ChannelLandingPage({ onRowClick }) {
  const { searchKeyword,setSearchKeyword } = useContext(SearchContext);
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 20,
  });

  const { loading, error, data, refetch } = useQuery(GET_CHANNELS, {
    client: dashboardServiceClient,
    variables: {
      take: paginationModel.pageSize,
      skip: paginationModel.page * paginationModel.pageSize,
      search: searchKeyword
    },
    fetchPolicy: 'network-only', // Ignore cache for this query
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setRowCount(data?.channels?.total); 
    },
  });
  const [rowCount, setRowCount] = React.useState(data?.channels?.total || 0);

  React.useEffect(() => {
    setRowCount((prevRowCount) =>
      data?.channels?.total !== undefined
        ? data?.channels?.total
        : prevRowCount,
    );
  }, [data?.channels?.total, setRowCount]);
  if (loading)
    return (
      <>
        <Loader />
      </>
    );
  if (error) return <p>Error: {error.message}</p>;

  const channels = data?.channels?.results;
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  
  return (
        <DataGrid
         autoHeight
            rows={channels}
            columns={columns}
            rowCount={rowCount}
            pageSizeOptions={[10, 20, 50, 100]}
            getRowId={(row) => row.id}
            onRowClick={onRowClick}
            loading={loading}
            onPageChange={handleChangePage}
            paginationModel={paginationModel}
            paginationMode="server"
            onPaginationModelChange={setPaginationModel}
        />
  );
}

export default ChannelLandingPage;
