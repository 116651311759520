import { gql } from "@apollo/client";

export const GET_BRANDS_LIST_TWO = gql `
  query brands {
    brands {
      id
      name
      description
      logo
      coverPic
      thumbnail
      usp
      brandCategory {
        id
        brand {
          id
        }
        category {
          id
        }
        isDeleted
      }
      brandMetaData {
        id
        key
        value
        isDeleted
      }
      companyDetails {
        id
        name
      }
      bankDetails {
        id
        name
      }
      brandStatus
      brandStore {
        id
        isDeleted
        end
        start
      }
      products {
        id
        metaTitle
        title
        slug
        isDeleted
      }
      username
    }
  }
`;

export const GET_BRAND_DETAILS = gql `
  query getBrand($username: String!) {
    brand(username: $username) {
      id
      username
      name
      earn
      burn
      hexColour
      description
      usp
      logo
      coverPic
      thumbnail
      isDeleted
      createdAt
      updatedAt
      brandStatus
      brandTags
      brandRating
      isBrandVisible
      brandMetaData {
        id
        key
        value
      }
      brandCategory {
        id
        isDeleted
        earn
        burn
        category {
          id
          title
          isDeleted
          isNodeItem
        }
      }
      brandStore {
        id
        start
        end
        images
        videos
        isDeleted
        store {
          name
          id
        }
      }
    }
  }
`;

export const UPDATE_BRAND_ABOUT = gql `
  mutation updateBrand($updateBrandInput: UpdateBrandInput!) {
    updateBrand(updateBrandInput: $updateBrandInput) {
      id
      username
      name
      description
      usp
      logo
      coverPic
      thumbnail
      isDeleted
      createdAt
      updatedAt
      brandStatus
      brandMetaData {
        id
        key
        value
      }
      brandCategory {
        id
        earn
        burn
        isDeleted
        category {
          id
          title
          isDeleted
          isNodeItem
        }
      }
      brandStore {
        id
        start
        end
        isDeleted
        store {
          name
          id
        }
      }
    }
  }
`;

export const CREATE_BRAND_METADATA = gql `
  mutation createBrandMetaData($metaDataInput: CreateBrandMetadataInput!) {
    createBrandMetadata(createBrandMetadataInput: $metaDataInput) {
      id
      key
      value
    }
  }
`;

export const UPDATE_BRAND_METADATA = gql `
  mutation updateBrandMetaData($metaDataInput: UpdateBrandMetadataInput!) {
    updateBrandMetadata(updateBrandMetadataInput: $metaDataInput) {
      id
      key
      value
    }
  }
`;

export const CREATE_BRAND_STORE = gql `
  mutation createBrandStore($createBrandStoreInput: CreateBrandStoreInput!) {
    createBrandStore(createBrandStoreInput: $createBrandStoreInput) {
      id
      isDeleted
      start
      end
      images
      videos
      createdAt
      updatedAt
      store {
        name
        id
      }
      brand {
        name
        id
      }
    }
  }
`;

export const UPDATE_BRAND_STORE = gql `
  mutation updateBrandStore($updateBrandStoreInput: UpdateBrandStoreInput!) {
    updateBrandStore(updateBrandStoreInput: $updateBrandStoreInput) {
      id
      isDeleted
      start
      end
      images
      videos
      createdAt
      updatedAt
    }
  }
`;

export const MAP_BRAND_STORE = gql `
query BrandStores ($listBrandStoreFilter: ListBrandStoreFilter!) {
  brandStores(listBrandStoreFilter: $listBrandStoreFilter) {
      total
      results {
          id
          isDeleted
          start
          end
          status
          store{
            id
            name
            status
          }
          brand{
            id
            name
            isDeleted
          }
      }
  }
}
`;

export const REMOVE_BRAND_STORE = gql `
mutation RemoveBrandStore ($id:String!){
  removeBrandStore(id: $id) {
      id
  }
}
`
export const LIST_BRAND_STORE = gql `
query BrandStores($listBrandStoreFilter: ListBrandStoreFilter!) {
  brandStores(listBrandStoreFilter: $listBrandStoreFilter) {
      total
      results {
          id
          status
          start
          end
          store {
              status
              id
              name
              storeLocation {
                id
                code
                locationName
                division {
                  id
                  title
                  metaTitle
                  image
                  displayOrder
                  isDeleted
                  isNodeItem
                  isMenuItem
                  createdAt
                  updatedAt
                }
                isDeleted
                subLocations {
                    id
                    name
                    assetTypes {
                        id
                        name
                        batchCode
                        size
                        code
                    }
                }
              }
          }
          brand{
              name
          }
      }
  }
}
`
export const LIST_BRAND_STORE_FOR_NODE_AGREEMENT = gql `
query BrandStores($listBrandStoreFilter: ListBrandStoreFilter!) {
  brandStores(listBrandStoreFilter: $listBrandStoreFilter) {
      total
      results {
          id
          status
          start
          end
          store {
              status
              id
              name
          }
          brand{
              name
          }
      }
  }
}
`
export const LIST_BRAND_PRODUCT = gql `
  query listProduct($listProductFilter: ListProductFilter!) {
    products(listProductFilter: $listProductFilter) {
      total
      results {
        id
        title
      }
    }
  }
`;

export const BRANDS_LIST = gql `
  query GetBrandList(    $take: Int!
    $skip: Int!
    $search: String
    $filter: JSONObject!
    $orderby: JSONObject) {
    brandlist(listBrandFilter: {
      take: $take
      skip: $skip
      search: $search
      filter: $filter
      orderby: $orderby
    }) {
      total
      results {
        id
        name
        brandStatus
        username
        brandMetaData {
          id
          key
          value
          isDeleted
        }
      }
    }
  }
`;

export const GET_BRANDS_LIST = gql `
  query GetBrandList(
    $take: Int!
    $skip: Int!
    $search: String
    $filter: JSONObject!
    $orderby: JSONObject
  ) {
    brandlist(
      listBrandFilter: {
        take: $take
        skip: $skip
        search: $search
        filter: $filter
        orderby: $orderby
      }
    ) {
      total
      results {
        id
        name
        description
        logo
        coverPic
        thumbnail
        usp
        createdAt
        updatedAt
        vendorId
        customerId
        isDeleted
        isBrandVisible
        brandRating
        hexColour
        brandTags
        brandCategory {
          id

          category {
            id
          }
          isDeleted
        }
        brandMetaData {
          id
          key
          value
          isDeleted
        }
        brandStatus
        brandStore {
          id
          isDeleted
          end
          start
        }
        products {
          id
          metaTitle
          title
          slug
          isDeleted
        }
        username
      }
    }
  }
`;

export const CREATE_BRAND_CATEGORY_STORE = gql `
  mutation createBrandCategoryStore(
    $createBrandCategoryStoreInput: CreateBrandCategoryStoreInput!
  ) {
    createBrandCategoryStore(
      createBrandCategoryStoreInput: $createBrandCategoryStoreInput
    ) {
      id
      brand {
        id
        name
      }
      store {
        id
        name
      }
      category {
        id
        title
        isNodeItem
      }
      markup
      markdown
      start
      end
    }
  }
`;

export const UPDATE_BRAND_CATEGORY_STORE = gql `
  mutation updateBrandCategoryStore(
    $updateBrandCategoryStoreInput: UpdateBrandCategoryStoreInput!
  ) {
    updateBrandCategoryStore(
      updateBrandCategoryStoreInput: $updateBrandCategoryStoreInput
    ) {
      id
      brand {
        id
        name
      }
      store {
        id
        name
      }
      category {
        id
        title
      }
      markup
      markdown
      start
      end
    }
  }
`;

export const GET_BRAND_CATEGORY_STORE = gql `
  query getBrandCategoryStore($take: Int, $skip: Int, $filter: JSONObject) {
    brandCategoryStores(
      listBrandCategoryStoreFilter: {
        take: $take
        skip: $skip
        filter: $filter
        orderby: { updatedAt: "ASC" }
      }
    ) {
      total
      results {
        id
        start
        images
        end
        markup
        markdown
        isDeleted
        category {
          id
          title
          isNodeItem
          categoryProductAttribute {
            fieldName
            fieldType
            attributeName
            attributeType
            isMandatory
            values
            masterAttribute{
              description
            }
          }
        }
        brandStore{
          id
          start
          end
          store{
            id 
            name
          }
        }
        brand {
          id
          name
        }
        store {
          id
          name
        }
      }
    }
  }
`;

export const REMOVE_BRAND_CATEGORY_STORE = gql `
  mutation removeBrandCategoryStore($id: String!) {
    removeBrandCategoryStore(id: $id) {
      id
    }
  }
`;

export const CREATE_BRAND = gql `
  mutation createBrand($input: CreateBrandInput!) {
    createBrand(createBrandInput: $input) {
      id
      username
    }
  }
`;
export const UPDATE_BRAND = gql `
  mutation updateBrand($input: UpdateBrandInput!) {
    updateBrand(updateBrandInput: $input) {
      id
      username
    }
  }
`;

export const GET_BANK_DETAILS = gql `
  query BankDetails {
    bankDetails(id: "<id>") {
      id
      bankName
      BankAccountNo
      bankCode
      bankAddress
      branchName
      city
      state
      bankAccountType
      micrNo
      cancelledCheque
      isDeleted
      createdAt
      updatedAt
    }
  }
`;

export const CREATE_BANK_DETAILS = gql `
  mutation CreateBankDetails($input: CreateBankDetailsInput!) {
    createBankDetails(createBankDetailsInput: $input) {
      bankName
      BankAccountNo
      bankCode
      bankAddress
      branchName
      city
      state
      bankAccountType
      micrNo
      cancelledCheque
    }
  }
`;

export const UPDATE_BANK_DETAILS = gql `
  mutation UpdateBankDetails($input: UpdateBankDetailsInput!) {
    updateBankDetails(updateBankDetailsInput: $input) {
      bankName
      BankAccountNo
      bankCode
      bankAddress
      branchName
      city
      state
      bankAccountType
      micrNo
      cancelledCheque
    }
  }
`;

export const REMOVE_BANK_DETAILS = gql `
  mutation removeBankDetails {
    removeBankDetails(id: "fc543c4a-8e9c-4c23-bd1f-10221824f33a") {
      id
      bankName
      BankAccountNo
      bankCode
      bankAddress
      branchName
      city
      state
      bankAccountType
      micrNo
      cancelledCheque
      isDeleted
      createdAt
      updatedAt
    }
  }
`;

export const CREATE_COMPANY_DETAILS = gql `
  mutation CreateCompanyDetails($input: CreateCompanyDetailsInput!) {
    createCompanyDetails(createCompanyDetailsInput: $input) {
      id
      companyName
      msmeCertificateApplicable
      msmeCertificate
      registeredAddress
      addressCity
      addressPinCode
      addressProofType
      addressProof
      serviceProvided
      typeofCompany
      panCardNumber
      panCardProof
      gstNumber
      gstProof
      tinNumber
      tinNumberProof
      tdsDeductible
      tdsNumber
      isDeleted
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_COMPANY_DETAILS = gql `
  mutation UpdateCompanyDetails($input: UpdateCompanyDetailsInput!) {
    updateCompanyDetails(updateCompanyDetailsInput: $input) {
      id
      companyName
      msmeCertificateApplicable
      msmeCertificate
      registeredAddress
      addressCity
      addressPinCode
      addressProofType
      addressProof
      serviceProvided
      typeofCompany
      panCardNumber
      panCardProof
      gstNumber
      gstProof
      tinNumber
      tinNumberProof
      tdsDeductible
      tdsNumber
      isDeleted
      createdAt
      updatedAt
    }
  }
`;

export const CREATE_CONTACT_DETAILS = gql `
  mutation createBrandMetaData($metaDataInput: CreateBrandMetadataInput!) {
    createBrandMetadata(createBrandMetadataInput: $metaDataInput) {
      id
      key
      value
    }
  }
`;

export const UPDATE_CONTACT_DETAILS = gql `
  mutation updateBrandMetaData($metaDataInput: UpdateBrandMetadataInput!) {
    updateBrandMetadata(updateBrandMetadataInput: $metaDataInput) {
      id
      key
      value
    }
  }
`;

// export const CREATE_COMPANY_DETAILS = gql`
// mutation CreateCompanyDetails {
//   createCompanyDetails(
//       createCompanyDetailsInput: {
//           companyName: "company 1"
//           msmeCertificateApplicable: true
//           msmeCertificatePath: "test"
//           registeredAddress: "testtesttesttesttesttest"
//           addressCity: "citytest"
//           addressPinCode: "123"
//           addressProofType: "Aadhaar Card"
//           addressProofPath: "test"
//           serviceProvided: "test"
//           typeofCompany: "test"
//           panCardNumber: "12323"
//           panCardProofPath: "test"
//           gstNumber: "123123"
//           gstProofPath: "test"
//           tinNumber: "12312313"
//           tinNumberProofPath: "test"
//           tdsDeductible: true
//           tdsNumber: "1231231"
//       }
//   ) {
//       id
//       companyName
//       msmeCertificateApplicable
//       msmeCertificate
//       registeredAddress
//       addressCity
//       addressPinCode
//       addressProofType
//       addressProof
//       serviceProvided
//       typeofCompany
//       panCardNumber
//       panCardProof
//       gstNumber
//       gstProof
//       tinNumber
//       tinNumberProof
//       tdsDeductible
//       tdsNumber
//       isDeleted
//       createdAt
//       updatedAt
//   }
// }`

// export const UPDATE_COMPANY_DETAILS = gql`
// mutation UpdateCompanyDetails {
//   updateCompanyDetails(
//       updateCompanyDetailsInput: {
//           companyName: "company 111"
//           msmeCertificateApplicable: true
//           msmeCertificatePath: "test"
//           registeredAddress: "testtesttesttesttesttest"
//           addressCity: "citytest"
//           addressPinCode: "123"
//           addressProofType: "Aadhaar Card"
//           addressProofPath: "test"
//           serviceProvided: "test"
//           typeofCompany: "test"
//           panCardNumber: "12323"
//           panCardProofPath: "test"
//           gstNumber: "123123"
//           gstProofPath: "test"
//           tinNumber: "12312313"
//           tinNumberProofPath: "test"
//           tdsDeductible: true
//           tdsNumber: "1231231"
//           id: "cb93156a-47c1-465b-b56d-9ec200c13a1e"
//       }
//   ) {
//       id
//       companyName
//       msmeCertificateApplicable
//       msmeCertificate
//       registeredAddress
//       addressCity
//       addressPinCode
//       addressProofType
//       addressProof
//       serviceProvided
//       typeofCompany
//       panCardNumber
//       panCardProof
//       gstNumber
//       gstProof
//       tinNumber
//       tinNumberProof
//       tdsDeductible
//       tdsNumber
//       isDeleted
//       createdAt
//       updatedAt
//   }
// }`

// export const MAP_WITH_BRAND_DETAILS = gql`
// mutation CreateBrand {
//   createBrand(
//       createBrandInput: {
//           username: "newBrand"
//           password: "newBrand"
//           name: "newBrand"
//           coverPicPath: null
//           companyDetailsId: "722d75ad-a8b5-4576-9d8f-276621e48311"
//       }
//   ) {
//       id
//       name
//       username
//       companyDetails {
//           companyName
//           id
//       }
//   }
// }

// {
//   "data": {
//       "createBrand": {
//           "id": "bc2ee05f-38c8-4a00-9eca-15c42b4b3b0f",
//           "name": "newBrand",
//           "username": "newBrand",
//           "companyDetails": {
//               "companyName": "company 2",
//               "id": "722d75ad-a8b5-4576-9d8f-276621e48311"
//           }
//       }
//   }
// }`

// export const CREATE_CONTACT_DETAILS = gql`
//   mutation CreateContactDetails($metaDataInput: MetaDataInput!) {
//     createContactDetails(metaDataInput: $metaDataInput) {
//       success
//       message
//       data {
//         id
//         key
//         value
//       }
//     }
//   }`

// export const UPDATE_CONTACT_DETAILS = gql`
//   mutation UpdateContactDetails($metaDataInput: MetaDataInput!) {
//     updateContactDetails(metaDataInput: $metaDataInput) {
//       success
//       message
//       data {
//         id
//         key
//         value
//       }
//     }
//   }`

// export const DELETE_CONTACT_DETAILS = gql`
// mutation {
//   removeBrandMetadata(id: "78ec5822-5bc3-4416-b244-e659bd92466f"){
//     id
//   }
// }`

// export const UPDATE_COMPANY_DETAILS = gql`
// mutation UpdateCompanyDetails($input: UpdateCompanyDetailsInput!){
//   updateCompanyDetails(updateCompanyDetailsInput: $input){
//       id
//       companyName
//       msmeCertificateApplicable
//       msmeCertificate
//       registeredAddress
//       addressCity
//       addressPinCode
//       addressProofType
//       addressProof
//       serviceProvided
//       typeofCompany
//       panCardNumber
//       panCardProof
//       gstNumber
//       gstProof
//       tinNumber
//       tinNumberProof
//       tdsDeductible
//       tdsNumber
//       isDeleted
//       createdAt
//       updatedAt
//   }
// }`

export const GET_CATEGORY_PRODUCT_LIST = gql `
  query getAllCategoryProduct(
    $listCategoryProductFilter: ListCategoryProductFilter!
  ) {
    categoryProduct(listCategoryProductFilter: $listCategoryProductFilter) {
      total
      results {
        id
        title
        metaTitle
        image
        displayOrder
        isDeleted
        isMenuItem
        isNodeItem
        createdAt
        updatedAt
        parent {
          isDeleted
          id
          title
          metaTitle
          parent {
            id
            title
            isDeleted
            parent {
              id
              title
              isDeleted
              parent {
                id
                title
                isDeleted
                parent {
                  id
                  title
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_ASSET_MAPPING_LIST = gql `
query findAllMapDeviceFixtureBrands(
  $page: Int! = 1,
  $limit: Int! = 10,
  $sortBy: String! = "updatedAt",
  $order: String! = "ASC",
  $searchCols: [String!] = [],
  $searchBy: [String!] = [],
  $relations: [String!] = [],
  $filter:JSONObject! = {},
) {
  findAllMapDeviceFixtureBrands(
    page: $page,
    limit: $limit,
    sortBy: $sortBy,
    order: $order,
    searchCols: $searchCols, 
    searchBy: $searchBy
    relations: $relations,
    filter: $filter
  ) {
    results {
      uuid
      code
      createdAt
      updatedAt
      createdBy
      updatedBy
      isActive
      isDeleted
      brandId
      end
      start
      brandStore
      devices {
          uuid
          code
          isActive
          isDeleted
          deviceName
          deviceType
          brandName
          status
          isShared
          supportContact
          storeId
          locationId
          subLocationId
          subLocationTypeId
          store
      }
      fixtures {
          uuid
          code
          createdAt
          updatedAt
          createdBy
          updatedBy
          isActive
          isDeleted
          isShared
          fixtureType
          fixtureSize
          storeId
          locationId
          subLocationId
          subLocationTypeId
          store
          devices{
            deviceName
            uuid
          }
      }
    }
    total
  }
}  
`
export const GET_ASSET_MAPPING_LIST_NEW = gql `
query findAllMapDeviceFixtureBrands(
  $page: Int! = 1,
  $limit: Int! = 10,
  $sortBy: String! = "updatedAt",
  $order: String! = "ASC",
  $searchCols: [String!] = [],
  $searchBy: [String!] = [],
  $relations: [String!] = [],
  $filter:JSONObject! = {},
) {
  findAllMapDeviceFixtureBrands(
    page: $page,
    limit: $limit,
    sortBy: $sortBy,
    order: $order,
    searchCols: $searchCols, 
    searchBy: $searchBy
    relations: $relations,
    filter: $filter
  ) {
    results {
      uuid
      code
      createdAt
      updatedAt
      createdBy
      updatedBy
      isActive
      isDeleted
      brandId
      end
      start
      fixtures {
          uuid
          code
          createdAt
          updatedAt
          createdBy
          updatedBy
          isActive
          isDeleted
          isShared
          fixtureType
          fixtureSize
          storeId
          locationId
          subLocationId
          subLocationTypeId
          store
          devices {
            uuid
            code
            isActive
            isDeleted
            deviceName
            deviceType
            brandName
            status
            isShared
            supportContact
            storeId
            locationId
            subLocationId
            subLocationTypeId
            store
            screenResolution
          }
      }
    }
    total
  }
}  
`
export const GET_STORE_DETAILS = gql `
query findAllStores($input: ListStoreFilter!) {
  stores(listStoreFilter: $input) {
      total
      results {
          id
          name
          status
          storeLocation {
             isDeleted
              id
              code
              locationName
              division
              subLocations {
                  id
                  name
                  assetTypes {
                      id
                      name
                      batchCode
                      size
                      code
                  }
              }
          }
      }
  }
}
`
export const GET_DEVICES_FOR_MAPPING = gql `
query findAllDevices(
  $page: Int! = 1,
  $limit: Int! = 10,
  $sortBy: String! = "id",
  $order: String! = "ASC",
  $searchCols: [String!] = [],
  $searchBy: [String!] = [],
  $relations: [String!] = [],
  $filter:JSONObject={}
) {
  findAllDevices(
    page: $page,
    limit: $limit,
    sortBy: $sortBy,
    order: $order,
    searchCols: $searchCols, 
    searchBy: $searchBy
    relations: $relations,
    filter:$filter
  ) {
    results {
      uuid
      code
      isActive
      deviceName
      deviceType
      status
      isShared
      storeId
      locationId
      subLocationId
      subLocationTypeId
    }
    total
  }
}  
`
export const GET_FIXTURES_FOR_MAPPING = gql `
query findAllFixtures(
  $page: Int! = 1,
  $limit: Int! = 10,
  $sortBy: String! = "id",
  $order: String! = "ASC",
  $searchCols: [String!] = [],
  $searchBy: [String!] = [],
  $relations: [String!] = [],
  $filter:JSONObject={}
) {
  findAllFixtures(
    page: $page,
    limit: $limit,
    sortBy: $sortBy,
    order: $order,
    searchCols: $searchCols, 
    searchBy: $searchBy
    relations: $relations
    filter:$filter
  ) {
    results {
      uuid
      code
      isActive
      createdAt
      updatedAt
      createdBy
      updatedBy
      storeId
      locationId
      subLocationId
      subLocationTypeId
      devices{
        deviceName
        uuid
      }
    }
    total
  }
}  

`

export const CREATE_ASSET_MAPPING = gql `
mutation mapFixtureDeviceBrand($input: CreateMapDeviceFixtureBrandInput!) {
  createMapDeviceFixtureBrand(input: $input) {
      uuid
      code
      createdAt
      updatedAt
      createdBy
      updatedBy
      isActive
      isDeleted
      brandId
      end
      start
      devices {
          uuid
          code
          isActive
          isDeleted
          deviceName
          deviceType
          brandName
          status
          isShared
          storeId
          locationId
          subLocationId
          subLocationTypeId
      }
      fixtures {
          uuid
          code
          createdAt
          updatedAt
          createdBy
          updatedBy
          isActive
          isDeleted
          fixtureType
          fixtureSize
          storeId
          locationId
          subLocationId
          subLocationTypeId
      }
  }
}
`

export const UPDATE_ASSET_MAPPING = gql `
mutation updateMapFixtureDeviceBrand($input: UpdateMapDeviceFixtureBrandInput!) {
  updateMapDeviceFixtureBrand(input: $input) {
      uuid
      code
      createdAt
      updatedAt
      createdBy
      updatedBy
      isActive
      isDeleted
      brandId
      end
      start
      devices {
          uuid
          code
          isActive
          isDeleted
          deviceName
          deviceType
          brandName
          status
          isShared
          storeId
          locationId
          subLocationId
          subLocationTypeId
      }
      fixtures {
          uuid
          code
          createdAt
          updatedAt
          createdBy
          updatedBy
          isActive
          isDeleted
          fixtureType
          fixtureSize
          storeId
          locationId
          subLocationId
          subLocationTypeId
      }
  }
}
`

export const REMOVE_BRAND_DEVICE_FIXTURE_MAPPING = gql `
  mutation removeMapDeviceFixtureBrand($id: String!) {
    removeMapDeviceFixtureBrand(id: $id)
  }
`;