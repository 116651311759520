import React, { useEffect, useState } from 'react';
import { Typography, Grid, Card, CardActionArea, CardContent, CardActions, IconButton, Icon } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as BrandTag } from "assets/images/BrandTag.svg";
import { ReactComponent as ProductTag } from "assets/images/ProductTag.svg";
import { ReactComponent as PromotionTag } from "assets/images/PromotionTag.svg";
import { ReactComponent as EventsTag } from "assets/images/EventsTag.svg";
import { ReactComponent as StoreDigitalContentTag } from "assets/images/StoreDigitalContentTag.svg";
import { ReactComponent as Arrow } from "assets/images/Arrow.svg";
import Analytics from '@mui/icons-material/Analytics';
import { routes } from 'routes';

const DashboardCard = ({ title, subtitle, icon, navigateTo }) => {
  const [isHovered, setIsHovered] = useState(false);
  const navigate = useNavigate();

  const handleClick = () => {
    if (navigateTo) {
      navigate(navigateTo);
    }
  };
  return (
    <Card
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      elevation={isHovered ? 10 : 1}
      style={{ maxWidth: 345, minWidth: 240, minHeight: 240, margin: '1rem', position: 'relative', display: 'flex', borderRadius: 8 }}
    >
      <CardActionArea sx={{ minHeight: 240 }} onClick={handleClick}>
        <CardContent sx={{ minHeight: 240, p: 3 }}>
          {/* <Icon color="black" style={{ width: 49, height: 49, marginTop: 30 }} /> */}
          <Icon fontSize={"large"} color="black" style={{ width: 49, height: 49, marginTop: 30 }} >{icon}</Icon>
          <Typography fontFamily={'Inter'} sx={{ mt: 2 }} gutterBottom variant="h5" component="h2">
            {title}
          </Typography>
          {!isHovered ? (
            <Typography fontFamily={'Inter'} fontSize={14} variant="body2" color="textSecondary" component="p">
              {subtitle}
            </Typography>
          ) : (
            <CardActions style={{ position: 'absolute', right: 90 }}>
              <IconButton size="small" color="primary">
                <Arrow />
              </IconButton>
            </CardActions>
          )}
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
const Home = () => {
  const [user, setUser] = useState({});
  useEffect(() => {
    setUser(localStorage.getItem("userDetails") &&
      localStorage.getItem("userDetails") !== "undefined"
      ? JSON.parse(localStorage.getItem("userDetails"))
      : {})
  }, []);
  return (
    <div style={{ padding: '2rem', textAlign: 'center' }}>
      <Grid container justifyContent="center" spacing={4} sx={{ padding: 5 }}>
        <Grid item xs={12}>
          <Typography variant="h3" component="h1" gutterBottom>
            {`Welcome , ${user && user?.name ? user?.name : user?.username?.split('@')[0]} !`}

          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={4} justifyContent="center">
        {routes && routes.map((d) => {
          console.log(d.icon.props.children);
          return (
            <Grid item>
              <DashboardCard title={d.name} subtitle={`View ${d.name} Details`} icon={d.icon.props.children} navigateTo={d.route} />
            </Grid>
          )
        })}
        {/* <Grid container justifyContent="center">
          <Grid item>
            <DashboardCard title="Brand" subtitle="Edit Brand Details" Icon={BrandTag} navigateTo="/dashboard" />
          </Grid>
          <Grid item>
            <DashboardCard title="Products" subtitle="Edit Product Details" Icon={ProductTag} navigateTo="/product" />
          </Grid>
        </Grid> */}
        {/* <Grid container justifyContent="center">
          <Grid item>
            <DashboardCard title="Events" subtitle="Manage Events" Icon={EventsTag} navigateTo="/events" />
          </Grid>
          <Grid item>
            <DashboardCard title="Store Digital Content" subtitle="Edit Store Digital Content Details" Icon={StoreDigitalContentTag} navigateTo="/advertisment" />
          </Grid>
          <Grid item>
            <DashboardCard title="Reports" subtitle="Report Details" Icon={Analytics} navigateTo="/reports" />
          </Grid>
        </Grid> */}
      </Grid>
    </div>
  );
};

export default Home;
