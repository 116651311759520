import React from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  Grid,
  TextField,
  FormControlLabel,
  Switch,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { dashboardServiceClient } from "graphql/client";
import { useMutation, useQuery } from "@apollo/client";
import {
    GET_MASTER_ACTIVITY_LIST,
    CREATE_MASTER_ACTIVITY,
    UPDATE_MASTER_ACTIVITY,
} from "services/master-activity-service";
import { useNotification } from "context";
import { ReactComponent as ArrowDropDownIcon } from "assets/images/Arrow-Down.svg";
import Loader from "components/MDLoader";
import MDButton from "components/MDButton";

const validationSchema = Yup.object().shape({
    masterActivityId : Yup.string(),
    masterActivityName: Yup.string()
    .required("Name is required")
    .max(50, "Maximum 50 characters allowed"),
});

const useMasterActivityMutations = (masterActivityData, handleClose, setNotification) => {
  const mutationOptions = {
    client: dashboardServiceClient,
    refetchQueries: [{ query: GET_MASTER_ACTIVITY_LIST, variables:  {take: 20, skip: 0,search:""}  },],
  };
  const [createMasterActivityMutation] = useMutation(
    CREATE_MASTER_ACTIVITY,
    mutationOptions
  );

  const [updateMasterActivityMutation] = useMutation(
    UPDATE_MASTER_ACTIVITY,
    mutationOptions
  );
  const handleSubmit = (values) => {
    const payload = {
      name: values.masterActivityName,
      isDeleted: !values.isActive,
    };
    const mutation =
      masterActivityData && masterActivityData?.id ? updateMasterActivityMutation : createMasterActivityMutation;
    const mutationInput =
      masterActivityData && masterActivityData?.id
        ? { updateMasterActivityInput: { id: masterActivityData.id, ...payload } }
        : { createMasterActivityInput: payload };

    mutation({ variables: mutationInput })
      .then((res) => {
        console.log(
          res.data[
            masterActivityData && masterActivityData.id
              ? "updated the Master Activity"
              : "craeted the Master Activity"
          ]
        );
        handleClose();
        setNotification({
          color: "success",
          isVisible: true,
          message:
            masterActivityData && masterActivityData.id
              ? "Master Activity  updated successfully"
              : "Master Activity  created successfully",
        });
      })
      .catch((error) => {
        console.error("error:", error.message);
        setNotification({
          color: "error",
          isVisible: true,
          message: error.message || "Something went wrong",
        });
      });
  };
  return handleSubmit;
};
const MasterActivityForm = ({ handleClose, masterActivityData }) => {

  const { setNotification } = useNotification();
  const handleSubmit = useMasterActivityMutations(
    masterActivityData,
    handleClose,
    setNotification
  );
  
  return (
    <Formik
      initialValues={{
        masterActivityId: masterActivityData?.id || "",
        masterActivityName: masterActivityData?.name || "",
        isActive: masterActivityData && !masterActivityData?.isDeleted || false,
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        handleSubmit(values);
      }}
    >
      {(formik) => (
        <Form>
          <Grid container spacing={3} p={2}>
          <Grid item xs={12}>
              <Field
                as={TextField}
                label="Master ID"
                disabled
                fullWidth
                name="masterActivityId"
                error={
                  formik.touched.masterActivityId && Boolean(formik.errors.masterActivityId)
                }
                helperText={formik.touched.masterActivityId && formik.errors.masterActivityId}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                as={TextField}
                label="Name Of The Activity"
                fullWidth
                name="masterActivityName"
                error={
                  formik.touched.masterActivityName && Boolean(formik.errors.masterActivityName)
                }
                helperText={formik.touched.masterActivityName && formik.errors.masterActivityName}
              />
            </Grid>

            <Grid item xs={12} mt={1}>
              <Field
                as={TextField}
                name="isActive"
                label="Is it active?"
                value="Is it active?"
                fullWidth
                InputProps={{
                  readOnly: true,
                  endAdornment: (
                    <FormControlLabel
                      control={
                        <Switch
                          checked={formik.values.isActive}
                          onChange={(e) =>
                            formik.setFieldValue("isActive", e.target.checked)
                          }
                        />
                      }
                      labelPlacement="start"
                    />
                  ),
                }}
              />
            </Grid>

            <Grid container spacing={1}>
              <Grid container xs={12} sx={{ mt: 4, ml: 2, pb: 2 }} gap={3} direction={"row-reverse"}>
                <MDButton
                  type="submit"
                  variant="contained"
                  circular={true}
                  color="black"
                >
                  Save
                </MDButton>
                <MDButton variant="outlined" circular={true} color="black" onClick={handleClose}>
                  Cancel
                </MDButton>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default MasterActivityForm;
