import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { headerEventReport, headerGMVREPORT, headerReturnAndRefund, headerSalesReport, headerStockReport } from "./header";
import DataReport from "./dataReport";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ px: 2 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function ReportTabs({ tabValue, setTabValue, user, Brand,gmvRows,salesRows,returnRefundRows,stockRows,eventRows }) {

  const isAdmin = user && user?.role === "admin";
  const isBrand = user && user?.role === "brand";
  const isEvent = user && user?.role === "event";

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <>
      <Tabs sx={{ px: 2 }} value={tabValue} onChange={handleChange} aria-label="ant example">
        <Tab sx={{ width: '150px', mr: 1, fontWeight: 300, color: 'black.main' }} label="GMV Reports" {...a11yProps(0)} />
        <Tab sx={{ width: '150px', mr: 1, fontWeight: 300, color: 'black.main' }} label="Sales Reports" {...a11yProps(1)} />
        <Tab sx={{ width: '150px', mr: 1, fontWeight: 300, color: 'black.main' }} label="Return and Refund Status" {...a11yProps(2)} />
        <Tab sx={{ width: '150px', mr: 1, fontWeight: 300, color: 'black.main' }} label="Stock Report" {...a11yProps(3)} />
        <Tab sx={{ width: '150px', mr: 1, fontWeight: 300, color: 'black.main' }} label="Event Report" {...a11yProps(4)} />
      </Tabs>
      <CustomTabPanel value={tabValue} index={0}>
        <DataReport  columnDefs = {headerGMVREPORT} rowData={gmvRows} />
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={1}>
        <DataReport  columnDefs = {headerSalesReport} rowData={salesRows} />
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={2}>
        <DataReport  columnDefs = {headerReturnAndRefund} rowData={returnRefundRows} />
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={3}>
      <DataReport  columnDefs = {headerStockReport} rowData={stockRows} />
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={4}>
      <DataReport  columnDefs = {headerEventReport} rowData={eventRows} />
      </CustomTabPanel>

    </>
  );
}
