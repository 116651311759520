import {
    MenuItem,
    FormControl,
    Select,
    Box,
    IconButton,
    Icon,
    Dialog,
    DialogTitle,
    DialogContent,
    Chip,
    Input,
    TextField,
    InputAdornment,
} from "@mui/material";
import {
    KeyboardArrowDown as KeyboardArrowDownIcon,
    KeyboardArrowUp as KeyboardArrowUpIcon,
  } from "@mui/icons-material";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { GridCloseIcon } from "@mui/x-data-grid";
import MDButton from "components/MDButton";

import { ReactComponent as previewIcon } from "assets/images/previewIcon.svg";
import { ReactComponent as commentIcon } from "assets/images/commentIcon.svg";
import { ReactComponent as downloadIcon } from "assets/images/downloadIcon.svg";
import { ReactComponent as deleteIcon } from "assets/images/deleteIcon.svg";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import { useQuery, useMutation } from "@apollo/client";
import { LIST_ALL_PLAYLISTS } from "services/advertisment-service";
import { vmServiceClient } from "graphql/client";
import Loader from "components/MDLoader";
import { CREATE_PLAYLIST } from "services/advertisment-service";
import { REMOVE_PLAYLIST } from "services/advertisment-service";
import SaveIcon from '@mui/icons-material/Save';
import { UPDATE_PLAYLIST } from "services/advertisment-service";
import PlaylistTable from "./playlistTable";
import JSZip, { filter } from "jszip";
import { sumDurations, zipFiles } from "../utils";
import { DropDownSelectionContext } from "..";
import { CREATE_MAP_PLAYLIST_VIDEO } from "services/advertisment-service";
import { LIST_ALL_MAP_DEVICE_PLAYLIST_VIDEOS } from "services/advertisment-service";
import input from "assets/theme/components/form/input";
import { LIST_ALL_MAP_PLAYLIST_VIDEOS } from "services/advertisment-service";
import { REMOVE_MAP_PLAYLIST_VIDEO } from "services/advertisment-service";
import { UPDATE_MAP_PLAYLIST_VIDEO } from "services/advertisment-service";
import { SearchContext } from "context";
dayjs.extend(duration);

export default function PlaylistsPage () {
    const { searchKeyword, setSearchKeyword } = useContext(SearchContext);
    const { selectedBrandId, user, Brand} = useContext(DropDownSelectionContext);
    const [key, setKey] = useState(Date.now());

    const handleClickOpen = () => {
        setDialogOpen(true);
        
    };
    const handleClose = () => {
        setDialogOpen(false);
    };
    const handleDeleteClick = (playlistId) => {
        
        // setIsLoading(true);
        // // setIsLoading(true); 
        // removeVideoMutation({
        //   variables: { id: videoId },
        //   onCompleted: () => {
        //     console.log("Video removed successfully");
        //     setIsLoading(false);
        //     // setIsLoading(false); 
        //   },
        //   onError: (error) => {
        //     console.error("Error removing video", error);
        //     setIsLoading(false);
        //   },
        // });
      };
    
      const handleEditClick = (videos) => {
        const newTab = window.open("", "_blank");
        newTab.document.body.innerHTML = `
            <div">
            <video id="videoPlayer" controls autoplay style="width: 100vw; height: 80vh;">
                <source src="${videos[0].publicUrl}" type="video/mp4">
                Your browser does not support the video tag.
            </video>
            <div id="videoList" style=" display:flex; padding:16px; align-items:center; width: 100vw; padding-left: 20px; height:auto; flex-wrap:wrap; overflow:auto">
                ${videos.map((video, index) => `
                <h4 id="videoItem${index}" style="margin:0 20px; cursor: pointer;">
                    ${video.name}
                </h4>`).join('=>')}
            </div>
            </div>
        `;

        const videoPlayer = newTab.document.getElementById("videoPlayer");
        let currentIndex = 0;

        const updateHighlight = (index) => {
            videos.forEach((_, i) => {
            const videoItem = newTab.document.getElementById(`videoItem${i}`);
            videoItem.style.fontWeight = i === index ? 'bold' : 'normal';
            videoItem.style.color = i === index ? 'blue' : 'black';
            });
        };

        const playVideo = (index) => {
            if (index < videos.length) {
            videoPlayer.src = videos[index].publicUrl;
            videoPlayer.play();
            currentIndex = index;
            updateHighlight(currentIndex);
            }
        };

        videoPlayer.onended = () => {
            currentIndex++;
            playVideo(currentIndex);
        };

        videos.forEach((_, index) => {
            newTab.document.getElementById(`videoItem${index}`).onclick = () => {
            playVideo(index);
            };
        });

        // Initialize with the first video highlighted
        updateHighlight(currentIndex);

        newTab.focus();
    
      };
    
    const handleDownloadClick = (videos, playlistName) => {
        zipFiles(playlistName, videos.map(plv=>({url:plv.publicUrl, name:plv.name})));
    };

    const { loading, error, data: playlistData, refetch } = useQuery(LIST_ALL_PLAYLISTS, {
        client: vmServiceClient,
        variables: {
            page: 1,
            limit: 10000,
            sortBy: "updatedAt",
            order: "DESC",
            relations: ["orderedVideos","orderedVideos.video"],
            "searchCols": ["brandId"],
            "searchBy": user && user?.role!== "admin" ? [selectedBrandId || '_nobrand'] : [selectedBrandId || '']
            
        },
        fetchPolicy: "network-only",
    });
    // const { loading, error, data: playlistData, refetch } = useQuery(LIST_ALL_MAP_PLAYLIST_VIDEOS, {
    //     client: vmServiceClient,
    //     variables: {
    //         page: 1,
    //         limit: 100,
    //         sortBy: "updatedAt",
    //         order: "DESC",
    //         relations: ["videos", "playlist"],
    //         "searchCols": ["playlist.brandId"],
    //         "searchBy": user && user?.role!== "admin" ? [selectedBrandId || ''] : []
            
    //     },
    //     fetchPolicy: "network-only",
    // });

    const [createPlaylist] = useMutation(
        CREATE_PLAYLIST,
        {
            client: vmServiceClient,
            fetchPolicy: "network-only",
            refetchQueries: [
                {
                    query: LIST_ALL_PLAYLISTS,
                    variables: {
                        page: 1,
                        limit: 100,
                        sortBy: "updatedAt",
                        order: "DESC",
                        relations: ["orderedVideos"],
                    },
                },
            ],
        }
    );
    const [updatePlaylist] = useMutation(
        UPDATE_PLAYLIST,
        {
            client: vmServiceClient,
            fetchPolicy: "network-only",
            refetchQueries: [
                {
                    query: LIST_ALL_PLAYLISTS,
                    variables: {
                        page: 1,
                        limit: 100,
                        sortBy: "updatedAt",
                        order: "DESC",
                        relations: ["orderedVideos"],
                    },
                },
            ],
        }
    );

    const [createPlaylistVideo] = useMutation(
        CREATE_MAP_PLAYLIST_VIDEO,
        {
            client: vmServiceClient,
            fetchPolicy: "network-only",
            refetchQueries: [
                {
                    query: LIST_ALL_MAP_PLAYLIST_VIDEOS,
                    variables: {
                        page: 1,
                        limit: 100,
                        sortBy: "updatedAt",
                        order: "DESC",
                        relations: ["playlist","videos"],
                        filter:{playlist:{brandId:selectedBrandId||'_nobrand'}}
                    },
                },
            ],
        }
    );
    const [updatePlaylistVideo] = useMutation(
        UPDATE_MAP_PLAYLIST_VIDEO,
        {
            client: vmServiceClient,
            fetchPolicy: "network-only",
            refetchQueries: [
                {
                    query: LIST_ALL_MAP_PLAYLIST_VIDEOS,
                    variables: {
                        page: 1,
                        limit: 100,
                        sortBy: "updatedAt",
                        order: "DESC",
                        relations: ["playlist","videos"],
                    },
                },
            ],
        }
    );

    const [removePlaylistVideo] = useMutation(REMOVE_MAP_PLAYLIST_VIDEO, {
        client: vmServiceClient,
        fetchPolicy: "network-only",
        refetchQueries: [
            {
                query: LIST_ALL_MAP_PLAYLIST_VIDEOS,
                variables: {
                    page: 1,
                    limit: 100,
                    sortBy: "updatedAt",
                    order: "DESC",
                    relations: ["playlist","videos"],
                },
            },
        ],
    });

    const handleNewPlaylist = async ({ name,selectedVideos, brandId }) => {
        // if (isSubmitting) return; // Prevent multiple submissions
        // setIsSubmitting(true);
    
        const payload = {
          brandId: selectedBrandId,
          name: name,
          videos:selectedVideos,
        };
        console.log(payload, "payload")
        const createPlaylistResult = await createPlaylist({
            variables: {
                input: {
                    // startDateTime: playlist.startTime,
                    // endDateTime: playlist.endTime,
                    // deviceIds: [], // Populate as necessary
                    name: payload.name,
                    brandId,
                    status: "PENDING",
                    VideoIds: payload.videos ? payload.videos.map(video=>video.uuid):[], // Populate as necessary
                    // duration:payload.duration || 0
                },
            },
        })
        // const result = await createPlaylistVideo({
        //     variables: {
        //         input:{
        //             playlistId: createPlaylistResult?.data?.createPlaylist?.uuid,
        //             videoIds: payload.videos ? payload.videos.map(video=>video.uuid):[], // Populate as necessary
        //         }
        //     }
        // })
        console.log(createPlaylistResult, "CREATING PLAYLIST");
        refetch();
        
      };

    const [removePlaylist, { loading: removing, error: removeError }] = useMutation(REMOVE_PLAYLIST, {
        client: vmServiceClient,
        refetchQueries: [
            {
                query: LIST_ALL_PLAYLISTS,
                variables: {
                    page: 1,
                    limit: 100,
                    sortBy: "updatedAt",
                    order: "DESC",
                    relations: ["orderedVideos","orderedVideos.video"],
                },
                fetchPolicy: "network-only",
            },
        ],
    });
    const handleRemovePlaylist = async (playlist) => {
        try {
            const { data } = await removePlaylist({
                variables: { id: playlist?.uuid },
            });
            refetch();
            // if(data){
            //     await(removePlaylistVideo({variables: { id: playlistVideoMap?.uuid}}))
            //     refetch();
            // }
        } catch (error) {
            console.error("Failed to remove playlist:", error);
        }
    };

    const handleStatusChange = (uuid, status,row) => {
        const videos = row?.orderedVideos?.map(ov=>ov.video)
        updatePlaylist({
            variables: {    
                input: {
                    uuid,
                    status,
                    // VideoIds: videos?.map(plv=>plv.uuid)
                },
            },
            onCompleted: (data) => {
            //   playlistData.findAllPlaylists.results[idx].name = data.updatePlaylist.name;
            //   refetchAndRefresh()
              refetch();
            },
            onError: (error) => {
              console.error("Failed to update playlist:", error);
            },});

    }
    const EditableTextField = ({text='',uuid,idx}) => {
        const [value, setValue] = useState(text);
      
        const handleSave = async () => {
          await updatePlaylist({
            variables: {    
                input: {
                    uuid: uuid,
                    name: value,
                },
            },
            onCompleted: (data) => {
              refetch();
            },
            onError: (error) => {
              console.error("Failed to update playlist:", error);
            },
  
        });
          // Handle the save action here, e.g., API call
        };
      
        const handleKeyPress = (event) => {
          if (event.key === 'Enter') {
            handleSave();
          }
        };
      
        return (
          <TextField
            value={value}
            onChange={(e) => setValue(e.target.value)}
            onKeyPress={handleKeyPress}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleSave}
                    edge="end"
                    size="small"
                    aria-label="save"
                  >
                    <SaveIcon fontSize="small" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            fullWidth
            placeholder="Edit and press Enter or click save"
          />
        );
      };

    const columns = [
        {
            field: 'icon',
            width: 50,
            headerName:''
        },
        {
            field: 'name',
            headerName: 'Name',
            width: 200,
            sortable:true,
            renderCell: (params) => {
                if (user && user?.role == "admin"){
                    return <EditableTextField text={params.row.name} uuid={params.row.uuid} idx={params.index}/>
                }
                return params.row.name;
            }
        },
        {
            field: "brandId",
            headerName: "Brand",
            width: 200,
            flex: 1,
            renderCell: (params) => {
              const brand = Brand?.find((brand) => brand.id === params.row.brandId);        return (
                <span>{brand?.name||'Uknown Brand'}</span>
              );
            },
        },
        {
            field: 'videosCount',
            headerName: 'Videos',
            align:'right',
            width: 100,
            renderCell: (params)=>{
                return playlistData?.findAllPlaylists?.results[params.index]?.orderedVideos?.length || 0
            }    
        },
        {
            field: 'duration',
            headerName: 'Duration',
            width: 200,
            align:'right',
            renderCell: (params) => {
                // console.log(getPlaylistRowData[params.index],params.index, "PARAMS");
                return sumDurations(playlistData?.findAllPlaylists?.results[params.index].orderedVideos.map(ov=>ov.video.duration))
                // return 0;
            }
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 250,
            renderCell: (params) => {
            if (user && user?.role !== "admin") {
              return (
                <Chip
                  label={params.row.status}
                  color={
                    params.row.status === "APPROVED"
                      ? "success"
                      : params.row.status === "PENDING"
                        ? "warning"
                        : "error"
                  }
                  size="small"
                />
              );
            }
    
            return (
              <FormControl fullWidth >
                <Select 
                  onChange={(e) =>
                    handleStatusChange(params.row.uuid, e.target.value, params.row)
                  }
                  defaultValue={params.row.status}
                  style={{ width: "100%", padding: 10, borderRadius: 6,height:32, }}
                  id="status-select"
                  IconComponent={()=><KeyboardArrowDownIcon sx={{fontSize:24, width:24, height:24}} />}
                  
                >
                  <MenuItem value="APPROVED">APPROVED</MenuItem>
                  <MenuItem value="PENDING">PENDING</MenuItem>
                  <MenuItem value="REJECTED">REJECTED</MenuItem>
                </Select>
              </FormControl>
            );
          },
        },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 300,
            renderCell: ({value, index, row}) => (
                <Box sx={{ "& button": { mr: 1 } }}>
                <IconButton
                    aria-label="edit"
                    // disabled
                    // sx={{opacity: 0.25}}
                    onClick={() => handleEditClick(row?.orderedVideos?.map(ov=>ov.video))}
                >
                    <Icon component={previewIcon} fontSize="small" />
                </IconButton>
                <IconButton disabled sx={{opacity: 0.25}} aria-label="save" onClick={() => alert("Clicked")}>
                    <Icon component={commentIcon} fontSize="small" />
                </IconButton>
                <IconButton
                    aria-label="download"
                    onClick={() =>
                    handleDownloadClick(row?.orderedVideos?.map(ov=>ov.video), row?.name)
                    }
                >
                    <Icon component={downloadIcon} fontSize="small" />
                </IconButton>
                <IconButton
                    aria-label="delete"
                    onClick={() => handleRemovePlaylist(playlistData?.findAllPlaylists?.results[index])}
                >
                    <Icon component={deleteIcon} fontSize="small" />
                </IconButton>
                </Box>
            )
        }
    ];

    const videoTableColumns = [
        { field: 'dragHandle', headerName: '', width: 50, },
        {
            field: 'name',
            headerName: 'Name',
            width: 200,
        },
        {
            width: 300,
        },
        {
            field: 'duration',
            headerName: 'Duration',
            width: 200,
            align:'right',
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 250,
            renderCell: (params) => {
                return (
                    <Chip
                    label={params.value}
                    color={
                        params.value === "APPROVED"
                        ? "success"
                        : params.value === "PENDING"
                            ? "warning"
                            : "error"
                    }
                    size="small"
                    />
                );
            },
        },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 300,
            renderCell: (params) => (
                <Box sx={{ "& button": { mr: 1 } }}>
                <IconButton
                    aria-label="edit"
                    onClick={() => handleVideoEditClick(params.row.publicUrl)}
                >
                    <Icon component={previewIcon} fontSize="small" />
                </IconButton>
                <IconButton aria-label="save" disabled sx={{opacity: 0.25}} onClick={() => alert("Clicked")}>
                    <Icon component={commentIcon} fontSize="small" />
                </IconButton>
                <IconButton
                    aria-label="download"
                    onClick={() =>
                    handleVideoDownloadClick(params.row.publicUrl, params.row.name)
                    }
                >
                    <Icon component={downloadIcon} fontSize="small" />
                </IconButton>
                <IconButton
                    aria-label="delete"
                    onClick={() => handleVideoDeleteClick(params.row.uuid, params.row.parentIdx)}
                >
                    <Icon component={deleteIcon} fontSize="small" />
                </IconButton>
                </Box>
            ),
        }
    ];

    const handleVideoEditClick = (videoUrl) => {
        const newTab = window.open("", "_blank");
        newTab.document.body.innerHTML = `
            <video controls autoplay style="width: 100%; height: 100vh;">
            <source src="${videoUrl}" type="video/mp4">
            Your browser does not support the video tag.
            </video>
        `;
        newTab.focus();
    }

    
    const handleVideoDeleteClick = (videoId, parentIdx) => {
        const videos = playlistData?.findAllPlaylists?.results[parentIdx]?.orderedVideos?.map(ov=>ov.video)?.filter(plv=>plv.uuid !== videoId)
        updatePlaylist({
            variables: {    
                input: {
                    uuid: playlistData?.findAllPlaylists?.results[parentIdx]?.uuid,
                    // startDateTime: playlist.startTime,
                    // endDateTime: playlist.endTime,
                    // deviceIds: [], // Populate as necessary
                    // name: payload.name,
                    VideoIds: videos?.map(plv=>plv.uuid)
                    // duration:payload.duration || 0
                },
            },
            onCompleted: (data) => {
              
              refetch();
            },
            onError:(err)=>{
                console.log(err);
            }
        });
    };
    const handleVideoDownloadClick = (videoUrl, videoName) => {
        // This creates a new anchor element and triggers a download
        const link = document.createElement("a");
        link.href = videoUrl;
        link.download = videoName; // You can set the default file name for the download here
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    

    
    const refetchAndRefresh = (refetchProps)=>{
        refetch(refetchProps);
        // setKey(Date.now());
    }
    const handleAddVideo = (video, row)=>{
        updatePlaylist({
          variables: {    
              input: {
                  uuid: row.uuid,
                  VideoIds: row.orderedVideos ? [...row.orderedVideos.map(ov=>ov?.video?.uuid),video.uuid]:[video.uuid], // Populate as necessary
              },
          },
          onCompleted: (data) => {
            refetch();
          },
      })
    }
    const handleVideoRearrange = (videoList, row)=>{
        const videos = videoList.map(video=>video.uuid);
        // console.log("PLAYLIST", videos)
        updatePlaylist({
            variables: {    
                input: {
                    uuid: row.uuid,
                    VideoIds: videos
                },
            },
            onCompleted: (data) => {
                refetch();
                // setVideos(videoList);
            //   refresh({
            //     variables: {
            //       "page": page,
            //       "limit": rowsPerPage,
            //       "sortBy": "updatedAt",
            //       "order": "DESC",
            //       "relations": ["videos"],
            //       "searchCols": ["code"],
            //       "searchBy": user && user?.role!== "admin" ? [selectedBrandId] : []
            //     },
            //   });
            },
        })
    }

    if (loading || removing)
        return (
            <p>
                <Loader />
            </p>
        );
    if (error) return <p>Error</p>;
    return (
        <Box sx={{overflowX:'auto'}}>
            <PlaylistTable key={key} 
                columns={columns}
                handleAddVideo={handleAddVideo}
                handleVideoRearrange={handleVideoRearrange}
                handleNewPlaylist={handleNewPlaylist}
                refresh={refetchAndRefresh}
                rows={playlistData?.findAllPlaylists?.results}
                rowTotal={playlistData?.findAllPlaylists?.total}
                selectedBrandId={selectedBrandId}
                user={user}
                videoTableColumns={videoTableColumns}
            />
        </Box>
    );
}
