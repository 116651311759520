import React,{useState,useEffect} from 'react';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Typography,
} from '@mui/material';
import Loader from "components/MDLoader";
import { ReactComponent as ArrowDropDownIcon } from "assets/images/Arrow-Down.svg";
import { useQuery } from "@apollo/client";
import { GET_STORES_LIST } from "services/store-service";
import {
  GET_LIST_OF_CATEGORIES,
  GET_LIST_OF_CHANNEL,
  GET_LIST_OF_BRAND_PRODUCT,
  UPDATE_PROMOTION_MUTATION,
  GET_PROMOTION_LIST,
  GET_LIST_OF_BRAND_EXCLUDED_PRODUCT,
} from "services/promotion-service";
import { dashboardServiceClient } from "graphql/client";
import { useMutation } from "@apollo/client";
import Autocomplete from "@mui/material/Autocomplete";
import { useNotification } from "context";
import MDButton from 'components/MDButton';

// Yup validation schema
const validationSchema = Yup.object({
  channel: Yup.string().required("Channel is required"),
  channelType: Yup.array()
    .of(Yup.string())
    .when("channel", {
      is: (channel) => !!channel, // when channel is not empty
      then: () =>
        Yup.array()
          .of(Yup.string())
          .required("Channel Type is required")
          .min(1, "At least one channel type must be selected"),
      otherwise: () => Yup.array().of(Yup.string()).notRequired(),
    }),
  categoryNode: Yup.array()
    .of(Yup.string())
    .min(1, "At least one category must be selected"),
  productName: Yup.array(),
  excludedProductName: Yup.array(),
});

const MappingForm = ({ promotionData, handleClose, user, brandChange, setBrandChange }) => {
  const [selectedChannel, setSelectedChannel] = useState(
    promotionData?.channelType || "online"
  );
  const [selectedCategoryIds, setSelectedCategoryIds] = useState(
    promotionData?.promotionCategory
      ?.filter((item) => !item.isDeleted)
      ?.map((item) => item.category.id) || []
  );

  const formikRef = React.useRef(); // Create a ref for the Formik instance
  useEffect(() => {
    // Effect for brandChange
    if (brandChange && formikRef.current) {
      formikRef.current.setFieldValue('categoryNode', []);
      formikRef.current.setFieldValue('productName', []);
      formikRef.current.setFieldValue('excludedProductName', []);
    }
  }, [brandChange]);

  const [updateStoreMutation, { data, loading: isLoading, error: isError }] =
    useMutation(UPDATE_PROMOTION_MUTATION, {
      client: dashboardServiceClient,
      refetchQueries: [
        {
          query: GET_PROMOTION_LIST,
          variables: { listPromotionFilter:  { take: 100, skip: 0, search: "" },}
        },
      ],
    });
  const { setNotification } = useNotification();
  const handleCategoryChange = (selectedCategories, setFieldValue) => {
    // Filter out categories where isDeleted is true
    const filteredCategories = selectedCategories.filter((catId) => {
      const category = categoryList.categoryProduct.results.find(
        (cat) => cat.id === catId
      );
      return category && !category.isDeleted;
    });

    const newSelectedCategoryIds =
      filteredCategories.length > 0 ? filteredCategories : [];
    setSelectedCategoryIds(newSelectedCategoryIds);

    // Clear product and excluded product fields
    setFieldValue("productName", []);
    setFieldValue("excludedProductName", []);
  };
  // Store List
  const {
    loading,
    error,
    data: storeList,
  } = useQuery(GET_STORES_LIST, {
    client: dashboardServiceClient,
    variables: { listStoreFilter: { take: 100, skip: 0 } },
  });
  // Categories List
  const {
    loading: cateogryLoading,
    error: categoryError,
    data: categoryList,
    refetch,
  } = useQuery(GET_LIST_OF_CATEGORIES, {
    client: dashboardServiceClient,
    variables: {
      listCategoryProductFilter: {
        take: 100000,
        skip: 0,
        search: "",
        filter: {
          isNodeItem: true,
          isDeleted: false,
          brandCategoryStore: {
            isDeleted: false,
            brand: {
              id: promotionData?.promotionBrand
                .filter((item) => !item.isDeleted)
                .map((item) => item.brand.id),
            },
          },
        },
      },
    },
  });
  // Channel List
  const {
    loading: channelLoading,
    error: channelError,
    data: channelList,
    refetch: refetchChannelList,
  } = useQuery(GET_LIST_OF_CHANNEL, {
    client: dashboardServiceClient,
    variables: { take: 100, skip: 0, filter: { channelType: selectedChannel } },
    skip: !selectedChannel, // Skip query if no channel is selected
  });
  // Products List
  const {
    loading: productLoading,
    error: productError,
    data: productData,
    refetch: includedRefetch,
  } = useQuery(GET_LIST_OF_BRAND_PRODUCT, {
    client: dashboardServiceClient,
    variables: {
      listProductFilter: {
        take: 20000,
        skip: 0,
        search: "",
        filter: {
          brand: {
            id: promotionData?.promotionBrand
              ?.filter((item) => !item.isDeleted)
              ?.map((item) => item?.brand?.id),
          },
          productCategory: {
            isDeleted: false,
            categoryProduct: {
              id: categoryList?.categoryProduct?.results
                .filter(
                  (item) =>
                    !selectedCategoryIds?.includes(item?.id) && !item.isDeleted
                )
                .map((item) => item.id),
            },
          },
        },
      },
    },
  });
  // Excluded Product List
  const {
    loading: excludedproductLoading,
    error: excludedProductError,
    data: excludedProductData,
    refetch: excludedRefetch,
  } = useQuery(GET_LIST_OF_BRAND_EXCLUDED_PRODUCT, {
    client: dashboardServiceClient,
    variables: {
      listProductFilter: {
        take: 20000,
        skip: 0,
        search: "",
        filter: {
          brand: {
            id: promotionData?.promotionBrand
              ?.filter((item) => !item.isDeleted)
              ?.map((item) => item?.brand?.id),
          },
          productCategory: {
            isDeleted: false,
            categoryProduct: { id: selectedCategoryIds },
          },
        },
      },
    },
  });
  // Effect to refetch channel list when selected channel changes
  useEffect(() => {
    if (selectedChannel) {
      refetchChannelList();
    }
  }, [selectedChannel, refetchChannelList]);
  useEffect(() => {
    if (refetch) {
      refetch();
      includedRefetch();
      excludedRefetch();
    }
  }, [selectedCategoryIds]);

  if (loading)
    return (
      <>
        <Loader />
      </>
    );
  if (error) return <p>Error: {error?.message}</p>;
  const getChannelTypeIds = (channelType) => {
    return promotionData?.promotionChannel
      ?.filter(
        (item) => item?.channel?.channelType === channelType && !item?.isDeleted
      )
      .map((item) => item?.channel?.id);
  };

  

  return (
    <Formik
      innerRef={formikRef}
      initialValues={{
        channel: promotionData?.channelType || "online",
        channelType:
          selectedChannel === "offline"
            ? getChannelTypeIds("offline")
            : getChannelTypeIds("online"),
        categoryNode: brandChange ? [] :
          promotionData?.promotionCategory
            ?.filter((item) => !item.isDeleted)
            ?.map((item) => item.category.id) || [],
        productName: brandChange ? [] :
          promotionData?.promotionProduct
            .filter((item) => !item.isExcluded && !item.isDeleted)
            .map((item) => ({
              id: item.product.id,
              label: item.product.title,
            })) || [],
        excludedProductName: brandChange ? [] :
          promotionData?.promotionProduct
            .filter((item) => item.isExcluded && !item.isDeleted)
            .map((item) => ({
              id: item.product.id,
              label: item.product.title,
            })) || [],
      }}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        const formattedValues = {
          ...values,
          promotionProduct: values.productName.map((item) => item.id),
          promotionExcludProduct: values.excludedProductName.map(
            (item) => item.id
          ),
        };
        updateStoreMutation({
          variables: {
            updatePromotionInput: {
              id: promotionData.id,
              channelType: values?.channel,
              promotionChannel: values?.channelType,
              promotionCategory: values?.categoryNode,
              promotionProduct: formattedValues.promotionProduct,
              promotionExcludProduct: formattedValues.promotionExcludProduct,
            },
          },
        })
          .then((res) => {
            handleClose();
            setNotification({
              color: "success",
              isVisible: true,
              message:
                promotionData && promotionData?.id
                  ? "Promotion Mapping   updated successfully"
                  : "Promotion Mapping   created successfully",
            });
          })
          .catch((error) => {
            console.error("Error:", error.message);
            setNotification({
              color: "error",
              isVisible: true,
              message: error.message || "Something went wrong",
            });
          });
      }}
    >
      {({
        values,
        setFieldValue,
        setFieldTouched,
        validateField,
        errors,
        touched,
        isSubmitting,
        handleChange,
        resetForm,
        initialValues
      }) => {
        return (
          <>
          <Form>
          <Grid container spacing={3} alignItems="center" p={2}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Channel</InputLabel>
                <Select
                  as={Select}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="channel"
                  label="Channel"
                  sx={{ height: 40 }}
                  value={values.channel}
                  IconComponent={() => (
                    <ArrowDropDownIcon style={{ marginRight: "18px" }} />
                  )}
                  onChange={(e) => {
                    setFieldValue("channel", e.target.value);
                    setFieldTouched(`channel`, true, false);
                    setFieldValue("channelType", null);
                    validateField(`channel`, e.target.value);
                    setSelectedChannel(e.target.value);
                  }}
                  error={touched.channel && Boolean(errors.channel)}
                  helperText={touched.channel && errors.channel}
                >
                  <MenuItem value="online">Online</MenuItem>
                  <MenuItem value="offline">Offline</MenuItem>
                </Select>
                {touched.channel && errors.channel && (
                  <Typography fontSize={12} color="error">
                    {errors.channel}
                  </Typography>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth disabled={!values.channel}>
                <InputLabel>Channel Type</InputLabel>
                <Select
                  as={Select}
                  multiple
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="channelType"
                  label="Channel Type"
                  IconComponent={() => (
                    <ArrowDropDownIcon style={{ marginRight: "18px" }} />
                  )}
                  sx={{ height: 40 }}
                  value={values.channelType || []}
                  onChange={(e) => {
                    setFieldValue("channelType", e.target.value);
                    setFieldTouched(`channelType`, true, false);
                    validateField(`channelType`, e.target.value);
                  }}
                  helperText={touched?.channelType && errors?.channelType}
                  error={touched?.channelType && Boolean(errors?.channelType)}
                >
                  {values.channel === "online" &&
                    channelList?.channels?.results
                      .filter(
                        (item) =>
                          item?.channelType === "online" && !item.isDeleted
                      )
                      .map((item) => (
                        <MenuItem key={item?.id} value={item?.id}>
                          {item?.name}
                        </MenuItem>
                      ))}
                  {values.channel === "offline" &&
                    channelList?.channels?.results
                      .filter(
                        (item) =>
                          item?.channelType === "offline" && !item.isDeleted
                      )
                      .map((item) => (
                        <MenuItem key={item?.id} value={item?.id}>
                          {item?.channelStore[0]?.store?.name}
                        </MenuItem>
                      ))}
                </Select>
                {touched?.channelType && errors?.channelType && (
                  <Typography fontSize={12} color="error">
                    {errors?.channelType}
                  </Typography>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>Category Node</InputLabel>
                <Select
                  as={Select}
                  disabled={!promotionData?.id}
                  multiple
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="categoryNode"
                  label="Category Node"
                  IconComponent={() => (
                    <ArrowDropDownIcon style={{ marginRight: "18px" }} />
                  )}
                  sx={{ height: 40 }}
                  value={values?.categoryNode || []}
                  onChange={(e) => {
                    setFieldValue("categoryNode", e.target.value);
                    setFieldTouched(`categoryNode`, false, false);
                    handleCategoryChange(e.target.value, setFieldValue);
                    validateField(`categoryNode`, e.target.value);
                  }}
                  helperText={touched?.categoryNode && errors?.categoryNode}
                  error={touched?.categoryNode && Boolean(errors?.categoryNode)}
                >
                  {categoryList?.categoryProduct?.results &&
                    categoryList?.categoryProduct?.results.length &&
                    categoryList?.categoryProduct?.results.map((cat) => (
                      <MenuItem value={cat?.id} key={cat?.id}>
                        {cat?.metaTitle}
                      </MenuItem>
                    ))}
                </Select>
                {touched?.categoryNode && errors?.categoryNode && (
                  <Typography fontSize={12} color="error">
                    {errors?.categoryNode}
                  </Typography>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                multiple
                id="tags-standard"
                name="productName"
                value={values.productName}
                options={
                  productData?.products?.results?.map((item) => ({
                    id: item?.id,
                    label: item?.title,
                  })) || []
                } // Changed this to brands
                getOptionLabel={(option) => option.label || "Unknown"}
                onChange={(event, newValue) => {
                  setFieldValue("productName", newValue);
                }}
                onBlur={() => {
                  setFieldTouched("productName");
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Product Name"
                    placeholder="Select"
                    helperText={touched.productName ? errors.productName : ""}
                    error={touched.productName && Boolean(errors.productName)}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                multiple
                id="tags-standard"
                name="excludedProductName"
                value={values?.excludedProductName || []}
                options={
                  excludedProductData?.products?.results?.map((item) => ({
                    id: item?.id,
                    label: item?.title,
                  })) || []
                } // Changed this to brands
                getOptionLabel={(option) => option?.label || "Unknown"}
                onChange={(event, newValue) => {
                  setFieldValue("excludedProductName", newValue);
                }}
                onBlur={() => {
                  setFieldTouched("excludedProductName");
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label=" Excluded Product Name"
                    placeholder="Select"
                    helperText={
                      touched.excludedProductName
                        ? errors.excludedProductName
                        : ""
                    }
                    error={
                      touched.excludedProductName &&
                      Boolean(errors.excludedProductName)
                    }
                    sx={{
                      ".MuiAutocomplete-input": {
                        color: "#344767",
                      },
                      ".MuiAutocomplete-option": {
                        "&[aria-selected='true']": {
                          backgroundColor: "#344767 !important",
                          color: "white",
                        },
                        "&[data-focus='true']": {
                          backgroundColor: "#da1751 !important",
                        },
                      },
                      ".MuiAutocomplete-tag": {
                        bgcolor: "#344767",
                        color: "white",
                      },
                    }}
                  />
                )}
              />
            </Grid>
            <Grid container gap={2} flexDirection={"row-reverse"} xs={12} sx={{ mt: 2 }}>
            <MDButton
                  type="submit"
                  variant="contained"
                  circular={true}
                  color="black"
              >
                Save
              </MDButton>
              <MDButton
                color='black'
                circular={true}
                variant={"outlined"}
                style={{ backgroundColor: "#FFFFFF" }}
                onClick={handleClose}
              >
                Cancel
              </MDButton>
            </Grid>
          </Grid>
        </Form>
          </>
        )
      }}
    </Formik>
  );
};

export default MappingForm;
