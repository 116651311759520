import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { gql, useLazyQuery } from "@apollo/client";
import axios from "axios";
import { dashboardServiceClient } from "graphql/client";
import { useNotification } from "context";
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';

const GET_SIGNED_URL = gql`
  query getSignedUrl($getSignedUrlRequest: GetSignedUrlInput!) {
    getSignedUrl(getSignedUrlRequest: $getSignedUrlRequest) {
      url
    }
  }
`;

export default function FileUploaderContainer({
  preLoadedFile,
  isMultiple,
  signedUrlCallback,
  fieldName,
  isFieldValid,
}) {
  const preFileInstances =
    (preLoadedFile &&
      preLoadedFile.map((file, index) => ({ id: index, fileName: file }))) ||
    [];
  const stateValue = isMultiple
    ? [...preFileInstances, { id: Date.now() }]
    : preFileInstances.length
    ? [...preFileInstances]
    : [{ id: Date.now() }];

  const [fileInstances, setFileInstances] = useState(stateValue);

  const addNewInstance = () => {
    isMultiple && setFileInstances([...fileInstances, { id: Date.now() }]);
  };

  const [getSignedUrl, { data }] = useLazyQuery(GET_SIGNED_URL, {
    client: dashboardServiceClient,
  });

  const deleteInstance = (idToDelete) => {
    setFileInstances(
      fileInstances.filter((instance) => instance.id !== idToDelete)
    );
  };

  return (
    <div>
      {fileInstances.map((instance, index) => (
        <div key={instance.id}>
          <FileUploader
            id={index}
            instance={instance}
            addNewInstance={addNewInstance}
            deleteInstance={deleteInstance}
            getSignedUrl={getSignedUrl}
            signedUrl={data?.getSignedUrl?.url}
            signedUrlCallback={signedUrlCallback}
            fieldName={fieldName}
            isMultiple={isMultiple}
            isFieldValid={isFieldValid}
          />
        </div>
      ))}
    </div>
  );
}

function FileUploader({
  addNewInstance,
  deleteInstance,
  id,
  getSignedUrl,
  signedUrl,
  instance,
  signedUrlCallback,
  fieldName,
  isMultiple,
  isFieldValid,
}) {
  const { setNotification } = useNotification();

  const [fileName, setFileName] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploaded, setIsUploaded] = useState(
    instance.fileName ? true : false
  );
  const [fileData, setFileData] = useState(null);
  const [axiosCallMade, setAxiosCallMade] = useState(false);

  const fileInputId = `${fieldName}-fileInput-${id}`;

  useEffect(() => {
    if (signedUrl && fileName && !axiosCallMade) {
      const uploadFile = async () => {
        try {
            const response = await axios.put(signedUrl,fileData,{
          headers: { "Content-Type": "application/octet-stream" },
          onUploadProgress:(progressEvent)=>{
            const percentCompleted =Math.round((progressEvent.loaded * 100)/progressEvent.total)
            setUploadProgress(percentCompleted);
          },
            })
            if(response.status === 200){
                setIsUploaded(true);
                setUploadProgress(100)
                setAxiosCallMade(true);
        getSignedUrl({
          variables: {
            getSignedUrlRequest: {
              fileName: `dashboard/staging/news/${fileName}`,
              action: "read",
              contentType: "application/octet-stream",
            },
          },
        });
        addNewInstance();
            }
        }
       catch (error){
        console.error('Upload faild',error);
        setNotification({
            color:"error",
            isVisible:true,
            message:'Upload Failed. Please try again'
        })
      }
    }
      uploadFile();
    }
  }, [signedUrl, fileName]);

  useEffect(() => {
    if (isUploaded && signedUrl && fileName) {
      signedUrlCallback(`dashboard/staging/news/${fileName}`, id,fileData,fileName);
    }
  }, [isUploaded, signedUrl]);

  const handleFileChange = (e) => {
    const fileInput = e.target; 
    const file = e.target.files[0];
    if (!file) return;

    if (!file.name.match(/\.(mp4|avi|mov|mpeg|mkv|MP4|AVI|MOV|MPEG|MKV)$/)) {
        setNotification({
          color: "warning",
          isVisible: true,
          message: "Selected file format is not allowed. Please upload a video file.",
        });
        return false;
      }
      if (file.size > 20 * 1024 * 1024) { 
        setNotification({
          color: "warning",
          isVisible: true,
          message: "The file size should not exceed 20MB.",
        });
        fileInput.value =''
        return false;
    }
    if (file) {
      setFileName(file.name);
      setFileData(e.target.files[0]);
      setUploadProgress(40);
      getSignedUrl({
        variables: {
          getSignedUrlRequest: {
            fileName: `dashboard/staging/news/${file.name}`,
            action: "write",
            contentType: "application/octet-stream",
          },
        },
      });
      setAxiosCallMade(false);
    }
  };

  const handleDelete = () => {
    deleteInstance(instance.id);
    signedUrlCallback(null, id);
  };

  return (
    <Paper
      elevation={0}
      onClick={() =>
        !isUploaded && document.getElementById(fileInputId).click()
      }
      sx={{
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "8px 0px 8px 16px",
        backgroundColor: isUploaded ? "#6a994e29" : "#fafafa",
        maxHeight: "52px",
        borderRadius: "4px",
        border: `1px ${isFieldValid ? "solid" : isUploaded ? "solid" : "dashed"} ${
          isFieldValid ? "#F44335" : isUploaded ? "#6a994e" : "#efefef"
        }`,
      }}
    >
      <input
        type="file"
        id={fileInputId}
        style={{ display: "none" }}
        accept="video/*"
        onChange={handleFileChange}
      />
      {isUploaded ? (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "inline-flex",
              height: "52px",
              gap: "16px",
              alignItems: "center",
            }}
          >
            <img
              src="https://generation-sessions.s3.amazonaws.com/7c71c9772decb49fcc575053870ef5ff/img/tick-circle@2x.png"
              alt="tick"
              style={{ width: "24px", height: "24px" }}
            />
            <div
              style={{
                width: "299px",
                fontFamily: "Inter-Medium, Helvetica",
                fontWeight: 500,
                color: "#000000",
                fontSize: "14px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {fileName ||
                (instance.fileName
                  ? instance.fileName.split("/").reverse()[0]
                  : "")}
            </div>
          </div>
          <div
            style={{
              display: "inline-flex",
              justifyContent: "flex-end",
              gap: "8px",
              padding: "10px 16px",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontFamily: '"Inter-Bold", Helvetica',
                fontWeight: 600,
                fontSize: "12px",
                lineHeight: "16px",
                color: "#6a994e",
              }}
            >
              Uploaded
            </Typography>
          </div>
        </div>
      ) : (
        <div style={{ display: "flex", alignItems: "center", gap: "16px" }}>
          <VideoLibraryIcon sx={{ color: '#344767', width: "24px", height: "24px" }} />

          <Typography variant="body2">
            {fileName ? `Uploading ${fileName}` : fieldName}
          </Typography>
        </div>
      )}
      {fileName && !isUploaded && (
        <div
          style={{
            width: `${uploadProgress}%`,
            backgroundColor: "red",
            height: "5px",
            position: "absolute",
            bottom: 0,
            left: 0,
          }}
        ></div>
      )}
      <div
        style={{
          display: "inline-flex",
          alignItems: "center",
          gap: "8px",
          padding: "10px 16px",
        }}
      >
        {!isUploaded && (
          <img
            src="https://generation-sessions.s3.amazonaws.com/df5a8cb33081b6df29ee38b7b81444bc/img/icon.svg"
            alt="Icon"
            style={{ width: "16px", height: "16px" }}
          />
        )}
        <Button
          sx={{ color: "#344767", pl: 0 }}
          onClick={
            isUploaded
              ? isMultiple
                ? handleDelete
                : () => {
                    document.getElementById(fileInputId).click();
                  }
              : null
          }
        >
          {isUploaded ? (isMultiple ? "DELETE" : "CHANGE") : "CHOOSE FILE"}
        </Button>
      </div>
    </Paper>
  );
}
