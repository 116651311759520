import { gql } from "@apollo/client";

export const GET_GMV_REPORT = gql`
query GetGmvReport ($filter: GetGMVReportFilter!) {
    getGmvReport(filter: $filter) {
        total
        data {
            id
            date
            brand
            salesByMrp
            sellingPrice
            promotionDiscount
            couponDiscount
            rewardsBurn
            amountCollected
        }
    }
}`;

export const GET_SALES_REPORT = gql`query GetSalesReport($filter: GetSalesReportFilter!){
  getSalesReport(filter: $filter){
    total
    data {
        id
        productID
        div
        section
        department
        node
        orderDate
        productName
        productVariant
        mrp
        sellingPrice
        amountCollected
        orderQuantity
        promotions
        coupons
        rewards
    }
}
}`;


  export const GET_RETURN_AND_REFUND_REPORT = gql`query GetReturnAndRefund($filter: GetReturnAndRefundFilter!){
    getReturnAndRefund(filter: $filter){
    total
    data {
        id
        productID
        productName
        amount
        refundStatus
        refundDate
        returnedReason
        salesOrderID
    }
}
}`;


  export const GET_STOCK_REPORT = gql`query GetStockReports($filter: GetStockReportFilter!){
    getStockReports(filter: $filter){
    total
    data {
        id
        productId
        productName
        div
        section
        department
        node
        productVariant
        availableQuantity
        store
        brand
    }
}
}`;


export const GET_EVENT_REPORT = 
gql`query GetEventReports ($filter : GetEventsReportFilter!){
  getEventReports(filter: $filter) {
    total
    data {
        id
        eventName
        eventDate
        eventTime
        ticketType
        category
        ticketsSold
        ticketAmount
        rewardsBurn
        numberOfParticipants
        samplesDistributed
    }
}
}`;