import { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import FileUploader from "components/MDFileUpload";
import * as Yup from "yup";
import { useMutation } from "@apollo/client";
import { dashboardServiceClient } from "graphql/client";
import { useNotification } from "context";
import { ReactComponent as ArrowDropDownIcon } from "assets/images/Arrow-Down.svg";
import {
  Button,
  Grid,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import { GET_BRAND_DETAILS } from "services/brand-service";
import { CREATE_BRAND_METADATA } from "services/brand-service";
import { UPDATE_BRAND_METADATA } from "services/brand-service";

const validationSchema = Yup.object({
  nameSurnameBusiness: Yup.string().required("Required"),
  phoneNoBusiness: Yup.string() .matches(/^[6-9][0-9]{9}$/, 'Phone number is not valid'),
  mobileNoBusiness: Yup.string() .matches(/^[6-9][0-9]{9}$/, 'Mobile number is not valid') .required('Mobile number is required'),
  emailBusiness: Yup.string()
            .email('Invalid email address')
            .matches(
              /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i,
              'Invalid email address'
            )
            .required('Email is required'),
  nameSurnameAccount: Yup.string().required("Required"),
  phoneNoAccount: Yup.string() .matches(/^[6-9][0-9]{9}$/, 'Phone number is not valid'),
  mobileNoAccount: Yup.string() .matches(/^[6-9][0-9]{9}$/, 'Mobile number is not valid') .required('Mobile number is required'),
  emailAccount: Yup.string()
            .email('Invalid email address')
            .matches(
              /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i,
              'Invalid email address'
            )
            .required('Email is required'),
  nameSurnameOperations: Yup.string().required("Required"),
  phoneNoOperations: Yup.string() .matches(/^[6-9][0-9]{9}$/, 'Phone number is not valid'),
  mobileNoOperations: Yup.string() .matches(/^[6-9][0-9]{9}$/, 'Mobile number is not valid') .required('Mobile number is required'),
  emailOperations: Yup.string()
            .email('Invalid email address')
            .matches(
              /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i,
              'Invalid email address'
            )
            .required('Email is required'),
});
const useMetadataMutation = (
  brandData,
  brandMetadata,
  setNotification,
  handleTabChange,
  setBrandData
) => {
  const mutationOptions = {
    client: dashboardServiceClient,
    refetchQueries: [
      {
        query: GET_BRAND_DETAILS,
        variables: { username: brandData?.username || "" },
      },
    ],
  };

  const [createBrandMetadataMutation] = useMutation(
    CREATE_BRAND_METADATA,
    mutationOptions
  );
  const [updateBrandMetadataMutation] = useMutation(
    UPDATE_BRAND_METADATA,
    mutationOptions
  );

  const handleSubmit = (values) => {
    console.log("brandMetadata.id ==== ", brandMetadata);
    const mutation =
      brandMetadata && brandMetadata.id
        ? updateBrandMetadataMutation
        : createBrandMetadataMutation;
    const mutationInput =
      brandMetadata && brandMetadata.id
        ? {
            metaDataInput: {
              id: brandMetadata.id,
              key: "ContactDetails",
              value: { ...values },
            },
          }
        : {
            metaDataInput: {
              brandId: brandData.id,
              key: "ContactDetails",
              value: { ...values },
            },
          };

    mutation({ variables: mutationInput })
      .then((res) => {
        setBrandData(brandData);
        handleTabChange(null, 3);
        setNotification({
          color: "success",
          isVisible: true,
          message:
            brandMetadata && brandMetadata.id
              ? "Brand Contact data updated successfully"
              : "Brand Contact data created successfully",
        });
      })
      .catch((error) => {
        console.error(" Error:", error.message);
        setNotification({
          color: "error",
          isVisible: true,
          message: error.message || "Something went wrong",
        });
      });
  };

  return handleSubmit;
};

const BrandContactDetailsFormComponent = ({ brandData, handleTabChange, setBrandData, user }) => {
  const { setNotification } = useNotification();
  console.log(handleTabChange)

  const contactData =
    brandData?.brandMetaData.find((data) => data.key === "ContactDetails") || {};
  const handleSubmit = useMetadataMutation(
    brandData,
    contactData,
    setNotification,
    handleTabChange,
    setBrandData
  );
// console.log("contactData ==== ", contactData);
  return (
    <Formik
      initialValues={{
        nameSurnameBusiness: contactData?.value?.nameSurnameBusiness || "",
        phoneNoBusiness: contactData?.value?.phoneNoBusiness || "",
        mobileNoBusiness: contactData?.value?.mobileNoBusiness || "",
        emailBusiness: contactData?.value?.emailBusiness || "",
        nameSurnameAccount: contactData?.value?.nameSurnameAccount || "",
        phoneNoAccount: contactData?.value?.phoneNoAccount || "",
        mobileNoAccount: contactData?.value?.mobileNoAccount || "",
        emailAccount: contactData?.value?.emailAccount || "",
        nameSurnameOperations: contactData?.value?.nameSurnameOperations || "",
        phoneNoOperations: contactData?.value?.phoneNoOperations || "",
        mobileNoOperations: contactData?.value?.mobileNoOperations || "",
        emailOperations: contactData?.value?.emailOperations || "",
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => handleSubmit(values)}
    >
    {(formik) => (
      <Form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid item xs={12} sx={{mb:2}}>
              <Typography
                variant="h5"
                gutterBottom
                sx={{ color: "#000000", fontFamily: "Montserrat" }}
              >
                Business Contact
              </Typography>
              <hr color="#EAEAEA" />
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Field
                  name="nameSurnameBusiness"
                  as={TextField}
                  label="Name Surname"
                  fullWidth
                  helperText={formik.touched.nameSurnameBusiness && formik.errors.nameSurnameBusiness}
                  error={formik.touched.nameSurnameBusiness && !!formik.errors.nameSurnameBusiness}
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  name="phoneNoBusiness"
                  as={TextField}
                  label="Phone No."
                  fullWidth
                  helperText={formik.touched.phoneNoBusiness && formik.errors.phoneNoBusiness}
                  error={formik.touched.phoneNoBusiness && !!formik.errors.phoneNoBusiness}
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  name="mobileNoBusiness"
                  as={TextField}
                  label="Mobile No."
                  // type="number"
                  fullWidth
                  helperText={formik.touched.mobileNoBusiness && formik.errors.mobileNoBusiness}
                  error={formik.touched.mobileNoBusiness && !!formik.errors.mobileNoBusiness}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  name="emailBusiness"
                  as={TextField}
                  label="Email"
                  fullWidth
                  helperText={formik.touched.emailBusiness && formik.errors.emailBusiness}
                  error={formik.touched.emailBusiness && !!formik.errors.emailBusiness}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="h5"
                  gutterBottom
                  sx={{ color: "#000000", fontFamily: "Montserrat" }}
                >
                  Account Contact
                </Typography>
                <hr color="#EAEAEA" />
              </Grid>
              <Grid item xs={12}>
                <Field
                  name="nameSurnameAccount"
                  as={TextField}
                  label="Name Surname"
                  fullWidth
                  helperText={formik.touched.nameSurnameAccount && formik.errors.nameSurnameAccount}
                  error={formik.touched.nameSurnameAccount && !!formik.errors.nameSurnameAccount}
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  name="phoneNoAccount"
                  as={TextField}
                  label="Phone No."
                  // type="number"
                  fullWidth
                  helperText={formik.touched.phoneNoAccount && formik.errors.phoneNoAccount}
                  error={formik.touched.phoneNoAccount && !!formik.errors.phoneNoAccount}
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  name="mobileNoAccount"
                  as={TextField}
                  label="Mobile No."
                  // type="number"
                  fullWidth
                  helperText={formik.touched.mobileNoAccount && formik.errors.mobileNoAccount}
                  error={formik.touched.mobileNoAccount && !!formik.errors.mobileNoAccount}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  name="emailAccount"
                  as={TextField}
                  label="Email"
                  type="email"
                  fullWidth
                  helperText={formik.touched.emailAccount && formik.errors.emailAccount}
                  error={formik.touched.emailAccount && !!formik.errors.emailAccount}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="h5"
                  gutterBottom
                  sx={{ color: "#000000", fontFamily: "Montserrat" }}
                >
                  Operations Contact
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Field
                  name="nameSurnameOperations"
                  as={TextField}
                  label="Name Surname"
                  fullWidth
                  helperText={formik.touched.nameSurnameOperations && formik.errors.nameSurnameOperations}
                  error={formik.touched.nameSurnameOperations && !!formik.errors.nameSurnameOperations}
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  name="phoneNoOperations"
                  as={TextField}
                  label="Phone No."
                  // type="number"
                  fullWidth
                  helperText={formik.touched.phoneNoOperations && formik.errors.phoneNoOperations}
                  error={formik.touched.phoneNoOperations && !!formik.errors.phoneNoOperations}
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  name="mobileNoOperations"
                  as={TextField}
                  label="Mobile No."
                  // type="number"
                  fullWidth
                  helperText={formik.touched.mobileNoOperations && formik.errors.mobileNoOperations}
                  error={formik.touched.mobileNoOperations && !!formik.errors.mobileNoOperations}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  name="emailOperations"
                  as={TextField}
                  label="Email"
                  fullWidth
                  helperText={formik.touched.emailOperations && formik.errors.emailOperations}
                  error={formik.touched.emailOperations && !!formik.errors.emailOperations}
                />
              </Grid>
              <Grid container sx={{ mt: 3 }} justifyContent={"flex-end"} gap={3}>
                <Button
                  sx={{ color: "#000000", borderRadius: "50px", borderColor: "#000000", fontFamily:"Montserrat" }}
                  variant="outlined"
                  onClick={() => formik.resetForm({
                    values: {
                      nameSurnameBusiness: "",
                      phoneNoBusiness: "",
                      mobileNoBusiness: "",
                      emailBusiness: "",
                      nameSurnameAccount: "",
                      phoneNoAccount: "",
                      mobileNoAccount: "",
                      emailAccount: "",
                      nameSurnameOperations: "",
                      phoneNoOperations: "",
                      mobileNoOperations: "",
                      emailOperations: "",
                    },
                  })}
                >
                  Reset
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  style={{ marginRight: "10px", backgroundColor: "#000000", color: "#fff", borderRadius: "50px", fontFamily:"Montserrat" }}
                >
                  Next
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Form>
    )}
  </Formik>
  );
};

export default BrandContactDetailsFormComponent;
