
import { ArrowDownward, ArrowUpward, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { Box, Button, Collapse, IconButton, Table, TableBody, TableCell, TablePagination, TableRow } from '@mui/material';
import React, { useState } from 'react'


const CollapsibleRow = ({ columns, getCollapseContent, index, row, parent=null }) => {
    // const { row } = props;
    const [open, setOpen] = React.useState(false);
    
    return (
        <React.Fragment>
            <TableRow sx={{ alignItems:'center',"& > td": { borderBottom: "unset" }}} >
                {columns.map((column) => {
                    if(column.field=='icon'){
                        return <TableCell align={column.align || 'left'} sx={{width:column.width, maxWidth:column.width }}>
                            <IconButton sx={{p:0}}
                                aria-label="expand row"
                                size="small"
                                
                                onClick={() => setOpen(!open)}
                            >
                                {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                            </IconButton>
                        </TableCell>
                    } else if(column.renderCell!=null){
                        return <TableCell tooltip={column.name} align={column.align || 'left'} sx={{width:column.width, maxWidth:column.width, overflow:'hidden', textOverflow:'ellipsis'}}>{column.renderCell({value:row[column.field], index, row, parent})}</TableCell>
                    }
                    return <TableCell tooltip={column.name} align={column.align || 'left'} sx={{width:column.width, maxWidth:column.width, overflow:'hidden', textOverflow:'ellipsis'}}>{row[column.field]}</TableCell>
                })}
            </TableRow>
            <TableRow sx={{ "& > *": { borderBottom: "unset" }, borderBottom:1,borderColor:'#fafafa',width:'100%', display:'flex', px:0}}>
          <TableCell sx={{ flex: 1, px:0,py:0}}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              {getCollapseContent(row, index)}
            </Collapse>
          </TableCell>
        </TableRow>
        </React.Fragment>
    );
}

export default function AdsTableCollapsible({ columns, getCollapseContent, footer, pagination=true, refetchProps, refetch, rowData, rowDataTotalCount, parent=null }) {
    const [page, setPage] = useState(refetchProps.page-1);
    const [rowsPerPage, setRowsPerPage] = useState(refetchProps.limit);
    const [sortBy, setSortBy] = useState(refetchProps.sortBy)
    const [order, setOrder] = useState(refetchProps.order)
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        console.log(newPage,"NEWPAGE");
        if(refetch) refetch({...refetchProps, page:newPage+1, limit:rowsPerPage});
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
        if(refetch) refetch({...refetchProps, page:1, limit:+event.target.value});
    };



    const SortControlHeader = ({column}) => {
        const [hovering,setHovering] = useState(false);
        return column.sortable ? <Box
                onClick={()=>{
                    if(sortBy!=column.field){
                        setSortBy(column.field)
                        setOrder("ASC")
                        if(refetch) refetch({...refetchProps, sortBy:column.field, order:"ASC"});
                    } else {
                        const sortOrder = order == "ASC" ? "DESC" : "ASC"
                        setOrder(sortOrder);
                        if(refetch) refetch({...refetchProps, sortBy:column.field, order:sortOrder});
                    }
                }}
                onMouseEnter={()=>setHovering(true)} 
                onMouseLeave={()=>setHovering(false)} 
                sx={{ cursor:'pointer', display:'flex', flexDirection: column.align=='right' && 'row-reverse', justifyContent:column.align=='center' && 'center', alignItems:'center'}}>
                    <Box>
                        {column.headerName}
                    </Box>
                    {column.field==sortBy && order=="ASC" && <ArrowUpward/>}
                    {column.field==sortBy && order=="DESC" && <ArrowDownward/>}
                    {column.field!=sortBy && hovering && <ArrowUpward/>}
            </Box>: column.headerName
    }
  
    return (
    <>
        <Table aria-label="collapsible table" sx={{width:'max-content'}} >
            <TableBody sx={{display:'flex', flexDirection:'column'}}>
                <TableRow sx={{}}>
                    {columns.map((column) => (
                        <TableCell colSpan={1}
                            key={column.field}
                            align={column.align || 'left'}
                            sx={{ width:column.width,fontWeight:600, overflow: 'hidden', textOverflow:'ellipsis' }}
                            tooltip={column.name}
                        >
                            <SortControlHeader column={column} />
                        </TableCell>
                    ))}
                </TableRow>

                {rowData?.map((row,index) => (
                    <CollapsibleRow key={row.uuid} columns={columns} getCollapseContent={getCollapseContent} row={row} index={index} parent={parent}  />
                ))}
            </TableBody>
        </Table>
        <Box sx={{display:'flex', justifyContent:'space-between',alignItems:'center', py:2, px:4}}>
            <Box sx={{maxWidth:'70%'}}>
                {footer}
            </Box>
            { pagination && 
                <TablePagination
                    count={rowDataTotalCount} 
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    page={page}
                    rowsPerPageOptions={[10, 20, 50, 100]}
                    rowsPerPage={rowsPerPage}
                ></TablePagination>
            }

        </Box>
    </>
  )
}
