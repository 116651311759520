import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import Chip from "@mui/material/Chip";
import { Grid, Pagination as MuiPagination, Box ,IconButton, Icon} from '@mui/material';
import { useQuery,useMutation } from "@apollo/client";
import { ReactComponent as deleteIcon } from "assets/images/deleteIcon.svg";
import { dashboardServiceClient } from "graphql/client";
import Loader from "components/MDLoader";
import { SearchContext,FilterContext } from "context";
import { useContext ,useEffect} from 'react';
import { GET_BRAND_CATEGORY_STORE } from "services/brand-service";
import dayjs from 'dayjs';
import { REMOVE_BRAND_CATEGORY_STORE } from "services/brand-service";
import AgGrid from "components/MDGrid";
import { GET_CATEGORIES } from "services/product-category-service";

const user = localStorage.getItem("userDetails") && localStorage.getItem("userDetails") !== "undefined" ? JSON.parse(localStorage.getItem("userDetails")) : {};
const getCategoryBreadcrumb = (categoryId, categories) => {
  const category = categories?.find(cat => cat?.id === categoryId);
  if (!category) return '';

  const breadcrumbs = [];
  let currentCategory = category;

  while (currentCategory) {
    breadcrumbs.unshift(currentCategory.title);
    currentCategory = categories.find(cat => cat.id === currentCategory.parent?.id);
  }
  return breadcrumbs.join('/');
};

const columns = (handleDeleteClick,categories)=>[
  {
    field: "categoryId",
    headerName: "Category Name",
    width: 300,
    valueGetter: (params) => {
      const categoryId = params?.data?.category?.id || '';
      return getCategoryBreadcrumb(categoryId, categories);
    }, 
    pivot:true,
    enableValue:true
  },

  {
    field: "storeId",
    headerName: "Store",
    width: 200,
    valueGetter: (params) => params?.data?.store.name || '',
    enableValue:true
  },
  {
    field: "start",
    headerName: "Start Date",
    width: 300,
    valueGetter: (params) =>
    params?.data?.start
      ? dayjs(params?.data?.start).format("DD-MM-YYYY")
      : "N/A",
  },

  {
    field: "end",
    headerName: "End Date",
    width: 300,
    valueGetter: (params) =>
    params?.data?.end
      ? dayjs(params?.data?.end).format("DD-MM-YYYY")
      : "N/A",
  },
...( user && user.role === "admin" ? [{
    field: 'actions',
    headerName: 'Actions',
    width: 140,
    sortable: false,
    cellRenderer: (params) => (
        <IconButton aria-label="delete" >
            <Icon component={deleteIcon} onClick={(e) => handleDeleteClick(e , params?.data?.id)} fontSize="small" />
        </IconButton>
    )
}]:[])
];


function NodeLandingPage({ onRowClick,brandData,refetchData }) {
  const { searchKeyword,setSearchKeyword } = useContext(SearchContext);
  const [isLoading, setIsLoading] = React.useState(false);

  const { loading, error, data, refetch } = useQuery(GET_BRAND_CATEGORY_STORE, {
    client: dashboardServiceClient,
    variables: {
      take: 1000,
      skip: 0,
      search: searchKeyword,
      filter: { brand: { id: brandData?.id }, isDeleted: false },
    },
    fetchPolicy: 'network-only', // Ignore cache for this query
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
    },
  });
  const { loading: catLoading, error:catError, data:catData, refetch:catRefetch } = useQuery(GET_CATEGORIES, {
    client: dashboardServiceClient,
    variables: {
      listCategoryProductFilter: {
        take: 100000,
        skip: 0,
        search: "",
        orderby: { isDeleted: "ASC", updatedAt: "DESC" },
      },
      fetchPolicy: "network-only",
    },
  });
  const [removeNodeCategory, { loading: removing, error: removeError }] =
  useMutation(REMOVE_BRAND_CATEGORY_STORE, {
      client: dashboardServiceClient,
      refetchQueries: [
          {
              query: GET_BRAND_CATEGORY_STORE,
              variables: {
                take:20,
                skip:0,
                filter:{}
              },
              fetchPolicy: "network-only",
          },
      ],
  });
  const handleDeleteClick = async (e, catNodeId) => {
    e.stopPropagation();
    setIsLoading(true);
    try {
        await removeNodeCategory({
            variables: { id: catNodeId },
        });
        await refetch();
    } catch (error) {
        console.error("Failed to remove device:", error);
    } finally {
        setIsLoading(false);
    }
};

  useEffect(()=>{
    refetch()
  },[refetchData])
  if (loading || isLoading) 
    return (
      <>
        <Loader />
      </>
    );
  if (error || removeError) return <p>Error: {error.message}</p>;

  const nodeCategories = data?.brandCategoryStores?.results

  
  return (
        <AgGrid
            gridProps={{
              rowHeight:60,
              defaultColDef:{
                // pivot:true,
                enableRowGroup:true,
                enablePivot:true,
                floatingFilter: true,
                sortable: true,
                editable: false,
                filter: true,
                flex: 1,
                minWidth: 200,
              },
              pivotMode:false,
              sideBar:{
                toolPanels: ['columns', 'filters'], 
              }, 
              enableRangeSelection:true,
              onRowClicked: onRowClick, // Use onRowClicked event
            }}
            queryData={nodeCategories}
            columns={columns(handleDeleteClick, catData?.categoryProduct?.results)}
            />
  );
}

export default NodeLandingPage;
