import * as React from "react";

 export const  headerSalesReport = [
    { headerName: "Product ID", field: "productId" },
    { headerName: "Division", field: "div" },
    { headerName: "Section", field: "section" },
    { headerName: "Department", field: "department" },
    { headerName: "Node", field: "node" },
    { headerName: "Order Date", field: "orderDate" },
    { headerName: "Product Name", field: "productName" },
    { headerName: "Product Variant(Size)", field: "productVariant" },
    { headerName: "MRP Selling Price", field: "sellingPrice" },
    { headerName: "Amount Collected", field: "amountCollected" },
    { headerName: "Order Quantity", field: "orderQuantity" },
    { headerName: "Promotion", field: "promotions" },
    { headerName: "Coupon Rewards", field: "coupons" },
    { headerName: "Rewards", field: "rewards"},

  ];

  export const  headerStockReport = [
    { headerName: "Product ID", field: "productId" },
    { headerName: "Product Name", field: "productName" },
    { headerName: "Division", field: "div" },
    { headerName: "Section", field: "section" },
    { headerName: "Department", field: "department" },
    { headerName: "Node", field: "node" },
    { headerName: "Product Variant", field: "productVarient" },
    { headerName: "Available Quantity", field: "availableQuantity" },

  ];

  export const  headerGMVREPORT = [
    { headerName: "Date", field: "date" },
    { headerName: "Brand", field: "brandName" , enableValue:true},
    { headerName: "Sales By MRP", field: "salesByMrp", enableValue:true },
    { headerName: "Selling Price", field: "sellingPrice" },
    { headerName: "Promotion Discount(%)", field: "promotionDiscount" },
    { headerName: "Coupon Discount", field: "couponDiscount" },
    { headerName: "Reward-Burn", field: "rewardsBurn" },
    { headerName: "Amount Collected", field: "amountCollected" },
  ];

  export const  headerEventReport = [
    { headerName: "Event Name", field: "eventName" },
    { headerName: "Event Date", field: "eventDate" },
    { headerName: "Enent Time", field: "eventTime" },
    { headerName: "Ticket Type", field: "ticketType" },
    { headerName: "Category", field: "category" },
    { headerName: "Ticket Sold", field: "ticketsSold" },
    { headerName: "Ticket Amount", field: "ticketAmount" },
    { headerName: "Rewards Burn", field: "rewardsBurn" },
    { headerName: "No. of Participant", field: "numberOfParticipants" },
    { headerName: "Samples Distributed (if applicable)", field: "samplesDistributed" },

  ];

  export const  headerReturnAndRefund = [
    { headerName: "Product ID", field: "productId" },
    { headerName: "Sales Order ID", field: "salesOrderID" },
    { headerName: "Product Name", field: "productName" },
    { headerName: "Amount", field: "amount" },
    { headerName: "Refund Date", field: "refundDate" },
    { headerName: "Refund Status", field: "refundStatus" },
    { headerName: "Returned Reason", field: "returnReason" },

  ];