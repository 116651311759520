import { useQuery } from '@apollo/client';
import { Autocomplete, Grid, Box, TextField, Typography } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'; // or another adapter like AdapterDateFns
import { ArrowDropDownIcon } from '@mui/x-date-pickers';
import { dashboardServiceClient } from 'graphql/client';
import React, { useEffect, useState } from 'react';
import { BRANDS_LIST } from 'services/brand-service';
import { GET_STORES_LIST_NEW } from 'services/store-service';
import DashboardLayout from 'ui/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'ui/Navbars/DashboardNavbar';
import ReportTabs from './reportTabs';
import { GET_EVENT_REPORT } from 'services/report-service';
import { GET_SALES_REPORT } from "services/report-service";
import { GET_RETURN_AND_REFUND_REPORT } from "services/report-service";
import { GET_STOCK_REPORT } from "services/report-service";
import { GET_GMV_REPORT } from 'services/report-service';
import { LIST_BRAND_STORE } from 'services/brand-service';
import dayjs from 'dayjs';

function Reports() {
    const [selectedBrand, setSelectedBrand] = useState(null);
    const [selectedStore, setSelectedStore] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [tabValue, setTabValue] = useState(0)
    const [startDateError, setStartDateError] = useState(null);
    const [endDateError, setEndDateError] = useState(null);

    const user = localStorage.getItem("userDetails") && localStorage.getItem("userDetails") !== "undefined" ? JSON.parse(localStorage.getItem("userDetails")) : {};
    const { loading, data, error, refetch } = useQuery(BRANDS_LIST, {
        client: dashboardServiceClient,
        variables: {
            take:100,
            skip: 0,
            filter:
                user && user?.role === "admin"
                    ? {
                        isDeleted: false,
                    }
                    : {
                        id: user?.userId,
                    },
        },
    });
    const {
        loading: storeLoading,
        error: storeError,
        data: storeList,
      } = useQuery(LIST_BRAND_STORE, {
        client: dashboardServiceClient,
        variables: { listBrandStoreFilter: { take: 100, skip: 0,filter:{status: ["active", "upcoming"],brand:{id:selectedBrand}} } },
        onError: () => {
            setSelectedStore(null); // Clear store selection on error
        },
      });
      const brandStoreList = storeList?.brandStores?.results?.map(item => item?.store)

      useEffect(() => {
        if(user && user?.role !== "admin" ){
            setSelectedBrand(user?.userId);
        }
      },[user])
    const { loading: gmvloading, data: gmvData, error: gmvError, refetch: gmvRefetch } = useQuery(GET_GMV_REPORT, {
        client: dashboardServiceClient,
        variables: {
            filter: { skip: 0, limit: 10000, brand:selectedBrand, store:selectedStore ,dateRangeStart:startDate,dateRangeEnd:endDate }
        },
    });

    const { loading: salesloading, data: salesData, error: salesError, refetch: salesRefetch } = useQuery(GET_SALES_REPORT, {
        client: dashboardServiceClient,
        variables: {
            filter: { skip: 0, limit: 10000 , brand:selectedBrand, store:selectedStore ,minDateRangeStart:startDate,maxDateRangeEnd:endDate}
        },
    });

    const { loading: returnloading, data: returnData, error: returnError, refetch: returnRefetch } = useQuery(GET_RETURN_AND_REFUND_REPORT, {
        client: dashboardServiceClient,
        variables: {
            filter: { skip: 0, limit: 10000, brand:selectedBrand, store:selectedStore ,minDateRangeStart:startDate,maxDateRangeEnd:endDate }
        },
    });

    const { loading: stockloading, data: stockData, error: stockError, refetch: stockRefetch } = useQuery(GET_STOCK_REPORT, {
        client: dashboardServiceClient,
        variables: {
            filter: { skip: 0, limit: 10000 , brand:selectedBrand, store:selectedStore ,minDateRangeStart:startDate,maxDateRangeEnd:endDate}
        },
    });
    const { loading: eventloading, data: eventData, error: eventError, refetch: eventRefetch } = useQuery(GET_EVENT_REPORT, {
        client: dashboardServiceClient,
        variables: {
            filter: { skip: 0, limit: 10000, brand:selectedBrand, store:selectedStore ,dateRangeStart:startDate,dateRangeEnd:endDate }
        },
    });

    const gmvDataRows = gmvData?.getGmvReport?.data ?? [];
    const salesDataRows = salesData?.getSalesReport?.data ?? [];
    const returnRefundDataRows = returnData?.getReturnAndRefund?.data ?? [];
    const stockDataRows = stockData?.getStockReports?.getStockReports ?? [];
    const eventDataRows = eventData?.getEventReports?.data
    return (
        <DashboardLayout>
            <DashboardNavbar />

            <Grid container>
                <Grid item xs={12} display="flex" gap={2}>
                   {  user && user?.role === "admin" && <Grid item xs={2}>
                        <Autocomplete
                            labelId="demo-simple-select-label"
                            label="Select Brand"
                            id="demo-simple-select"
                            name="brand"
                            loading={loading}
                            iconComponent={() => (
                                <ArrowDropDownIcon style={{ marginRight: "18px" }} />
                            )}
                            options={data?.brandlist?.results
                                ?.map((item) => (
                                    { label: item?.name, value: item?.id }
                                )) || []}
                                onChange={(e, newValue) => {
                                    setSelectedBrand(newValue?.value || null);
                                    setSelectedStore(null);
                                }}
                                
                            sx={{ "& .MuiInputBase-root": { height: 40, borderRadius: "20px", px: 2, backgroundColor: "white.main" } }}
                            renderInput={(params) => <TextField {...params} label="Select Brand" />}
                        />
                    </Grid>}
                    <Grid item xs={2}>
                        <Autocomplete
                            key={selectedBrand} 
                            labelId="demo-simple-select-label"
                            label="Select Store"
                            id="demo-simple-select"
                            name="store"
                            loading={storeLoading}
                            disabled={!selectedBrand}
                            iconComponent={() => (
                                <ArrowDropDownIcon style={{ marginRight: "18px" }} />
                            )}
                            options={brandStoreList
                                ?.filter((item) => item?.status === "Active")
                                ?.map((item) => (
                                    { label: item.name, value: item.id }
                                ))}
                            onChange={(e, newValue) => {
                                setSelectedStore(newValue?.value)
                                if(!selectedBrand){
                                    setSelectedStore(null)
                                }
                            }}
                            sx={{ "& .MuiInputBase-root": { height: 40, borderRadius: "20px", px: 2, backgroundColor: "white.main" } }}
                            renderInput={(params) => <TextField {...params} label="Select Store" />}
                        />
                    </Grid>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Grid item xs={2}>
                            <DatePicker
                                label="Start Date"
                                value={startDate}
                                onChange={(newValue) => {
                                    const today = dayjs();
                                    if (newValue && newValue.isBefore(today, 'day')) {
                                        setStartDateError("Start date cannot be in the past.");
                                        // setStartDate(null);
                                    } else {
                                        setStartDateError(null);
                                        setStartDate(newValue);
                                    }
                                }}
                                minDate={dayjs()}
                                renderInput={(params) => (
                                    <TextField 
                                    {...params} 
                                    error={Boolean(startDateError)} 
                                    helperText={startDateError || ''} 
                                />
                                )}
                            />
                             {startDateError && (
                                <Typography variant="body2" color="error" sx={{ mt: 1 ,fontSize:"10px"}}>
                                    {startDateError}
                                </Typography>
                            )}
                        </Grid>
                        <Grid item xs={2}>
                            <DatePicker
                                label="End Date"
                                value={endDate}
                                minDate={startDate ? dayjs(startDate) : dayjs()}  
                                disabled={!startDate}
                                onChange={(newValue) => {
                                    if (newValue && startDate && newValue.isBefore(startDate, 'day')) {
                                        setEndDateError("End date cannot be before the start date.");
                                        // setEndDate(null);
                                    } else {
                                        setEndDateError(null);
                                        setEndDate(newValue);
                                    }
                                }}
                                renderInput={(params) => (
                                    <TextField 
                                    {...params} 
                                    error={Boolean(endDateError)} 
                                        helperText={endDateError || ''} 
                                    />
                                )}
                            />
                            {endDateError && (
                                <Typography variant="body2" color="error" sx={{ mt: 1 ,fontSize:"10px"}}>
                                    {endDateError}
                                </Typography>
                            )}
                        </Grid>
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={12} mt={2}>
                    <ReportTabs tabValue={tabValue} setTabValue={setTabValue}
                    gmvDataRows={gmvDataRows}
                    salesDataRows={salesDataRows}
                    returnRefundDataRows={returnRefundDataRows}
                    stockDataRows={stockDataRows}
                    eventDataRows={eventDataRows} />
                </Grid>
            </Grid>
        </DashboardLayout>
    )
}

export default Reports;
