

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
// import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DefaultNavbar from "ui/Navbars/DefaultNavbar";
import PageLayout from "ui/LayoutContainers/PageLayout";

// Authentication layout components
import Footer from "layouts/authentication/components/Footer";

function CoverLayout({ coverHeight, image, children }) {
  return (
    <PageLayout>
      <MDBox
        sx={{ height: "auto" }}
        display="flex"
        flexDirection="column"
        minHeight="100vh"
      >
        <MDBox
          position="absolute"
          width="100%"
          minHeight="100vh"
        >
          <MDBox
            position="relative"
            height="80vh"
            display="flex"
            flexDirection="column"
            width="100%"
            justifyContent="center"
            // paddingTop="4em"
            sx={{
              display: 'grid',
              gap: 1,
              gridTemplateColumns: 'repeat(2, 1fr)',
            }}
          >
            {(
              <MDBox display="flex" width="100%" justifyContent="center" height={"v100vh"} sx={{
                zIndex: "99",
                backgroundImage: `url(/images/SignInGraphic.png)`,
                backgroundSize: "80% 80%",
                backgroundPosition: "right",
                backgroundRepeat: "no-repeat",
              }}>
              </MDBox>
            )}
            <MDBox paddingBottom="3rem" sx={{
              textAlign: "center",
            }}>

              <MDBox px={1} width="100%" mx="auto">
                <Grid container spacing={1} alignItems="center" justifyContent="center" sx={{
                  height: "95vh"
                }} >
                  <Grid item xs={11} sm={11} md={11} lg={11} xl={8} >
                    {children}
                  </Grid>
                </Grid>
              </MDBox>
            </MDBox>
          </MDBox>
          <Footer light />
        </MDBox>
      </MDBox>
    </PageLayout>
  );
}

// Setting default props for the CoverLayout
CoverLayout.defaultProps = {
  coverHeight: "35vh",
};

// Typechecking props for the CoverLayout
CoverLayout.propTypes = {
  coverHeight: PropTypes.string,
  image: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default CoverLayout;
