import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PromotionForm from './promotionForm';
import MappingForm from './mappingForm';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function PromotionTabs({promotionData,handleClose, setSelectedPromotion, setTabValue, tabValue, setBrandChange, brandChange}) {

  const handleChange = (event, newValue, promotion) => {
    promotion && promotion !== undefined && setSelectedPromotion(promotion)
    setTabValue(newValue)
  };
  const user = localStorage.getItem("userDetails") && localStorage.getItem("userDetails") !== "undefined" ? JSON.parse(localStorage.getItem("userDetails")) : {};

  React.useEffect(() => {
  }, [brandChange])

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="General Information" {...a11yProps(0)} />
          <Tab label="Mapping Promotion" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={tabValue} index={0}>
        <PromotionForm promotionData={promotionData} handleClose={handleClose} handleTabChange={handleChange} user={user} setBrandChange={setBrandChange} setSelectedPromotion={setSelectedPromotion} />
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={1}>
        <MappingForm promotionData={promotionData} handleClose={handleClose} handleTabChange={handleChange} user={user} brandChange={brandChange} setBrandChange={setBrandChange}  />
      </CustomTabPanel>
    </Box>
  );
}