import React from "react";

// @mui material components
import { Grid, Box } from "@mui/material";

import DashboardLayout from "ui/LayoutContainers/DashboardLayout";
import DashboardNavbar from "ui/Navbars/DashboardNavbar";
import EcomSecTabs from "./ecomSecTabs";

function EcommerceSection() {

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container spacing={2}>
        <Box
          sx={{
            width: "100%",
            minHeight: "auto",
            backgroundColor: "#FFFFFF",
            borderRadius: "10px",
            mt: 4,
          }}
        >
          <EcomSecTabs />
        </Box>
      </Grid>
    </DashboardLayout>
  );
}

export default EcommerceSection;
