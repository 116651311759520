export const getFlatIdMap = (obj) => {
    const indexMap = {};

    function traverse(obj) {
        if (Array.isArray(obj)) {
            obj.forEach(item => traverse(item));
        } else if (typeof obj === 'object' && obj !== null) {
            if ('id' in obj) {
                indexMap[obj.id] = obj;
            }if ('uuid' in obj) {
                indexMap[obj.uuid] = obj;
            }if ('batchCode' in obj) {
                indexMap[obj.batchCode] = obj;
            }if ('code' in obj) {
                indexMap[obj.code] = obj;
            }
            
            Object.values(obj).forEach(value => traverse(value));
        }
    }

    traverse(obj);
    return indexMap;
}