import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { styled } from "@mui/material/styles";
import * as Yup from "yup";
import { Field, FieldArray, Form, Formik } from "formik";
import dayjs from "dayjs";
import { useNotification } from "context";
import { dashboardServiceClient } from "graphql/client";
import { useMutation } from "@apollo/client";
import { CREATE_BRAND_STORE } from "services/brand-service";
import { UPDATE_BRAND_STORE } from "services/brand-service";
import { LIST_BRAND_STORE } from "services/brand-service";
import { GET_BRAND_DETAILS } from "services/brand-service";

const validationSchema = Yup.object().shape({
  stores: Yup.array().of(
    Yup.object().shape({
      start: Yup.date()
        .required("Start date is required")
        .when("id", (id, yup) =>
          id
            ? yup
            : yup.min(
                dayjs().add(1, "day"),
                "Start date should be in the future"
              )
        ),
      end: Yup.date()
        .required("End date is required")
        .min(dayjs().add(1, "day"), "End date should be in the future")
        .when(
          "start",
          (start, yup) =>
            start && yup.min(start, "End date should be later than start date")
        ),
      storeName: Yup.string().required("Store name is required"),
    })
  ),
});

const PinkButton = styled(Button)({
  width: "100%",
  backgroundColor: "#f2aec5",
  color: "#344767",
  marginTop: "5%",
  "&:hover": {
    backgroundColor: "#344767",
  },
});

const useBrandMetadataMutation = (
  brandData,
  brandMetadata,
  setNotification,
  handleTabChange,
  setBrandData
) => {
  const mutationOptions = {
    client: dashboardServiceClient,
    refetchQueries: [
      {
        query: GET_BRAND_DETAILS,
        variables: { username: brandData.username || "" },
      },
    ],
  };

  const [createBrandStore] = useMutation(CREATE_BRAND_STORE, mutationOptions);
  const [updateBrandStore] = useMutation(UPDATE_BRAND_STORE, mutationOptions);

  const handleSubmit = (values) => {
    values.stores.forEach((store) => {
      const mutation = store && store.id ? updateBrandStore : createBrandStore;

      const mutationInput =
        store && store.id
          ? {
              updateBrandStoreInput: {
                id: store.id,
                brandId: brandData.id,
                storeId: store.storeName,
                start: store.start,
                end: store.end,
              },
            }
          : {
              createBrandStoreInput: {
                brandId: brandData.id,
                storeId: store.storeName,
                start: store.start,
                end: store.end,
              },
            };

      mutation({ variables: mutationInput })
        .then((res) => {
          setBrandData(brandData)
          handleTabChange(null, 4);
          setNotification({
            color: "success",
            isVisible: true,
            message:
              brandData && brandData.id
                ? "Brand store data updated successfully"
                : "Brand store data created successfully",
          });
        })
        .catch((error) => {
          console.error(" Error:", error.message);
          setNotification({
            color: "error",
            isVisible: true,
            message: error.message || "Something went wrong",
          });
        });
    });
  };

  return handleSubmit;
};
const user = localStorage.getItem("userDetails") && localStorage.getItem("userDetails") !== "undefined" ? JSON.parse(localStorage.getItem("userDetails")) : {};
const isAdmin = user && user?.role === "admin" ? true : false
const StoreCalender = ({ storeData, brandData, handleTabChange, setBrandData }) => {
  const isStoreCalendar =
    (brandData &&
      brandData.brandStore &&
      brandData.brandStore.length && [...brandData.brandStore]) ||
    null;
  const storeCalendar = isStoreCalendar ? brandData.brandStore : [];
  const { setNotification } = useNotification();
  const handleSubmit = useBrandMetadataMutation(
    brandData,
    isStoreCalendar,
    setNotification,
    handleTabChange,
    setBrandData
  );
  return (
    <Formik
      initialValues={{
        stores: isStoreCalendar
          ? [
              ...storeCalendar.map((item) => ({
                id: item.id,
                start: dayjs(item.start).format("YYYY-MM-DD"),
                end: dayjs(item.end).format("YYYY-MM-DD"),
                storeName: item.store.id,
              })),
            ]
          : [
              {
                start: dayjs(new Date()).add(1, "day"),
                end: dayjs(new Date()).add(2, "day"),
                storeName: "",
              },
            ],
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        console.log("Submitted values:", values);
        handleSubmit(values);
      }}
    >
      {(formik) => (
        <Form>
          <FieldArray name="stores">
            {({ push }) => (
              <Grid container spacing={2}>
                {formik.values.stores.map((_, index) => (
                  <Grid container spacing={2} key={index}>
                    <Grid item xs={12}>
                      <Typography variant="h6">Store {index + 1}</Typography>
                    </Grid>

                    <Grid item xs={6}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={["DatePicker"]}>
                          <DatePicker
                            disabled={!isAdmin}
                            label="Start Date"
                            name={`stores[${index}].start`}
                            value={
                              formik.values.stores[index].start
                                ? dayjs(formik.values.stores[index].start)
                                : null
                            }
                            onChange={(newValue) =>
                              formik.setFieldValue(
                                `stores[${index}].start`,
                                dayjs(newValue).format("YYYY-MM-DD")
                              )
                            }
                            minDate={
                              formik.values.stores[index].id
                                ? undefined // Allow any date for existing entries
                                : dayjs().add(1, "day") // Restrict to future dates for new entries
                            }
                            renderInput={(params) => (
                              <Field
                                as={TextField}
                                {...params}
                                fullWidth
                                error={
                                  formik.touched.stores?.[index]?.start &&
                                  Boolean(formik.errors.stores?.[index]?.start)
                                }
                                helperText={
                                  formik.touched.stores?.[index]?.start &&
                                  formik.errors.stores?.[index]?.start
                                }
                              />
                            )}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </Grid>

                    <Grid item xs={6}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={["DatePicker"]}>
                          <DatePicker
                            disabled={!isAdmin}
                            label="End Date"
                            name={`stores[${index}].end`}
                            minDate={
                              formik.values.stores[index].start
                                ? dayjs(formik.values.stores[index].start).add(
                                    1,
                                    "day"
                                  )
                                : dayjs().add(1, "day")
                            }
                            value={
                              formik.values.stores[index].end
                                ? dayjs(formik.values.stores[index].end)
                                : null
                            }
                            onChange={(newValue) =>
                              formik.setFieldValue(
                                `stores[${index}].end`,
                                dayjs(newValue).format("YYYY-MM-DD")
                              )
                            }
                            renderInput={(params) => (
                              <Field
                                as={TextField}
                                {...params}
                                fullWidth
                                error={
                                  formik.touched.stores?.[index]?.end &&
                                  Boolean(formik.errors.stores?.[index]?.end)
                                }
                                helperText={
                                  formik.touched.stores?.[index]?.end &&
                                  formik.errors.stores?.[index]?.end
                                }
                              />
                            )}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </Grid>

                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <InputLabel id={`select-label-${index}`}>
                          Store Name
                        </InputLabel>
                        <Field
                            disabled={!isAdmin}
                            as={Select}
                          labelId={`select-label-${index}`}
                          id={`select-${index}`}
                          name={`stores[${index}].storeName`}
                          label="Select Option"
                          error={
                            formik.touched.stores?.[index]?.storeName &&
                            Boolean(formik.errors.stores?.[index]?.storeName)
                          }
                          helperText={
                            formik.touched.stores?.[index]?.storeName &&
                            formik.errors.stores?.[index]?.storeName
                          }
                          style={{
                            padding: "12px",
                            input: { padding: "12px" },
                          }}
                        >
                          {storeData.stores.results.map((store) => (
                            <MenuItem value={store.id}>{store.name}</MenuItem>
                          ))}
                        </Field>
                        { formik.touched.stores?.[index]?.storeName &&
                            formik.errors.stores?.[index]?.storeName ? 
                            <Typography fontSize={12} color="error">
                              {formik.errors.stores?.[index]?.storeName}
                            </Typography>: null}
                        
                      </FormControl>
                    </Grid>
                  </Grid>
                ))}
               {isAdmin === true && (
                <>
                <Grid item xs={12}>
                  <PinkButton
                    onClick={() =>
                      push({
                        start: dayjs(new Date()).add(1, "day"),
                        end: dayjs(new Date()).add(2, "day"),
                        storeName: "",
                      })
                    }
                  >
                    + ADD ANOTHER
                  </PinkButton>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    style={{
                      marginTop: "24px",
                      marginRight: "10px",
                      backgroundColor: "#344767",
                      color: "#fff",
                      borderRadius: "100px",
                    }}
                  >
                    Save
                  </Button>
                </Grid></>
               )}

              </Grid>
            )}
          </FieldArray>
        </Form>
      )}
    </Formik>
  );
};

export default StoreCalender;
