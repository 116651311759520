import { useLazyQuery, useQuery } from '@apollo/client';
import { Chip, Grid } from '@mui/material';
import AgGrid from 'components/MDGrid';
import { reportServiceClient } from 'graphql/client';
import { dashboardServiceClient } from 'graphql/client';
import React,{useContext} from 'react';
import { GET_LOYALITY_ACTIVITY } from 'services/loyality-service';
import { DateFiltersContext } from './index';
const columnDefs = [
    { field: "activityType", headerName: "Activity type", width: 130, },
    { field: "totalActivites", headerName: "Total Activities", width: 130, },
    {
        headerName: "Earned Rewards",
        children: [
            {
                headerName: "Total Customer",
                field: "totalCustomers",
                width: 150,
                valueGetter:(params)=> params?.data?.rewardsEarned?.totalCustomers
            },
            {
                headerName: "Total Rewards",
                field: "totalRewardPoints",
                width: 150,
                valueGetter:(params)=> params?.data?.rewardsEarned?.totalRewardPoints
            }
        ]
    },
    {
        headerName: "Burned Rewards",
        children: [
            {
                headerName: "Total Customers",
                field: "totalCustomers",
                width: 150,
                valueGetter:(params)=> params?.data?.rewardsBurned?.totalCustomers
            },
            {
                headerName: "Total Rewards",
                field: "totalRewardPoints",
                width: 150,
                valueGetter:(params)=> params?.data?.rewardsBurned?.totalRewardPoints
            }
        ]
    }
];

function ActivitiesTab({onRowClick}) {
    const { startDate, endDate ,setActivityData} = useContext(DateFiltersContext);

    const { data, loading, error } = useQuery(GET_LOYALITY_ACTIVITY,
        {
            client: reportServiceClient,
            variables: { filter: { startDate: startDate, endDate: endDate } },
            fetchPolicy: "network-only",
            notifyOnNetworkStatusChange: true,
            onCompleted: (data) => {
                setActivityData(data?.getLoyaltyActivity)
            },
            onError: (error) => {
                console.error('Error fetching data', error);
            }

        },
    )
    return (
        <Grid>
              <AgGrid
                gridProps={{
                    rowHeight: 60,
                    defaultColDef: {
                        editable: false,
                        filter: true,
                        flex: 1,
                        minWidth: 200,
                    },
                    pivotMode: false,
                    sideBar: {
                        toolPanels: ['columns', 'filters'],
                    },
                    enableRangeSelection: true,
                    onRowClicked: onRowClick, // Use onRowClicked event
                }}
                queryData={data?.getLoyaltyActivity || []}
                columns={columnDefs}
            />
        </Grid>
    );
}

export default ActivitiesTab;
