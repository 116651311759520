import React, { useState } from "react";
import { Formik, Form, Field } from 'formik';
import { Grid, Box, Icon, Button, Drawer, Typography } from "@mui/material";
// import StarIcon from '@mui/icons-material/Star';
import { Add as AddIcon } from "@mui/icons-material";
import MDButton from "components/MDButton";
import AssetMapListingPage from "./assetMapListingPage";
import AssetMappingForm from "./assetMappingForm";

function AssetMapping({brandData}) {
  const [open, setOpen] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState(null);

  const handleRowClick = (params) => {
    setSelectedAsset(params.data);
    handleOpen();
  };
  const ControlSection = ({ handleOpen, setCategoryData ,user}) => (
    user && user?.role === "admin" ? (<>
      <Grid item xs={12} sm={6} md={6} />
      <Grid item xs={12} sm={6} md={6} mb={2} textAlign="right">
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <MDButton
            type="button"
            variant="contained"
            color="black"
            sx={{
                    fontFamily: "Montserrat",
                    fontSize: "15px",
                    fontWeight: 400,
                    marginBottom:2
                  }}
            circular={true} 
            onClick={() => {
              setOpen(true);
              setSelectedAsset(null);
            }}
            startIcon={<AddIcon />}
          >
            Assign New Assets
          </MDButton>
        </Box>
      </Grid>
    </>) : <></>
   
  );
  const AssetDrawer = ({ open, handleClose, selectedAsset }) => (
    <Drawer
      anchor="right"
      open={open}
      onClose={handleClose}
      PaperProps={{ sx: { width: "50%" } }}
    >
      <Grid
        container
        sx={{ backgroundColor: "#FFFFFF", borderBottom:1, borderColor:'#FAFAFA', pb: 3, pt: 2, pl: "5px" }}
      >
        <Grid item xs={12}>
          &nbsp;
        </Grid>
        <Grid item xs={12} sx={{display: 'flex', alignItems:'center', mx:2}}>
          <Icon
            sx={{ }}
            fontSize="large"
          >star</Icon>
          <Typography variant="h5" fontFamily={'Montserrat'} fontSize={24} sx={{ ml: 2 }}>
         { selectedAsset && selectedAsset?.uuid ? "Update Assets Mapping":"Assign New Assets"}
          </Typography>
        </Grid>
      </Grid>
      <Box sx={{ width: "100%", minHeight: "70vh" }}>
      <AssetMappingForm assetData={selectedAsset} handleClose={handleClose} brandData={brandData} />
      </Box>
    </Drawer>
  );
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const user = localStorage.getItem("userDetails") && localStorage.getItem("userDetails") !== "undefined" ? JSON.parse(localStorage.getItem("userDetails")) : {};

  return (
      <>
      <Grid container display="flex">
      <Grid item xs={6}>
        <Typography> Fixtures </Typography>
      </Grid>
      <Grid item xs={6} display="flex" >
      <ControlSection handleOpen={handleOpen} user={user} />
      </Grid>
      </Grid>
        <AssetMapListingPage onRowClick={handleRowClick} brandData={brandData} user={user}/>
        { user && user.role === "admin" &&<AssetDrawer
          open={open}
          handleClose={handleClose}
          selectedAsset={selectedAsset}
        />}
      </>
  );
}

export default AssetMapping;
