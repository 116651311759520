//==========================================%%%%%%%%%%%%%%%%%%%%%%===========================================

import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import * as XLSX from "xlsx";
import {
  Grid,
  Box,
  Icon,
  MenuItem,
  Select,
  Drawer,
  Button,
  Card,
  Chip,
  CardActions,
  CardContent,
  FormControl,
  IconButton,
  Pagination,
  TextField,
  Typography,
  Tabs,
  Tab,
} from "@mui/material";
import { useQuery, useMutation, gql } from "@apollo/client";
import * as Yup from "yup";
import { GET_ATTRIBUTE_LIST } from "services/master-attribute-service";
import Loader from "components/MDLoader";
import { SearchContext } from "context";
import { ReactComponent as previewIcon } from "assets/images/previewIcon.svg";
import { ReactComponent as commentIcon } from "assets/images/commentIcon.svg";
import { ReactComponent as downloadIcon } from "assets/images/downloadIcon.svg";
import { ReactComponent as deleteIcon } from "assets/images/deleteIcon.svg";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { Add as AddIcon, Star, Store as StoreIcon } from "@mui/icons-material";
import MDButton from "components/MDButton";
import { GET_BRANDS_LIST } from "services/brand-service";
import { dashboardServiceClient } from "graphql/client";
import { UPDATE_BRAND } from "services/brand-service";
import dayjs from "dayjs";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { FilterContext } from "context";
import MDConfirm from "components/MDConfirm";


const formatDate = (dateString) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // months are 0-based
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};
const CREATE_BRAND_MUTATION = gql`
  mutation CreateBrand($input: CreateBrandInput!) {
    createBrand(createBrandInput: $input) {
      id
      username
    }
  }
`;


const validationSchema = Yup.object({
  companyName: Yup.string().required("Company name is required"),
  emailId: Yup.string()
    .required("Email Id is required")
    .email("Please enter valid email address"),
  password: Yup.string(),
});

const EditBrandDrawer = ({ open, handleClose, currentRow }) => {
  const [newBrandData, setNewBrandData] = useState(null);
  const [createBrand] = useMutation(CREATE_BRAND_MUTATION);

  const initialValues = {
    companyName: currentRow?.brandMetaData?.find(meta => meta.key === "CompanyDetails")?.value?.companyName || "",
    emailId: currentRow?.username || "",
    password: currentRow?.password || "",
    customerId: currentRow?.customerId || "",
    vendorId: currentRow?.vendorId || "",
  };

  const handleSubmit = async (values) => {
    console.log("Submitted values: ", values);
    setNewBrandData(values);
    // Perform mutation or any other operation here
    await createBrand({ variables: { input: values } });
    handleClose();
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formik) => {
        return (
          <Drawer
            anchor="right"
            open={open}
            onClose={handleClose}
            PaperProps={{ sx: { width: "50%" } }}
          >
            <Form onSubmit={formik.handleSubmit}>
              <Grid container sx={{ pb: 3, pt: 2, pl: "5px" }}>
                <Grid item xs={12}>
                  &nbsp;
                </Grid>
                <Grid item>
                  <Icon sx={{ ml: 2 }} component={Star} fontSize="large" />
                </Grid>
                <Grid item>
                  <p
                    style={{
                      fontFamily: "Montserrat",
                      fontSize: "20px",
                      fontWeight: "700",
                      marginLeft: "10px",
                    }}
                  >
                    Edit Brand
                  </p>
                </Grid>
              </Grid>
              <Box sx={{ width: "100%", minHeight: "70vh" }}>
                <Grid container spacing={3}>
                  <Grid item xs={11} mx="auto">
                    <Field
                      name="companyName"
                      as={TextField}
                      label="Company Name"
                      fullWidth
                      value={formik.values.companyName}
                      onChange={formik.handleChange}
                      error={formik.touched.companyName && !!formik.errors.companyName}
                    />
                    <ErrorMessage name="companyName" component="div" className="error" />
                  </Grid>
                  <Grid item xs={11} mx="auto">
                    <Field
                      name="emailId"
                      as={TextField}
                      label="Email Id"
                      fullWidth
                      value={formik.values.emailId}
                      onChange={formik.handleChange}
                      error={formik.touched.emailId && !!formik.errors.emailId}
                    />
                    <ErrorMessage name="emailId" component="div" className="error" />
                  </Grid>
                  <Grid item xs={11} mx="auto">
                    <Field
                      name="password"
                      as={TextField}
                      label="Password"
                      type="password"
                      fullWidth
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      error={formik.touched.password && !!formik.errors.password}
                    />
                    <ErrorMessage name="password" component="div" className="error" />
                  </Grid>
                  <Grid item xs={11} mx="auto">
                    <Field
                      name="customerId"
                      as={TextField}
                      label="Customer Id"
                      fullWidth
                      value={formik.values.customerId}
                      onChange={formik.handleChange}
                      error={formik.touched.customerId && !!formik.errors.customerId}
                    />
                    <ErrorMessage name="customerId" component="div" className="error" />
                  </Grid>
                  <Grid item xs={11} mx="auto">
                    <Field
                      name="vendorId"
                      as={TextField}
                      label="Vendor Id"
                      fullWidth
                      value={formik.values.vendorId}
                      onChange={formik.handleChange}
                      error={formik.touched.vendorId && !!formik.errors.vendorId}
                    />
                    <ErrorMessage name="vendorId" component="div" className="error" />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  &nbsp;
                </Grid>
                <Grid item xs={12}>
                  &nbsp;
                </Grid>
                <Grid
                  container
                  direction="row-reverse"
                  justifyContent="flex-start"
                  alignItems="center"
                  gap={3}
                  xs={11}
                  mx="auto"
                >
                  <Button
                    type="submit"
                    variant="contained"
                    style={{
                      backgroundColor: "#000000",
                      color: "#fff",
                      borderRadius: "50px",
                    }}
                  >
                    Create
                  </Button>
                  <MDButton
                    variant="outlined"
                    color="black"
                    sx={{ borderRadius: "50px", fontFamily: "Montserrat" }}
                    onClick={handleClose}
                  >
                    Cancel
                  </MDButton>
                </Grid>
              </Box>
            </Form>
          </Drawer>
        );
      }}
    </Formik>
  );
};



function BrandListingPage({ onRowClick, setShowBrandForm, setBrandData }) {
  const [selectedStatus, setSelectedStatus] = useState("live");
  const handleTabChange = (event, newValue) => {
    setSelectedStatus(newValue);
  };
  const columns = [
    {
      field:"name",
      headerName: "Company Name",
      width: 250,
    //   valueGetter:(params)=>{
    //   return  params.row.brandMetaData?.find(meta => meta.key === "CompanyDetails")?.value?.companyName  
    //  }
    },
    {
      field:"usp",
      headerName: "Brand Name",
      width: 250,
    },
    {
      field: "vendorId",
      headerName: "Vendor ID",
      width: 250,
    },
    {
      field: "customerId",
      headerName: "Customer ID",
      width: 250,
    },
    {
      field: "username",
      headerName: "Email ID",
      width: 250,
    },
    // {
    //   field: "createdAt",
    //   headerName: "Creation Date",
    //   width: 180,
    //   valueGetter: (params) => {
    //     //   params.value
    //     // ? dayjs(params.value).format("YYYY-MM-DD HH:mm:ss")
    //     // : "N/A"},
    //     return params.value ? dayjs(params.value).format("YYYY-MM-DD HH:mm:ss") : "N/A"
    //   },
    // },
    
    {
      field: "brandStatus",
      headerName: "Status",
      width: 200,
      valueGetter: (params) => {
        if (params.row.brandStatus === "Live") {
          return "live"
        }
        else if (params.row.brandStatus === "New") {
          return "new"
        }
        else if (params.row.brandStatus === "Pending") {
          return "pending"
        }
        else if (params.row.brandStatus === "Rejected") {
          return "rejected"
        }
      },
      renderCell: (params) => (
        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <Select
            value={params?.value}
            onChange={(event) =>
              handleStatusChange(params.row.id, event.target.value)
            }
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
          >
            <MenuItem key="live" value="live">Live</MenuItem>
            <MenuItem key="new" value="new">New</MenuItem>
            <MenuItem key="pending" value="pending">Pending</MenuItem>
            <MenuItem key="rejected" value="rejected">Rejected</MenuItem>
          </Select>
        </FormControl>
      ),
    },
    {
      field: "actions",
      sortable: false,
      headerName: "Actions",
      width: 180,
      renderCell: (params) => (
        <Grid item xs={2}>
          <Box>
            <IconButton
              aria-label="edit"
              onClick={(e) => {
                e.stopPropagation();
                setShowBrandForm(true)
                const brandClick = bData?.brandlist?.results?.find(brand => brand.id === params.row.id) || {}
                setBrandData(brandClick)
              }}
            >
              <Icon component={previewIcon} fontSize="small" />
            </IconButton>
            <IconButton
              aria-label="download"
              onClick={(event) => {
                handleDownload(params.row);
                event.stopPropagation();
              }}
            >
              <Icon component={downloadIcon} fontSize="small" />
            </IconButton>
            <MDConfirm
              handleAccept={()=>handleDelete(params.row.id)}
              title="Delete Brand"
              description={`Are you sure you want to delete "${params.row.name}"`}
            >
            {(showDialog) => (
              <IconButton
                aria-label="delete"
                onClick={(e) => {
                  e.stopPropagation();
                  showDialog();
                }}
              >
                <Icon component={deleteIcon} fontSize="small" />
              </IconButton>
            )}
            </MDConfirm>
          </Box>
        </Grid>
      ),
    },
  ];
  const { searchKeyword } = useContext(SearchContext);
  const [rows, setRows] = useState([]);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 20,
  });
  const [edit, setEdit] = useState(false);
  const [currentRow, setCurrentRow] = useState({});
  const { selectedBrands } = useContext(FilterContext);

  const { loading, error, data: bData, refetch } = useQuery(GET_BRANDS_LIST, {
    client: dashboardServiceClient,
    variables: {
      take: paginationModel.pageSize,
      skip: paginationModel.page * paginationModel.pageSize,
      search: searchKeyword,
      filter: selectedBrands ? { id: selectedBrands, isDeleted:false,brandStatus:selectedStatus } : {isDeleted: false,brandStatus: selectedStatus },
      orderby: { brandStatus: "DESC", name: "ASC" }
    },
    fetchPolicy: 'network-only', // Ignore cache for this query
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setRowCount(data?.brandlist?.total);
    },
  });
  const [rowCount, setRowCount] = useState(bData?.brandlist?.total || 0);

  useEffect(() => {
    setRowCount(
      bData?.brandlist?.total !== undefined ? bData?.brandlist?.total : rowCount
    );
  }, [bData?.brandlist?.total, rowCount]);

  const [updateBrand] = useMutation(UPDATE_BRAND, {
    client: dashboardServiceClient,
    refetchQueries: [
      {
        query: GET_BRANDS_LIST,
        variables: {
          take: paginationModel.pageSize,
          skip: paginationModel.page * paginationModel.pageSize,
          search: searchKeyword,
          filter: selectedBrands ? { id: selectedBrands, isDeleted:false,brandStatus:selectedStatus } : {isDeleted: false,brandStatus: selectedStatus },
          orderby: { brandStatus: "DESC", name: "ASC" }
        }
      }
    ]
  })
  const handleDelete = (id) => {
    updateBrand({
      variables: {
        input:
        {
          id: id,
          isDeleted: true
        }
      },
      onCompleted: () => {
        console.log(" Deleted successfully");
        refetch();
      },
      onError: (error) => {
        console.error("Error in Delete ", error);
      },
    })
  }
  const handleStatusChange = (id, newStatus) => {
    updateBrand({
      variables: {
        input: {
          id: id,
          brandStatus: newStatus
        }
      },
      onCompleted: () => {
        console.log(" Updated successfully");
        refetch();
      },
      onError: (error) => {
        console.error("Error in Update ", error);
      },
    })
  };

  const handleDownload = (brandData) => {
    const pdfBlob = generatePdf(brandData);
    const excelBlob = generateExcel(brandData);
    generateZip(brandData,pdfBlob, excelBlob);
  }

  const getTables = (brandData) => [
    {
      title:'COMPANY INFORMATION',
      key:'CompanyDetails',
      rows:[
        {key:'companyName',valueGetter:()=>brandData.name, label:'Company name', useValue:true},
        {key:'address1',label:'Address 1'},
        {key:'address2',label:'Address 2'},
        {key:'city',label:'City'},
        {key:'state',label:'State'},
        {key:'pincode',label:'Pin code'},
        {key:'serviceProvided',label:'Service provided'},
        {key:'companyType',label:'Type of company(Properitor/ Pvt ltd/ public ltd/ Others)', useValue: true, valueGetter: (companyType)=>{
          switch (companyType) {
            case 'llp': return "Limited liability Partnership (LLP)"
            case 'proprietorship': return "Proprietorship";
            case 'partnership': return "Partnership";
            case 'publiclimited': return "Public Limited";
            case 'privatelimited': return "Private Limited";
            default:
              break;
          }
        }},
      ]
    },
    {
      title:'BUSINESS CONTACT',
      key:'ContactDetails',
      rows:[
        {key:'nameSurnameBusiness',label:'Contact person'},
        {key:'phoneNoBusiness',label:'Phone'},
        {key:'mobileNoBusiness',label:'Mobile'},
        {key:'emailBusiness',label:'Email id'},
      ]
    },
    {
      title:'ACCOUNT CONTACT',
      key:'ContactDetails',
      rows:[
        {key:'nameSurnameAccount',label:'Contact person'},
        {key:'phoneNoAccount',label:'Phone'},
        {key:'mobileNoAccount',label:'Mobile'},
        {key:'emailAccount',label:'Email id'},
      ]
    },
    {
      title:'TAX DETAILS',
      key:'CompanyDetails',
      rows:[
        {key:'panCardNo',label:'PAN card no'},
        {key:'gstNo',label:'GST no'},
        {key:'tinNo',label:'TIN no'},
        {key:'tdsSection',label:'TDS Deductible'},
      ]
    },
    {
      title:'BANK DETAILS',
      key:'BankDetails',
      rows:[
        {key:'bankName',label:'Bank name'},
        {key:'bankAccountNo',label:'Bank account no'},
        {key:'bankNEFTCode',label:'Bank NEFT code'},
        {key:'bankAddress',label:'Bank Address'},
        {key:'branch',label:'Branch'},
        {key:'city',label:'Bank City'},
        {key:'state',label:'State'},
        {key:'bankAccountType',label:'Bank Account type (Savings or Current)'},
        {key:'micrNo',label:'MICR No'},
      ]
    }
  ]

  const generatePdf = (brandData) => {
    const doc = new jsPDF();
    
    // Add title
    doc.setFontSize(18);
    const titleText = 'Vendor Registration Form';
    const titleWidth = doc.getStringUnitWidth(titleText) * doc.internal.getFontSize() / doc.internal.scaleFactor;
    const titleX = (doc.internal.pageSize.width) / 2;
    doc.setFont('Montserrat', 'bold').text(titleText, titleX, 20, { align: 'center' }).setFont('Montserrat', 'normal');

    const pageWidth = doc.internal.pageSize.width;
    const lineStartX = 15;
    const lineEndX = pageWidth;
  
    // Generate 10 tables
    let startY = 25; // Initial startY position
    const tables = getTables(brandData);
    for (let i = 0; i < tables.length; i++) {
      // console.log(table)
        startY += 5; // Gap between tables
  
        // Prepare table data with random number of rows
        const tableRows = [];
        const tableData = brandData.brandMetaData?.find(bmd=>bmd.key==tables[i].key)?.value;
        if(tableData==null) continue;
        for (let j = 0; j < tables[i].rows.length; j++) {
            tableRows.push([
                tables[i].rows[j].label,
                tables[i].rows[j].useValue? tables[i].rows[j].valueGetter(tableData[tables[i].rows[j].key]) : tableData[tables[i].rows[j].key]
            ]);
        }
  
        // Add table
        doc.setFontSize(12);
        const tableTitle = tables[i].title;
        const titleWidth = doc.getStringUnitWidth(tableTitle) * doc.internal.getFontSize() / doc.internal.scaleFactor;
        const titleX = 15; // Start of the table
        const cellPadding = 4;
        const fontSize = 10;
        doc.setFont('Montserrat', 'bold').text(tableTitle, titleX+cellPadding-1, startY + 5).setFont('Montserrat', 'normal');
        doc.setFontSize(10);
        const table = doc.autoTable({
            startY: startY + 6, // Add additional gap between title and tables
            body: tableRows,
            theme: 'plain', // No borders or background
            columnStyles: {
                0: { cellWidth: 35, cellPadding, fontSize }, // Width for first column
                1: { cellWidth: 'auto', cellPadding, fontSize, halign: 'left', fillColor: [255, 255, 255], textColor: [0, 0, 0]} // Padding and border for second column
            },
            didDrawCell: function (data) {
              if (data.column.index === 1) { // Only apply to the second column
                  const cell = data.cell;
                  const padding = 1; // Padding inside the rectangle
                  const x = cell.x;
                  const y = cell.y + padding*2;
                  const width = cell.width;
                  const height = cell.height - padding * 4;

                  doc.setDrawColor(250, 250, 250);
                  doc.roundedRect(x, y, width, height, 1.5, 1.5,'S'); // Draw rounded rectangle
              }
          }
            // margin: { top:5 }, // Adjust margins
            
            
        });

        // Update startY position for the next table
        if(i<tables.length - 1){
          const lineY = doc.lastAutoTable.finalY + 5;
          doc.setDrawColor(240, 240, 240);
          doc.setLineWidth(0.001);
          doc.line(lineStartX, lineY, lineEndX, lineY);
          startY = table.lastAutoTable.finalY+2;
        }
    }

    // Save the PDF
    // doc.save('vendor_details.pdf');
    // doc.output('dataurlnewwindow');
    return doc.output('blob');
  };
  const generateExcel = (brandData) => {
    const worksheetData = [];
    const merges = [];
    const tables = getTables(brandData);
    worksheetData.push(['VENDOR REGISTRATION FORM', '']);
    merges.push({ s: { r: 0, c: 0 }, e: { r: 0, c: 1 } });
    worksheetData.push(['','']);
    merges.push({ s: { r: 1, c: 0 }, e: { r: 1, c: 1 } });
    for (let i = 0; i < tables.length; i++) {
      const table = tables[i];
      const startRow = worksheetData.length;

      // Add table title
      worksheetData.push([table.title, '']);
      merges.push({ s: { r: startRow, c: 0 }, e: { r: startRow, c: 1 } });

      const tableData = brandData.brandMetaData?.find(bmd => bmd.key === table.key)?.value;
      if (!tableData) continue;

      for (let j = 0; j < table.rows.length; j++) {
        worksheetData.push([
          table.rows[j].label,
          table.rows[j].useValue ? table.rows[j].valueGetter(tableData[table.rows[j].key]) : tableData[table.rows[j].key]
        ]);
      }

      // Add an empty row after each table for spacing
      worksheetData.push([]);
    }

    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

    // Apply merges
    if (!worksheet['!merges']) {
      worksheet['!merges'] = [];
    }
    worksheet['!merges'] = worksheet['!merges'].concat(merges);

    // Apply styles
    for (const merge of merges) {
      const cell = worksheet[XLSX.utils.encode_cell({ r: merge.s.r, c: merge.s.c })];
      if (cell) {
        cell.s = {
          font: { bold: true },
          alignment: { horizontal: "center" }
        };
      }

      // Apply style to the second cell in the merged range as well
      const secondCell = worksheet[XLSX.utils.encode_cell({ r: merge.s.r, c: merge.s.c + 1 })];
      if (secondCell) {
        secondCell.s = {
          font: { bold: true },
          alignment: { horizontal: "center" }
        };
      }
    }
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Brand Details');

    // Write the workbook to file
    // XLSX.writeFile(workbook, 'BrandDetails.xlsx');
    return XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
  }
  const extractFileType = (url) => {
    // Regular expression to match the file extension
    const fileTypeRegex = /\.([a-zA-Z0-9]+)(?=\?|$)/;
    
    // Apply the regex to the URL
    const match = url.match(fileTypeRegex);
    
    // Extract and return the file type
    if (match && match.length > 1) {
        return match[1];
    } else {
        return null; // Return null if no match is found
    }
  };
  const generateZip = async (brandData, pdfBlob, excelBlob) => {
    const zip = new JSZip();
    // Add PDF to zip
    zip.file(`${brandData.name}.pdf`, pdfBlob);
    zip.file(`${brandData.name}.xlsx`, excelBlob);

    const artifacts = [
      {name:'Pan card',urlKey:'panCardUrl',metadataKey:'CompanyDetails'},
      {name:'GST',urlKey:'gstUrl',metadataKey:'CompanyDetails'},
      {name:'MSME Certificate',urlKey:'msmeCertificateUrl',metadataKey:'CompanyDetails'},
      {name:'TIN',urlKey:'tinUrl',metadataKey:'CompanyDetails'},
      {name:'Address Proof',urlKey:'addressProofUrl',metadataKey:'CompanyDetails'},
      {name:'Cancelled Cheque',urlKey:'cancelledChequeUrl',metadataKey:'BankDetails'},
      
    ];

    for(let idx in artifacts){
      const url = brandData.brandMetaData?.find(bmd=>bmd.key==artifacts[idx].metadataKey)?.value[artifacts[idx].urlKey];
      if(!url) continue;
      try {
        // Fetch the file
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error(`Failed to fetch file: ${response.statusText}`);
        }

        // Convert response to ArrayBuffer
        const fileData = await response.arrayBuffer();

        // Determine file extension
        const fileType = extractFileType(url);
        if(fileType==null) continue;
        // Add file to zip
        zip.file(`${artifacts[idx].name}.${fileType}`, fileData,{ type: 'binary' });

        console.log('Zip file created and downloaded successfully!');
    } catch (error) {
        console.error('Error zipping file:', error);
    }

    }
  
    // Add other files to zip
  
    // Generate the zip file and trigger download
    zip.generateAsync({ type: 'blob' }).then((content) => {
      saveAs(content, `${brandData.name}.zip`);
    });
  };

  // useEffect(() => {
  //   const attributes = bData?.brandlist?.results.map((result) => {
  //     const metadata = result?.brandMetaData[0]?.value || {};
  //     return {
  //       id: result.id,
  //       vendorId: metadata.vendorId || "",
  //       customerId: result.id,
  //       emailId:
  //         metadata.founderEmail ||
  //         metadata.operationsEmail ||
  //         metadata.accountsEmail ||
  //         "",
  //       status: metadata.isActive ? "active" : "inactive",
  //       creationDate: new Date().toISOString(),
  //     };
  //   });
  //   setRows(attributes);
  // }, [bData?.brandlist?.results]);



  if (loading) return <Loader />
  if (error) return <p>Error: {error.message}</p>;
  const brands = bData?.brandlist?.results.filter((brand) => !brand.isDeleted)
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <>
      <Tabs value={selectedStatus} onChange={handleTabChange}>
        <Tab label="Live" value={"live"} />
        <Tab label="New" value={"new"} />
        <Tab label="Pending" value={"pending"} />
        <Tab label="Rejected" value={"rejected"} />
        {/* Add more tabs as needed */}
      </Tabs>
      <DataGrid
        autoHeight
        rows={brands}
        columns={columns}
        rowCount={rowCount}
        pageSizeOptions={[10, 20, 50, 100]}
        getRowId={(row) => row.id}
        onRowClick={onRowClick}
        loading={loading}
        onPageChange={handleChangePage}
        paginationModel={paginationModel}
        paginationMode="server"
        onPaginationModelChange={setPaginationModel}
      />
      <EditBrandDrawer
        open={edit}
        currentRow={currentRow}
        handleClose={() => {
          setEdit(false);
          // setCreateNew(false);
        }}
      />
    </>
  );
}

export default BrandListingPage;
