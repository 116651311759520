import React, { useState } from "react";
import { Grid, Box, Icon, Button, Drawer, Typography } from "@mui/material";
// import StarIcon from '@mui/icons-material/Star';
// import { ReactComponent as StarIcon } from "assets/images/StarIcon.svg";
import { Add as AddIcon } from "@mui/icons-material";
import MDButton from "components/MDButton";
import NodeLandingPage from "./nodLanndingPage";
import NodeCategoryForm from "./nodeCategoryForm";

function NodeAgreement({brandData,tabValue,setTabValue}) {
  const [open, setOpen] = useState(false);
  const [selectedNode, setSelectedNode] = useState(null);
  const [refetchData, setRefetchData] = useState(false);
  const handleRowClick = (params) => {
    setSelectedNode(params.data);
    handleOpen();
  };
  const ControlSection = ({ handleOpen, setCategoryData ,user}) => (
    user && user?.role === "admin" ? (<>
      <Grid item xs={12} sm={6} md={6} />
      <Grid item xs={12} sm={6} md={6} mb={2} textAlign="right">
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <MDButton
            type="button"
            variant="contained"
            color="black"
            sx={{
                    fontFamily: "Montserrat",
                    fontSize: "15px",
                    fontWeight: 400,
                    marginBottom:2,
                  }}
            circular={true} 
            onClick={() => {
              setOpen(true);
              setSelectedNode(null);
            }}
            startIcon={<AddIcon />}
          >
            Assign Categories
          </MDButton>
        </Box>
      </Grid>
    </>) : <></>
   
  );
  const CustomDrawer = ({ open, handleClose, selectedNode,onFormSubmit }) => (
    <Drawer
      anchor="right"
      open={open}
      onClose={handleClose}
      PaperProps={{ sx: { width: "50%" } }}
    >
      <Grid
        container
        sx={{ backgroundColor: "#FFFFFF", borderBottom:1, borderColor:'#FAFAFA', pb: 3, pt: 2, pl: "5px" }}
      >
        <Grid item xs={12}>
          &nbsp;
        </Grid>
        <Grid item xs={12} sx={{display: 'flex', alignItems:'center', mx:2}}>
          <Icon
            fontSize="large"
          >star</Icon>
          <Typography variant="h5" fontFamily={'Montserrat'} fontSize={24} sx={{ ml: 2 }}>
            Assign New Category
          </Typography>
        </Grid>
      </Grid>
      <Box sx={{ width: "100%", minHeight: "70vh" }}>
      <NodeCategoryForm categoryData={selectedNode} handleClose={handleClose} brandData={brandData} onFormSubmit={onFormSubmit} />
      </Box>
    </Drawer>
  );
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const user = localStorage.getItem("userDetails") && localStorage.getItem("userDetails") !== "undefined" ? JSON.parse(localStorage.getItem("userDetails")) : {};
  const handleRefetch = () => {
    setRefetchData((prev) => !prev); 
  };
  return (
      <>
      <Grid>
      <Grid container display="flex">
      <Grid item xs={6}>
        <Typography> Category</Typography>
      </Grid>
      <Grid item xs={6} display="flex" >
      <ControlSection handleOpen={handleOpen} user={user} />
      </Grid>
      </Grid>
      <Grid>
      <NodeLandingPage onRowClick={handleRowClick} brandData={brandData} refetchData={refetchData} />
        { user && user.role === "admin" && <CustomDrawer
          open={open}
          handleClose={handleClose}
          selectedNode={selectedNode}
          onFormSubmit={handleRefetch} 
                  />}
      </Grid>
      </Grid>
      <Grid m={2} sx={{display:"flex",justifyContent:"end"}}>
        <MDButton
          type="submit"
          variant="contained"
          circular={true}
          color="black"
          sx={{fontFamily:"Montserrat"}}
          onClick={() => {
            setTabValue(6);
          }}
        >
          Next
        </MDButton>
      </Grid>
      </>
  );
}

export default NodeAgreement;
