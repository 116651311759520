import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import Chip from "@mui/material/Chip";
import { Grid, Typography } from "@mui/material";
import { useQuery } from "@apollo/client";
import { GET_TRANSACTION_ORDER } from "services/order-service";
import { orderServiceClient } from "graphql/client";
import Loader from "components/MDLoader";
import { SearchContext, FilterContext } from "context";
import { useContext } from 'react';
import AgGrid from "components/MDGrid";
import MDButton from "components/MDButton";
import { Add as AddIcon } from "@mui/icons-material";
import * as XLSX from "xlsx";
import { GET_ORDER_DETAILS } from "services/order-service";
import dayjs from "dayjs";


const columns = [
  { 
    field: "skuId",
    headerName: "SKU Id", 
    width: 130, 
  },
 {
  field: "image",
  headerName: "Image",
  cellRenderer: params => {
    const value = params.value;
    return <img
      src={value}
      alt="Image"
      style={{width:'50px', height:'50px', objectFit:'contain',border:'1px solid #ddd',borderRadius:'5px',boxShadow:'0px 0px 5px 0px #ddd'}}
      onError={(e) => { e.target.onerror = null; e.target.src = 'https://via.placeholder.com/50'; }}
    />
    
  },
},
  { 
    field: "productName", 
    headerName: "Product Name", 
    width: 130, 
  },
  { 
    field: "brandName", 
    headerName: "Brand Name", 
    width: 130, 
  },
  { 
    field: "paidPrice", 
    headerName: "Paid Price", 
    width: 130, 
  },
  { 
    field: "mrp", 
    headerName: "MRP", 
    width: 130, 
  },
  { 
    field: "sellingPrice", 
    headerName: "Selling Price", 
    width: 130, 
  },
  { 
    field: "genysisArticleid", 
    headerName: "Genisys Article ID", 
    width: 200, 
  },
  { 
    field: "dOneItemPromotion", 
    headerName: "D1 - Item Promotion", 
    width: 200, 
  },
  { 
    field: "dTwoCartPromotion", 
    headerName: "D2 - Cart Promotion", 
    width: 200, 
  },
  { 
    field: "dThreeCouponDiscount", 
    headerName: "D3 - Coupon Discount", 
    width: 200, 
  },
  { 
    field: "dFourLoyaltyDiscount", 
    headerName: "D4 - Loyality Discount", 
    width: 200, 
  },
  { 
    field: "storeName", 
    headerName: "Store Name", 
    width: 200, 
  },
  {
    field: "status",
    headerName: "Status",
    width: 130,
    cellRenderer: (params) => (
      <Chip
      sx={{
        backgroundColor: params?.data?.status === "CREATED" ? "#8feba8" : "#ebaba9",
        color: params?.data?.status === "CREATED" ? "green" : "red"
      }}
      color={params?.data?.status === 'CREATED' ? 'success' : 'error'}
      label={params?.data?.status}
      variant="contained"
    />
    ),
    // TODO will need to confirm waht can be status for chip colour
  },
  { 
    field: "deliveryDate",
    headerName: "Delivery Date", 
    width: 200, 
    valueGetter: (params) => params?.data?.deliveryDate ? dayjs(params?.data?.deliveryDate).format("DD-MM-YYYY") : "N/A",
  }
];

function OrderDetailLandingPage({ selectedOrder }) {
  const { searchKeyword } = useContext(SearchContext);

  const { loading, error, data, refetch } = useQuery(GET_ORDER_DETAILS, {
    client: orderServiceClient,
    variables: {filter:{orderId:selectedOrder,limit:10000,skip:0}},
    fetchPolicy: 'network-only', // Ignore cache for this query
    notifyOnNetworkStatusChange: true
  });


  if (loading) return <Loader />;
  if (error) return <p>Error: {error.message}</p>;

  const ordersDetailsData = data?.PaginatedOrderDetails?.items

  return (
    <Grid container spacing={1} mt={2} justifyContent={"space-between"}>
      <Typography sx={{ fontFamily: "montserrat", color: "#000", fontWeight: "700" }} ml={1}>Order Details</Typography>
      <Grid item xs={12} style={{ backgroundColor: "white" }}>
        <div className="ag-theme-alpine" style={{ height: '90vh', width: '100%' }}>
          <AgGrid
                     gridProps={{
                      rowHeight:60,
                      defaultColDef:{
                        editable: false,
                        filter: true,
                        flex: 1,
                        minWidth: 200,
                      },
                      pivotMode:false,
                      sideBar:{
                        toolPanels: ['columns', 'filters'], 
                      }, 
                      enableRangeSelection:true,
                    }}
            queryData={ordersDetailsData || []}
            columns={columns}
          />
        </div>
      </Grid>
    </Grid>
  );
}

export default OrderDetailLandingPage;
