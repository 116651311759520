import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import SectionListing from "./sectionListing";
import MDButton from "components/MDButton";
import { Add as AddIcon } from "@mui/icons-material";
import { Drawer, Grid, Icon } from "@mui/material";
import { List as ListIcon } from "@mui/icons-material";
import SectionForm from "./sectionForm";
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const EcommerceMediaSection = [
  {
    name: "Home",
    component: SectionListing,
    page: "welcom",
  },
  {
    name: "Brand Landing",
    component: SectionListing,
    page: "brandLanding",
  },
  {
    name: "Brand",
    component: SectionListing,
    page: "brandDetail",
  },
  {
    name: "PDP",
    component: SectionListing,
    page: "pdpPage",
  },
  {
    name: "Event",
    component: SectionListing,
    page: "eventListing",
  },
  {
    name: "Rewards",
    component: SectionListing,
    page: "rewardListing",
  },
];

export default function EcomSecTabs() {
  const [value, setValue] = React.useState(0);
  const [showCreateSectionForm, setShowCreateSectionForm] =
    React.useState(false);
  const [key, setKey] = React.useState(Date.now());
  const [selectedSection, setSelectedSection] = React.useState(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleEdit = (section) => {
    setSelectedSection(section);
    setShowCreateSectionForm(true);
  };

  React.useEffect(() => {
    if (!showCreateSectionForm) {
      setSelectedSection(null);
    }
  }, [showCreateSectionForm]);

  const SectionDrawer = ({ open, handleClose, callback = null }) => (
    <Drawer
      anchor="right"
      open={open}
      onClose={handleClose}
      PaperProps={{ sx: { width: "50%" } }}
    >
      <Grid
        container
        sx={{ backgroundColor: "#F0F2F5", pb: 3, pt: 2, pl: "5px" }}
      >
        <Grid item xs={12}>
          &nbsp;
        </Grid>
        <Grid item xs={12}>
          <Icon
            sx={{ mr: 2, ml: 2 }}
            component={ListIcon} // Use MonitorIcon here
            fontSize="large"
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" sx={{ ml: 2 }}>
            Create {EcommerceMediaSection[value].name} Section
          </Typography>
        </Grid>
      </Grid>
      <Box sx={{ width: "100%", minHeight: "70vh" }}>
        <SectionForm
          selectedSection={selectedSection}
          handleClose={() => setShowCreateSectionForm(false)}
          callback={() => {
            setShowCreateSectionForm(false);
            setKey(Date.now());
          }}
          page={EcommerceMediaSection[value].page}
        />
        {/* <DeviceForm deviceData={selectedDevice} handleClose={handleClose} callback={callback} /> */}
      </Box>
    </Drawer>
  );

  return (
    <Box key={key} sx={{ width: "100%" }}>
      <SectionDrawer
        open={showCreateSectionForm}
        handleClose={() => setShowCreateSectionForm(false)}
      />
      <MDButton
        type="button"
        variant="contained"
        color="black"
        sx={{
          fontFamily: "Montserrat",
          fontSize: "15px",
          fontWeight: 400,
          marginLeft: "auto",
          marginBottom: "10px",
          float: "right",
        }}
        circular={true}
        startIcon={<AddIcon />}
        onClick={() => {
          setShowCreateSectionForm(true);
        }}
      >
        Create {EcommerceMediaSection[value].name} Section
      </MDButton>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          {EcommerceMediaSection.map((tab, index) => (
            <Tab
              key={tab.name}
              label={`${tab.name} Page`}
              {...a11yProps(index)}
            />
          ))}
        </Tabs>
      </Box>
      {EcommerceMediaSection.map((tab, index) => (
        <>
          <CustomTabPanel key={tab.name} value={value} index={index}>
            <tab.component {...tab} handleEdit={handleEdit} />
          </CustomTabPanel>
        </>
      ))}
    </Box>
  );
}
