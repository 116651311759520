import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import Chip from "@mui/material/Chip";
import { Grid } from "@mui/material";
// import { mockData } from "./mockData";
import { useQuery } from "@apollo/client";
import dayjs from 'dayjs';
import { GET_MASTER_ACTIVITY_LIST } from "services/master-activity-service";
import { dashboardServiceClient } from "graphql/client";
import Loader from "components/MDLoader";
import { SearchContext, FilterContext } from "context";
import { useContext } from "react";

const columns = [
  {
    field: "id",
    headerName: "Master ID",
    width: 230,
    valueGetter: (params) => params?.row?.id.slice(-8),
  },
  {
    field: "name",
    headerName: "Name",
    width: 250,
  },
 
  {
    field: "createdAt",
    headerName: "Created At",
    width: 330,
    valueGetter: (params) =>
      params.row.createdAt
        ? dayjs(params.row.createdAt).format("DD-MM-YYYY HH:mm a")
        : "N/A",
  },
  {
    field: "updatedAt",
    headerName: "Updated At",
    width: 330,
    valueGetter: (params) =>
      params.row.updatedAt
        ? dayjs(params.row.updatedAt).format("DD-MM-YYYY HH:mm a")
        : "N/A",
  },
];

function MasterActivityLandingPage({ onRowClick }) {
  const { searchKeyword, setSearchKeyword } = useContext(SearchContext);
  const { selectedBrands } = useContext(FilterContext);
  const user =
    localStorage.getItem("userDetails") &&
    localStorage.getItem("userDetails") !== "undefined"
      ? JSON.parse(localStorage.getItem("userDetails"))
      : {};
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 20,
  });

  const { loading, error, data, refetch } = useQuery(GET_MASTER_ACTIVITY_LIST, {
    client: dashboardServiceClient,
    variables: {
      take: paginationModel.pageSize,
      skip: paginationModel.page * paginationModel.pageSize,
      search: searchKeyword,
      // filter: selectedBrands ? { brandId: selectedBrands } : {},
      filter:
        user && user?.role === "admin"
          ? selectedBrands
            ? { brandId: selectedBrands }
            : {}
          : { brandId: user?.userId },
    },
    fetchPolicy: "network-only", // Ignore cache for this query
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setRowCount(data?.masterActivity?.total);
    },
  });
  const [rowCount, setRowCount] = React.useState(data?.masterActivity?.total || 0);

  React.useEffect(() => {
    setRowCount((prevRowCount) =>
      data?.masterActivity?.total !== undefined ? data?.masterActivity?.total : prevRowCount
    );
  }, [data?.masterActivity?.total, setRowCount]);
  if (loading)
    return (
      <>
        <Loader />
      </>
    );
  if (error) return <p>Error: {error.message}</p>;

  const masterActivities = data.masterActivity.results;
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  return (
    <Grid container spacing={1} mt={2}>
      <Grid item xs={12} style={{ backgroundColor: "white" }}>
        <DataGrid
          autoHeight
          rows={masterActivities}
          columns={columns}
          rowCount={rowCount}
          pageSizeOptions={[10, 20, 50, 100]}
          getRowId={(row) => row.id}
          onRowClick={onRowClick}
          loading={loading}
          onPageChange={handleChangePage}
          paginationModel={paginationModel}
          paginationMode="server"
          onPaginationModelChange={setPaginationModel}
        />
      </Grid>
    </Grid>
  );
}

export default MasterActivityLandingPage;
