import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import Chip from "@mui/material/Chip";
import { Grid, Modal, Box, Typography } from "@mui/material";
import { useQuery } from "@apollo/client";
import dayjs from "dayjs";
import { GET_ACTIVITY_LIST } from "services/activity-service";
import { dashboardServiceClient } from "graphql/client";
import Loader from "components/MDLoader";
import { SearchContext, FilterContext } from "context";
import { useContext } from "react";
import IconButton from "@mui/material/IconButton";
import ImageSearchIcon from "@mui/icons-material/ImageSearch";
import ImageCarousel from "./imagePreview"; // Make sure to import ImageCarousel

const ImagePreviewModal = ({ images, open, handleClose }) => {
  const formattedImages = images
    ? images.map((image, index) => ({
        imgPath: image?.publicurl,
        label: `Image ${index}`,
        index: index,
      }))
    : [];

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 600,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 2,
          overflowY: "auto",
          maxHeight: "90%",
          borderRadius:"10px"
        }}
      >
        {images && images.length ? (
          <ImageCarousel images={formattedImages} />
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Typography variant="subtitle1">No images to preview</Typography>
          </Box>
        )}
      </Box>
    </Modal>
  );
};

function ActivityLandingPage({ onRowClick }) {
  const columns = [
    {
      field: "id",
      headerName: "Activity ID",
      width: 130,
      valueGetter: (params) => params?.row?.id.slice(-8),
    },
    {
      field: "activity",
      headerName: "Current Activity",
      width: 130,
      valueGetter: (params) => params?.row?.activity.isDeleted == false ? params?.row?.activity?.name : '',
    },
    {
      field: "store",
      headerName: "Store",
      width: 130,
      valueGetter: (params) => params?.row?.store?.name,
    },
    {
      field: "brand",
      headerName: "Brand",
      width: 130,
      valueGetter: (params) => params?.row?.brand?.name,
    },
    {
      field: "imagePreview",
      headerName: "Image",
      width: 100,
      renderCell: (params) => (
        <IconButton
          onClick={(event) => {
            handleImagePreview(params.row, event);
          }}
          color="primary"
        >
          <ImageSearchIcon />
        </IconButton>
      ),
      sortable: false,
      filterable: false,
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      valueGetter: (params) => {
        return (
          params.row.status.charAt(0).toUpperCase() + params.row.status.slice(1)
        );
      },
    },

    {
      field: "createdAt",
      headerName: "Created At",
      width: 200,
      valueGetter: (params) =>
        params.row.createdAt
          ? dayjs(params.row.createdAt).format("DD-MM-YYYY HH:mm a")
          : "N/A",
    },
    {
      field: "updatedAt",
      headerName: "Updated At",
      width: 200,
      valueGetter: (params) =>
        params.row.updatedAt
          ? dayjs(params.row.updatedAt).format("DD-MM-YYYY HH:mm a")
          : "N/A",
    },
  ];
  const { searchKeyword, setSearchKeyword } = useContext(SearchContext);
  const { selectedBrands } = useContext(FilterContext);

  const [selectedImages, setSelectedImages] = React.useState([]);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const handleImagePreview = (row, event) => {
    event.stopPropagation();
    setSelectedImages(row.images);
    setIsModalOpen(true);
  };
  const user =
    localStorage.getItem("userDetails") &&
    localStorage.getItem("userDetails") !== "undefined"
      ? JSON.parse(localStorage.getItem("userDetails"))
      : {};
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 20,
  });

  const { loading, error, data, refetch } = useQuery(GET_ACTIVITY_LIST, {
    client: dashboardServiceClient,
    variables: {
      listBrandSpaceReadinessFilter: {
        take: paginationModel.pageSize,
        skip: paginationModel.page * paginationModel.pageSize,
        search: searchKeyword,
        filter:
          user && user?.role === "admin"
            ? selectedBrands
              ? { brand: { id: selectedBrands } }
              : {}
            : { brand: { id: user?.userId } },
      },
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setRowCount(data?.brandSpaceReadiness?.total);
    },
  });
  const [rowCount, setRowCount] = React.useState(
    data?.brandSpaceReadiness?.total || 0
  );

  React.useEffect(() => {
    setRowCount((prevRowCount) =>
      data?.brandSpaceReadiness?.total !== undefined
        ? data?.brandSpaceReadiness?.total
        : prevRowCount
    );
  }, [data?.brandSpaceReadiness?.total, setRowCount]);
  if (loading)
    return (
      <>
        <Loader />
      </>
    );
  if (error) return <p>Error: {error.message}</p>;

  const activities = data.brandSpaceReadiness.results;
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  return (
    <Grid container spacing={1} mt={2}>
      <Grid item xs={12} style={{ backgroundColor: "white" }}>
        <DataGrid
          autoHeight
          rows={activities}
          columns={columns}
          rowCount={rowCount}
          pageSizeOptions={[10, 20, 50, 100]}
          getRowId={(row) => row.id}
          onRowClick={onRowClick}
          loading={loading}
          onPageChange={handleChangePage}
          paginationModel={paginationModel}
          paginationMode="server"
          onPaginationModelChange={setPaginationModel}
        />
      </Grid>
      <ImagePreviewModal
        images={selectedImages}
        open={isModalOpen}
        handleClose={() => setIsModalOpen(false)}
      />
    </Grid>
  );
}

export default ActivityLandingPage;
