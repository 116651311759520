import { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { GET_STORES_LIST_NEW } from 'services/store-service';
import { dashboardServiceClient } from 'graphql/client';
import { getFlatIdMap } from 'layouts/device-Master/utils';

export const useStoreSelection = ({
    storeId = '',
    brandId = '',

} = {}) => {
    const [storeSelection, setStoreSelection] = useState({
        selectedStore: null,
        brands:[],
        selectedBrand: null,
        // locations: [],
        // selectedLocation: null,
        // storeIdMap: null,
    });

    const {
        loading: storeLoading,
        error: storeError,
        data: storeList,
    } = useQuery(GET_STORES_LIST_NEW, {
        client: dashboardServiceClient,
        variables: { input: { take: 100, skip: 0 } },
    });

    useEffect(() => {
        if (storeList?.stores?.results?.length > 0) {
            // const map = getFlatIdMap(storeList.stores.results);
            setStoreSelection((prevState) => ({
                ...prevState,
                selectedStore: map[storeId] || null,
                selectedLocation: map[locationId] || null,
            }));
        }
    }, [storeList]);

    // useEffect(() => {
    //     if (storeSelection.storeIdMap) {
    //         setStoreSelection((prevState) => ({
    //             ...prevState,
    //             selectedStore: storeSelection.storeIdMap[storeId] || null,
    //         }));
    //     }
    // }, [storeId, storeSelection.storeIdMap]);

    // useEffect(() => {
    //     if (storeSelection.storeIdMap) {
    //         setStoreSelection((prevState) => ({
    //             ...prevState,
    //             selectedBrand: storeSelection.storeIdMap[locationId] || null,
    //         }));
    //     }
    // }, [brandId, storeSelection.storeIdMap]);

    

    

    useEffect(() => {
        if (storeSelection.selectedStore && storeSelection.selectedStore.storeLocation) {
            setStoreSelection((prevState) => ({
                ...prevState,
                locations: storeSelection.selectedStore.storeLocation,
            }));
        } else {
            setStoreSelection((prevState) => ({
                ...prevState,
                locations: [],
            }));
        }
    }, [storeSelection.selectedStore]);


    const setSelectedStore = (store) => {
        setStoreSelection((prevState) => ({
            ...prevState,
            selectedStore: store,
            selectedBrand: null,
        }));
    };

    const setSelectedBrand = (brand) => {
        setStoreSelection((prevState) => ({
            ...prevState,
            selectedBrand: brand,
        }));
    };

    const loading = storeLoading || !storeSelection.storeIdMap;

    return {
        ...storeSelection,
        setSelectedStore,
        setSelectedLocation,
        storeLoading,
        storeError,
        storeList,
        loading,
    };
};

export default useStoreSelection;