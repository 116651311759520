import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import BrandContactDetailsFormComponent from "./ContactDetailsForm";
import CompanyForm from "./companyForm";
import { useQuery } from "@apollo/client";
import { GET_STORES } from "services/store-service";
import { dashboardServiceClient } from "graphql/client";
import Loader from "components/MDLoader";
import BankDetailsForm from "./BankDetailsForm";
import StoreCalender from "./BrandStoreCalender";
import DiscoveryForm from "./discoveryForm";
import StorDataForm from "./storeDataForm";
import BrandMarginComponent from "./brandMargin";
import NodeAgreement from "./Node Agreement/nodeAgreement";
import AssetMapping from "./Asset Mapping/assetMapping";
import CompanyVerificationForm from "./companyVerificationForm";
import StoreAgreement from "./Store Agreement";
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
const PinkButton = styled(Button)({
  width: "100%",
  backgroundColor: "#f2aec5",
  color: "#344767",
  marginTop: "5%",
  "&:hover": {
    backgroundColor: "#344767",
    color: "white",
  },
});
CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs({
  brandData,
  setBrandData,
  tabValue,
  setTabValue,
  user,
}) {
  const { loading, error, data: storeData } = useQuery(GET_STORES, {
    client: dashboardServiceClient,
    variables: { listStoreFilter: { take: 100, skip: 0 } },
  });

  if (loading) return <Loader />
      
  if (error) return <p>Error: {error.message}</p>;

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Box sx={{}}>
      <Box sx={{}}>
        <Tabs value={tabValue} onChange={handleChange} aria-label="ant example">
          <Tab label="Company Details" {...a11yProps(0)} />
          <Tab label="Company Verification" {...a11yProps(1)} />
          <Tab label="Contact Details" {...a11yProps(2)} />
          <Tab label="Bank Details" {...a11yProps(3)} />
          <Tab label="Store Agreement" {...a11yProps(4)} />
          <Tab label="Node Assigning" {...a11yProps(4)} />
          <Tab label="Asset Mapping" {...a11yProps(5)} />
          {/* <Tab label="Discovery" {...a11yProps(2)} />
          <Tab label="Store Calendar" {...a11yProps(3)} />
          <Tab label="Store Data" {...a11yProps(4)} />
          <Tab label="Sampling" {...a11yProps(5)} /> */}
        </Tabs>
      </Box>
      <CustomTabPanel value={tabValue} index={0}>
        <CompanyForm
          handleTabChange={handleChange}
          brandData={brandData}
          setBrandData={setBrandData}
          user={user}
        />
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={1}>
        <CompanyVerificationForm
          handleTabChange={handleChange}
          brandData={brandData}
          setBrandData={setBrandData}
          user={user}
        />
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={2}>
        <Box sx={{ width: "100%", minHeight: "auto", padding: "0px" }}>
          <BrandContactDetailsFormComponent
            handleTabChange={handleChange}
            brandData={brandData}
            setBrandData={setBrandData}
            tabValue={tabValue}
          />
        </Box>
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={3}>
        <Box sx={{ width: "100%", minHeight: "auto", padding: "0px" }}>
          <BankDetailsForm
            handleTabChange={handleChange}
            brandData={brandData}
            setBrandData={setBrandData}
            tabValue={tabValue}
          />
        </Box>
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={4}>
        <Box sx={{ width: "100%", minHeight: "auto", padding: "0px" }}>
          <StoreAgreement
            handleTabChanage={handleChange}
            brandData={brandData}
            setBrandData={setBrandData}
            tabValue={tabValue}
            setTabValue={setTabValue}
          />
        </Box>
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={5}>
        <Box sx={{ width: "100%", minHeight: "auto", padding: "0px" }}>
          <NodeAgreement
            handleTabChanage={handleChange}
            brandData={brandData}
            setBrandData={setBrandData}
            tabValue={tabValue}
            setTabValue={setTabValue}
          />
        </Box>
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={6}>
        <Box sx={{ width: "100%", minHeight: "auto", padding: "0px" }}>
          <AssetMapping
            handleTabChanage={handleChange}
            brandData={brandData}
            setBrandData={setBrandData}
            tabValue={tabValue}
          />
        </Box>
      </CustomTabPanel>
          {/* <BrandMarginComponent
            handleTabChange={handleChange}
            brandData={brandData}
            setBrandData={setBrandData}
            tabValue={tabValue}
          /> */}
      {/* <CustomTabPanel value={tabValue} index={2}>
        <DiscoveryForm
          handleTabChange={handleChange}
          brandData={brandData}
          listData={[]}
          setBrandData={setBrandData}
        />
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={3}>
        <StoreCalender
          storeData={storeData}
          handleTabChange={handleChange}
          brandData={brandData}
          setBrandData={setBrandData}
        />
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={4}>
        <StorDataForm
          storeData={storeData}
          handleTabChange={handleChange}
          brandData={brandData}
          setBrandData={setBrandData}
        />
      </CustomTabPanel> */}
    </Box>
  );
}
