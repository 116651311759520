import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import Chip from "@mui/material/Chip";
import { Grid, Pagination as MuiPagination, Box, TablePagination, IconButton, Icon, FormControl, Select, MenuItem } from '@mui/material';
import dayjs from 'dayjs';
import { useMutation, useQuery } from "@apollo/client";
import { LIST_ALL_DEVICE, LIST_ALL_DEVICE_BULK_UPLOAD, REMOVE_DEVICE_BULK_UPLOAD, UPDATE_DEVICE_BULK_UPLOAD } from '../../services/advertisment-service';
import { vmServiceClient } from 'graphql/client';
import Loader from "components/MDLoader";
import { SearchContext, FilterContext } from "context";
import { useContext, useEffect } from 'react';
import { ReactComponent as deleteIcon } from "assets/images/deleteIcon.svg";
import { ReactComponent as ArrowDropDownIcon } from "assets/images/Arrow-Down.svg";
import { useNotification } from "context";






function BulkDevice({ onRowClick }) {
    const columns = [
        // {
        //     field: "id",
        //     headerName: "ID",
        //     width: 250,
        // },
        {
            field: "filename",
            headerName: "File Name",
            width: 300,
        },
        {
            field: "createdAt",
            headerName: "Created At",
            width: 300,
            valueGetter: (params) =>
                params.row.createdAt
                    ? dayjs(params.row.createdAt).format("DD-MM-YYYY HH:mm a")
                    : "N/A",
        },
        {
            field: "updatedAt",
            headerName: "Updated At",
            width: 300,
            valueGetter: (params) =>
                params.row.updatedAt
                    ? dayjs(params.row.updatedAt).format("DD-MM-YYYY HH:mm a")
                    : "N/A",
        },
        
        {
          field: "status",
          headerName: "Status",
          width: 300,
          renderCell: (params) => (
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <Select
                value={params?.value}
                onChange={(event) =>
                  handleStatusChange(params?.row, event.target.value)
                }
                displayEmpty
                IconComponent={() => (
                  <ArrowDropDownIcon style={{ marginRight: "18px" }} />
                )}
                sx={{
                  height:"40px"
                }}
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value="APPROVED">Approved</MenuItem>
                <MenuItem value="PENDING">Pending</MenuItem>
                <MenuItem value="REJECTED">Rejected</MenuItem>
              </Select>
            </FormControl>
          ),
        },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 300,
            sortable: false,
            renderCell: (params) => (
                <IconButton onClick={(e) => handleDeleteClick(e , params.row.uuid)} aria-label="delete" >
                    <Icon component={deleteIcon}  fontSize="small" />
                </IconButton>
            )
        },
    ];
    
    const { searchKeyword, setSearchKeyword } = useContext(SearchContext);
    const [paginationModel, setPaginationModel] = React.useState({
        page: 0,
        pageSize: 20,
    });
    const { setNotification } = useNotification();

    const { loading, error, data, refetch } = useQuery(LIST_ALL_DEVICE_BULK_UPLOAD, {
        client: vmServiceClient,
        variables: {
            "page": paginationModel.page+1,
            "limit": paginationModel.pageSize,
            "sortBy": "updatedAt",
            "order": "DESC",
            "relations": []
        },
        fetchPolicy: "network-only",
        notifyOnNetworkStatusChange: true,
        // onCompleted: (data) => {
        //     setRowCount(data?.findAllDevices?.total);
        // },
    });

    const [removeDeviceBulkUpload, { loading: removing, error: removeError }] =
    useMutation(REMOVE_DEVICE_BULK_UPLOAD, {
        client: vmServiceClient,
        refetchQueries: [
            {
                query: LIST_ALL_DEVICE_BULK_UPLOAD,
                variables: {
                    page: paginationModel.page + 1,
                    limit: paginationModel.pageSize,
                    "sortBy": "updateAt",
                    "order": "DESC",
                },
                fetchPolicy: "network-only",
            },
        ],
    });
    const [changeStatusBulkDeviceUpload] = useMutation(
        UPDATE_DEVICE_BULK_UPLOAD, {
            client: vmServiceClient,
            refetchQueries: [
                {
                    query: LIST_ALL_DEVICE_BULK_UPLOAD,
                    variables: {
                        page: paginationModel.page + 1,
                        limit: paginationModel.pageSize,
                        "sortBy": "updateAt",
                        "order": "DESC",
                    },
                    fetchPolicy: "network-only",
                },
            ],
        }
    )

    const handleDeleteClick = async (e, deviceId) => {
        e.stopPropagation();
        try {
            await removeDeviceBulkUpload({
                variables: { id: deviceId },
            });
            await refetch();
        } catch (error) {
            console.error("Failed to remove device:", error);
        }
    };

    const handleStatusChange = async (device, statusValue) => {
        try {
            await changeStatusBulkDeviceUpload({
                variables: {
                    input: {
                        uuid:device?.uuid,
                        // storeId:device.storeId,
                        // locationId:device.locationId,
                        // subLocationId:device.subLocationId,
                        // subLocationTypeId:device.subLocationTypeId,
                        // assetTypeCode:device.assetTypeCode,
                        status: statusValue
                    }},
            });
            await refetch();
        } catch (error) {
            console.error("Failed to update device:", error);
            setNotification({
                color: "error",
                isVisible: true,
                message: error.message || "Something went wrong",
              });
        }
    }
    // const [rowCount, setRowCount] = React.useState(data?.findAllDevices?.total || 0);

    // React.useEffect(() => {
    //     setRowCount((prevRowCount) =>
    //         data?.findAllDevices?.total !== undefined
    //             ? data?.findAllDevices?.total
    //             : prevRowCount,
    //     );
    // }, [data?.findAllDevices?.total, setRowCount]);
    if (loading)
        return (
            <>
                <Loader />
            </>
        );
    if (error) return <p>Error: {error.message}</p>;

    // const allDevices = data?.findAllDeviceBulkUplods?.results;
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    return (
        <DataGrid
            autoHeight
            rows={data?.findAllDeviceBulkUplods?.results}
            columns={columns}
            rowCount={data?.findAllDeviceBulkUplods?.total}
            pageSizeOptions={[10, 20, 50, 100]}
            getRowId={(row) => row.uuid}
            // onRowClick={onRowClick}
            loading={loading}
            onPageChange={handleChangePage}
            paginationModel={paginationModel}
            paginationMode="server"
            onPaginationModelChange={setPaginationModel}
        />
    );
}

export default BulkDevice;


