import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
// import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
// import { ModuleRegistry } from "@ag-grid-community/core";
import { AgGridReact } from "ag-grid-react";
import { useNotification } from "context";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";



// ModuleRegistry.registerModules([ClientSideRowModelModule]);

const AgGrid = ({
  columns = [],
  readMethod = {},
  createMethod = {},
  updateMethod = {},
  deleteMethod = {},
  isGraphql = true,
  queryLoading = true,
  queryError = null,
  queryData = [],
  page = "",
  addButtonLabel = "Add Component",
}) => {
  const gridRef = useRef();
  const { setNotification } = useNotification();
  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  const gridStyle = useMemo(() => ({ height: "40vh", width: "100%" }), []);
  const [rowData, setRowData] = useState([]);
  const [columnDefs, setColumnDefs] = useState([]);
  const [gridApi, setGridApi] = useState(null);

  useEffect(() => {
    setColumnDefs(columns);
  }, [columns]);

  useEffect(() => {
    if (queryData && JSON.stringify(queryData) !== JSON.stringify(rowData)) {
      // Update the rowData state based on the fetched data
      setRowData(queryData); // Adjust this based on your data structure
    }
  }, [queryData]);

  const defaultColDef = useMemo(
    () => ({
      floatingFilter: true,
      sortable: true,
      editable: true,
      filter: true,
      flex: 1,
      minWidth: 100,
    }),
    []
  );

  const onGridReady = useCallback(
    (params) => {
      params.api && setGridApi(params.api);
      if (Object.keys(readMethod).length > 0) {
        if (isGraphql) {
          const { query, input } = readMethod;
          query({
            variables: { ...input, skip: 0, limit: 10 },
          });
        } else {
          // TODO: implement the REST API logic
        }
      }
    },
    [readMethod, isGraphql]
  );

  const onGridSizeChanged = useCallback((params) => {
    const gridWidth = document.querySelector(".ag-body-viewport").clientWidth;
    let columnsToShow = [];
    let columnsToHide = [];
    let totalColsWidth = 0;
    const allColumns = params.api.getColumns();

    if (allColumns && allColumns.length > 0) {
      for (let column of allColumns) {
        totalColsWidth += column.getMinWidth();
        if (totalColsWidth > gridWidth) {
          columnsToHide.push(column.getColId());
        } else {
          columnsToShow.push(column.getColId());
        }
      }
    }
    params.api.setColumnsVisible(columnsToShow, true);
    params.api.setColumnsVisible(columnsToHide, false);
    window.setTimeout(() => {
      params.api.sizeColumnsToFit();
    }, 10);
  }, []);

  const onFirstDataRendered = useCallback((params) => {
    params.api.sizeColumnsToFit();
  }, []);

  const onRowDragEnd = useCallback(
    (event) => {
      // console.log(event, "EVENT")
      const data = event.node.data;
      const payload = {
        id: data?.id,
        componentId: data?._component?.id,
        key: 'formData',
        value:{
          data:{
            ...data,
            _component:undefined,
            displayOrder: +(+event.node.rowIndex + 1)
          }
        },
      }
      updateMethod?.mutation({
        variables: {
          input: payload
        },
        // onCompleted: () => updateMethod?.refetch(),
      });
    },
    [rowData]
  );



  return (
    <>
      <div style={containerStyle}>
        <div id="grid-wrapper" style={{ width: "100%", height: "100%" }}>
          <div style={gridStyle} className={"ag-theme-alpine"}>
            <AgGridReact
              ref={gridRef}
              rowData={rowData}
              columnDefs={columnDefs}
              onGridReady={onGridReady}
              defaultColDef={defaultColDef}
              // onGridSizeChanged={onGridSizeChanged}
              alwaysShowHorizontalScroll={true}
              suppressHorizontalScroll={false}
              onFirstDataRendered={onFirstDataRendered}
              rowDragMultiRow={true}
              rowDragManaged={true}
              rowSelection={"multiple"}
              animateRows={true}
              onRowDragEnd={onRowDragEnd}
              pagination={true}
              paginationPageSizeSelector={[10,20,50,100]}
            />
          </div>
        </div>
        
        {queryLoading && <p>Loading...</p>}
        {queryError && <p>Error: {queryError.message}</p>}
      </div>
    </>
  );
};

export default AgGrid;
