

import React,{ useEffect, useState } from "react";

// react-router-dom components
import { useLocation } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React context
import { useMaterialUIController, setLayout } from "context";

function PageLayout({ background, children }) {
  const [, dispatch] = useMaterialUIController();
  const { pathname } = useLocation();
  const [notification, setNotification] = useState({
    isVisible: false,
    message: "",
  });

  useEffect(() => {
    setLayout(dispatch, "page");
  }, [pathname]);

  return (
    <>
      {notification.isVisible && (
        <MDAlert
          color="error"
          mt="20px"
          dismissible
          sx={{ position: "absolute", right: "20px", zIndex: "9999" }}
          mr="10px"
        >
          <MDTypography variant="body2" color="white">
            {notification.message}
          </MDTypography>
        </MDAlert>
      )}

      <MDBox width="100vw" height="100%" minHeight="100vh" bgColor={background}>
        {/* {children} */}
        {React.Children.map(children, (child) => {
          return React.cloneElement(child, setNotification);
        })}
      </MDBox>
    </>
  );
}

// Setting default values for the props for PageLayout
PageLayout.defaultProps = {
  background: "default",
};

// Typechecking props for the PageLayout
PageLayout.propTypes = {
  background: PropTypes.oneOf(["white", "light", "default"]),
  children: PropTypes.node.isRequired,
};

export default PageLayout;
