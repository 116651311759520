import React, { useState, useEffect } from 'react';
import { Autocomplete, Box, Button, Drawer, Grid, Icon, TextField, Typography } from "@mui/material";
import DashboardLayout from 'ui/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'ui/Navbars/DashboardNavbar';
import AgGrid from 'components/MDGrid';
import QRCodeListing from './qrlisting';
import { Add, QrCodeScanner } from '@mui/icons-material';
import { Field, Form, Formik } from 'formik';
import * as Yup from "yup";
import MDButton from 'components/MDButton';
import { useQuery } from '@apollo/client';
import { BRANDS_LIST } from 'services/brand-service';
import { dashboardServiceClient } from 'graphql/client';
// import QrCodeDrawer from './qrdrawer';
import qrcodeService from 'services/qrcode-service';

const validationSchema = Yup.object().shape({
    url: Yup.string()
        .required("URL is required"),
    name: Yup.string()
        .required("Brand Name is required"),
});

const handleSubmit = async (values) => {
    const payload = {
        name: values.name,
        url: values.url,
    }
    try {
        if (values.uuid) {
            const response = await qrcodeService.getQrCodeUpdate(values.uuid, payload);
        } else {
            const response = await qrcodeService.getQrCodeCreate(payload);
            console.log(response)
        }
    } catch (res) {
        // Handling error is remaining
    }
    window.location.reload();
}

function QRCode() {
    const [open, setOpen] = useState(false);
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [selectedQr, setSelectedQr] = useState(null);
    const user = JSON.parse(localStorage.getItem("userDetails") || "{}");



    const { loading: BrandLoading, data: BrandData, error: BrandError, refetch } = useQuery(BRANDS_LIST, {
        client: dashboardServiceClient,
        variables: {
            take: 100,
            skip: 0,
            filter:
                user && user?.role === "admin"
                    ? {
                        isDeleted: false,
                    }
                    : {
                        id: user?.userId,
                    },
        },
    });

    const ControlSection = ({ handleOpen, setCategoryData, user }) => (
        user && user?.role === "admin" ? (<>
            <Grid item xs={12} sm={6} md={6} textAlign="right">
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                    mb={2}
                >
                    <Grid sm={6} md={6}>
                        <Typography sx={{ fontFamily: "montserrat", color: "#000", fontWeight: "700" }} ml={1}>QR-Code</Typography>
                    </Grid>
                    <MDButton
                        type="button"
                        variant="contained"
                        color='black'
                        circular={true}
                        onClick={() => {
                            setOpen(true);
                            setSelectedQr(null);
                        }}
                        startIcon={<Add />}
                    >
                        Create QR
                    </MDButton>
                </Box>
            </Grid>
        </>) : <></>

    );

    const [selectedBrands, setSelectedBrands] = useState(null);
    const QrCodeDrawer = ({ open, handleClose, selectedQr }) => (
        <Drawer
            anchor="right"
            open={open}
            onClose={handleClose}
            PaperProps={{ sx: { width: "50%" } }}
        >
            {console.log(selectedQr)}
            <Grid
                container
                sx={{ backgroundColor: "#F0F2F5", pb: 3, pt: 2, pl: "5px" }}
            >
                <Grid item xs={12}>
                    &nbsp;
                </Grid>
                <Grid item xs={12}>
                    <Icon
                        sx={{ mr: 2, ml: 2 }}
                        component={QrCodeScanner}
                        fontSize="large"
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h5" sx={{ ml: 2, color: "#000" }}>
                        Qr Code
                    </Typography>
                </Grid>
            </Grid>
            <Box sx={{ width: "95%", minHeight: "70vh", mx: "auto" }}>
                <Formik
                    initialValues={{
                        uuid: selectedQr?.uuid || "",
                        name: selectedQr?.name || "",
                        url: selectedQr?.url || "",
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values) => { handleSubmit(values); handleClose() }}
                >
                    {(formik) => {
                        return (
                            <Form onSubmit={formik.handleSubmit}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Grid item xs={12} mt={2}>
                                            <Field
                                                as={TextField}
                                                name="uuid"
                                                label="UUID"
                                                fullWidth
                                                disabled
                                                inputProps={{
                                                    style: { fontFamily: "Montserrat" },
                                                }}
                                                InputLabelProps={{
                                                    style: { fontFamily: "Montserrat" },
                                                }}
                                                error={
                                                    formik.touched.uuid && !!formik.errors.uuid
                                                }
                                                helperText={
                                                    formik.touched.uuid
                                                        ? formik.errors.uuid
                                                        : ""
                                                }
                                            />
                                        </Grid>
                                        {/* <Grid item xs={12} mt={2}>
                                            <Autocomplete
                                                labelId="demo-simple-select-label"
                                                label="Select Brand"
                                                id="name"
                                                name="name"
                                                value={formik.values.name}
                                                // loading={loading}
                                                iconComponent={() => (
                                                    <ArrowDropDownIcon style={{ marginRight: "18px" }} />
                                                )}
                                                options={BrandData?.brandlist?.results
                                                    ?.map((item) => (
                                                        { label: item?.name, value: item?.id }
                                                    ))}
                                                onChange={(e, newValue) => {
                                                    console.log(newValue)
                                                    formik.setFieldValue("name", newValue?.label || null);
                                                }}
                                                error={
                                                    formik.touched.name && !!formik.errors.name
                                                }
                                                helperText={
                                                    formik.touched.name
                                                        ? formik.errors.name
                                                        : ""
                                                }
                                                sx={{ "& .MuiInputBase-root": { height: 40, borderRadius: "5px", px: 2, backgroundColor: "white.main" } }}
                                                renderInput={(params) => <TextField {...params} label="Select Brand" />}
                                            />
                                        </Grid> */}
                                        <Grid item xs={12} mt={2}>
                                            <Field
                                                as={TextField}
                                                name="name"
                                                label="Name"
                                                fullWidth
                                                inputProps={{
                                                    style: { fontFamily: "Montserrat" },
                                                }}
                                                InputLabelProps={{
                                                    style: { fontFamily: "Montserrat" },
                                                }}
                                                error={
                                                    formik.touched.name && !!formik.errors.name
                                                }
                                                helperText={
                                                    formik.touched.name
                                                        ? formik.errors.name
                                                        : ""
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12} mt={2}>
                                            <Field
                                                as={TextField}
                                                name="url"
                                                label="URL"
                                                fullWidth
                                                inputProps={{
                                                    style: { fontFamily: "Montserrat" },
                                                }}
                                                InputLabelProps={{
                                                    style: { fontFamily: "Montserrat" },
                                                }}
                                                error={
                                                    formik.touched.url && !!formik.errors.url
                                                }
                                                helperText={
                                                    formik.touched.url
                                                        ? formik.errors.url
                                                        : ""
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        sx={{ mt: 3 }}
                                        justifyContent={"flex-end"}
                                        gap={3}
                                    >
                                        <Button
                                            sx={{
                                                color: "#000000",
                                                borderRadius: "50px",
                                                borderColor: "#000000",
                                                fontFamily: "Montserrat",
                                            }}
                                            variant="outlined"
                                            onClick={handleClose}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            style={{
                                                marginRight: "10px",
                                                backgroundColor: "#000000",
                                                color: "#fff",
                                                borderRadius: "50px",
                                                fontFamily: "Montserrat",
                                            }}
                                        >
                                            Save
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Form>
                        );
                    }}
                </Formik>
            </Box>
        </Drawer>
    );
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    if (BrandLoading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;

    const handleRowClick = (params) => {
        setSelectedQr(params.data);
        handleOpen();
    };


    return (
        <DashboardLayout>
            <DashboardNavbar />
            <ControlSection handleOpen={handleOpen} user={user} />
            <QRCodeListing onRowClick={handleRowClick} />
            <QrCodeDrawer
                open={open}
                handleClose={handleClose}
                selectedQr={selectedQr}
            />

        </DashboardLayout>
    );
}

export default QRCode;
