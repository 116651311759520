import {
    Button,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Typography,
  } from "@mui/material";
  import React from "react";
  import * as Yup from "yup";
  import { Field, FieldArray, Form, Formik } from "formik";
  import dayjs from "dayjs";
  import { useNotification } from "context";
  import { dashboardServiceClient } from "graphql/client";
  import { useMutation } from "@apollo/client";
  import { CREATE_BRAND_STORE } from "services/brand-service";
  import { UPDATE_BRAND_STORE } from "services/brand-service";
  import { LIST_BRAND_STORE } from "services/brand-service";
  import { GET_BRAND_DETAILS } from "services/brand-service";
  import FileUploader from "components/MDFileUpload";
  import VideoUpload from "components/MDVideoUpload"
  import { ReactComponent as ArrowDropDownIcon } from "assets/images/Arrow-Down.svg";

  
  const validationSchema = Yup.object({
        id: Yup.string().required("Store name is required"),
        imagesPath: Yup.array()
        .required("At least one image is required")
        .min(1, "At least one image is required"),
        videosPath: Yup.array()
        .required("At least one video is required")
        .min(1, "At least one video is required"),
      })
      const useBrandMetadataMutation = (
        brandData,
        setNotification,
        handleTabChange,
        setBrandData,
      ) => {
        const mutationOptions = {
          client: dashboardServiceClient,
          refetchQueries: [
            {
              query: GET_BRAND_DETAILS,
              variables: { username: brandData?.username || "" },
            },
          ],
        };
        const [updateBrandStore] = useMutation(UPDATE_BRAND_STORE, mutationOptions);
      
        const handleSubmit = (values) => {
            const mutation = updateBrandStore

            const mutationInput = {
              updateBrandStoreInput: {
                id: values.id,
                imagesPath: values.imagesPath,
                videosPath: values.videosPath,
              },
            };
            mutation({ variables: mutationInput })
              .then((res) => {
                setBrandData(brandData);
                // handleTabChange(null, 0);
                setNotification({
                  color: "success",
                  isVisible: true,
                  message: " Store data updated successfully"
                });
              })
              .catch((error) => {
                console.error(" Error:", error.message);
                setNotification({
                  color: "error",
                  isVisible: true,
                  message: error.message || "Something went wrong",
                });
              });
        };
      
        return handleSubmit;
      };
    const StorDataForm = ({ storeData, brandData, handleTabChange, setBrandData }) => {
        const { setNotification } = useNotification();
        const handleSubmit = useBrandMetadataMutation(
          brandData,
          setNotification,
          handleTabChange,
          setBrandData
        );
        const handleStoreChange = (event,formik) => {
            const storeId = event.target.value;
            const selectedStore = brandData?.brandStore?.find(store => store.id === storeId);
  
            formik.setValues({
              id: storeId,
              imagesPath: selectedStore?.images?.map(image => image?.path),
              videosPath: selectedStore?.videos?.map(video => video?.path)
            });
          };
    return (
      <Formik
        initialValues={{
          id: brandData?.brandStore?.length ? brandData?.brandStore[0]?.id : "",
          imagesPath:
            (brandData?.brandStore &&
              brandData?.brandStore?.length &&
              brandData?.brandStore[0]?.images?.map((item) => item.path)) ||
            [],
          videosPath:
            (brandData?.brandStore &&
                brandData?.brandStore?.length &&
                brandData?.brandStore[0]?.videos?.map((item) => item.path)) ||
            [],
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          console.log("Submitted values:", values);
          handleSubmit(values);
        }}
      >
        {(formik) => {
            return( <Form key={formik.values.id}>
                <Grid container spacing={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <InputLabel>Store Name</InputLabel>
                        <Field
                          as={Select}
                          IconComponent={ArrowDropDownIcon}
                          name="id"
                          label="Select Store Name"
                          onChange={(event) => handleStoreChange(event, formik)} 
                          error={
                            formik.touched.id &&
                            Boolean(formik.errors.id)
                          }
                          helperText={
                            formik.touched.id && formik.errors.id
                          }
                          sx={{
                            padding:"12px",
                            input: { padding: "12px" },
                            svg: {
                            display: "block !important",
                            },
                          }}
                        >
                          {brandData?.brandStore?.map((value) => (
                            <MenuItem value={value.id}>{value.store.name} &nbsp;({dayjs(value.start).format("DD MMM-YY")} to {dayjs(value.end).format("DD MMM-YY")})</MenuItem>
                          ))}
    
                        </Field>
                        {formik.touched.id && formik.errors.id ? (
                          <Typography fontSize={12} color="error">
                            {formik.errors.id}
                          </Typography>
                        ) : null}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} m={1}>
                      <Typography fontSize={15} fontWeight={"bold"}>
                        Images
                      </Typography>
                      <FileUploader
                        fieldName="Upload Image"
                        isMultiple={true}
                        preLoadedFile={
                          !!formik?.values?.imagesPath
                            ? formik?.values?.imagesPath
                            : null
                        }
                        signedUrlCallback={(newUrl, index) => {
                          formik.setFieldValue("imagesPath", newUrl);
                          const updatedImagePathArray =  formik?.values?.imagesPath ? [...formik.values.imagesPath] : [];
                          if (newUrl) {
                            updatedImagePathArray[index] = newUrl;
                          } else {
                            updatedImagePathArray.splice(index, 1);
                          }
                          formik.setFieldValue("imagesPath", updatedImagePathArray);
                        }}
                        isFieldValid={
                          formik?.touched?.imagesPath && formik?.errors?.imagesPath
                        }
                      />
                      {formik?.touched?.imagesPath && formik?.errors?.imagesPath && (
                        <Typography fontSize={12} color="error">
                          {formik.errors?.imagesPath}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={12} m={1}>
                      <Typography fontSize={15} fontWeight={"bold"}>
                        Videos
                      </Typography>
                      <VideoUpload
                        fieldName="Upload Video"
                        isMultiple={true}
                        preLoadedFile={
                          !!formik?.values?.videosPath
                            ? formik?.values?.videosPath
                            : null
                        }
                        signedUrlCallback={(newUrl, index) => {
                          formik.setFieldValue("videosPath", newUrl);
                          const updatedImagePathArray = formik?.values?.videosPath ? [
                            ...formik?.values?.videosPath,
                          ]:[];
                          if (newUrl) {
                            updatedImagePathArray[index] = newUrl;
                          } else {
                            updatedImagePathArray.splice(index, 1);
                          }
                          formik.setFieldValue("videosPath", updatedImagePathArray);
                        }}
                        isFieldValid={
                          formik?.touched?.videosPath && formik?.errors?.videosPath
                        }
                      />
                      {formik?.touched?.videosPath && formik?.errors?.videosPath && (
                        <Typography fontSize={12} color="error">
                          {formik?.errors?.videosPath}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      variant="contained"
                      style={{
                        marginTop: "24px",
                        marginRight: "10px",
                        backgroundColor: "#344767",
                        color: "#fff",
                        borderRadius: "100px",
                      }}
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </Form>)
        }}
      </Formik>
    );
  };
  
  export default StorDataForm;
  