import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { gql, useLazyQuery } from "@apollo/client";
import axios from "axios";
import { dashboardServiceClient } from "graphql/client";
import { useNotification } from "context";
import { Grid } from "@mui/material";
import videojs from "video.js";


const GET_SIGNED_URL = gql`
  query getSignedUrl($getSignedUrlRequest: GetSignedUrlInput!) {
    getSignedUrl(getSignedUrlRequest: $getSignedUrlRequest) {
      url
      readUrl
    }
  }
`;

export default function FileUploaderContainer({
  preLoadedFile,
  hasDisplayName = false,
  isMultiple,
  signedUrlCallback,
  fieldName,
  isFieldValid,
  isPdf,
  type,
}) {
  const [fileInstances, setFileInstances] = useState([]);

  useEffect(() => {
    const preFileInstances =
      (preLoadedFile &&
        preLoadedFile.map((file, index) =>
          hasDisplayName
            ? {
                id: index,
                fileName: file.fileName,
                displayName: file.displayName,
              }
            : { id: index, fileName: file }
        )) ||
      [];
    const stateValue = isMultiple
      ? [...preFileInstances, { id: Date.now() }]
      : preFileInstances.length
      ? [...preFileInstances]
      : [{ id: Date.now() }];
    setFileInstances(stateValue);
  }, [preLoadedFile]);

  const addNewInstance = () => {
    isMultiple && setFileInstances([...fileInstances, { id: Date.now() }]);
  };

  const deleteInstance = (idToDelete) => {
    setFileInstances(
      fileInstances.filter((instance) => instance.id !== idToDelete)
    );
  };

  return (
    <div>
      {fileInstances.map((instance, index) => (
        <Grid container key={instance.id}>
          <Grid item xs={10} sm={12}>
            <FileUploader
              id={index}
              instance={instance}
              addNewInstance={addNewInstance}
              deleteInstance={deleteInstance}
              signedUrlCallback={signedUrlCallback}
              fieldName={fieldName}
              isPdf={isPdf}
              type={type}
              isMultiple={isMultiple}
              isFieldValid={isFieldValid}
            />
          </Grid>
        </Grid>
      ))}
    </div>
  );
}

function FileUploader({
  addNewInstance,
  deleteInstance,
  id,
  instance,
  signedUrlCallback,
  fieldName,
  isMultiple,
  isFieldValid,
  isPdf,
  type,
}) {
  const { setNotification } = useNotification();

  const [fileName, setFileName] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploaded, setIsUploaded] = useState(
    instance.fileName ? true : false
  );
  const [fileData, setFileData] = useState(null);
  const [axiosCallMade, setAxiosCallMade] = useState(false);
  const [readUrl, setReadUrl] = useState(
    instance.fileName ? instance.fileName : null
  );
  const [openPreview, setOpenPreview] = useState(false);
  const [previewFile, setPreviewFile] = useState(null);
  const [fileMetadata, setFileMetadata] = useState(null);

  const fileInputId = `${fieldName}-fileInput-${id}`;

  const [getSignedUrl, { data }] = useLazyQuery(GET_SIGNED_URL, {
    client: dashboardServiceClient,
  });

  useEffect(() => {
    if (data?.getSignedUrl?.readUrl) {
      setPreviewFile(data?.getSignedUrl?.readUrl);
    }
  }, [data?.getSignedUrl?.readUrl]);

  useEffect(() => {
    if (data?.getSignedUrl?.url && fileName && !axiosCallMade) {
      const uploadFile = async () => {
        await axios.put(data?.getSignedUrl?.url, fileData, {
          headers: { "Content-Type": "application/octet-stream" },
        });
        setUploadProgress(66);
        setAxiosCallMade(true);
        getSignedUrl({
          variables: {
            getSignedUrlRequest: {
              fileName: `dashboard/staging/news/${fileName}`,
              action: "read",
              contentType: "application/octet-stream",
            },
          },
        });
        addNewInstance();
        setIsUploaded(true);
        setUploadProgress(100);
      };
      uploadFile();
    }
  }, [data?.getSignedUrl?.url, fileName]);

  useEffect(() => {
    if (isUploaded && data?.getSignedUrl?.readUrl && fileName) {
      signedUrlCallback(
        `dashboard/staging/news/${fileName}`,
        id,
        data?.getSignedUrl?.readUrl,
        fileData,
        fileName,
        fileMetadata
      );
      setReadUrl(data?.getSignedUrl?.readUrl);
    }
  }, [isUploaded, data?.getSignedUrl?.readUrl]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    console.log(...[file, "👀 [index.js:148]: file"].reverse());
    if (!file) return;
    else if (isPdf) {
      if (!file.name.match(/\.(jpg|jpeg|png|pdf|JPG|JPEG|PNG|PDF)$/)) {
        setNotification({
          color: "warning",
          isVisible: true,
          message: "Selected file format is not allowed.",
        });
        return false;
      }
    } else if (type === "Media") {
      if (!file.name.match(/\.(jpg|png|mp4|JPG|PNG|MP4)$/)) {
        setNotification({
          color: "warning",
          isVisible: true,
          message: "Selected file format is not allowed.",
        });
        return false;
      }
    } else {
      if (!file.name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG)$/)) {
        setNotification({
          color: "warning",
          isVisible: true,
          message: "Selected file format is not allowed.",
        });
        return false;
      }
    }
    if (type === "Media" && file.name.match(/\.(mp4|MP4)$/)) {
      if (file.size > 20 * 1024 * 1024) {
        setNotification({
          color: "warning",
          isVisible: true,
          message: "File size exceeds the 20MB limit.",
        });
        return false;
      }
      const url = URL.createObjectURL(file);

      // Setup video.js to extract metadata
      const videoElement = document.createElement("video");
      const player = videojs(videoElement, {
        controls: false,
        preload: "auto",
      });

      player.ready(() => {
        player.src({ type: file.type, src: url });
        player.on("loadedmetadata", () => {
          const videoWidth = player?.videoWidth();
          const videoHeight = player?.videoHeight();
          const gcd = (a, b) => (b === 0 ? a : gcd(b, a % b));
          const divisor = gcd(videoWidth, videoHeight);
          const aspectRatio = `${videoWidth / divisor}:${videoHeight / divisor}`;
          setFileMetadata({
            duration: player.duration()?.toFixed(2),
            orientation:
              player.videoWidth() > player.videoHeight()
                ? "Landscape"
                : "Portrait",
            size: `${(file.size / 1024 / 1024).toFixed(2)} MB`,
            aspectRatio: aspectRatio,
            type:'video'
          });
          URL.revokeObjectURL(url);
          player.dispose();
        });
      });
    } else {
      if (file.size > 6 * 1024 * 1024) {
        setNotification({
          color: "warning",
          isVisible: true,
          message: "File size exceeds the 6MB limit.",
        });
        return false;
      }
      const loadImage = (file) => {
        return new Promise((resolve, reject) => {
          const img = new Image();
          img.onload = () => resolve(img);
          img.onerror = reject;
          img.src = URL.createObjectURL(file);
        });
      };
      try {
        loadImage(file).then(img=>{
          const dimensions = `${img.width} X ${img.height}`;
          setFileMetadata({
            dimension: dimensions,
            format: file.type.split('/')[1],
            size: (file.size / (1024 * 1024)).toFixed(2) + " MB",
            type:'image'
          })
          URL.revokeObjectURL(img.src);
        });
      } catch (error) {
        console.error('Error loading image:', error);
      }
    }
    if (file || isPdf) {
      setFileName(file.name);
      setFileData(e.target.files[0]);
      setUploadProgress(40);
      getSignedUrl({
        variables: {
          getSignedUrlRequest: {
            fileName: `dashboard/staging/news/${file.name}`,
            action: "write",
            contentType: "application/octet-stream",
          },
        },
      });
      setAxiosCallMade(false);
    }
  };

  const handleDelete = () => {
    deleteInstance(instance.id);
    signedUrlCallback(null, id);
  };

  const handlePreviewOpen = (file) => {
    console.log(
      ...[file, readUrl, "👀 [index.js:223]: file, readUrl"].reverse()
    );
    if (readUrl === null) return;
    if(!(readUrl && /^https?:\/\//.test(readUrl))) {
      getSignedUrl({
        variables: {
          getSignedUrlRequest: {
            fileName: `${readUrl}`,
            action: "read",
            contentType: "application/octet-stream",
          },
        },
      });
    }

    if (file === "") {
      setOpenPreview(true); // Handle image preview (open modal or perform other logic)
    } else if (isUploaded && file.match(/\.(pdf|PDF)$/)) {
      window.open(readUrl); // Open PDF in new tab
    } else {
      setOpenPreview(true); // Handle image preview (open modal or perform other logic)
    }
  };

  const handlePreviewClose = () => {
    setOpenPreview(false);
  };

  return (
    <>
      <Paper
        elevation={0}
        onClick={() =>
          !isUploaded && document.getElementById(fileInputId).click()
        }
        sx={{
          position: "relative",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "8px 0px 8px 16px",
          backgroundColor: isUploaded ? "#6a994e29" : "#fafafa",
          maxHeight: "52px",
          borderRadius: "4px",
          border: `1px ${
            isFieldValid ? "solid" : isUploaded ? "solid" : "dashed"
          } ${isFieldValid ? "#F44335" : isUploaded ? "#6a994e" : "#efefef"}`,
        }}
      >
        <input
          type="file"
          id={fileInputId}
          style={{ display: "none" }}
          accept={isPdf ? "image/*, application/pdf" : "image/*,video/*"}
          onChange={handleFileChange}
        />
        {isUploaded ? (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "inline-flex",
                height: "52px",
                gap: "16px",
                alignItems: "center",
              }}
            >
              <img
                src="https://generation-sessions.s3.amazonaws.com/7c71c9772decb49fcc575053870ef5ff/img/tick-circle@2x.png"
                alt="tick"
                style={{ width: "24px", height: "24px" }}
              />
              <div
                style={{
                  width: "299px",
                  fontFamily: "Inter-Medium, Helvetica",
                  fontWeight: 500,
                  color: "#000000",
                  fontSize: "14px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {fileName ||
                  (instance?.displayName
                    ? instance?.displayName?.split("/").reverse()[0]
                    : instance?.fileName
                    ? instance?.fileName.split("/").reverse()[0].split("?")[0]
                    : "")}
              </div>
            </div>
            <div
              style={{
                display: "inline-flex",
                justifyContent: "flex-end",
                gap: "8px",
                padding: "10px 16px",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  fontFamily: '"Inter-Bold", Helvetica',
                  fontWeight: 600,
                  fontSize: "12px",
                  lineHeight: "16px",
                  color: "#6a994e",
                }}
              >
                Uploaded
              </Typography>
            </div>
          </div>
        ) : (
          <div style={{ display: "flex", alignItems: "center", gap: "16px" }}>
            <img
              src="https://generation-sessions.s3.amazonaws.com/df5a8cb33081b6df29ee38b7b81444bc/img/gallery-export@2x.png"
              alt="Gallery"
              style={{ width: "24px", height: "24px" }}
            />
            <Typography variant="body2">
              {fileName ? `Uploading ${fileName}` : fieldName}
            </Typography>
          </div>
        )}
        {fileName && !isUploaded && (
          <div
            style={{
              width: `${uploadProgress}%`,
              backgroundColor: "red",
              height: "5px",
              position: "absolute",
              bottom: 0,
              left: 0,
            }}
          ></div>
        )}
        <div
          style={{
            display: "inline-flex",
            alignItems: "center",
            gap: "8px",
            padding: "10px 16px",
          }}
        >
          {isUploaded && (
            <Button
              sx={{ color: "#344767", pl: 0 }}
              onClick={() => {
                handlePreviewOpen(instance?.displayName || "");
              }}
            >
              PREVIEW
            </Button>
          )}
          <Button
            sx={{ color: "#344767", pl: 0 }}
            onClick={
              isUploaded
                ? isMultiple
                  ? handleDelete
                  : () => {
                      document.getElementById(fileInputId).click();
                    }
                : null
            }
          >
            {isUploaded ? (isMultiple ? "DELETE" : "CHANGE") : "CHOOSE FILE"}
          </Button>
        </div>
      </Paper>

      <Modal
        open={openPreview}
        onClose={handlePreviewClose}
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <img
          src={previewFile || readUrl}
          alt="Preview"
          style={{ maxWidth: "80%", maxHeight: "80%" }}
        />
      </Modal>
    </>
  );
}
