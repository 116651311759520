import React from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Switch,
  Button,
} from "@mui/material";
import MDButton from "components/MDButton";

function CustomerWalletForm({ customerData, handleClose, user }) {
  return (
    <Formik
      initialValues={{
        availableBalance:customerData?.account?.balance ||  "",
        expiryDate: customerData?.account?.expiryDate ||  "",
        expiryAmount: customerData?.account?.expiryAmount,
        upcomingCashback: customerData?.account?.upcomingCashback,
        upcomingCashbackDate: customerData?.account?.upcomingCashbackDate ||  "",
      }}
      onSubmit={(values) => {
        console.log(values);
      }}
    >
      {() => (
        <Form>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Field
                disabled
                name="availableBalance"
                as={TextField}
                label="Available Balance"
                fullWidth
                inputProps={{
                  style: { fontFamily: "Montserrat" },
                }}
                InputLabelProps={{
                  style: { fontFamily: "Montserrat" },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                disabled
                name="expiryAmount"
                as={TextField}
                label="Expiry Amount"
                fullWidth
                inputProps={{
                  style: { fontFamily: "Montserrat" },
                }}
                InputLabelProps={{
                  style: { fontFamily: "Montserrat" },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                disabled
                name="expiryDate"
                as={TextField}
                label="Expiry Date"
                fullWidth
                inputProps={{
                  style: { fontFamily: "Montserrat" },
                }}
                InputLabelProps={{
                  style: { fontFamily: "Montserrat" },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                disabled
                name="upcomingCashback"
                as={TextField}
                label="Upcoming Cashback"
                fullWidth
                inputProps={{
                  style: { fontFamily: "Montserrat" },
                }}
                InputLabelProps={{
                  style: { fontFamily: "Montserrat" },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                disabled
                name="upcomingCashbackDate"
                as={TextField}
                label="Upcoming Cashback Date"
                fullWidth
                inputProps={{
                  style: { fontFamily: "Montserrat" },
                }}
                InputLabelProps={{
                  style: { fontFamily: "Montserrat" },
                }}
              />
            </Grid>
            {/* {user && user?.role === "admin" ?(
               <Grid container spacing={1}>
               <Grid item xs={12} sx={{ mt: 4, ml: 2, pb: 2 }}>
               <MDButton
                  type="submit"
                  variant="outlined"
                  circular={true}
                  color="dark"
                 >
                   Save
                 </MDButton>
                 <Button color="dark" onClick={handleClose} >
                   Cancel
                 </Button>
               </Grid>
             </Grid>
            ):<></>} */}
           
          </Grid>

        </Form>
      )}
    </Formik>
  );
}

export default CustomerWalletForm;
