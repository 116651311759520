import { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDAlert from "components/MDAlert";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/bg-reset-cover.jpeg";
import authService from "services/auth-service";
import { Link } from "react-router-dom";
import '../style.css'


function ForgotPassword() {
  const [isDemo, setIsDemo] = useState(false);
  const [notification, setNotification] = useState({
    color: "info",
    isVisible: false,
    message: ""
  });
  const [input, setEmail] = useState({
    email: "",
  });
  const [error, setError] = useState({
    err: false,
    textError: "",
  });

  useEffect(() => {
    setIsDemo(process.env.REACT_APP_IS_DEMO === "true");
  }, []);

  const changeHandler = (e) => {
    setEmail({
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,7})+$/;

    if (
      input.email.trim().length === 0 ||
      !input.email.trim().match(mailFormat)
    ) {
      setError({ err: true, textError: "The email must be valid" });
      return;
    }

    // somthing not right with the data
    const myData = {
      data: {
        type: "password-forgot",
        attributes: {
          redirect_url: `${process.env.REACT_APP_URL}/auth/reset-password`,
          ...input,
        },
      },
    };

    try {
        const response = await authService.forgotPassword({
          username: input.email,
        });
        if (response.message === "Username Does not exist") {
          setError({ err: true, textError: "Username Does not exist" });
          setNotification({
            color: "error",
            isVisible: true,
            message: "Username Does not exist"
          });
        } else {
          setError({ err: false, textError: "" });
          setNotification({
            color: "info",
            isVisible: true,
            message: response.message
          });
        }
    } catch (err) {
      if (err.hasOwnProperty("errors")) {
        if (err.errors.hasOwnProperty("email")) {
          setError({ err: true, textError: err.errors.email[0] });
          setNotification(true);
        } else {
          setError({ err: true, textError: "An error occured" });
          setNotification(true);
        }
      }
      return null;
    } finally {
      setTimeout(() => {setNotification(false)}, 3000)
    }
  };

  return (
    <CoverLayout coverHeight="50vh" image={bgImage}>
      <Card sx={{width:"500px",borderRadius:"40px"}}>
      <MDBox
          borderRadius="lg"
          mx={2}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDBox mt={10} pb={1}>
            <img src={"/images/broadwayLogo.png"} />
          </MDBox>
          <MDTypography variant="h4" fontWeight="medium" color="black" mt={1} sx={{fontFamily:"Montserrat"}} >
            Reset Password
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox
            component="form"
            role="form"
            method="POST"
            onSubmit={handleSubmit}
          >
            <MDBox mx={5}>
              <MDInput
                type="email"
                label="Email"
                fullWidth
                value={input.email}
                name="email"
                onChange={changeHandler}
                error={error.err}
                inputProps={{formPropsyle: {fontFamily: 'Montserrat'}}}
                InputLabelProps={{style: {fontFamily: 'Montserrat'}}}
              />
            </MDBox>
            {error.err && (
              <MDTypography variant="caption" color="error" fontWeight="light" sx={{fontFamily:"Montserrat"}}>
                {error.textError}
              </MDTypography>
            )}
            <MDBox mt={6} mb={1} mx={5}>
              <MDButton variant="gradient" color="dark" fullWidth type="submit" sx={{ borderRadius: "50px", fontFamily:"Montserrat" }}>
                Reset
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>

        <MDBox mb={5} textAlign="center">
          <MDTypography variant="button" color="dark" sx={{fontFamily:"Montserrat"}}>
            Remember paasword?{" "}
            <Typography
              component={Link}
              to="/auth/login"
              variant="button"
              color="dark"
              fontWeight="medium"
              textGradient
              sx={{fontFamily:"Montserrat"}}
            >
              <Typography sx={{ textDecoration: 'underline', display: "inline", color: "#000", textTransform: "capitalize", fontFamily: "Montserrat", fontWeight: 600, fontSize: "15px" }}>Sign In</Typography>
            </Typography>
          </MDTypography>
        </MDBox>
      </Card>
      {notification.isVisible && (
        <MDAlert color={notification.color} mt="20px" dismissible sx={{ position: "absolute", top: "10px", right: "10px" }}>
          <MDTypography variant="body2" color="white" sx={{fontFamily:"Montserrat"}}>
            {notification.message}
          </MDTypography>
        </MDAlert>
      )}
    </CoverLayout>
  );
}

export default ForgotPassword;
