import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import BulkDevice from "./bulkDevice";
import Fixture from "./fixturePage";
// import DeviceMapping from "./deviceMapping";
function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

export default function FixtureMasterTabs({ tabValue, setTabValue,open,setOpen,createClick,setCreateClick}) {

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <Box sx={{}}>
            <Box sx={{}}>
                <Tabs value={tabValue} onChange={handleChange} aria-label="ant example">
                    <Tab label="Fixture List" {...a11yProps(0)} />
                    {/* <Tab label="Bulk Upload" {...a11yProps(1)} /> */}
                </Tabs>
            </Box>
            <CustomTabPanel value={tabValue} index={0}>
                <Fixture open={open} setOpen={setOpen} createClick={createClick} setCreateClick={setCreateClick}/>
            </CustomTabPanel>
            <CustomTabPanel value={tabValue} index={1}>
                <Box sx={{ width: "100%", minHeight: "auto", padding: "0px" }}>
                    {/* <BulkDevice /> */}
                </Box>
            </CustomTabPanel>
        </Box>
    );
}
