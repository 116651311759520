import React, { useState } from "react";
import OrderLandingPage from "./orderLandingPage";
// import CustomerTabs from "./customerTabs";
import { Grid, Box, Icon, Button, Drawer, Typography, IconButton } from "@mui/material";
import DashboardLayout from "ui/LayoutContainers/DashboardLayout";
import DashboardNavbar from "ui/Navbars/DashboardNavbar";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { ReactComponent as OrderIcon } from "assets/images/orderIcon.svg";
import { Add as AddIcon } from "@mui/icons-material";
import OrderTabs from "./orderTabs"
import OrderDetailLandingPage from "./orderDetailsListing";

function Order() {
  const [isOrderDetail, setIsOrderDetail] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);

  const handleRowClick = (params) => {
    setSelectedOrder(params.data?.id);
    setIsOrderDetail(true)
  };
  const OrderDrawer = ({ open, handleClose, selectedOrder }) => (
    <Drawer
      anchor="right"
      open={open}
      onClose={handleClose}
      PaperProps={{ sx: { width: "50%" } }}
    >
      <Grid
        container
        sx={{ backgroundColor: "#F0F2F5", pb: 3, pt: 2, pl: "5px" }}
      >
        <Grid item xs={12}>
          &nbsp;
        </Grid>
        <Grid item xs={12}>
          <Icon
            sx={{ mr: 2, ml: 2 }}
            component={OrderIcon}
            fontSize="large"
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" sx={{ ml: 2 }}>
            Order
          </Typography>
        </Grid>
      </Grid>
      <Box sx={{ width: "100%", minHeight: "70vh" }}>
        <OrderTabs
          orderData={selectedOrder}
          handleClose={handleClose}
        />
      </Box>
    </Drawer>
  );
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const user = localStorage.getItem("userDetails") && localStorage.getItem("userDetails") !== "undefined" ? JSON.parse(localStorage.getItem("userDetails")) : {};

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid item xs={12} sm={6} md={3}>
        {!isOrderDetail ? (<>
          <OrderLandingPage onRowClick={handleRowClick} /></>) : <div>
          <Grid mt={2}>
            <IconButton
              aria-label="Go Back"
              onClick={() => { setIsOrderDetail(false); }}
            >
              <ArrowBackIcon />
              <Typography
                sx={{ fontSize: "16px", ml: 1, fontWeight: "bold" }}
              >
                Back
              </Typography>
            </IconButton>
          </Grid>
          <OrderDetailLandingPage selectedOrder={selectedOrder}/>
          </div>}
      </Grid>
    </DashboardLayout>
  );
}

export default Order;
