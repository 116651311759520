import React, { useState } from "react";
import CustomerLandingPage from "./CustomerLandingPage";
import CustomerTabs from "./customerTabs";
import { Grid, Box, Icon, Button, Drawer, Typography } from "@mui/material";
import DashboardLayout from "ui/LayoutContainers/DashboardLayout";
import DashboardNavbar from "ui/Navbars/DashboardNavbar";
import { ReactComponent as SearchIcon } from "assets/images/searchIcon.svg";
import { ReactComponent as CustomerIcon } from "assets/images/customer.svg";
import { Add as AddIcon } from "@mui/icons-material";
import MDButton from "components/MDButton";

function Customer() {
  const [open, setOpen] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);

  const handleRowClick = (params) => {
    setSelectedCustomer(params.row);
    handleOpen();
  };
  const ControlSection = ({ handleOpen, setCategoryData ,user}) => (
    user && user?.role === "admin" ? (<>
      <Grid item xs={12} sm={6} md={6} />
      <Grid item xs={12} sm={6} md={6} textAlign="right">
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
          mb={2}
        >
          {/* <Icon sx={{ mr: 2 }} component={SearchIcon} fontSize="large" /> */}
          <MDButton
            type="button"
            variant="contained"
            color='black'
            circular={true} 
            onClick={() => {
              setOpen(true);
              setSelectedCustomer(null);
            }}
            startIcon={<AddIcon />}
          >
            New Customer
          </MDButton>
        </Box>
      </Grid>
    </>) : <></>
   
  );
  const CustomerDrawer = ({ open, handleClose, selectedCustomer }) => (
    <Drawer
      anchor="right"
      open={open}
      onClose={handleClose}
      PaperProps={{ sx: { width: "50%" } }}
    >
      <Grid
        container
        sx={{ backgroundColor: "#F0F2F5", pb: 3, pt: 2, pl: "5px" }}
      >
        <Grid item xs={12}>
          &nbsp;
        </Grid>
        <Grid item xs={12}>
          <Icon
            sx={{ mr: 2, ml: 2 }}
            component={CustomerIcon}
            fontSize="large"
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" sx={{ ml: 2 }}>
            Customer
          </Typography>
        </Grid>
      </Grid>
      <Box sx={{ width: "100%", minHeight: "70vh" }}>
        <CustomerTabs
          customerData={selectedCustomer}
          handleClose={handleClose}
        />
      </Box>
    </Drawer>
  );
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const user = localStorage.getItem("userDetails") && localStorage.getItem("userDetails") !== "undefined" ? JSON.parse(localStorage.getItem("userDetails")) : {};

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid item xs={12} sm={6} md={3}>
        <Grid display={"flex"} justifyContent={"space-between"}>
          <Typography sx={{fontFamily:"montserrat", color:"#000", fontWeight:"700"}} ml={1}>Customer</Typography> 
          <ControlSection handleOpen={handleOpen} user={user}/>
        </Grid>
        <CustomerLandingPage onRowClick={handleRowClick} />
        <CustomerDrawer
          open={open}
          handleClose={handleClose}
          selectedCustomer={selectedCustomer}
        />
      </Grid>
    </DashboardLayout>
  );
}

export default Customer;
