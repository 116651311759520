import React, { useState, useEffect } from 'react';
import { Box, Grid, Icon, IconButton, Typography } from "@mui/material";
import AgGrid from 'components/MDGrid';
import { ReactComponent as deleteIcon } from "assets/images/deleteIcon.svg";
import { Edit } from '@mui/icons-material';
import { ReactComponent as previewIcon } from "assets/images/previewIcon.svg";
import { ReactComponent as downloadIcon } from "assets/images/downloadIcon.svg";
import qrcodeService from 'services/qrcode-service';

const handleDelete = async (value) => {
    
    window.location.reload(); //To prevent the refetch issue
    try {
        const response = await qrcodeService.getQrCodeDelete(value.uuid);
        console.log(response)
    } catch (res) {
        console.log(res)
        // Handling error is remaining
    }
}

const columns = [
    {
        field: "uuid",
        headerName: "UUID",
        width: 130,
    },
    {
        field: "name",
        headerName: "Brand Name",
        width: 130,
    },
    {
        field: "url",
        headerName: "URL",
        width: 130,
    },
    {
        field: "action",
        headerName: "Action",
        width: 130,
        cellRenderer: (params) => (
            <Grid item xs={2}>
                <Box>
                    <IconButton
                        aria-label="edit"
                    // onClick={(e) => {

                    // }}
                    >
                        <Icon component={previewIcon} fontSize="small" />
                    </IconButton>
                    <IconButton
                        aria-label="download"
                    // onClick={(event) => {
                        
                    // }}
                    >
                        <Icon component={downloadIcon} fontSize="small" />
                    </IconButton>
                    <IconButton
                        aria-label="delete"
                        onClick={(e) => {
                            e.stopPropagation();
                            handleDelete(params.data)
                        }}
                    >
                        <Icon component={deleteIcon} fontSize="small" />
                    </IconButton>
                </Box>
            </Grid>
        ),
    }
];

function QRCodeListing({ onRowClick }) {

    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                console.log(qrcodeService.getQrCodeListing())
                const response = await qrcodeService.getQrCodeListing();
                console.log(response)
                setData(response);
            } catch (res) {
                if (res.hasOwnProperty("message")) {
                    // Handle error with message
                    console.error(res.message);
                } else {
                    // Handle other errors
                    console.error("An unexpected error occurred");
                }
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;

    return (
        <Grid mt={3}>
            <AgGrid
                gridProps={{
                    rowHeight: 60,
                    defaultColDef: {
                        editable: false,
                        filter: true,
                        flex: 1,
                        minWidth: 200,
                    },
                    pivotMode: false,
                    sideBar: {
                        toolPanels: ['columns', 'filters'],
                    },
                    enableRangeSelection: true,
                    onRowClicked: onRowClick, // Use onRowClicked event
                }}
                queryData={data?.results?.filter((d) => !d.isDeleted)}
                columns={columns}
            />
        </Grid>
    );
}

export default QRCodeListing;
