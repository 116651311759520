import React, { useState } from "react";
import ChannelLandingPage from "./channelLandingPage";
import { Grid, Box, Icon, Button, Drawer, Typography } from "@mui/material";
import DashboardLayout from "ui/LayoutContainers/DashboardLayout";
import DashboardNavbar from "ui/Navbars/DashboardNavbar";
import { ReactComponent as SearchIcon } from "assets/images/searchIcon.svg";
import { ReactComponent as CustomerIcon } from "assets/images/customer.svg";
import { Add as AddIcon } from "@mui/icons-material";
import ChannelForm from "./channelForm";
import MDButton from "components/MDButton";

function Channel() {
  const [open, setOpen] = useState(false);
  const [selectedChannel, setSelectedChannel] = useState(null);

  const handleRowClick = (params) => {
    setSelectedChannel(params.row);
    handleOpen();
  };
  const ControlSection = ({ handleOpen, setCategoryData ,user}) => (
    user && user?.role === "admin" ? (<>
      <Grid item xs={12} sm={6} md={6} />
      <Grid item xs={12} sm={6} md={6} mb={2} textAlign="right">
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          {/* <Icon sx={{ mr: 2 }} component={SearchIcon} fontSize="large" /> */}
          <MDButton
                type="button"
                variant="contained"
                color='black'
                circular={true} 
            onClick={() => {
              setOpen(true);
              setSelectedChannel(null);
            }}
            startIcon={<AddIcon />}
          >
            New Channel
          </MDButton>
        </Box>
      </Grid>
    </>) : <></>
   
  );
  const ChannelDrawer = ({ open, handleClose, selectedChannel }) => (
    <Drawer
      anchor="right"
      open={open}
      onClose={handleClose}
      PaperProps={{ sx: { width: "50%" } }}
    >
      <Grid
        container
        sx={{ backgroundColor: "#F0F2F5", pb: 3, pt: 2, pl: "5px" }}
      >
        <Grid item xs={12}>
          &nbsp;
        </Grid>
        <Grid item xs={12}>
          <Icon
            sx={{ mr: 2, ml: 2 }}
            component={CustomerIcon}
            fontSize="large"
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" sx={{ ml: 2 }}>
            Channel
          </Typography>
        </Grid>
      </Grid>
      <Box sx={{ width: "100%", minHeight: "70vh" }}>
        <ChannelForm channelData={selectedChannel} handleClose={handleClose}/>
      </Box>
    </Drawer>
  );
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const user = localStorage.getItem("userDetails") && localStorage.getItem("userDetails") !== "undefined" ? JSON.parse(localStorage.getItem("userDetails")) : {};

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid item xs={12} sm={6} md={3}>
        <Grid display={"flex"} justifyContent={"space-between"}>
          <Typography sx={{fontFamily:"montserrat", color:"#000", fontWeight:"700"}} ml={1}>Channel</Typography>
          <ControlSection handleOpen={handleOpen} user={user}/>
        </Grid>
        <ChannelLandingPage onRowClick={handleRowClick} />
        <ChannelDrawer
          open={open}
          handleClose={handleClose}
          selectedChannel={selectedChannel}
        />
      </Grid>
    </DashboardLayout>
  );
}

export default Channel;
