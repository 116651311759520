import React, { useEffect, useState } from "react";
import { Grid, Box, Icon, Button, Drawer, Typography } from "@mui/material";
import DashboardLayout from "ui/LayoutContainers/DashboardLayout";
import DashboardNavbar from "ui/Navbars/DashboardNavbar";
import { ReactComponent as SearchIcon } from "assets/images/searchIcon.svg";
import { ReactComponent as CustomerIcon } from "assets/images/customer.svg";
import { Add as AddIcon } from "@mui/icons-material";
import PromotionLandingPage from "./promotionLandingPage";
import PromotionTabs from "./promotionTabs";
import MDButton from "components/MDButton";

function Promotion() {
  const [open, setOpen] = useState(false);
  const [selectedPromotion, setSelectedPromotion] = useState(null);
  const [tabValue, setTabValue] = React.useState(0);
  const [brandChange, setBrandChange] = React.useState(false);

  const handleRowClick = (selectedPromotionData) => {
    setSelectedPromotion(selectedPromotionData);
    handleOpen();
  };
  const handleDuplicate = (promotionData,event) => {
    event.stopPropagation();
    const duplicatedData = { ...promotionData, id:null }; // Assuming you have a function to generate a new ID
    setSelectedPromotion(duplicatedData);
    handleOpen();
  };
  const ControlSection = ({ handleOpen, setCategoryData ,user}) => (
 (<>
      <Grid item xs={12} sm={6} md={6} />
      <Grid item xs={12} sm={6} md={6} mb={2} textAlign="right">
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <MDButton
                type="button"
                variant="contained"
                color='black'
                circular={true} 
            onClick={() => {
              setOpen(true);
              setSelectedPromotion(null);
            }}
            startIcon={<AddIcon />}
          >
            New Promotion
          </MDButton>
        </Box>
      </Grid>
    </>)
   
  );
  const PromotionDrawer = ({ open, handleClose, selectedPromotion, setSelectedPromotion, setTabValue, tabValue, setBrandChange, brandChange}) => (
    <Drawer
      anchor="right"
      open={open}
      onClose={handleClose}
      PaperProps={{ sx: { width: "50%" } }}
    >
      <Grid
        container
        sx={{ backgroundColor: "#F0F2F5", pb: 3, pt: 2, pl: "5px" }}
      >
        <Grid item xs={12}>
          &nbsp;
        </Grid>
        <Grid item xs={12}>
          <Icon
            sx={{ mr: 2, ml: 2 }}
            component={CustomerIcon}
            fontSize="large"
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" sx={{ ml: 2 }}>
            Promotion
          </Typography>
        </Grid>
      </Grid>
      <Box sx={{ width: "100%", minHeight: "70vh" }}>
       <PromotionTabs promotionData={selectedPromotion} handleClose={handleClose} setSelectedPromotion={setSelectedPromotion} setTabValue={setTabValue} tabValue={tabValue} setBrandChange={setBrandChange} brandChange={brandChange} />
      </Box>
    </Drawer>
  );
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setBrandChange(false);
    setOpen(false) 
    setTabValue(0)}
  const user = localStorage.getItem("userDetails") && localStorage.getItem("userDetails") !== "undefined" ? JSON.parse(localStorage.getItem("userDetails")) : {};

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid item xs={12} sm={6} md={3}>
        <Grid display={"flex"} justifyContent={"space-between"}>
          <Typography sx={{fontFamily:"montserrat", color:"#000", fontWeight:"700"}} ml={1}>Promotion</Typography>
          <ControlSection handleOpen={handleOpen} user={user}/>
        </Grid>
        <PromotionLandingPage onRowClick={handleRowClick} onDuplicate={handleDuplicate} user={user}/>
        <PromotionDrawer
          open={open}
          handleClose={handleClose}
          selectedPromotion={selectedPromotion}
          setSelectedPromotion={(promotion) => {
            setSelectedPromotion(promotion)
          }}
          setTabValue={setTabValue}
          tabValue={tabValue}
          brandChange={brandChange}
          setBrandChange={setBrandChange}
        />
      </Grid>
    </DashboardLayout>
  );
}

export default Promotion;
