import React, { useState } from "react";
import { Button } from "@mui/material";
import { AvailableTabs } from "../tableData";
import SingleProductFilterForm from "./FilterForms/SingleProductFilterForm";
import BulkProductFilterForm from "./FilterForms/BulkProductFilterForm";
import MDButton from "components/MDButton";

export default function ProductFilter({
  singleProductFilters,
  setSingleProductFilters,
  setBulkProductFilters,
  bulkProductFilters,
  selectedTab,
}) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <MDButton
        variant="contained"
        onClick={handleClickOpen}
        color={"black"}
        circular={true}
        // sx={{
        //   border: "1px solid #E91E63",
        //   backgroundColor: "#fff",
        //   color: "#E91F63",
        //   marginRight: "10px",
        //   borderRadius: "5rem",

        //   "&:focus": {
        //     backgroundColor: "#fff !important",
        //     color: "#E91F63 !important",
        //   },
        //   "&:hover": {
        //     color: "#fff !important",
        //     backgroundColor: "#E91E63 !important",
        //   },
        // }}
      >
        Filter
      </MDButton>

      {selectedTab === AvailableTabs.singleProduct ? (
        <SingleProductFilterForm
          setFilters={setSingleProductFilters}
          filters={singleProductFilters}
          setOpen={setOpen}
          open={open}
        />
      ) : (
        <BulkProductFilterForm
          setFilters={setBulkProductFilters}
          filters={bulkProductFilters}
          setOpen={setOpen}
          open={open}
        />
      )}
    </>
  );
}
