import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  Grid,
  TextField,
  FormControlLabel,
  Switch,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { dashboardServiceClient } from "graphql/client";
import { useMutation, useQuery } from "@apollo/client";
import {
  GET_ACTIVITY_LIST,
  CREATE_ACTIVITY,
  UPDATE_ACTIVITY,
} from "services/activity-service";
import { GET_MASTER_ACTIVITY_LIST } from "services/master-activity-service";
import { BRANDS_LIST } from "services/brand-service";
import { GET_STORES_LIST } from "services/store-service";
import { useNotification } from "context";
import { ReactComponent as ArrowDropDownIcon } from "assets/images/Arrow-Down.svg";
import FileUploader from "components/MDFileUpload";
import Loader from "components/MDLoader";
import MDButton from "components/MDButton";

const validationSchema = Yup.object().shape({
  activityName: Yup.string().required("Activity Name is required"),
  brand: Yup.string().required("Name is required"),
  store: Yup.string().required("Name is required"),
  status: Yup.string().required("Status is required"),
  imagePath: Yup.array(),
});

const useMasterActivityMutations = (
  activityData,
  handleClose,
  setNotification
) => {
  const mutationOptions = {
    client: dashboardServiceClient,
    refetchQueries: [
      {
        query: GET_ACTIVITY_LIST,
        variables: {
          listBrandSpaceReadinessFilter: {
            take: 20,
            skip: 0,
            search: "",
            filter: {},
          },
        },
      },
    ],
  };
  const [createActivityMutation] = useMutation(
    CREATE_ACTIVITY,
    mutationOptions
  );

  const [updateActivityMutation] = useMutation(
    UPDATE_ACTIVITY,
    mutationOptions
  );
  const handleSubmit = (values) => {
    const payload = {
      imagePath: values?.imagePath,
      status: values.status,
      isDeleted: false,
      storeId: values.store,
      brandId: values.brand,
      activityId: values.activityName,
    };
    const mutation =
      activityData && activityData?.id
        ? updateActivityMutation
        : createActivityMutation;
    const mutationInput =
      activityData && activityData?.id
        ? {
            updateBrandSpaceReadinessInput: { id: activityData.id, ...payload },
          }
        : { createBrandSpaceReadinessInput: payload };

    mutation({ variables: mutationInput })
      .then((res) => {
        console.log(
          res.data[
            activityData && activityData.id
              ? "Updated the  Activity"
              : "Created the  Activity"
          ]
        );
        handleClose();
        setNotification({
          color: "success",
          isVisible: true,
          message:
            activityData && activityData.id
              ? " Activity  Updated successfully"
              : " Activity  Created successfully",
        });
      })
      .catch((error) => {
        console.error("error:", error.message);
        setNotification({
          color: "error",
          isVisible: true,
          message: error.message || "Something went wrong",
        });
      });
  };
  return handleSubmit;
};
const ActivityForm = ({ handleClose, activityData, user }) => {
  const isAdmin = user && user?.role === "admin" ? true : false;
  // Activity List
  const {
    loading: activityLoading,
    error: activityError,
    data: activityList,
    refetch,
  } = useQuery(GET_MASTER_ACTIVITY_LIST, {
    client: dashboardServiceClient,
    variables: { listMasterActivityFilter: { take: 20, skip: 0 } },
  });

  // Store List
  const {
    loading: storeLoading,
    error: storeError,
    data: storeList,
  } = useQuery(GET_STORES_LIST, {
    client: dashboardServiceClient,
    variables: { listStoreFilter: { take: 100, skip: 0 } },
  });
  const { loading, data, error } = useQuery(BRANDS_LIST, {
    client: dashboardServiceClient,
    variables: {
      filter:
          user && user?.role === "admin"
            ? {
              isDeleted: false
            }
            : {
                id: user?.userId,
              },
    },
  });

  const { setNotification } = useNotification();
  const handleSubmit = useMasterActivityMutations(
    activityData,
    handleClose,
    setNotification
  );

  const carouselImages =
    (activityData &&
      activityData.images &&
      activityData.images.length &&
      activityData.images.map((item) => item.path)) ||
    [];
  useEffect(() => {
    if (refetch) {
      refetch();
    }
  }, []);
  if (activityLoading && storeLoading && loading) return <Loader />;
  if (activityError) return <p>Error...</p>;
  return (
    <Formik
      initialValues={{
        activityId: activityData?.id || "",
        store: activityData?.store?.id || "",
        activityName: activityData?.activity?.isDeleted == false ? activityData?.activity?.id : '' || "",
        brand: activityData?.brand?.id || "",
        status: activityData?.status || "",
        imagePath:
          activityData && activityData.images && activityData.images.length
            ? carouselImages
            : [],
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        handleSubmit(values);
      }}
    >
      {({
        values,
        setFieldValue,
        setFieldTouched,
        validateField,
        errors,
        touched,
        isSubmitting,
        handleChange,
      }) => (
        <Form>
          <Grid container spacing={3} p={2}>
            <Grid item xs={12}>
              <Field
                as={TextField}
                label="Activity ID"
                disabled
                fullWidth
                name="activityId"
                error={touched.activityId && Boolean(errors.activityId)}
                helperText={touched.activityId && errors.activityId}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>Current Activity</InputLabel>
                <Select
                  as={Select}
                  disabled={!isAdmin}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="activityName"
                  label="Current Activity"
                  IconComponent={() => (
                    <ArrowDropDownIcon style={{ marginRight: "18px" }} />
                  )}
                  sx={{ height: 40 }}
                  value={values?.activityName || []}
                  onChange={(e) => {
                    setFieldValue("activityName", e.target.value);
                    setFieldTouched(`activityName`, false, false);
                  }}
                  helperText={touched?.activityName && errors?.activityName}
                  error={touched?.activityName && Boolean(errors?.activityName)}
                >
                  {activityList?.masterActivity?.results
                    ?.filter((item) => !item.isDeleted)
                    .map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                </Select>
                {touched?.activityName && errors?.activityName && (
                  <Typography fontSize={12} color="error">
                    {errors?.activityName}
                  </Typography>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>Store</InputLabel>
                <Select
                  as={Select}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="store"
                  label="Store"
                  disabled={!isAdmin}
                  IconComponent={() => (
                    <ArrowDropDownIcon style={{ marginRight: "18px" }} />
                  )}
                  sx={{ height: 40 }}
                  value={values?.store || []}
                  onChange={(e) => {
                    setFieldValue("store", e.target.value);
                    setFieldTouched(`store`, false, false);
                  }}
                  helperText={touched?.store && errors?.store}
                  error={touched?.store && Boolean(errors?.store)}
                >
                  {storeList?.stores?.results
                    ?.filter((item) => item.status === "Active")
                    .map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                </Select>
                {touched?.store && errors?.store && (
                  <Typography fontSize={12} color="error">
                    {errors?.store}
                  </Typography>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>Brand</InputLabel>
                <Select
                  as={Select}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="brand"
                  label="Brand"
                  disabled={!isAdmin}
                  IconComponent={() => (
                    <ArrowDropDownIcon style={{ marginRight: "18px" }} />
                  )}
                  sx={{ height: 40 }}
                  value={values?.brand || []}
                  onChange={(e) => {
                    setFieldValue("brand", e.target.value);
                    setFieldTouched(`brand`, false, false);
                  }}
                  helperText={touched?.brand && errors?.brand}
                  error={touched?.brand && Boolean(errors?.brand)}
                >
                 {data?.brandlist?.results
                  ?.filter((item) => item.brandStatus === "Live")
                  ?.map((brand) => {
                    return (
                      <MenuItem key={brand.id} value={brand.id}>
                        {brand ? brand.name : "Unknown Brand"}
                      </MenuItem>
                    );
                  })}
                </Select>
                {touched?.brand && errors?.brand && (
                  <Typography fontSize={12} color="error">
                    {errors?.brand}
                  </Typography>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Typography fontSize={12} fontWeight={"bold"}>
                Upload Image Carousal
              </Typography>
              <FileUploader
                disabled={!isAdmin}
                fieldName={"Upload Image Carousal"}
                isMultiple={true}
                preLoadedFile={!!values.imagePath ? values.imagePath : null}
                signedUrlCallback={(newUrl, index) => {
                  // console.log("coverpic >>", newUrl, index);
                  const updatedImagePathArray = [...values.imagePath];
                  if (newUrl) {
                    // Update the URL at the given index
                    //   console.log("new url >>", newUrl);
                    updatedImagePathArray[index] = newUrl;
                  } else {
                    // Delete the URL at the given index
                    //   console.log("delete item ", index);
                    updatedImagePathArray.splice(index, 1);
                  }
                  console.log(updatedImagePathArray);
                  setFieldValue("imagePath", updatedImagePathArray);
                }}
                isFieldValid={touched?.imagePath && errors?.imagePath}
              />
              {touched?.imagePath && errors?.imagePath && (
                <Typography fontSize={12} color="error">
                  {errors?.imagePath}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                <Select
                  as={Select}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="status"
                  label="Status"
                  disabled={!isAdmin}
                  IconComponent={() => (
                    <ArrowDropDownIcon style={{ marginRight: "18px" }} />
                  )}
                  sx={{ height: 40 }}
                  value={values.status}
                  onChange={(e) => {
                    setFieldValue("status", e.target.value);
                    setFieldTouched(`status`, true, false);
                    validateField(`status`, e.target.value);
                  }}
                  error={touched.status && Boolean(errors.status)}
                  helperText={touched.status && errors.status}
                >
                  <MenuItem value="new">New</MenuItem>
                  <MenuItem value="inprogress">In Progress</MenuItem>
                  <MenuItem value="completed">Completed</MenuItem>
                  <MenuItem value="delayed">Delayed</MenuItem>
                </Select>
                {touched.status && errors.status ? (
                  <Typography fontSize={12} color="error">
                    {errors.status}
                  </Typography>
                ) : null}
              </FormControl>
            </Grid>
            {isAdmin ? (
              <Grid container spacing={1}>
                <Grid container xs={12} sx={{ mt: 4, ml: 2, pb: 2 }} flexDirection={"row-reverse"} gap={3}>
                <MDButton
                  type="submit"
                  variant="contained"
                  circular={true}
                  color="black"
                  >
                    Save
                  </MDButton>
                  <MDButton color="black" circular={true} variant={"outlined"} onClick={handleClose}>
                    Cancel
                  </MDButton>
                </Grid>
              </Grid>
            ) : (
              <></>
            )}
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default ActivityForm;
