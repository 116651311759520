import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
// import DeviceMapping from "./deviceMapping";
import Devices from "./devices";
import AdsDeviceMap from "./adsDeviceMap"
import VideoPage from "./videoMaster";
import ImagesPage from "./imageMaster";
import PlaylistsPage from "./playlistMaster";
import AssignedDevicesPage from "./assignedDevicesMaster";
import FixturesPage from "./assignedFixturesMaster";
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ px: 2 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function AdsTabs({ tabValue, setTabValue, user, Brand }) {

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <>
      <Tabs sx={{ px: 2 }} value={tabValue} onChange={handleChange} aria-label="ant example">
        <Tab sx={{ width: '150px', mr: 1, fontWeight: 300, color: 'black.main' }} label="Assign Devices" {...a11yProps(0)} />
        <Tab sx={{ width: '150px', mr: 1, fontWeight: 300, color: 'black.main' }} label="Assign Fixtures" {...a11yProps(1)} />
        <Tab sx={{ width: '150px', mr: 1, fontWeight: 300, color: 'black.main' }} label="Playlists" {...a11yProps(2)} />
        <Tab sx={{ width: '150px', mr: 1, fontWeight: 300, color: 'black.main' }} label="Videos" {...a11yProps(3)} />
        <Tab sx={{ width: '150px', mr: 1, fontWeight: 300, color: 'black.main' }} label="Images" {...a11yProps(4)} />
      </Tabs>
      <CustomTabPanel value={tabValue} index={0}>
        <AssignedDevicesPage  user={user} />
        {/* <Devices
          handleTabChange={handleChange}
          user={user}
          selectedBrandId={selectedBrandId}
          Brand={Brand}
        /> */}
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={1}>
        <FixturesPage user={user}  Brand={Brand} tabValue={tabValue}/>
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={2}>
        <PlaylistsPage
          tabValue={tabValue}
          user={user}
        />
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={3} >
        <VideoPage
          user={user}
          Brand={Brand}
          tabValue={tabValue}
        />
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={4} >
        <ImagesPage
          // user={user}
          // Brand={Brand}
          // tabValue={tabValue}
        />
      </CustomTabPanel>
    </>
  );
}
