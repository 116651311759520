import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import Chip from "@mui/material/Chip";
import { Grid, Pagination as MuiPagination, Box, TablePagination, IconButton, Icon } from '@mui/material';
import dayjs from 'dayjs';
import { LIST_ALL_DEVICE, LIST_ALL_DEVICE_BULK_UPLOAD, REMOVE_DEVICE, REMOVE_DEVICE_BULK_UPLOAD, UPDATE_DEVICE, UPDATE_DEVICE_BULK_UPLOAD } from '../../services/advertisment-service';
import { vmServiceClient } from 'graphql/client';
import Loader from "components/MDLoader";
import { SearchContext, FilterContext } from "context";
import { useContext, useEffect } from 'react';
import { ReactComponent as deleteIcon } from "assets/images/deleteIcon.svg";
import { useQuery, useMutation } from "@apollo/client";
import { DropDownSelectionContext } from "./";
import { GET_STORES_LIST_NEW } from "services/store-service";
import { dashboardServiceClient } from "graphql/client";
import { getFlatIdMap } from "./utils";

const columns = (handleDeleteClick, handleStatusChange, storeIdMap) =>  [
    {
        field: "code",
        headerName: "Device Code",
        width: 250,
        // headerAlign: 'center',
    },
    {
        field: "deviceName",
        headerName: "Device Name",
        width: 250,
    },
    {
        field: "fixture",
        headerName: "Fixture",
        width: 250,
        valueGetter: (params) => params?.row?.fixture?.code,
        // headerAlign: 'center',
    },
    {
        field: "storId",
        headerName: "Store Name",
        width: 250,
        valueGetter: (params) => storeIdMap[params?.row?.storeId]?.name,
        // headerAlign: 'center',
    },
    {
        field: "location",
        headerName: "Location",
        width: 230,
        valueGetter: (params) => storeIdMap[params?.row?.locationId]?.locationName,
        
    },
    {
        field: "subLocation",
        headerName: "Sub-Location",
        width: 230,
        valueGetter: (params) => storeIdMap[params?.row?.subLocationId]?.name,
    },
    {
        field: "type",
        headerName: "Type",
        width: 230,
        valueGetter: (params) => storeIdMap[params?.row?.subLocationTypeId]?.name,
    },
    {
        field: "isShared",
        headerName: "Shared",
        width: 200,
        valueGetter: (params) => params?.row?.isShared === true ? "Yes" : "No",
    },
    {
        field: "status",
        headerName: "Status",
        width: 260,
        renderCell: (params) => {
            // return <Chip label={params.row.isActive === true ? "Active" : "Inactive"} color={params.row.isActive === true ? "success" : "error"} size="small" />;
            return (
                <select
                  onChange={(e) =>{
                      handleStatusChange(params.row, e.target.value)
                      e.stopPropagation(); //
                  }
                  }
                  defaultValue={params.value}
                  style={{ width: "100%", padding: "10px", borderRadius: "5px" }}
                >
                  <option value="ACTIVE">ACTIVE</option>
                  <option value="INACTIVE">INACTIVE</option>
                  <option value="WORKING">WORKING</option>
                  <option value="NOT_WORKING">NOT_WORKING</option>
                  <option value="ERROR">ERROR</option>
                </select>
              );
        },
    },
    {
        field: 'actions',
        headerName: 'Actions',
        width: 140,
        sortable: false,
        renderCell: (params) => (
            <IconButton aria-label="delete" >
                <Icon component={deleteIcon} onClick={(e) => handleDeleteClick(e , params.row.uuid)} fontSize="small" />
            </IconButton>
        )
    },
];
function DeviceLandingPage({ onRowClick }) {
    const { searchKeyword, setSearchKeyword } = useContext(SearchContext);
    const { selectedStore, selectedLocation, selectedSubLocation, selectedAssetTypeBatchCode, selectedAssetTypeCode} = useContext(DropDownSelectionContext);
    const [paginationModel, setPaginationModel] = React.useState({
        page: 0,
        pageSize: 20,
    });
    const [isLoading, setIsLoading] = React.useState(false);

    const {
        loading: storeLoading,
        error: storeError,
        data: storeList,
    } = useQuery(GET_STORES_LIST_NEW, {
        client: dashboardServiceClient,
        variables: { input:{take: 100, skip: 0} },
    });

    const storeIdMap = React.useMemo(()=>getFlatIdMap(storeList?.stores?.results?.filter(store=>!store.isDeleted) || []),[storeList])

    const { loading, error, data, refetch } = useQuery(LIST_ALL_DEVICE, {
        client: vmServiceClient,
        variables: {
            page: paginationModel.page + 1,
            limit: paginationModel.pageSize,
            sortBy: "updatedAt",
            order: "DESC",
            "searchCols":["deviceName"],
            "relations":["fixture"],
            "searchBy":searchKeyword ? [searchKeyword]: [],
            "filter":{
                "storeId": selectedStore?.id,
                "locationId": selectedLocation?.id,
                "subLocationId": selectedSubLocation?.id,
                "subLocationTypeId": selectedAssetTypeBatchCode,
                "assetTypeCode": selectedAssetTypeCode,
            }
        },
        fetchPolicy: "network-only",
        notifyOnNetworkStatusChange: true,
        onCompleted: (data) => {
            // data?.findAllDevices?.results = data?.findAllDevices?.results?.filter(device=>{
            //     if(selectedStore && device.storeId!=selectedStore.id) return false;
            //     if(selectedLocation && device.locationId!=selectedLocation.id) return false;
            //     if(selectedSubLocationType && device.subLocationId!=selectedSubLocationType.id) return false;
            //     if(selectedSubLocationType && device.subLocationTypeId!=selectedSubLocationType.id) return false;
            //     return true;
            // }
            // )
            setRowCount(data?.findAllDevices?.total);
        },
    });
    const [removeDevice, { loading: removing, error: removeError }] =
    useMutation(REMOVE_DEVICE, {
        client: vmServiceClient,
        refetchQueries: [
            {
                query: LIST_ALL_DEVICE,
                variables: {
                    page: paginationModel.page + 1,
                    limit: paginationModel.pageSize,
                    "sortBy": "updateAt",
                    "order": "DESC",
                },
                fetchPolicy: "network-only",
            },
        ],
    });
    const [updateDevice] = useMutation(
        UPDATE_DEVICE, {
            client: vmServiceClient,
            refetchQueries: [
                {
                    query: LIST_ALL_DEVICE,
                    variables: {
                        page: paginationModel.page + 1,
                        limit: paginationModel.pageSize,
                        "sortBy": "updateAt",
                        "order": "DESC",
                    },
                    fetchPolicy: "network-only",
                },
            ],
        }
    )
    const handleDeleteClick = async (e, deviceId) => {
        e.stopPropagation();
        setIsLoading(true);
        try {
            await removeDevice({
                variables: { id: deviceId },
            });
            await refetch();
        } catch (error) {
            console.error("Failed to remove device:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleStatusChange = async (device, statusValue) => {
        setIsLoading(true);
        try {
            await updateDevice({
                variables: {
                    input: {
                        uuid:device?.uuid,
                        storeId:device?.storeId,
                        locationId:device?.locationId,
                        subLocationId:device?.subLocationId,
                        subLocationTypeId:device?.subLocationTypeId,
                        assetTypeCode:device?.assetTypeCode,
                        status: statusValue
                    }},
            });
            await refetch();
        } catch (error) {
            console.error("Failed to update device:", error);
        } finally {
            setIsLoading(false);
        }
    }   
    const [rowCount, setRowCount] = React.useState(data?.findAllDevices?.total || 0);

    React.useEffect(() => {
        setRowCount((prevRowCount) =>
            data?.findAllDevices?.total !== undefined
                ? data?.findAllDevices?.total
                : prevRowCount,
        );
    }, [data?.findAllDevices?.total, setRowCount]);
   
    // useEffect(() => {
    //     refetch();
    // }, [paginationModel, refetch]);
    const allDevices = data?.findAllDevices?.results;
    
    if (loading || removing || isLoading)
        return (
            <>
                <Loader />
            </>
        );
    if (error || removeError) return <p>Error: {error.message}</p>;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    return (
        <DataGrid
            autoHeight
            rows={allDevices || []}
            columns={columns(handleDeleteClick, handleStatusChange, storeIdMap)}
            rowCount={rowCount}
            pageSizeOptions={[10, 20, 50, 100]}
            getRowId={(row) => row.uuid}
            onRowClick={(params, event)=>{
                if (event.target.tagName === 'SELECT' || event.target.closest('select')) {
                    // Clicked on select dropdown, do nothing or handle dropdown actions
                    return;
                  }
                onRowClick(params)
            }}
            loading={loading || isLoading}
            onPageChange={handleChangePage}
            paginationModel={paginationModel}
            paginationMode="server"
            onPaginationModelChange={setPaginationModel}
        />
    );
}

export default DeviceLandingPage;
